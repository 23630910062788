import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";

import api from "../../../services/api";
import Create from "./create";
import { FaLinkedin } from "react-icons/fa";
import { TbWorld } from "react-icons/tb";
import { HiEye } from "react-icons/hi2";

export default () => {
  const [filter, setFilter] = useState({ search: "", status: "active" });
  const [jobs, setJobs] = useState(null);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    load();
  }, [filter]);

  async function load() {
    setLoading(true);
    const newFilter = { ...filter };
    // if (organisation) newFilter.organisation_id = organisation._id;
    // newFilter.status = "active";
    const { data, total } = await api.post("/job/search", newFilter);
    setJobs(data);
    setTotal(total);
    setLoading(false);
  }

  if (loading) return <div>Loading...</div>;

  return (
    <div className="p-1">
      <section>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <SelectStatus value={filter.status} onChange={(status) => setFilter({ ...filter, status })} />
          <Create />
        </div>
      </section>
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Job title
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Location
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Client
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Project Name
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Contract
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Created at
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Applicants
            </th>
            <th scope="col" className="flex flex-row items-center  px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Job posts {"("}
              <HiEye className="h-5 w-5 text-gray-300 " aria-hidden="true" />
              {")"}
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Account manager
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {(jobs || []).map((job, index) => (
            <Job job={job} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

const SelectStatus = ({ value, onChange }) => {
  return (
    <select className="form-select block w-full" value={value} onChange={(e) => onChange(e.target.value)}>
      <option value="">All</option>
      <option value="active">Active</option>
      <option value="inactive">Archived</option>
    </select>
  );
};

const Job = ({ job }) => {
  const [job_post, setJob_post] = useState(null);
  async function get() {
    const { data } = await api.post("/jobpost/search", { job_id: job._id });
    setJob_post(data);
  }

  useEffect(() => {
    get();
  }, []);

  return (
    <tr key={job._id} className="group hover:bg-gray-100">
      <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
        <div className="flex items-center group-hover:underline">
          <Link to={`/job/${job._id}`} target="_blank">
            {job.title}
          </Link>
        </div>
      </td>
      <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500">{job.location}</td>
      <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500">{job.client}</td>
      <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500">{job.project_name}</td>
      <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500">{job.contract_type}</td>
      <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500">{job.created_at.substring(0, 10)}</td>
      <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500 "> {job.applicant_count_v2?.applicant || 0}</td>
      <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500 ">
        {job_post?.map((job_post, index) => (
          <p key={index}>
            - {job_post.channel_name} {"("}
            {job_post.views}
            {")"}
          </p>
        ))}
      </td>
      <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500">{job.user_name}</td>
    </tr>
  );
};
