import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import api from "../../../services/api";

export default ({ channel, setChannel }) => {
  return (
    <div className="flex flex-col gap-4 pt-2 ">
      <div className="bg-white rounded-2xl p-12 pb-6">
        <div className="grid grid-cols-1 gap-4">
          <div className="text-[18px] text-[#212325] font-medium mt-2">{channel.name}</div>
          <div className="text-[14px] text-[#212325] font-medium mt-2">
            <a href={channel.url} target="_blank">
              {channel.url}
            </a>
          </div>
          <div
            className="text-[14px] text-[#212325] font-medium mt-2 cursor-pointer"
            onClick={() => {
              navigator.clipboard.writeText(channel.login);
              toast.success("Copied to clipboard!");
            }}>
            {channel.login}
          </div>
          <div
            className="text-[14px] text-[#212325] font-medium mt-2 cursor-pointer"
            onClick={() => {
              navigator.clipboard.writeText(channel.password);
              toast.success("Copied to clipboard!");
            }}>
            {channel.password}
          </div>
          <div className="text-[14px] text-[#212325] font-medium mt-2">{channel.description}</div>
        </div>
      </div>
      <Statistiques channel={channel} />
      <div className="italic text-sm mt-3">created at {new Date(channel.created_at).toLocaleString()}</div>
    </div>
  );
};

const Statistiques = ({ channel }) => {
  const [items, setItems] = useState([]);
  useEffect(() => {
    get();
  }, []);

  async function get() {
    const { data } = await api.post("/jobpost/search", { channel_id: channel._id });
    setItems(data);
  }

  console.log("items", items);
  return (
    <div>
      <div>Statistiques</div>
      <div className="flex flex-col gap-4 p-2 ">
        <div className="p-12 pb-6 bg-white rounded-2xl shadow-md">
          <table className="w-full text-left table-collapse">
            <thead>
              <tr className="bg-gray-200">
                <th className="px-4 py-2">Job name</th>
                <th className="px-4 py-2">Clicks</th>
              </tr>
            </thead>
            <tbody>
              {items.map((item) => (
                <tr key={item._id} className="hover:bg-gray-100">
                  <td className="border px-4 py-2">{item.job_title}</td>
                  <td className="border px-4 py-2">{item.views}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
