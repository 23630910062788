import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import moment from "moment";

import Loader from "../../components/loader";
import Modal from "../../components/modal";

import api from "../../services/api";

export default () => {
  const [items, setItems] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    get();
  }, []);

  async function get() {
    const { data } = await api.get("/jobchannel");
    setItems(data);
  }

  if (!items) return <Loader />;

  return (
    <div>
      <div className="p-4">
        <Create />
        <div className="bg-white rounded-[10px] mt-3">
          <div className="flex items-center gap-2 py-[14px] border-b border-[#E5EAEF]">
            <span className="text-[18px] font-bold text-[#212325] pl-[20px]">Channels</span>
            <span className="text-sm font-normal">({items.length})</span>
          </div>
          <table className="w-full">
            <thead className="border-b border-[#E5EAEF]">
              <tr className="text-[14px] text-[#212325] font-semibold text-left">
                <th className="py-3 px-5">Name</th>
                <th className="py-3 px-5">Category</th>
                <th className="py-3 px-5">Created At</th>
              </tr>
            </thead>
            <tbody>
              {items.map((hit) => {
                return (
                  <tr
                    className="hover:bg-[#f6f6f6] border-b border-[#dee2e6] text-[#676D7C] cursor-pointer text-[14px] font-medium"
                    key={hit._id}
                    onClick={() => navigate(`/jobchannel/${hit._id}`)}>
                    <td className="py-3 px-5">{hit.name}</td>
                    <td className="py-3 px-5">{hit.category}</td>
                    <td className="py-3 px-5">{moment(hit.created_at).format("DD/MM/YYYY, HH:MM")}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const Create = () => {
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({});

  const navigate = useNavigate();

  async function onCreate() {
    const res = await api.post("/jobchannel", values);
    toast.success("Created!");
    navigate(`/jobchannel/${res.data._id}`);
  }
  return (
    <div style={{ marginBottom: 10 }}>
      <div className="text-right">
        <button className="btn btn-primary" onClick={() => setOpen(true)}>
          Create a channel
        </button>
      </div>

      <Modal isOpen={open} className="max-w-xl w-full p-6" onClose={() => setOpen(false)}>
        <div className="w-full mb-6">
          <div className="text-sm font-medium mb-2">Channel (*)</div>
          <input className="projectsInput" value={values.name} onChange={(e) => setValues({ ...values, name: e.target.value })} />
        </div>

        <button className="btn btn-primary mt-4" onClick={onCreate}>
          Create
        </button>
      </Modal>
    </div>
  );
};
