import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

import Loader from "./loader";

export default ({ header, sort, total, onSort, loading, children, fixed = false, sticky = false, height = "h-full" }) => {
  if (total === 0 && !loading) return <div className="w-full px-3 py-5">No data found</div>;

  if (loading) {
    return (
      <div className="w-full flex justify-center items-center">
        <Loader />
      </div>
    );
  }

  return (
    <div className={`w-full border rounded overflow-x-auto no-scrollbar ${height}`}>
      <table className={`w-full ${fixed ? "table-fixed" : ""}`}>
        <thead className={`text-left ${sticky ? "sticky top-0 z-10 bg-white shadow-sm" : ""}`}>
          <tr className="border-b">
            {header.map((item, index) => {
              return (
                <th key={index} className="p-2" colSpan={item.colSpan || 1}>
                  <div className={`flex items-center gap-2 ${item.position === "right" ? "justify-end" : item.position === "center" ? "justify-center" : "justify-start"}`}>
                    <h3 className="text-sm font-semibold">{item.title}</h3>
                    {item.key && (
                      <button
                        className="h-4 flex flex-col justify-center"
                        onClick={() => {
                          if (!item.key) return;
                          if (!onSort) return;
                          onSort(item.key);
                        }}>
                        {sort && sort[item.key] === 1 ? (
                          <IoIosArrowUp className="text-secondary" />
                        ) : sort && sort[item.key] === -1 ? (
                          <IoIosArrowDown className="text-secondary" />
                        ) : (
                          <>
                            <IoIosArrowUp className="text-secondary" />
                            <IoIosArrowDown className="text-secondary" />
                          </>
                        )}
                      </button>
                    )}
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className="relative">{children}</tbody>
      </table>
    </div>
  );
};
