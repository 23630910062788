import React from "react";

export default ({
  value, onChange, className
}) => {

  return (
    <select
      className={className}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      >
      <option value="">All types</option>
      {["prospection", "startup-project", "startup-invest", "admin"].map((type) => {
        return <option key={type} value={type}>{type}</option>;
      })}
    </select>
  );
};