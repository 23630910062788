import React, { useEffect, useState } from "react";

import api from "../../../services/api";

//value should be user ID
export default ({ value, userName, onChange, disabled = false, placeholder = "All users", name = "user" }) => {
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState(userName ?? "");

  useEffect(() => {
    searchUser();
  }, []);

  async function searchUser() {
    const res = await api.post("/user/search", { name: search });
    setUsers(res.data.users);
  }

  return (
    <div>
      <div>
        <input
          list="user"
          value={search || userName}
          className={`w-full border border-gray-300 bg-white text-[14px] text-[#212325] font-normal py-[9px] px-[14px] shadow-sm"`}
          placeholder={placeholder}
          onChange={(e) => {
            setSearch(e.target.value);
            if (e.target.value === "all") {
              return onChange();
            }
            const f = users.find((f) => e.target.value === f.name);
            if (f) onChange(f);
            if (!e.target.value) onChange({});
          }}
        />
        <datalist
          id="user"
          name={name}
          disabled={disabled}
          className="w-[180px] bg-[#FFFFFF] text-[12px] text-[#212325] font-semibold py-[4px] px-[4px] rounded-[5px] border-r-[16px] border-[transparent] cursor-pointer shadow-sm"
          value={value && value.name}>
          <option value="" disabled selected>
            Select user
          </option>
          {users
            .sort(function (a, b) {
              if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
              if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
              return 0;
            })
            .map((e) => (
              <option key={e.name} value={e.name} />
            ))}
        </datalist>
      </div>
    </div>
  );
};
