import React, { useState } from "react";
import validator from "validator";
import { Link, Navigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";

import { setUser } from "../../redux/auth/actions";

import api from "../../services/api";
import Loader from "../../components/loader";

const Signin = () => {
  const [values, setValues] = useState({ email: "", password: "" });
  const [errors, setErrors] = useState({ email: "", password: "" });
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();

  const dispatch = useDispatch();
  const user = useSelector((state) => state.Auth.user);
  const redirect = searchParams.get("redirect");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!values.email) return setErrors({ ...errors, email: "This field is Required" });
    if (!values.password) return setErrors({ ...errors, password: "This field is Required" });

    setLoading(true);
    try {
      const { user, token } = await api.post(`/user/signin`, values);
      if (token) api.setToken(token);
      if (user) dispatch(setUser(user));
    } catch (error) {
      console.error(error);
      toast.error("Error while signing in");
    }
    setLoading(false);
  };

  if (user) return <Navigate to={redirect || "/"} />;

  return (
    // Auth Wrapper
    <div className="w-screen h-screen flex justify-center items-center">
      <form className="py-12 px-4 w-full md:w-1/2" onSubmit={handleSubmit}>
        <div className="font-[Helvetica] text-center text-[32px] font-semibold	mb-[15px]">ACCOUNTING</div>

        <div className="space-y-6">
          <div className="space-y-2">
            <label className="text-lg font-semibold" htmlFor="email">
              E-mail
            </label>
            <input
              className="input"
              validate={(v) => !validator.isEmail(v) && "Invalid email address"}
              name="email"
              type="email"
              id="email"
              value={values.email}
              onChange={(e) => setValues({ ...values, email: e.target.value })}
            />
            <p className="text-sm text-red-700">{errors.email}</p>
          </div>
          <div className="space-y-2">
            <label className="text-lg font-semibold" htmlFor="password">
              Password
            </label>
            <input className="input" name="password" type="password" id="password" value={values.password} onChange={(e) => setValues({ ...values, password: e.target.value })} />
            <p className="text-sm text-red-700">{errors.password}</p>
            <div className="text-right text-sm">
              <Link to="/auth/forgot">Forgot password ?</Link>
            </div>
          </div>
          <div className="w-full flex flex-col gap-4 justify-center items-center">
            <button className="blue-btn w-56" type="submit">
              {loading ? <Loader size="small" color="white" /> : "Sign in"}
            </button>

            <div className="w-full flex items-center my-2">
              <div className="border-b border-gray-200 w-full" />
              <span className="px-2 text-gray-400 text-xs">or</span>
              <div className="border-b border-gray-200 w-full" />
            </div>
            <div className="w-full flex justify-center">
              <Link className="empty-btn w-56" to={`/auth/signup`}>
                Sign up
              </Link>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Signin;
