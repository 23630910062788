import React, { useEffect, useState, useContext } from "react";
import toast from "react-hot-toast";
import queryString from "query-string";
import api from "../../../services/api";

export const AppContext = React.createContext({});

export default ({ children, workshopId }) => {
  const [workshop, setWorkshop] = useState(null);
  const [userWorkshop, setUserWorkshop] = useState(null);
  const [index, setIndex] = useState(0);
  const [mode, setMode] = useState("normal");

  // const [answers, setAnswers] = useState(userWorkshop?.answers || {});
  const [unsaved, setUnsaved] = useState(false);

  useEffect(() => {
    if (!workshop) return;
    window.history.replaceState(null, "New Page Title", `/learn/${workshop._id}?index=${index}`);
  }, [index]);

  useEffect(() => {
    (async () => {
      const { data } = await api.get(`/workshop/${workshopId}`);
      setWorkshop(data);
      const { data: Wu } = await api.get(`/workshop_user/${workshopId}`);
      setUserWorkshop(Wu);

      // my code is horrible. Its complexe for nothing. and wont work for the end or home. Its bad. Sorry . seb
      const { index } = queryString.parse(location.search);
      if (index) setIndex(parseInt(index));
    })();
  }, [workshopId]);

  // useEffect(() => {
  //   if (unsaved) {
  //     window.onbeforeunload = () => "You have unsaved changes, are you sure you want to leave?";
  //   } else {
  //     window.onbeforeunload = null;
  //   }
  // }, [unsaved]);

  async function next(answer) {
    console.log("NEXT");

    const obj = {};
    const totalSteps = workshop.steps.length + 1;

    const newIndex = index + 1;

    // todo later
    // if (answer && workshop.category === "profil") {
    //   answer.index = index;
    //   const choices = (userWorkshop.choices || []).filter((e) => e.index !== index);
    //   obj.choices = [...choices, answer];
    //   console.log(obj.choices);
    // }

    // setUnsaved(false);
    // setAnswers(newAnswers);

    // const obj = { answers: newAnswers, index: newIndex };

    const finished = newIndex >= totalSteps;

    if (userWorkshop) {
      if (!userWorkshop.finished_at && finished) {
        obj.finished_at = Date.now();
      }

      if (!userWorkshop?.step_id || userWorkshop.step_id <= newIndex) {
        obj.step_id = newIndex;
        if (index === totalSteps) obj.step_id = totalSteps;
      }
      const { data: uw } = await api.put(`/workshop_user/${userWorkshop._id}`, obj);
      setUserWorkshop(uw);
    }

    if (finished) {
      toast.success("Workshop finished!");
    }
    setIndex(newIndex);
  }

  const setValues = (v) => {
    // const newAnswers = v ? { ...answers, ...v } : { ...answers };
    // setAnswers(newAnswers);
    // setUnsaved(true);
  };

  return (
    <AppContext.Provider
      value={{
        index,
        setIndex,
        workshop,
        setWorkshop,
        next,
        unsaved,
        setValues,
        userWorkshop,
        setUserWorkshop,
        setMode,
        mode,
      }}>
      {children}
    </AppContext.Provider>
  );
};
