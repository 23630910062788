import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import moment from "moment";
import { HiMagnifyingGlass } from "react-icons/hi2";
import DebounceInput from "react-debounce-input";
import Board from "react-trello";
import Card from "./kanban_card";
import SelectUsers from "../../../components/selectUser";
import Pagination from "../../../components/pagination";
import { SUPPORT_LABELS } from "../../../constants";
import api from "../../../services/api";

export default () => {
  const [tickets, setTickets] = useState([]);
  const [filters, setFilters] = useState({
    limit: 100000,
  });

  useEffect(() => {
    fetch();
  }, [filters]);

  async function fetch() {
    const newFilters = {};
    if (filters.user) newFilters.leader_id = filters.user;
    if (filters.search) newFilters.search = filters.search;
    if (filters.status) newFilters.status = filters.status;
    if (filters.priority) newFilters.priority = filters.priority;
    if (filters.label) newFilters.label = filters.label;
    if (filters.limit) newFilters.limit = filters.limit;
    newFilters.type = "ticket";

    const { data, total } = await api.post("/ticket/search", newFilters);

    setTickets(data);
  }

  let KANBAN_LANES = ["TODO", "INPROGRESS", "AWAITING_FEEDBACK"];
  if (filters.status) KANBAN_LANES = [filters.status];

  return (
    <div className="bg-white h-full">
      <div className="flex justify-between mb-4">
        <div className="flex space-x-4">
          <div>
            <div className="px-1 text-sm text-gray-600 font-medium">{"Search"}</div>
            <div className="relative">
              <div className="pointer-events-none absolute inset-y-0 left-0 top-0 flex items-center pl-3">
                <HiMagnifyingGlass className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </div>
              <DebounceInput
                debounceTimeout={300}
                name="search"
                id="search"
                className="block w-full rounded-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 md:text-sm"
                placeholder="Search"
                value={filters.search}
                onChange={(e) => {
                  e.persist();
                  setFilters((f) => ({ ...f, search: e.target.value, page: 1 }));
                }}
              />
            </div>
          </div>
          <div>
            <div className="px-1 text-sm text-gray-600 font-medium">{"Lead"}</div>
            <SelectUsers
              value={filters.user}
              userName={filters.user?.name}
              onChange={(v) => {
                setFilters({ ...filters, user: v?._id });
              }}
            />
          </div>
          <div className="px-1 text-sm text-gray-600 font-medium">
            {"Status"}
            <select
              onChange={(e) => setFilters({ ...filters, status: e.target.value })}
              id="statusSelect"
              className="w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
              <option value="">All</option>
              <option value="TODO">To Do</option>
              <option value="INPROGRESS">In Progress</option>
              <option value="AWAITING_FEEDBACK">Awaiting Feedback</option>
              <option value="CLOSE">Closed</option>
            </select>
          </div>
          <div className="px-1 text-sm text-gray-600 font-medium">
            {"Priority"}
            <select
              onChange={(e) => setFilters({ ...filters, priority: e.target.value })}
              id="statusSelect"
              className="w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
              <option value="">All</option>
              <option value="HIGH">HIGH</option>
              <option value="MEDIUM">MEDIUM</option>
              <option value="LOW">LOW</option>
            </select>
          </div>
          <div className="px-1 text-sm text-gray-600 font-medium">
            {"Label"}
            <select
              onChange={(e) => setFilters({ ...filters, label: e.target.value })}
              id="statusSelect"
              className="w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
              <option value="">None</option>
              {Object.keys(SUPPORT_LABELS).map((e) => {
                return (
                  <option key={e} value={e} label={e}>
                    {e}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      </div>

      {tickets.length > 0 && (
        <Board
          components={{ Card }}
          style={{ backgroundColor: "#F5F6FC", overflow: "scroll", height: "100%" }}
          laneStyle={{ backgroundColor: "#e5e6eC", overflow: "scroll" }}
          hideCardDeleteIcon
          data={{
            lanes: KANBAN_LANES.map((category) => ({
              id: category,
              title: (
                <div>
                  <h2 className="text-base">
                    {category}&nbsp;-&nbsp;
                    {tickets.filter((t) => t.status === category).length}&nbsp;tickets
                  </h2>
                </div>
              ),
              cards: tickets.filter((t) => t.status === category).map((t) => ({ ticket: t, id: t._id })),
            })),
          }}
          onCardClick={(id) => {
            const ticket = tickets.find((f) => f._id === id);
            const url = `/message/${ticket._id}`;
            window.open(url, "_blank");
          }}
          onCardMoveAcrossLanes={async (fromLaneId, toLaneId, cardId) => {
            const ticket = tickets.find((f) => f._id === cardId);
            ticket.status = toLaneId;
            const { data, ok } = await api.put(`/ticket/${cardId}`, ticket);
            if (!ok) return toast.error("Something went wrong");
            toast.success("applicant moved successfully");
          }}
        />
      )}
    </div>
  );
};
