import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useSearchParams } from "react-router-dom";

import api from "../../../services/api";
import TablePagination from "../../../components/TablePagination";

const HEADER = [
  { title: "Budget" },
  { title: "Budgeted", position: "right" },
  { title: "Used", position: "right" },
  { title: "Used vs Budgeted", colSpan: 2, position: "center" }
];

export default ({ project }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [filters, setFilters] = useState({ status: searchParams.get("status") || "active" });

  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const query = { projectId: project._id };
        if (filters.status) query.status = filters.status;

        const res = await api.post(`/budget/search`, query);
        if (!res.ok) return toast.error("Failed to fetch credits");
        setData(res.data);

        const newSearchParams = new URLSearchParams();
        if (filters.status) newSearchParams.set("status", filters.status);
        setSearchParams(newSearchParams);
      } catch (error) {
        console.error(error);
        toast.error("Failed to fetch data");
      }
    };
    fetchData();
  }, [project, filters]);

  return (
    <div className="space-y-6 ">
      <div className="flex flex-wrap gap-4">
        <select value={filters.status} onChange={(e) => setFilters({ ...filters, status: e.target.value })} className="select max-w-sm">
          <option value="">All budgets</option>
          <option value="active">Active</option>
          <option value="inactive">Inactive</option>
        </select>
      </div>
      <TablePagination header={HEADER}>
        {data.map((item, index) => (
          <BudgetRow key={index} item={item} />
        ))}
      </TablePagination>
    </div>
  );
};

const BudgetRow = ({ item }) => {
  const duration = (new Date(item.endAt) - new Date(item.startAt)) / (1000 * 60 * 60 * 24);
  const passed = (new Date() - new Date(item.startAt)) / (1000 * 60 * 60 * 24);
  const forecasted = (item.amountUsed * duration) / (passed || 1);
  return (
    <tr className={`h-14 border-t ${item.status === "inactive" ? "bg-gray-100" : "bg-white"}`}>
      <td className="text-left p-2 text-sm">
        {item.name} {item.status === "inactive" && <span className="ml-4 text-xs text-gray-500">Inactive</span>}{" "}
      </td>

      <td className="text-right p-2 text-sm">{(item.amount || 0).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</td>
      <td className="text-right p-2 text-sm">{(item.amountUsed || 0).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</td>
      <td colSpan={2} className="text-left p-2 px-8 text-sm">
        <ProgressBar value={(item.amountUsed / item.amount) * 100} />
      </td>
    </tr>
  );
};

const ProgressBar = ({ value }) => (
  <div className="w-full flex items-center gap-2">
    <div className="w-full relative h-2 rounded-full bg-gray-200">
      <div className={`absolute h-2 rounded-full ${value >= 100 ? "bg-red-900" : value >= 80 ? "bg-red-600" : "bg-sky-700"}`} style={{ width: `${value > 100 ? 100 : value}%` }} />
    </div>
    <div className="w-8 text-sm text-right">{value.toFixed(0)}%</div>
  </div>
);
