import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

import Modal from "../../../components/modal";
import SelectProject from "../../../components/selectProject";

import api from "../../../services/api";
import { useSelector } from "react-redux";

export default () => {
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({ project_id: "62fb3ecdf4d045068e31a78d" });
  const navigate = useNavigate();

  const user = useSelector((state) => state.Auth.user);

  async function onCreate() {
    if (!values.title) return toast.error("Missing title !");
    const res = await api.post("/job", values);

    let obj = {};
    obj.job_id = res.data._id;
    obj.job_title = res.data.title;

    obj.user_id = user._id;
    obj.user_name = user.name;
    obj.user_avatar = user.avatar;

    // user_id: { type: String },
    // user_name: { type: String },
    // user_avatar: { type: String },

    obj.channel_id = "650c0ae98e232b2a9879d6c0";
    obj.channel_name = "Accounting";

    const jobPost = await api.post("/jobpost", obj);
    toast.success("Created!");

    navigate(`/job/${res.data._id}`);
  }

  return (
    <div>
      <button className="btn btn-primary" onClick={() => setOpen(true)}>
        Create a job
      </button>

      <Modal isOpen={open} className="max-w-xl w-full p-6" onClose={() => setOpen(false)}>
        <div className="w-full mb-2">
          <div className="text-sm font-medium mb-2">Title (*)</div>
          <input className="projectsInput block" value={values.title} onChange={(e) => setValues({ ...values, title: e.target.value })} />
        </div>
        <button className="btn btn-primary mt-4" onClick={onCreate}>
          Create
        </button>
      </Modal>
    </div>
  );
};
