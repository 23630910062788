import React, { useEffect, useState } from "react";
import { IoInformationCircleOutline } from "react-icons/io5";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import Modal from "../modal";
import DebounceInput from "react-debounce-input";
import { HiDotsHorizontal, HiOutlineShare } from "react-icons/hi";
import { copyToClipboard } from "../../utils";
import Info from "./info";
import Raw from "./raw";
import Logs from "./logs";

import { classNames } from "../../utils";

import api from "../../services/api";
import Loader from "../loader";

export default () => {
  const [tab, setTab] = useState("info");
  const [values, setValues] = useState();
  const [loading, setLoading] = useState(false);
  const { search } = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(search);

  const user = useSelector((state) => state.Auth.user);

  useEffect(() => {
    const task_modal_id = query.get("task_modal_id");
    if (!values || values._id !== task_modal_id) fetch();
  }, [query]);

  const fetch = async () => {
    try {
      const task_modal_id = query.get("task_modal_id");
      if (!task_modal_id) return;
      setLoading(true);
      const res = await api.get(`/notion/${task_modal_id}`);
      if (!res.ok) return;
      setValues(res.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  // TO DO - Adding fields and update/delete functions

  if (!values) return <></>;

  return (
    <Modal
      isOpen={true}
      onClose={() => {
        query.delete("task_modal_id");
        navigate({ pathname: location.pathname, search: query.toString() });
        setValues(null);
      }}>
      {loading ? (
        <Loader />
      ) : (
        <div className="w-full h-[90vh] bg-gray-100 overflow-y-scroll">
          <div className="bg-white flex flex-col w-full overflow-hidden">
            <div className="flex justify-between items-center gap-2 p-4 mr-4">
              <div className="text-gray-600 text-lg">{`# ${values.index}`}</div>
              <DebounceInput
                debounceTimeout={300}
                className="input text-base font-semibold flex-1"
                placeholder="Title..."
                type="text"
                id="title"
                value={values.name || ""}
                onChange={async (e) => {
                  const { data } = await api.put(`/notion/${values._id}`, { name: e.target.value });
                  setValues(data);
                  toast.success("Title updated!");
                }}
              />
              <button
                className="transparent-btn"
                onClick={() => {
                  copyToClipboard(window.location.href);
                  toast.success("Copied to clipboard!");
                }}>
                <HiOutlineShare className="mr-1" />
                <span className="text-xs">Share</span>
              </button>
              <Menu notion={values} setNotion={setValues} />
            </div>
            <nav className="flex flex-row w-full border-t-[1px] border-gray-200 overflow-x-scroll">
              <TabItem title="Info" tab="info" setTab={setTab} active={tab === "info"} Icon={IoInformationCircleOutline} />
              <TabItem title="Logs" tab="logs" setTab={setTab} active={tab === "logs"} />
              <TabItem title="Raw" tab="raw" setTab={setTab} active={tab === "raw"} />
            </nav>
          </div>
          <div className="my-2" />
          {tab === "info" && <Info notion={values} setNotion={setValues} />}
          {tab === "raw" && <Raw notion={values} />}
          {tab === "logs" && <Logs notion={values} />}
        </div>
      )}
    </Modal>
  );
};

const TabItem = ({ tab, title, setTab, active, Icon, Badge }) => (
  <button
    onClick={() => setTab(tab)}
    className={classNames(
      "group inline-flex items-center px-4 py-2 cursor-pointer text-sm text-gray-500 hover:border-gray-300 hover:text-gray-700 border-b-2",
      active ? "text-primary  border-primary" : "border-transparent",
    )}>
    {Icon ? <Icon className={classNames(active ? "text-primary" : "text-gray-400 group-hover:text-gray-500", "-ml-0.5 mr-2 h-4 w-4")} aria-hidden="true" /> : null}
    <div className="flex items-center gap-2">{title}</div>
    {Badge ? Badge : null}
  </button>
);

const Menu = ({ notion, setNotion }) => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(search);

  const [open, setOpen] = useState(false);

  const handleDelete = async () => {
    if (!confirm("Are you sure you want to delete this ticket?")) return;
    try {
      const { ok } = await api.remove(`/notion/${notion._id}`);
      if (!ok) return toast.error("Something went wrong!");
      toast.success("Notion deleted!");

      query.delete("task_modal_id");
      navigate({ pathname: location.pathname, search: query.toString() });
      setNotion(null);
    } catch (e) {
      console.log(e);
      toast.error("Something went wrong!");
    }
  };

  return (
    <div className="relative">
      <button className="transparent-btn" onClick={() => setOpen((p) => !p)}>
        <HiDotsHorizontal />
      </button>
      {open && (
        <div className="absolute right-0 top-8 bg-white border border-gray-200 rounded-md shadow-md">
          <button className="flex items-center gap-2 px-4 py-2 w-full hover:bg-gray-100" onClick={handleDelete}>
            <span>Delete</span>
          </button>
        </div>
      )}
    </div>
  );
};
