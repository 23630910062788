import React, { useEffect, useState } from "react";
import SelectUser from "../../../components/selectUser";

import SebSignaturePng from "../../../assets/seb-signature.png";

const TEMPLATE_FREELANCE = ({ values, setValues }) => {
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    const e = [];
    if (!values.date) e.push(`Missing date`);
    if (!values.name) e.push(`Missing name`);
    if (!values.location) e.push(`Missing location`);
    if (!values.job_title) e.push(`Missing role`);
    if (!values.tjm) e.push(`Missing tjm`);
    setErrors(e);

    document.querySelector("#content #name").innerHTML = values.name;
    document.querySelector("#content #name-1").innerHTML = values.name;
    document.querySelector("#content #date").innerHTML = formatDate(values?.date);
    document.querySelector("#content #date-1").innerHTML = formatDate(values?.date);
    document.querySelector("#content #location").innerHTML = values.location;
    document.querySelector("#content #role").innerHTML = values.job_title;
    document.querySelector("#content #tjm").innerHTML = values.tjm;
  }, [values]);

  return (
    <div>
      <div className="grid grid-cols-4 gap-3 items-center justify-between mb-3">
        <div>
          <div className="font-medium mb-1 text-sm">Choose date</div>
          <input
            className="projectsInput text-[14px] font-normal text-[#212325] rounded-[10px]"
            type="date"
            value={formatDate(values.date)}
            onChange={(e) => setValues({ ...values, date: new Date(e.target.value) })}
          />
        </div>

        <div>
          <div className="font-medium mb-1 text-sm">Name</div>
          <input
            className="projectsInput text-[14px] font-normal text-[#212325] rounded-[10px]"
            value={values.name}
            onChange={(e) => setValues({ ...values, name: e.target.value })}
          />
        </div>
        <div>
          <div className="font-medium mb-1 text-sm">Location</div>
          <input
            className="projectsInput text-[14px] font-normal text-[#212325] rounded-[10px]"
            value={values.location}
            onChange={(e) => setValues({ ...values, location: e.target.value })}
          />
        </div>

        <div>
          <div className="font-medium mb-1 text-sm">TJM</div>
          <input
            className="projectsInput text-[14px] font-normal text-[#212325] rounded-[10px]"
            value={values.tjm}
            onChange={(e) => setValues({ ...values, tjm: e.target.value })}
          />
        </div>

        <div>
          <div className="font-medium mb-1 text-sm">Job Title</div>
          <input
            className="projectsInput text-[14px] font-normal text-[#212325] rounded-[10px]"
            value={values.job_title}
            onChange={(e) => setValues({ ...values, job_title: e.target.value })}
          />
        </div>
      </div>
      {!!errors.length && (
        <div className="mb-4">
          <div className="font-bold mb-2">Errors</div>
          <div>
            {errors.map((e) => {
              return (
                <div className="text-light-grey text-sm mb-1" key={e}>
                  {e}
                </div>
              );
            })}
          </div>
        </div>
      )}
      <div id="content" className="p-4 text-base">
        <div dangerouslySetInnerHTML={{ __html: html }} />
        <img src={SebSignaturePng} alt="" />
      </div>
    </div>
  );
};

function formatDate(date) {
  if (!date) return;
  return new Date(date).toISOString().slice(0, 10);
}

const html = `
<p><strong>&nbsp;FREELANCE CONTRACT&nbsp;</strong></p>
<p>&nbsp;This Freelance Contract (this "Agreement") is made as of this <span id="date">{{DATE}}</span>, (the&nbsp;“Effective Date”) by and
    between <span id="name">{{NAME}}</span>  located at <span id="location">{{LOCATION}}</span>  (“Independent Contractor”) and Selego B.V located at Oudeschans
    83-2,1011 KW, Amsterdam, Netherlands (“Client”). Client and&nbsp;Independent Contractor may each be referred to in
    this Agreement as a “Party” and collectively as the&nbsp;“Parties.&nbsp;</p>
<p><br></p>
<p><strong>&nbsp;1. Services.&nbsp;</strong></p>
<p>Independent Contractor shall provide the following services to Client (the “Services”): <span id="role">{{ROLE}}</span>.&nbsp;In
    addition, Independent Contractor shall perform such other duties and tasks, or changes to the Services,&nbsp;as may
    be agreed upon by the Parties.&nbsp;</p>
<p><br></p>
<p><strong>&nbsp;2. Compensation.&nbsp;</strong></p>
<p>Inconsideration for Independent Contractor’s performance of the Services, Client shall pay&nbsp;Independent
    Contractor <span id="tjm">{{TJM}}</span>.&nbsp;</p>
<p><br></p>
<p><strong>&nbsp;3. Expenses.&nbsp;</strong></p>
<p>All costs and expenses incurred by Independent Contractor in connection with the performance of the Services shall be
    the sole responsibility of and paid by Independent Contractor&nbsp;</p>
<p><br></p>
<p><strong>&nbsp;4. Term and Termination.&nbsp;</strong></p>
<p>Independent Contractor's engagement with Client under this Agreement shall begin on&nbsp; <span id="date-1">{{DATE}}</span> The parties agree
    andacknowledge that this Agreement and&nbsp;the Independent Contractor's engagement with the Client under this
    Agreement is an independent&nbsp;contractor agreement renewed by tacit agreement each month.&nbsp;Should either
    party wish to terminate the contract, an email must be sent. Upon termination, the&nbsp;Independent Contractor
    agrees to return all of the Client's property used in the performance of the&nbsp;Services, including, computers,
    cell phones, keys, reports and other equipment and documents.&nbsp;Independent Contractor will reimburse Client for
    any lost or damaged Client property in an&nbsp;amount equal to the market price of such property.</p>
<p>&nbsp;Notice period :&nbsp;</p>
<p>1 day before 15 days of work&nbsp;</p>
<p>1 week before 3 month and after 15 days&nbsp;</p>
<p>1 month notice after 3 month&nbsp;</p>
<p><br></p>
<p><br></p>
<p><strong>&nbsp;5. Independent Contractor.&nbsp;</strong></p>
<p>The parties agree and acknowledge that the Independent Contractor is an independent contractor and&nbsp;is not, for
    any purpose, an employee of the Client. Independent Contractor shall not have the&nbsp;authority to enter into any
    agreements or contracts on behalf of Client, nor shall Independent&nbsp;Contractor represent that it has such
    authority. The Independent Contractor is not entitled to any&nbsp;of the Client's benefits, including, but not
    limited to, medical, dental, retirement or other plan coverage. Clients shall not be required to pay workers'
    compensation insurance, unemployment compensation, social security tax, withholding tax, or other taxes or
    withholdings for or on behalf of Independent Contractor in connection with the performance of services under this
    Agreement.</p>
<p><strong>6. Days off&nbsp;</strong></p>
<p>For monthly based contractor, they can benefit from 2 paid days per month ( can be accumulated ) but need to notify a
    week in advance.&nbsp;He can also have 14 days of sick leave per year for them</p>
<p><br></p>
<p><strong>&nbsp;7. Confidentiality.&nbsp;</strong></p>
<p><br></p>
<p><strong>a. Confidential and Proprietary Information.</strong></p>
<p>In the course of performing the Services, Independent Contractor will be exposed to confidential and proprietary
    information of the Client. “Confidential Information” shall mean any data or information that is competitively
    sensitive material and not&nbsp;generally known to the public, including, but not limited to, information relating
    to development and plans, marketing strategies, finance, operations, systems, proprietary concepts, documentation,
    reports, data, specifications, computer software, source code, object code, flow charts, data, databases,
    inventions, know-how, trade secrets, customer lists, customer relationships, customer&nbsp;profiles, supplier lists,
    supplier relationships, supplier profiles, pricing, sales estimates, business plans and internal performance results
    relating to the past, present or future business activities, technical information, designs, processes, procedures,
    formulas or improvements, which Client considers &nbsp;confidential and proprietary. Independent Contractor
    acknowledges and agrees that the Confidential Information is valuable property of Client, developed over a long
    period of time at substantial expense and that it is worthy of protection.&nbsp;</p>
<p><strong>b. Confidentiality Obligations.</strong></p>
<p>Except as otherwise expressly permitted in this Agreement, Independent Contractor shall not disclose or use in any
    manner, directly or indirectly, any Confidential Information either during the term of this Agreement or at any time
    thereafter, except as required to perform the Services or with Client’s prior written consent</p>
<p><strong>c. Irreparable Harm.&nbsp;</strong></p>
<p>Independent Contractor acknowledges that the use or disclosure of any Confidential Information in a manner
    inconsistent with this Agreement will result in irreparable harm for which damages would not be an adequate remedy.
    Therefore, in addition to any other legal remedies that may be available at law or in equity, Client shall be
    entitled to equitable or injunctive relief against the&nbsp;unauthorized use or disclosure of Confidential
    Information.&nbsp;</p>
<p><br></p>
<p><br></p>
<p><strong>&nbsp;8. Ownership of Work Product.</strong></p>
<p>The Parties agree that all work product, information or other materials created and developed by Independent
    Contractor in connection with the performance of the Services under this Agreement and any resulting intellectual
    property rights (collectively, the “Work Product”) are the sole and exclusive property of Client. The Parties
    acknowledge that the Work Product shall, to the extent permitted by&nbsp;law, be considered a “work made for hire”
    within the definition of Section 101 of the Copyright Act of 1976, as amended, (the “Copyright Act”) and that Client
    is deemed to be the author and is the owner of all copyright and all other rights therein. If the work product is
    not deemed to be a “work made for&nbsp;hire” under the Copyright Act, then Independent Contractor hereby assigns to
    Client all of&nbsp;Independent Contractor’s rights, title and interest in and to the Work Product, including but not
    limited&nbsp;to all copyrights, publishing rights and rights to use, reproduce and otherwise exploit the Work
    Product&nbsp;in any and all formats, media, or all channels, whether now known or hereafter created.&nbsp;</p>
<p><br></p>
<p><strong>&nbsp;9. Non-Solicit.&nbsp;</strong></p>
<p>Independent Contractor agrees and covenants that for a period of 12 months following the termination&nbsp;of this
    Agreement, Independent Contractor will not, directly or indirectly, solicit any officer, director or&nbsp;employee,
    or any customer, client, supplier or vendor of Client for the purpose of inducing such party&nbsp;to terminate its
    relationship with Client in favor of Independent Contractor or another business directly&nbsp;or indirectly in
    competition with Client.&nbsp;</p>
<p><br></p>
<p><strong>&nbsp;10. Mutual Representations and Warranties.&nbsp;</strong></p>
<p>Both Client and Independent Contractor represent and warrant that each Party has full power,&nbsp;authority and right
    to execute and deliver this Agreement, has full power and authority to perform its&nbsp;obligations under this
    Agreement, and has taken all necessary action to authorize the execution and&nbsp;delivery of this Agreement. No
    other consents are necessary to enter into or perform this Agreement.&nbsp;</p>
<p><br></p>
<p><strong>&nbsp;11. Governing Law &amp; Disputes.&nbsp;</strong></p>
<p>The terms of this agreement and the rights of the parties hereto shall be governed by the Dutch&nbsp;law. Any dispute
    arising from this Agreement will be resolved by mediation first. If the dispute cannot&nbsp;be resolved by
    mediation, it will be resolved by the competent courts of the Netherlands.&nbsp;</p>
<p><strong>&nbsp;12. Communication and Agreements</strong></p>
<p>Any notice or other communication given or made to either Party under this Agreement shall be in&nbsp;writing and
    delivered by hand, sent by overnight courier, or sent by certified or registered mail,&nbsp;return receipt
    requested, to the address set forth above or to such other address as such&nbsp;Party may subsequently designate by
    notice, and shall be deemed given on the date of delivery&nbsp;&nbsp;IN WITNESS WHEREOF, this Agreement has been
    executed and delivered as of the date first written above.&nbsp;</p>
<p><br></p>
<p><strong>Client:</strong> Selego BV, Sebastien Le Goff
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>Independent
        Contractor</strong> :&nbsp;<span id="name-1">{{NAME}}</span></p>
<p><br></p>
<p>Signature
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Signature
</p>
`;

function isFreelanceValid(values) {
  return values.name && values.location && values.job_title && values.tjm;
}

export { TEMPLATE_FREELANCE, isFreelanceValid };
