import React, { useState } from "react";
import { classNames } from "../../utils";

import { PRICING, PRICING_START } from "./pricing";

import { CONTRACTS, DIVISION, LOCATION, ROLES, YEARSSCHOOL, SCHOOL, MANAGEMENT } from "./constants";
import { dutchInfo, frenchInfo, spanishInfo } from "./salary";

export default ({ filter }) => {

  const [sellTest, setSellTest] = useState();

  if (!filter.role) return <div />;

  let experience_total = 0;
  if (filter.experience_total) {
    experience_total = filter.experience_total;
  }

  let sellPerDay = getPricing(filter);

  let elements = [];

  if ([DIVISION.LE_COLLECTIF, DIVISION.SELEGO_FR].includes(filter.division)) {
    elements = frenchInfo(filter);
  }
  if ([DIVISION.SELEGO_NL].includes(filter.division)) {
    elements = dutchInfo(filter);
  }
  if ([DIVISION.SELESPAGNE].includes(filter.division)) {
    elements = spanishInfo(filter);
  }

  let costPerDayExpenses = getCostExpenses(elements, filter);
  let costPerDaySalary = getCostSalary(elements, filter);
  let costPerDay = costPerDayExpenses + costPerDaySalary;

  let marginTheoretical = (((sellPerDay - costPerDay) / sellPerDay) * 100).toFixed(2);
  let marginTest = (((sellTest - costPerDay) / sellTest) * 100).toFixed(2);

  return (
    <div className="w-full">
      <label className="font-bold">Sell & margin</label>
      <div className="p-4 mt-2 border border-gray-200 rounded-md flex flex-col">
        <div className="mt-2">
          <div className="flex flex-row justify-between py-1">
            <div>
              <label>Total cost per day</label>
            </div>
            <div>{costPerDay}€</div>
          </div>
          <div className="flex flex-row justify-between py-1 text italic text-sm">
            <div>
              <label>Including costs due to salary</label>
            </div>
            <div>{costPerDaySalary}€</div>
          </div>
          <div className="flex flex-row justify-between py-1 text italic text-sm">
            <div>
              <label>Including cost due to fixed costs</label>
            </div>
            <div>{costPerDayExpenses}€</div>
          </div>
        </div>
        <div className="flex flex-row justify-between py-1">
          <div>
            <label>Price based on benchmark</label>
          </div>
          <div>{sellPerDay}€</div>
        </div>
        <div className="flex flex-row justify-between py-1">
          <div>
            <label>Margin based on price from benchmark</label>
          </div>
          <div>{marginTheoretical}%</div>
        </div>
        <div className="flex flex-row justify-between py-1">
          <div>
            <label>Other TJM</label>
          </div>
          <input
            className="block w-1/3 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 text-right"
            name="sellTest"
            type="number"
            value={sellTest}
            onChange={(e) => setSellTest(e.target.value)}
          />
        </div>
        <div className="flex flex-row justify-between py-1">
          <div>
            <label>Margin if other TJM</label>
          </div>
          <div>{marginTest}%</div>
        </div>
      </div>
    </div>
  );
};

function getPricing(filter) {
  let sellPerDay = 0;

  if (filter.contract == CONTRACTS.APPRENTICESHIP) {
    sellPerDay = 350;
  } else if (filter.contract == CONTRACTS.INTERNSHIP) {
    sellPerDay = 250;
  } else if ([CONTRACTS.CDI, CONTRACTS.CDD, CONTRACTS.VIE].includes(filter.contract)) {
    const index = PRICING_START.findIndex((e) => e[0] === filter.role);

    if (filter.experience_total < 7) {
      sellPerDay = Math.round(PRICING_START[index][2] * Math.pow(1.06, filter.experience_total));
    } else {
      sellPerDay = Math.round(PRICING_START[index][2] * Math.pow(1.075, 7) * Math.pow(1.03, filter.experience_total - 7));
    }
  }

  if (filter.management == MANAGEMENT.LEAD) sellPerDay = Math.round(sellPerDay * 1.05);
  if (filter.management == MANAGEMENT.HEADOF) sellPerDay = Math.round(sellPerDay * 1.1);
  if (filter.school == SCHOOL.ENGINEERING) sellPerDay = Math.round(sellPerDay * 1.08);

  return sellPerDay;
}

function getCostSalary(elements, filter) {
  let numberOfWeeksOff = 5;
  if (filter.division == DIVISION.LE_COLLECTIF) numberOfWeeksOff += 5;
  if (filter.division == DIVISION.SELEGO_FR) numberOfWeeksOff += 5;

  let totalDaysWorkedPeyYear = filter.numberDays * (52 - numberOfWeeksOff) - 8;
  let totalSalary = 0;

  for (let i = 0; i < elements.length; i++) {
    // Vérifiez si l'objet a un attribut 'key' égal à 'salary'
    if (elements[i].key === "salary") {
      // Si oui, ajoutez sa valeur à la somme
      totalSalary += elements[i].value;
    } else if (elements[i].key === "salary-reduction") {
      totalSalary -= elements[i].value;
    }
  }

  let totalSalaryPerDay = Math.round(totalSalary / totalDaysWorkedPeyYear);

  return totalSalaryPerDay;
  // ici besoin de sommer depuis elements le brut annuel + coût entreprise
  // diviser par le nb de jours travaillés/semaine - 52 semaines - 6 semaines (5 de congés payés + 1 jours fériés)
}

function getCostExpenses(elements, filter) {
  let numberOfWeeksOff = 5;
  if (filter.division == DIVISION.LE_COLLECTIF) numberOfWeeksOff += 5;
  if (filter.division == DIVISION.SELEGO_FR) numberOfWeeksOff += 5;

  let totalDaysWorkedPeyYear = filter.numberDays * (52 - numberOfWeeksOff) - 8;
  let totalExpenses = 0;

  for (let i = 0; i < elements.length; i++) {
    // Vérifiez si l'objet a un attribut 'key' égal à 'salary'
    if (elements[i].key === "expenses") {
      // Si oui, ajoutez sa valeur à la somme
      totalExpenses += elements[i].value;
    }
  }

  let totalExpensesPerDay = Math.round(totalExpenses / totalDaysWorkedPeyYear);

  return totalExpensesPerDay;
  // ici besoin de sommer depuis elements retraite + prévoyance + mutuelle + ordi + bureau + CEG
}
