import React, { useEffect, useState } from "react";
import api from "../../../services/api";
import Loader from "../../../components/loader";

const Stats = ({ filters }) => {
  const [stats, setStats] = useState({ categories: [], negpos: { positive: 0, negative: 0 }, tva: { nl: 0, fr: 0, other: 0 }, total: 0 });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      const obj = {};
      if (filters.projects) obj.project_ids = filters.projects.map((e) => e.value);
      if (filters.statuses) obj.statuses = filters.statuses.map((e) => e.value);
      if (filters.banks) obj.banks = filters.banks.map((e) => e.value);
      if (filters.cards) obj.card_ids = filters.cards.map((e) => e.value);
      if (filters.categories) obj.categories = filters.categories.map((e) => e.value);
      if (filters.countries) obj.countries = filters.countries.map((e) => e.value);
      if (filters.user) obj.user_id = filters.user._id;
      if (filters.startDate) obj.startDate = filters.startDate;
      if (filters.endDate) obj.endDate = filters.endDate;
      if (filters.priceMin) obj.priceMin = filters.priceMin;
      if (filters.priceMax) obj.priceMax = filters.priceMax;
      if (filters.search) obj.search = filters.search;
      if (filters.organisation_id) obj.organisation_id = filters.organisation_id;

      setLoading(true);
      const { data, total } = await api.post(`/bank/stats`, obj);
      setLoading(false);
      const categories = data.categories;

      console.log(categories);

      let t1 = 0;
      for (let i = 0; i < categories.length; i++) {
        t1 += categories[i].total;
      }
      console.log("t1", t1);

      const arr = [];
      arr.push({ name: "BANK", value: categories.find((e) => e._id === "BANK")?.total || 0 });
      arr.push({ name: "HOSTING", value: categories.find((e) => e._id === "HOSTING")?.total || 0 });
      arr.push({ name: "ADS", value: categories.find((e) => e._id === "ADS")?.total || 0 });
      arr.push({ name: "SALARY", value: categories.find((e) => e._id === "SALARY")?.total || 0 });
      arr.push({ name: "TOOLS", value: categories.find((e) => e._id === "TOOLS")?.total || 0 });
      arr.push({ name: "OFFICE", value: categories.find((e) => e._id === "OFFICE")?.total || 0 });
      arr.push({ name: "ACCOUNTING", value: categories.find((e) => e._id === "ACCOUNTING")?.total || 0 });
      arr.push({ name: "INVOICE", value: categories.find((e) => e._id === "INVOICE")?.total || 0 });
      arr.push({ name: "TRANSFER", value: categories.find((e) => e._id === "TRANSFER")?.total || 0 });
      arr.push({ name: "TAX", value: categories.find((e) => e._id === "TAX")?.total || 0 });
      arr.push({ name: "TRAVEL", value: categories.find((e) => e._id === "TRAVEL")?.total || 0 });
      arr.push({ name: "FOODANDBEVERAGE", value: categories.find((e) => e._id === "FOODANDBEVERAGE")?.total || 0 });
      arr.push({ name: "OTHER", value: categories.find((e) => e._id === "")?.total || 0 });

      let t2 = 0;
      for (let i = 0; i < arr.length; i++) {
        t2 += arr[i].value;
      }
      console.log("t2", t2);

      const negpos = { negative: data.negpos.find((e) => e._id === "negative")?.total || 0, positive: data.negpos.find((e) => e._id === "positive")?.total || 0 };
      const tva = {
        nl: data.tva.find((e) => e._id === "Netherland")?.total || 0,
        fr: data.tva.find((e) => e._id === "France")?.total || 0,
        other: data.tva.find((e) => e._id === "Other")?.total || 0,
      };
      setStats({ categories: arr, negpos, tva, total });
    })();
  }, [JSON.stringify(filters)]);

  return (
    <div className="w-full flex flex-col gap-2">
      <dl className="grid grid-cols-1 gap-px bg-gray-900/5 md:grid-cols-4 lg:grid-cols-7">
        <MainItem label="Mutations" value={stats?.total} loading={loading} />
        <MainItem label="Sum total" value={stats?.categories.reduce((prev, acc) => (acc.value || 0) + prev, 0).toLocaleString()} unit="€" loading={loading} />
        <MainItem label="Sum negative" value={stats?.negpos.negative.toLocaleString()} unit="€" loading={loading} />
        <MainItem label="Sum positive" value={stats?.negpos.positive.toLocaleString()} unit="€" loading={loading} />
        <MainItem label="TVA NL" value={stats?.tva.nl.toLocaleString()} unit="€" loading={loading} />
        <MainItem label="TVA FR" value={stats?.tva.fr.toLocaleString()} unit="€" loading={loading} />
        <MainItem label="TVA Other" value={stats?.tva.other.toLocaleString()} unit="€" loading={loading} />
      </dl>
      <dl className="grid grid-cols-1 gap-px bg-gray-900/5 md:grid-cols-4 lg:grid-cols-7">
        {stats.categories.map((e, i) => (
          <SecondaryItem label={e.name} value={e.value.toLocaleString()} unit="€" loading={loading} />
        ))}
      </dl>
    </div>
  );
};

const MainItem = ({ key = "", label, value, unit = "", loading }) => {
  return (
    <div key={key} className="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white px-2 py-1">
      <dt className="text-sm font-medium leading-4 text-gray-500">{label}</dt>
      {loading ? (
        <Loader size="small" />
      ) : (
        <dd className="w-full flex-none text-2xl font-medium leading-8 tracking-tight text-primary-600">
          {value}
          <span className="text-xl">{unit}</span>
        </dd>
      )}
    </div>
  );
};

const SecondaryItem = ({ key = "", label, value, unit = "", loading }) => {
  return (
    <div key={key} className="flex flex-row items-baseline justify-between gap-x-4 gap-y-2 bg-white px-2 py-1">
      <dt className="text-xs font-medium leading-4 text-gray-500">{label}</dt>
      {loading ? (
        <Loader size="small" />
      ) : (
        <dd className="flex-none text-sm font-normal leading-6 tracking-tight text-gray-700">
          {value}
          <span className="text-xs">{unit}</span>
        </dd>
      )}
    </div>
  );
};

export default Stats;
