import React, { useState, useEffect } from "react";
import api from "../../services/api";
import Loader from "../../components/loader";
import LOGO_SELEGO from "../../assets/selego_logo2.jpg";

import { HiEye, HiOutlineDocumentText, HiOutlineMap, HiOutlineRss, HiOutlineClock } from "react-icons/hi2";
import { NavLink } from "react-router-dom";

const Jobs = () => {
  const [jobsPost, setJobsPost] = useState();

  useEffect(() => {
    getJobsPost();
  }, []);

  async function getJobsPost() {
    const { data } = await api.post(`/jobpost/public-search`);
    setJobsPost(data.filter((jp) => jp.channel_name === "Accounting"));
  }

  return (
    <div className="max-w-7xl mx-auto px-3 mb-4">
      <div className="flex justify-center my-4">
        <h1 className="font-bold text-2xl">Discover the job offers</h1>
      </div>
      {jobsPost ? (
        jobsPost.length > 0 ? (
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            {jobsPost.map((jobpost) => {
              return <Card jobId={jobpost.job_id} jobPostId={jobpost._id} key={jobpost._id} />;
            })}
          </div>
        ) : (
          <div>No jobs</div>
        )
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default Jobs;

const Card = ({ jobId, jobPostId }) => {
  const [job, setJob] = useState();
  const [isLoading, setIsLoading] = useState(true);

  async function getJob() {
    try {
      const { data } = await api.get(`/job/${jobId}`);
      setJob(data);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getJob();
  }, []);

  if (isLoading) return null;
  if (!job || job?.status !== "active") return null;
  return (
    <div className="flex flex-col col-span-1 rounded-lg bg-white border-[1px] border-gray-200 overflow-hidden" key={job}>
      <div className="flex flex-1 w-full items-center justify-between space-x-3 md:space-x-6 p-3 md:p-6">
        <img className="h-7 md:h-10 w-7 md:w-10 flex-shrink-0 rounded-full" src={LOGO_SELEGO} alt="" />
        <div className="flex-1 truncate">
          <div className="flex items-center">
            <h3 className="uppercase truncate text-xs font-medium text-gray-600 tracking-wider">{job.project_name}</h3>
          </div>
          <h2 className="my-1 truncate text-base md:text-lg text-gray-900 font-semibold">{job.title}</h2>
          <div className="flex gap-x-5 gap-y-2 flex-wrap">
            {job.contract_type ? (
              <div className="flex items-center gap-1">
                <HiOutlineDocumentText className="text-gray-500" />
                <span className="text-gray-600 text-xs">{job.contract_type}</span>
              </div>
            ) : null}
            {job.contract_duration ? (
              <div className="flex items-center gap-1">
                <HiOutlineClock className="text-gray-500" />
                <span className="text-gray-600 text-xs">{job.contract_duration}</span>
              </div>
            ) : null}
            {job.location ? (
              <div className="flex items-center gap-1">
                <HiOutlineMap className="text-gray-500" />
                <span className="text-gray-600 text-xs">{job.location}</span>
              </div>
            ) : null}
            {job.remote ? (
              <div className="flex items-center gap-1">
                <HiOutlineRss className="text-gray-500" />
                <span className="text-gray-600 text-xs">{job.remote}</span>
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <div>
        <div className="-mt-px flex">
          <div className="flex w-0 flex-1">
            <NavLink
              to={`/auth/signup?jobpost=${jobPostId.toString()}`}
              className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center rounded-b-lg border-t-[1px] border-gray-200 py-3 md:py-4 text-sm font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-700">
              <HiEye className="h-5 w-5 text-gray-400" aria-hidden="true" />
              <span className="ml-2 md:ml-3">View offer</span>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};
