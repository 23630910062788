import React, { useEffect, useState } from "react";
import { ResponsiveBar } from "@nivo/bar";
import { Link } from "react-router-dom";
import api from "../../../services/api";
import BankModal from "../../../components/bankModal";
const OPTIONS = ["", 2024, 2023, 2022, 2021, 2020];

const CATEGORIES = ["", "SALARY", "TAX", "HOSTING", "OFFICE", "OTHER", "TRAVEL", "TOOLS", "FOODANDBEVERAGE", "ACCOUNTING", "ADS", "BANK", "REFUND"];

export default ({}) => {
  const [data, setData] = useState(null);
  const [annualData, setAnnualData] = useState(null);
  const [selected, setSelected] = useState(null);

  const [filters, setFilters] = useState({ month: ``, category: `` });

  useEffect(() => {
    get();
  }, [filters]);

  async function get() {
    const nf = { month: filters.month, category: filters.category };
    const res = await api.post(`/report/rentability`, nf);
    setData(res.data);
    setAnnualData(res.annualData);
  }

  if (!data) return <div>Loading</div>;

  return (
    <div className="p-4">
      <div className="flex justify-between h-12">
        <h1>Profitability</h1>
        <div>
          <select defaultValue={filters.month} onChange={(e) => setFilters({ ...filters, month: e.target.value })}>
            {OPTIONS.map((e) => (
              <option key={e} value={`${e}-12-01T00:00:00.000Z`}>
                {e}
              </option>
            ))}
          </select>
          <select defaultValue={filters.category} onChange={(e) => setFilters({ ...filters, category: e.target.value })}>
            {CATEGORIES.map((e) => (
              <option key={e} value={e}>
                {e}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div>{`Revenue : ${annualData?.Revenue}€ | Cost : ${annualData?.Cost}€ | Benef : ${annualData?.Benefit}€ `}</div>
      <div className="h-[60vh] bg-white mt-4 p-2 rounded">
        <Graph data={data} category={filters.category} onClick={(e) => setSelected(e)} />
      </div>
      <Detail data={selected} />
    </div>
  );
};

const Detail = ({ data }) => {
  const [invoices, setInvoices] = useState([]);
  const [banks, setBanks] = useState([]);
  const [stats, setStats] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedBank, setSelectedBank] = useState(null);

  useEffect(() => {
    getDetails();
  }, [data]);

  async function getDetails() {
    if (!data) return;
    setLoading(true);
    const res1 = await api.post(`/invoice/search`, { date: data.data.date, sent: "yes", sortBy: "-total" });
    setInvoices(res1.data);

    const res2 = await api.post(`/bank/search`, { month: data.data.date, sortBy: "amount", per_page: 20000, category: { $nin: ["INVOICE", "TRANSFER", "VAT"] } });
    setBanks(res2.data.banks);

    const arr = [];

    for (let i = 0; i < res2.data.banks.length; i++) {
      const category = res2.data.banks[i].category || "OTHER";
      const find = arr.find((e) => e.category === category);
      if (find) {
        find.amount += res2.data.banks[i].amount;
      } else {
        arr.push({ category, amount: res2.data.banks[i].amount });
      }
    }
    setStats(arr);

    setLoading(false);
  }

  if (!data) return <div />;

  const revenuTotal = invoices.reduce((acc, e) => acc + e.netTotal, 0).toFixed(0);
  const costTotal = banks.reduce((acc, e) => acc + e.amount, 0).toFixed(0);

  if (loading) return <div>Loading</div>;

  return (
    <div>
      <BankModal bank={selectedBank} setBank={setSelectedBank} />
      <h1>{`Details for ${data.indexValue}`}</h1>
      <div className="gap-4 mt-4 flex min-w-[1200px]">
        <div className="flex-1">
          <div className="text-lg font-semibold mb-5">{`Revenue (${revenuTotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}€)`}</div>
          <div className="border rounded-md">
            <table className="text-sm ">
              <thead>
                <tr>
                  <th className="text-left py-2 px-3">Name</th>
                  <th className="text-left py-2 px-3">Total (net)</th>
                </tr>
              </thead>
              <tbody>
                {invoices.map((e) => (
                  <tr key={e._id} className="border-t">
                    <td className="py-2 px-3">
                      <Link target="_blank" to={`/invoice/${e._id}`}>
                        {(e.name || "").substring(0, 25)}
                      </Link>
                    </td>
                    <td className="py-2 px-3">{`${e.netTotal
                      .toFixed(0)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}€`}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className="flex-1">
          <div className="text-lg font-semibold mb-5">{`Cost (${costTotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}€)`}</div>
          <div className="border rounded-md">
            <table className="text-sm">
              <thead>
                <tr>
                  <th className="text-left py-2 px-3">Name</th>
                  <th className="text-left py-2 px-3">Category</th>
                  <th className="text-left py-2 px-3">Amount</th>
                  <th className="text-left py-2 px-3">Date</th>
                </tr>
              </thead>
              <tbody>
                {banks.map((e) => (
                  <tr key={e._id} onClick={() => setSelectedBank(e)} className="border-t">
                    <td className="py-2 px-3">{(e.name || "").substring(0, 25)}</td>
                    <td className="py-2 px-3">{`${e.category || "OTHER"}`}</td>
                    <td className="py-2 px-3">{`${e.amount
                      .toFixed(0)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}€`}</td>
                    <td className="py-2 px-3">{`${new Date(e.month).toLocaleDateString()}`}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className="flex-1">
          <div className="text-lg font-semibold mb-5">Stats</div>
          <div className="border rounded-md">
            <table className="text-sm">
              <thead>
                <tr>
                  <th className="text-left py-2 px-3">Category</th>
                  <th className="text-left py-2 px-3">Amount</th>
                </tr>
              </thead>
              <tbody>
                {stats
                  .sort((a, b) => a.amount - b.amount)
                  .map((e) => (
                    <tr key={e._id} className="border-t">
                      <td className="py-2 px-3">{e.category}</td>
                      <td className="py-2 px-3">
                        {e.amount
                          .toFixed(0)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

const Graph = ({ data, onClick, category }) => {
  const [newData, setNewData] = useState(data);
  const [keys, setKeys] = useState(["Revenue", "Cost", "Benefit"]);

  useEffect(() => {
    setKeys(["Revenue", "Cost", "Benefit", category]);
    const n = data.map((e) => {
      if (!category) return e;
      const find = e.banks.find((f) => {
        if (category === "OTHER") return !f._id || f._id === "OTHER";
        return f._id === category;
      });
      if (!find) return e;
      return { ...e, [category]: -find.total.toFixed(0) };
    });
    setNewData(n);
  }, [category]);

  return (
    <ResponsiveBar
      data={newData}
      keys={keys}
      indexBy={"month"}
      margin={{ top: 50, right: 10, bottom: 10, left: 65 }}
      padding={0.3}
      groupMode={"grouped"}
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      colors={{ scheme: "nivo" }}
      borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
      axisBottom={{ tickSize: 5, tickPadding: 5, tickRotation: 0, legend: "month", legendPosition: "middle", legendOffset: 32 }}
      axisLeft={{ tickSize: 5, tickPadding: 5, tickRotation: 0, legend: "Rentability", legendPosition: "middle", legendOffset: -60 }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
      role="application"
      ariaLabel={"Rentability"}
      onClick={onClick}
      barAriaLabel={(e) => e.id + ": XXXX" + e.formattedValue + " in: " + e.indexValue}
      legends={[
        {
          anchor: "top",
          direction: "row",
          translateY: -35,
          itemWidth: 150,
          itemHeight: 20,
          symbolSize: 20,
          symbolShape: "circle",
          textColor: "#999",
          effects: [{ on: "hover", style: { itemTextColor: "#000" } }],
        },
      ]}
    />
  );
};
