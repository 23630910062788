import React, { useContext, useEffect } from "react";
// import { Prompt } from "react-router-dom";

import Step1 from "./step";
import Step2 from "./step_medium";

import Header from "./header";

import App, { AppContext } from "./context";
import Comments from "./comments";

export default ({}) => {
  const context = useContext(AppContext);

  useEffect(() => {
    const sidebar = document.getElementById("sidebar");
    if (!sidebar) return;

    if (context.index !== 0 && context.index !== context.workshop.steps.length + 1) {
      sidebar.style.display = "none";
    }
    return () => {
      sidebar.style.display = "block";
    };
  }, [context.index]);

  if (!context.workshop) return <div>Loading...</div>;
  if (context.index == 0 || context.index == context.workshop.steps.length + 1) return <div />;

  return (
    <div className="w-screen h-screen fixed inset-0 z-50 bg-white flex flex-col justify-between overflow-y-auto pb-[120px]">
      <div className="w-full  h-full">
        <Header />
        <div className="flex p-4">
          <div className="max-w-7xl w-full ml-auto py-3 lg:py-16 px-3" id="content" key={context.index}>
            {/* <Prompt when={context.unsaved} message="You have unsaved changes, are you sure you want to leave?" /> */}
            <div className="flex justify-between">{context.mode === "normal" ? <Step1 /> : <Step2 />}</div>
          </div>
          <Comments />
        </div>
      </div>
    </div>
  );
};
