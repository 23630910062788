import React, { useEffect, useRef, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import toast from "react-hot-toast";
import { IoCreate, IoCheckmark, IoBanOutline, IoTrash } from "react-icons/io5";

import Team from "./components/Team";
import Table from "./components/Table";
import Header from "./components/Header";
import Portfolio from "./components/Portfolio";
import CvHeader from "./components/CvHeader";
import CvFormation from "./components/CvFormation";
import CvExperience from "./components/CvExperience";
import MultipleImages from "./components/MultipleImages";
import Signature from "./components/Signature";
import Text from "./components/Text";
import Cards from "./components/Cards";
import TwoColsPresentation from "./components/TwoColsPresentation";
import Contact from "./components/Contact";
import Image from "./components/Image";

import Pages from "./pages";

import api from "../../../services/api";

const PDF_PAGE_HEIGHT = 1145;

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);

  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const Content = ({ pages, blocks, quote, onPagesChange, onBlocksChange, selection, onSelect }) => {
  const pageRefs = useRef([]);
  const [copiedBlock, setCopiedBlock] = useState(null);
  const [isPageOverflow, setIsPageOverflow] = useState([]);
  const [ctrlDown, setCtrlDown] = useState(false);

  useEffect(() => {
    const handleKeyDown = async (e) => {
      const isTextInput = e.target.tagName === "INPUT" || e.target.tagName === "TEXTAREA" || e.target.isContentEditable;
      if (isTextInput) return;

      if (e.key === "Control" || e.key === "Meta") {
        setCtrlDown(true);
      }

      if (ctrlDown && e.key === "c") {
        if (!selection.block) return;
        const blockToCopy = blocks.find((block) => block._id === selection.block);
        setCopiedBlock(blockToCopy);
        console.log("Block copied:", blockToCopy);
        e.preventDefault();
      }

      if (ctrlDown && e.key === "v") {
        if (!copiedBlock) return;
        const newBlock = { ...copiedBlock, _id: undefined, position: blocks.length };
        const { data: b } = await api.post(`/quote_block`, newBlock);
        onBlocksChange([...blocks, b]);
        e.preventDefault();
      }
    };

    const handleKeyUp = (e) => {
      if (e.key === "Control" || e.key === "Meta") {
        setCtrlDown(false);
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, [blocks]);

  const checkOverflow = () => {
    const overflowCheck = pageRefs.current.map((ref) => {
      if (ref && ref.current) {
        return ref.current.offsetHeight - PDF_PAGE_HEIGHT;
      }
      return 0;
    });
    setIsPageOverflow(overflowCheck);
  };

  useEffect(() => {
    pages.map((item, index) => {
      if (!pageRefs.current[index]) {
        pageRefs.current[index] = React.createRef();
      }
    });

    checkOverflow();

    const observers = pageRefs.current.map((ref) => {
      if (ref && ref.current) {
        const observer = new MutationObserver(checkOverflow);
        observer.observe(ref.current, { attributes: true, childList: false, subtree: false });
        return observer;
      }
      return null;
    });

    return () => {
      observers.forEach((observer) => {
        if (observer) {
          observer.disconnect();
        }
      });
    };
  }, [pages, blocks]);

  const handleAddPage = async (index) => {
    const currentPosition = pages[index].position;
    const obj = {
      name: `Page ${pages.length + 1}`,
      position: currentPosition + 1,
      format: "A4",
      quote_id: quote._id,
      quote_name: quote.name,
    };
    const { ok, data } = await api.post("/quote_page", obj);
    if (!ok) return toast.error("Error adding page");

    const newPages = [...pages];

    for (const page of newPages) {
      if (page.position < currentPosition + 1) continue;
      page.position++;
      await api.put(`/quote_page/${page._id}`, { position: page.position });
    }

    onPagesChange([...newPages, data]);
    onSelect({ block: null, page: data._id });

    toast.success("Page added");
  };

  const handlePageDelete = async (id) => {
    if (!window.confirm("Are you sure you want to delete this page?")) {
      return;
    }

    try {
      const { ok } = await api.remove(`/quote_page/${id}`);
      if (!ok) throw new Error("Error deleting page");

      const deletedBlocks = blocks.filter((b) => b.quote_page_id === id);
      for (let i = 0; i < deletedBlocks.length; i++) {
        const { ok } = await api.remove(`/quote_block/${deletedBlocks[i]._id}`);
        if (!ok) throw new Error("Error deleting block");
      }

      const newPages = pages.filter((p) => p._id !== id);
      const newBlocks = blocks.filter((b) => b.quote_page_id !== id);
      onPagesChange(newPages);
      onBlocksChange(newBlocks);
      toast.success("Page deleted");
    } catch (error) {
      console.error(error);
      toast.error("Error deleting page");
    }
  };

  const handleBlockChange = (values) => {
    const newBlocks = [...blocks];
    const index = newBlocks.findIndex((block) => block._id === values._id);
    newBlocks[index] = values;
    onBlocksChange(newBlocks);
  };

  const handleBlockDelete = (id) => {
    if (!window.confirm("Are you sure you want to delete this block?")) {
      return;
    }
    const newBlocks = blocks.filter((b) => b._id !== id);
    onBlocksChange(newBlocks);
  };

  const handleDragEnd = async (result) => {
    if (!result.destination) return;

    const { source, destination } = result;

    if (source.droppableId === destination.droppableId && source.index === destination.index) return;
    if (source.droppableId === destination.droppableId) {
      const pageBlocks = blocks.filter((block) => block.quote_page_id === source.droppableId);
      pageBlocks.sort((a, b) => a.position - b.position);
      const newBlocks = reorder(pageBlocks, source.index, destination.index);

      newBlocks
        .filter((block) => block.quote_page_id === source.droppableId)
        .forEach((block, index) => {
          block.position = index;
        });

      try {
        for (let i = 0; i < newBlocks.length; i++) {
          const { ok } = await api.put(`/quote_block/${newBlocks[i]._id}`, { position: newBlocks[i].position });
          if (!ok) throw new Error("Error updating block order");
        }
        onBlocksChange((oldBlocks) => {
          return oldBlocks.map((block) => {
            const newBlock = newBlocks.find((b) => b._id === block._id);
            if (newBlock) return newBlock;
            return block;
          });
        });
        toast.success("Block order updated");
      } catch (error) {
        console.error(error);
        toast.error("Error updating block order");
      }
    } else {
      const sourceBlocks = blocks.filter((block) => block.quote_page_id === source.droppableId);
      const destinationBlocks = blocks.filter((block) => block.quote_page_id === destination.droppableId);

      sourceBlocks.sort((a, b) => a.position - b.position);
      destinationBlocks.sort((a, b) => a.position - b.position);
      const result = move(sourceBlocks, destinationBlocks, source, destination);
      result[destination.droppableId].forEach((block, index) => {
        block.position = index;
        block.quote_page_id = destination.droppableId;
      });
      result[source.droppableId].forEach((block, index) => {
        block.position = index;
        block.quote_page_id = source.droppableId;
      });

      const newBlocks = blocks.filter((block) => block.quote_page_id !== source.droppableId && block.quote_page_id !== destination.droppableId);
      newBlocks.push(...result[source.droppableId]);
      newBlocks.push(...result[destination.droppableId]);

      try {
        for (let i = 0; i < newBlocks.length; i++) {
          const { ok } = await api.put(`/quote_block/${newBlocks[i]._id}`, { position: newBlocks[i].position, quote_page_id: newBlocks[i].quote_page_id });
          if (!ok) throw new Error("Error updating block order");
        }

        onBlocksChange((oldBlocks) => {
          return oldBlocks.map((block) => {
            const newBlock = result[destination.droppableId].find((b) => b._id === block._id);
            if (newBlock) {
              return newBlock;
            }
            return block;
          });
        });
        toast.success("Block order updated");
      } catch (error) {
        console.error(error);
        toast.error("Error updating block order");
      }
    }
  };

  return (
    <div className="flex-1 py-8 flex flex-col items-center">
      <Pages onSelect={(page) => onSelect({ page })}>
        <DragDropContext onDragEnd={handleDragEnd}>
          {pages
            .sort((a, b) => a.position - b.position)
            .map((page, index) => (
              <Droppable key={index} droppableId={`${page._id}`}>
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps} className="w-[794px] flex flex-col justify-center">
                    <div className="flex justify-end items-center mb-2">
                      <button
                        className="text-red-600"
                        onClick={(e) => {
                          console.log("delete page", page);
                          e.stopPropagation();
                          handlePageDelete(page._id);
                        }}>
                        <IoTrash />
                      </button>
                    </div>

                    <div
                      id={`page-${page._id}`}
                      style={{ "--image-url": `url(${page.background_image_url})` }}
                      className={`bg-cover bg-no-repeat ${
                        page.background_image_url ? `bg-[image:var(--image-url)] w-full` : `bg-white`
                      } page mx-auto px-8 py-6 border-2 flex flex-col w-full min-h-[1145px] relative ${
                        selection.page === page._id && !selection.block ? "border-sky-400" : "border-transparent"
                      }`}
                      onClick={(e) => {
                        e.stopPropagation();
                        onSelect({ block: null, page: page._id });
                      }}
                      ref={pageRefs.current[index]}>
                      {isPageOverflow[index] > 0 && (
                        <div
                          className={`w-full bg-red-600/30 text-center px-20 flex justify-center items-end absolute top-[1145px] left-0 z-10 pointer-events-none`}
                          style={{
                            height: `${isPageOverflow[index]}px`,
                          }}>
                          <div className="text-red-500 font-black mb-1">The content in the red area will overflow the PDF page</div>
                        </div>
                      )}
                      {blocks
                        .filter((block) => block.quote_page_id === page._id)
                        .sort((a, b) => a.position - b.position)
                        .map((block, index) => (
                          <Draggable key={`${block._id} - ${index}`} draggableId={`${block._id}`} index={index}>
                            {(provided) => (
                              <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                <Block
                                  block={block}
                                  selected={selection.block === block._id}
                                  onChange={handleBlockChange}
                                  onDelete={() => handleBlockDelete(block._id)}
                                  onSelect={() => onSelect({ block: block._id, page: page._id })}
                                />
                              </div>
                            )}
                          </Draggable>
                        ))}
                    </div>

                    <button className="w-[794px] flex justify-center items-center my-2 text-gray-400 hover:text-sky-400" onClick={() => handleAddPage(index)}>
                      <span className="w-1/2 h-px bg-gray-400" />
                      <span className="mx-10 ">+</span>
                      <span className="w-1/2 h-px bg-gray-400" />
                    </button>
                  </div>
                )}
              </Droppable>
            ))}
        </DragDropContext>
      </Pages>
    </div>
  );
};

const Block = ({ block, selected, onSelect, onChange, onDelete }) => {
  const [hovering, setHovering] = useState(false);
  const [editing, setEditing] = useState(false);
  const [values, setValues] = useState({ ...block });

  const handleCancel = () => {
    setEditing(false);
    setValues({ ...block });
  };

  const handleSave = async () => {
    try {
      console.log(values);
      const { ok } = await api.put(`/quote_block/${values._id}`, values);
      if (!ok) throw new Error("Error updating block");
      onChange(values);
      setEditing(false);
      toast.success("Block updated");
    } catch (error) {
      console.error(error);
      toast.error("Error updating block");
    }
  };

  const handleDelete = async () => {
    try {
      const { ok } = await api.remove(`/quote_block/${values._id}`);
      if (!ok) throw new Error("Error deleting block");
      onDelete();
    } catch (error) {
      console.error(error);
      toast.error("Error deleting block");
    }
  };

  return (
    <div
      className={`block px-4 py-2 relative border rounded ${selected ? "border border-sky-400" : hovering || editing ? "border-gray-400" : "border-transparent"}`}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      onDoubleClick={() => setEditing(true)}
      onClick={(e) => {
        e.stopPropagation();
        onSelect();
      }}>
      {hovering && !editing && (
        <div className="absolute top-2 right-2 flex items-center gap-2">
          <button className="gray-btn h-5 w-5 p-0" onClick={() => setEditing(true)}>
            <IoCreate />
          </button>
          <button className="gray-btn h-5 w-5 p-0" onClick={handleDelete}>
            <IoTrash />
          </button>
        </div>
      )}
      {editing && (
        <div className="absolute top-1 right-1 flex items-center gap-2">
          <button className="blue-btn h-5 w-5 p-0" onClick={() => handleSave(values)}>
            <IoCheckmark />
          </button>
          <button className="gray-btn h-5 w-5 p-0" onClick={handleCancel}>
            <IoBanOutline />
          </button>
          <button className="gray-btn h-5 w-5 p-0" onClick={handleDelete}>
            <IoTrash />
          </button>
        </div>
      )}

      {
        {
          text: <Text values={values} editing={editing} onChange={setValues} />,
          table: <Table values={values} editing={editing} onChange={setValues} onSave={() => handleSave(values)} />,
          team: <Team values={values} editing={editing} onChange={setValues} />,
          header: <Header values={values} editing={editing} onChange={setValues} />, // not in block menu
          portfolio: <Portfolio values={values} editing={editing} onChange={setValues} />, // not in block menu
          cv_header: <CvHeader values={values} editing={editing} onChange={setValues} />,
          cv_formation: <CvFormation values={values} editing={editing} onChange={setValues} />,
          cv_experience: <CvExperience values={values} editing={editing} onChange={setValues} />,
          multiple_images: <MultipleImages values={values} editing={editing} onChange={setValues} />,
          signature: <Signature values={values} editing={editing} onChange={setValues} />,
          cards: <Cards values={values} editing={editing} onChange={setValues} />,
          contact: <Contact values={values} editing={editing} onChange={setValues} />,
          two_cols_presentation: <TwoColsPresentation values={values} editing={editing} onChange={setValues} />,
          image: <Image values={values} editing={editing} onChange={setValues} />,
        }[block.type]
      }
    </div>
  );
};

export default Content;
