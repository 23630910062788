import React from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import Loader from "../../../components/loader";
import api from "../../../services/api";
import SelectUser from "../../../components/selectUser";
import { JOB_CONTRACTS, JOB_LOCATIONS, JOB_POSITIONS } from "../../../constants";

const View = ({ job, setJob }) => {
  const navigate = useNavigate();

  async function onDelete() {
    const confirm = window.confirm("Are you sure ?");
    if (!confirm) return;
    await api.remove(`/job/${job._id}`);
    toast.success("successfully removed!");
    navigate("/job");
  }

  async function handleSubmit() {
    try {
      const { ok, data, code } = await api.put(`/job/${job._id}`, job);
      if (!ok) return toast.error(`Error !\n${code}`, { icon: "❌" });
      setJob(data);
      toast.success("Job post updated !");
    } catch (e) {
      console.log(e);
      toast.error("Some Error!", e.code);
    }
  }

  if (!job) return <Loader />;

  return (
    <div className="flex flex-col gap-4 p-2 ">
      <div className="p-12 pb-6 bg-white rounded-2xl">
        <div className="grid grid-cols-2 gap-4 p-2">
          <div>
            <div className="">Title</div>
            <input
              type="text"
              name="title"
              id="title"
              className="block rounded-md border-[1px] border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-sm placeholder:text-gray-300 py-2 px-4"
              placeholder="unlimited contract"
              value={job.title}
              onChange={(e) => setJob({ ...job, title: e.target.value })}
            />
          </div>
          <div>
            <div className="">Project</div>
            <input
              disabled
              type="text"
              name="project_name"
              id="project_name"
              className="block rounded-md border-[1px] border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-sm placeholder:text-gray-300 py-2 px-4"
              placeholder="unlimited contract"
              value={job.project_name}
            />
          </div>
          <div>
            <div className="">Client</div>
            <input
              type="text"
              name="client"
              onChange={(e) => setJob({ ...job, client: e.target.value })}
              id="client"
              className="block rounded-md border-[1px] border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-sm placeholder:text-gray-300 py-2 px-4"
              placeholder="Client"
              value={job.client}
            />
          </div>
          <div>
            <div className="">Lead</div>
            <SelectUser
              value={{ name: job.user_name, _id: job.user_id }}
              onChange={(user) =>
                setJob({
                  ...job,
                  user_id: user._id,
                  user_name: user.name,
                  user_avatar: user.avatar,
                  user_email: user.email,
                })
              }
            />
          </div>
          <div>
            <div className="">Status</div>
            <select
              id="status"
              className="block rounded-md border-[1px] border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-sm placeholder:text-gray-300 py-2 px-4"
              defaultValue="active"
              value={job.status}
              onChange={(e) => setJob({ ...job, status: e.target.value })}>
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </select>
          </div>
          <div>
            <label className="m-0" htmlFor="email">
              Contract type
            </label>
            <select className="input" name="contract" value={job.contract_type} onChange={(e) => setJob({ ...job, contract_type: e.target.value })}>
              <option value=""></option>
              {JOB_CONTRACTS.map((e) => (
                <option key={e} value={e}>
                  {e}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label className="m-0" htmlFor="location">
              Location
            </label>
            <select className="input" name="location" value={job.location} onChange={(e) => setJob({ ...job, location: e.target.value })}>
              <option value=""></option>
              {JOB_LOCATIONS.map((e) => (
                <option key={e} value={e}>
                  {e}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label className="m-0" htmlFor="sector">
              Sector
            </label>
            <select className="input" name="sector" value={job.sector} onChange={(e) => setJob({ ...job, sector: e.target.value })}>
              <option value=""></option>
              {JOB_POSITIONS.map((e) => (
                <option key={e} value={e}>
                  {e}
                </option>
              ))}
            </select>
          </div>
          <div />
        </div>
        <div className="flex mt-2">
          <button className="bg-[#0560FD] text-[16px] font-medium text-[#fff] py-[12px] px-[22px] rounded-[10px]" onClick={handleSubmit}>
            Update
          </button>
          <button className="ml-[10px] bg-[#F43F5E] text-[16px] font-medium text-[#FFFFFF] py-[12px] px-[22px] rounded-[10px]" onClick={onDelete}>
            Delete
          </button>
        </div>
        {/* <pre className="mt-5 text-xs">{JSON.stringify(job, null, 2)}</pre> */}
      </div>
    </div>
  );
};

export default View;
