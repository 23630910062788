import React, { Fragment, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { MdDeleteOutline, MdOutlineModeEdit, MdUpload } from "react-icons/md";

import { readFileAsync } from "../utils";
import api from "../services/api";
import { AiFillFilePdf } from "react-icons/ai";

// folder should look like "/myfolder"
const FileInput = ({ value, onChange, name, folder, allowMultiple = false, resetOnChange = false, className = "w-20 h-20" }) => {
  const [loading, setLoading] = useState(false);
  let preview = null;

  if (!value) {
    preview = null;
  } else if (value.indexOf(".pdf") !== -1) {
    console.log("pdf");
    preview = (
      <div className="absolute top-0 left-0 w-20 h-20 bg-gray-300 opacity-80 hover:opacity-90 flex items-center justify-center cursor-pointer text-[12px] text-black font-normal">
        <AiFillFilePdf size={48} />
      </div>
    );
  } else {
    preview = <img className="" src={value} alt="user.png" />;
  }
  const handleDeleteFile = async () => {
    onChange({ target: { value: "", name } });
  };

  if (!value)
    return (
      <div className={`relative rounded-xl overflow-hidden border border-gray-200 ${className}`}>
        <label
          htmlFor={`openFile-${name}`}
          className="absolute top-0 right-0 px-3 py-2 w-full h-full hover:backdrop-blur-sm cursor-pointer flex flex-col items-center justify-center">
          <MdUpload />
          <span className="text-sm">Upload</span>
        </label>
        <input
          id={`openFile-${name}`}
          type="file"
          multiple={allowMultiple}
          onChange={async (e) => {
            e.persist();
            setLoading(true);
            const files = e.target.files;
            const filePromises = Array.from(files).map(async (f) => {
              const rawBody = await readFileAsync(f);
              const { data } = await api.post(`/file`, { file: { rawBody, name: f.name }, folder });
              return data;
            });
            const fileDataArray = await Promise.all(filePromises);
            onChange({ target: { value: fileDataArray, name } });
            if (resetOnChange) e.target.value = null;
            setLoading(false);
          }}
        />
      </div>
    );

  return (
    <div className="relative w-20 h-20 ">
      <div className="rounded-xl w-full h-full overflow-hidden border border-gray-200">{preview}</div>
      <div className="absolute top-0 right-0">
        <Popover className="relative">
          <Popover.Button className="p-1 group inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">
            <div className="p-1 flex items-center justify-center rounded-full bg-gray-100 opacity-60 group-hover:opacity-100">
              <BiDotsVerticalRounded className="text-lg text-black" />
            </div>
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1">
            <Popover.Panel className="absolute left-1/2 z-10 flex w-screen max-w-min -translate-x-1/2 px-4">
              <div className="flex flex-col w-40 shrink rounded-xl overflow-hidden bg-white text-sm leading-6 text-gray-900 shadow-lg ring-1 ring-gray-900/5 divide-y divider-gray-200">
                <label htmlFor={`openFile-${name}`} className="px-3 py-2 w-full hover:bg-gray-50 cursor-pointer">
                  <div className="flex gap-1 items-center">
                    <MdOutlineModeEdit />
                    Edit
                  </div>
                </label>
                <button className="px-3 py-2 w-full hover:bg-gray-50 text-left flex gap-1 items-center" onClick={handleDeleteFile}>
                  <MdDeleteOutline />
                  Delete file
                </button>
              </div>
            </Popover.Panel>
          </Transition>
        </Popover>
      </div>
      <input
        id={`openFile-${name}`}
        type="file"
        multiple={allowMultiple}
        onChange={async (e) => {
          setLoading(true);
          const files = e.target.files;
          const filePromises = Array.from(files).map(async (f) => {
            const rawBody = await readFileAsync(f);
            const { data } = await api.post(`/file`, { file: { rawBody, name: f.name }, folder });
            return data;
          });
          const fileDataArray = await Promise.all(filePromises);
          onChange({ target: { value: fileDataArray, name } });
          setLoading(false);
        }}
      />
    </div>
  );
};

export default FileInput;
