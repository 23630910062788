import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import api from "../../services/api";

import Table from "../../components/Table";
import Paginator from "../../components/Paginator";
import ActionModal from "../../components/ActionModal";

export default () => {
  const [actions, setActions] = useState([]);
  const [selectedAction, setSelectedAction] = useState(null);
  const [filters, setFilters] = useState({ page: 1 });
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    fetch();
  }, [filters]);

  async function fetch() {
    setLoading(true);
    const newFilters = { ...filters };
    const { data, total } = await api.post("/action/search", newFilters);
    setActions(data);
    setTotal(total);
    setLoading(false);
  }

  return (
    <div className="bg-white h-full">
      <div className="flex justify-between items-center">
        <Search setFilters={setFilters} filters={filters} />
        <ActionModal action={selectedAction} setAction={setSelectedAction} />
        <button
          className="bg-[#0560FD] text-[16px] font-medium text-[#FFFFFF] py-[12px] px-[22px] rounded-[10px]"
          onClick={async () => {
            const { data } = await api.post("/action", { name: "Mon action" });
            toast.success("Action created!");
            loadActions();
            setSelectedAction(data);
          }}>
          Add
        </button>
      </div>

      <div>{total} items</div>
      <Table
        total={total}
        header={[
          { title: "Name", key: "name" },
          { title: "Status", key: "status" },
          { title: "User", key: "userName" },
          { title: "Lead", key: "responsibleName" },
          { title: "Contact Name", key: "contactName" },
          { title: "Folk Name", key: "folkName" },
          { title: "Category", key: "category" },
          { title: "Deadline", key: "deadline" },
          { title: "Created At", key: "created_at" },
        ]}
        loading={loading}
        height="h-[32rem]"
        sticky={true}
        noResultRender={<div className="w-full bg-white text-center p-2 my-5 rounded shadow-sm">Aucune résultat n'a été trouvé</div>}>
        {actions.map((item, index) => {
          return (
            <tr className={`${index % 2 === 0 ? "bg-gray-100" : "bg-gray-50"} cursor-pointer`} key={item._id} onClick={() => setSelectedAction(item)}>
              <td className="border hover:bg-gray-200 px-4 py-2 max-w-xs truncate">{item.name}</td>
              <td className="border hover:bg-gray-200 px-4 py-2 max-w-xs truncate">{item.status}</td>
              <td className="border hover:bg-gray-200 px-4 py-2 max-w-xs truncate">
                <img src={item.userAvatar} className="w-6 h-6 rounded-full" alt="avatar" />
                <span className="ml-2">{item.userName}</span>
              </td>
              <td className="border hover:bg-gray-200 px-4 py-2 max-w-xs truncate">
                {item.responsibleName && (
                  <div>
                    <img src={item.responsibleAvatar} className="w-6 h-6 rounded-full" alt="avatar" />
                    <span className="ml-2">{item.responsibleName}</span>
                  </div>
                )}
              </td>
              <td className="border hover:bg-gray-200 px-4 py-2 max-w-xs truncate">{item.contactName}</td>
              <td className="border hover:bg-gray-200 px-4 py-2 max-w-xs truncate">{item.folkName}</td>
              <td className="border hover:bg-gray-200 px-4 py-2 max-w-xs truncate">{item.category}</td>
              <td className="border hover:bg-gray-200 px-4 py-2 max-w-xs truncate">{(item.deadline || "").substring(0, 10)}</td>
              <td className="border hover:bg-gray-200 px-4 py-2 max-w-xs truncate">{(item.createdAt || "").substring(0, 10)}</td>
            </tr>
          );
        })}
      </Table>
      <div className="mt-1">
        <Paginator page={filters.page} setPage={(page) => setFilters({ ...filters, page })} last={Math.ceil(total / 100)} />
      </div>
    </div>
  );
};

const Search = ({ filters, setFilters }) => {
  const [str, setStr] = useState("");

  useEffect(() => {
    setStr(filters.search || "");
  }, [filters.search]);

  return (
    <div className="pr-2 pl-2 pb-2 flex items-start">
      <input type="text" className="form-control" placeholder="Search" value={str} onChange={(e) => setStr(e.target.value)} />
      <button type="submit" className="ml-2 btn btn-primary" onClick={() => setFilters({ ...filters, search: str })}>
        Search
      </button>
    </div>
  );
};
