import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

import Pagination from "./Paginator";
import Table from "./Table";

const TablePagination = ({ header, total, sort, onSort, loading, children, page, onPageChange, pageSize = 10, fixed = true, sticky = false, height = "h-fit" }) => {
  if (total === 0 && !loading) return <div className="w-full">No data found</div>;
  return (
    <div className="w-full space-y-4">
      <Table header={header} sort={sort} onSort={onSort} total={total} loading={loading} fixed={fixed} sticky={sticky} height={height}>
        {children}
      </Table>
      {total / pageSize > 1 && (
        <div className="flex justify-center mx-[25%]">
          <Pagination page={page} setPage={onPageChange} last={Math.ceil(total / pageSize)} />
        </div>
      )}
    </div>
  );
};

export default TablePagination;
