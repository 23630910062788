import React, { useEffect, useState } from "react";

import SebSignaturePng from "../../../assets/seb-signature.png";

const TEMPLATE_NDA = ({ values, setValues }) => {
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    const e = [];
    if (!values.date) e.push(`Missing date`);
    if (!values.properties?.name) e.push(`Missing name`);
    if (!values.properties?.address) e.push(`Missing address`);
    setErrors(e);

    document.querySelector("#content #name").innerHTML = values.properties?.name;
    document.querySelector("#content #name-1").innerHTML = values.properties?.name;
    document.querySelector("#content #date").innerHTML = formatDate(values?.date);
    document.querySelector("#content #date-1").innerHTML = formatDate(values?.date);
    document.querySelector("#content #date-2").innerHTML = formatDate(values?.date);
    document.querySelector("#content #address").innerHTML = values.properties?.address;
  }, [values]);

  return (
    <div>
      <div className="grid grid-cols-4 gap-3 items-center justify-between mb-3">
        <div>
          <div className="font-medium mb-1 text-sm">Choose date</div>
          <input
            className="projectsInput text-[14px] font-normal text-[#212325] rounded-[10px]"
            type="date"
            value={formatDate(values.date)}
            onChange={(e) => setValues({ ...values, date: new Date(e.target.value) })}
          />
        </div>
        <div>
          <div className="font-medium mb-1 text-sm">Name</div>
          <input
            className="projectsInput text-[14px] font-normal text-[#212325] rounded-[10px]"
            value={values.properties?.name}
            onChange={(e) => setValues({ ...values, properties: { ...values.properties, name: e.target.value } })}
          />
        </div>
        <div>
          <div className="font-medium mb-1 text-sm">Address</div>
          <input
            className="projectsInput text-[14px] font-normal text-[#212325] rounded-[10px]"
            value={values.properties?.address}
            onChange={(e) => setValues({ ...values, properties: { ...values.properties, address: e.target.value } })}
          />
        </div>
      </div>
      {!!errors.length && (
        <div className="mb-4">
          <div className="font-bold mb-2">Errors</div>
          <div>
            {errors.map((e) => {
              return (
                <div className="text-light-grey text-sm mb-1" key={e}>
                  {e}
                </div>
              );
            })}
          </div>
        </div>
      )}
      <div id="content" className="p-4 text-base">
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    </div>
  );
};

function formatDate(date) {
  if (!date) return;
  return new Date(date).toISOString().slice(0, 10);
}

const html = `

<html>
<p><strong>NON-DISCLOSURE AGREEMENT (NDA)</strong>&nbsp;</p>
<p><br></p>
<p>This Nondisclosure Agreement or ("Agreement") has been entered into on the date of <span id="date">{{DATE}}</span> and is by and
    between:&nbsp;Party Disclosing Information: <span id="name">{{NAME}}</span> with a
    mailing address of <span id="address">{{ADDRESS}}</span> (“Disclosing Party”).&nbsp;Party Receiving Information :
    SELEGO B.V with a mailing address of Oudeschans 85 II, 1011 KW Amsterdam, Netherlands (“Receiving Party”)&nbsp;For the purpose of preventing the unauthorized
    disclosure of Confidential Information as defined below. The parties agree to enter into a confidential relationship
    concerning the disclosure of certain proprietary and confidential information ("Confidential Information").&nbsp;
</p>
<p><br></p>
<p><strong>1. Definition of Confidential Information.&nbsp;</strong></p>
<p>For purposes of this Agreement, "Confidential Information" shall include all information or material that has or
    could have commercial value or other utility in the business in which Disclosing Party is engaged. If Confidential
    Information is in written form, the Disclosing Party shall label or stamp the materials with the word "Confidential"
    or some similar warning. If Confidential Information is transmitted orally, the Disclosing Party shall promptly
    provide writing indicating that such oral communication constituted Confidential Information.&nbsp;</p>
<p><br></p>
<p><strong>&nbsp;2. Exclusions from Confidential Information.&nbsp;</strong></p>
<p>Receiving Party's obligations under this Agreement do not extend to information that is:<br>
    (a) publicly known at the time of disclosure or subsequently becomes publicly known through no fault of the
    Receiving Party;<br>
    (b) discovered or created by the Receiving Party before disclosure by Disclosing Party;<br>
    (c) learned by the Receiving Party through legitimate means other than from the Disclosing Party or Disclosing
    Party's representatives; or<br>
    (d) is disclosed by Receiving Party with Disclosing Party's prior written approval&nbsp;</p>
<p><br></p>
<p><strong>&nbsp;3. Obligations of Receiving Party.&nbsp;</strong></p>
<p>Receiving Party shall hold and maintain the Confidential Information in strictest confidence for the sole and
    exclusive benefit of the Disclosing Party. Receiving Party shall carefully restrict access to Confidential
    Information to employees, contractors and third parties as is reasonably required and shall require those persons to
    sign nondisclosure restrictions at least as protective as those in this Agreement. Receiving Party shall not,
    without the prior written approval of Disclosing Party, use for Receiving Party's benefit, publish, copy, or
    otherwise disclose to others, or permit the use by others for their benefit or to the detriment of Disclosing Party,
    any Confidential Information. Receiving Party shall return to Disclosing Party any and all records, notes, and other
    written, printed, or tangible materials in its possession pertaining to Confidential Information immediately if
    Disclosing Party requests it in writing.&nbsp;</p>
<p><br></p>
<p><strong>&nbsp;4. Time Periods.&nbsp;</strong></p>
<p>The nondisclosure provisions of this Agreement shall survive the termination of this Agreement and Receiving Party's
    duty to hold Confidential Information in confidence shall remain in effect until the Confidential Information no
    longer qualifies as a trade secret or until Disclosing Party sends Receiving Party written notice releasing
    Receiving Party from this Agreement, whichever occurs first.&nbsp;</p>
<p><br></p>
<p><strong>&nbsp;5. Relationships.&nbsp;</strong></p>
<p>Nothing contained in this Agreement shall be deemed to constitute either party a partner, joint venture or employee
    of the other party for any purpose.&nbsp;</p>
<p><br></p>
<p><strong>&nbsp;6. Severability.&nbsp;</strong></p>
<p>If a court finds any provision of this Agreement invalid or unenforceable, the remainder of this Agreement shall be
    interpreted so as best to affect the intent of the parties&nbsp;</p>
<p><br></p>
<p><strong>&nbsp;7. Integration.&nbsp;</strong></p>
<p>This Agreement expresses the complete understanding of the parties with respect to the subject matter and supersedes
    all prior proposals, agreements, representations, and understandings. This Agreement may not be amended except in
    writing signed by both parties.&nbsp;</p>
<p><br></p>
<p><strong>&nbsp;8. Waiver.&nbsp;</strong></p>
<p>The failure to exercise any right provided in this Agreement shall not be a waiver of prior or subsequent
    rights.&nbsp;</p>
<p><br></p>
<p><br></p>
<p><strong>&nbsp;9. Notice of Immunity.&nbsp;</strong></p>
<p>Employee is provided notice that an individual shall not be held criminally or civilly liable under any federal or
    state trade secret law for the disclosure of a trade secret that is made (i) in confidence to a federal, state, or
    local government official, either directly or indirectly, or to an attorney; and (ii) solely for the purpose of
    reporting or investigating a suspected violation of law; or is made in a complaint or other document filed in a
    lawsuit or other proceeding, if such filing is made under seal. An individual who files a lawsuit for retaliation by
    an employer for reporting a suspected violation of law may disclose the trade secret to the attorney of the
    individual and use the trade secret information in the court proceeding, if the individual (i) files any document
    containing the trade secret under seal; and (ii) does not disclose the trade secret, except pursuant to court
    order.&nbsp;</p>
<p><br></p>
<p>This Agreement and each party's obligations shall be binding on the representatives, assigns and successors of such
    party. Each party has signed this Agreement through its authorized representative&nbsp;</p>
<p><br></p>
<div className="page_break_before pt-10"></div>
<p><strong>DISCLOSING PARTY&nbsp;</strong></p>
<p>&nbsp;Signature:&nbsp;</p>
<p>&nbsp;Typed or Printed Name: <strong id="name-1">{{NAME}}</strong>
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Date: <span id="date-1">{{DATE}}</span>
</p>
<p className="mt-10"><strong>RECEIVING PARTY&nbsp;</strong></p>
<p>&nbsp;Signature:&nbsp;</p>
<p>&nbsp;Typed or Printed Name: <strong>Selego B.V &nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong>Date
    :&nbsp; <span id="date-2">{{DATE}}</span></p>
<p><br></p>
<img src="${SebSignaturePng}" alt="" />
<p><br></p>
<h3><br></h3>
<h2><br></h2>
<h1><br></h1>
<p><br></p>

</html>
`;

function isNdaValid(values) {
  return values.properties?.name && values.properties?.address;
}

export { TEMPLATE_NDA, isNdaValid };
