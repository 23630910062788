import React from "react";
import { Route, Routes } from "react-router-dom";

import Reset from "./reset";
import Forgot from "./forgot";
import Signin from "./signin";
import Signup from "./signup";
import MagicLink from "./MagicLink";

const Auth = () => {
  return (
    <Routes>
      <Route path="/" element={<Signin />} />
      <Route path="/magic-link" element={<MagicLink />} />
      <Route path="/reset" element={<Reset />} />
      <Route path="/forgot" element={<Forgot />} />
      <Route path="/signup/*" element={<Signup />} />
    </Routes>
  );
};

export default Auth;
