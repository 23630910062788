import React from "react";
import api from "../../../services/api";
import toast from "react-hot-toast";

function Edit({ values, setValues }) {
  async function onSave() {
    await api.put(`/portfolio_company/${values._id}`, values);
    toast.success("Company updated. Refresh to update shares");
  }
  async function onDelete() {
    if (!window.confirm("Are you sure you want to delete this company?")) return;
    await api.remove(`/portfolio_company/${values._id}`);
    toast.success("Company deleted");
  }
  return (
    <div className="w-full flex flex-col gap-2">
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
        <div className="mb-4">
          <label className="block font-medium text-gray-600 mb-1">Name of the company</label>
          <input
            value={values.name}
            onChange={(e) => setValues({ ...values, name: e.target.value })}
            className="w-full border border-gray-300 rounded px-2 py-1 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        <div className="mb-4">
          <label className="block font-medium text-gray-600 mb-1">Valuation of the company</label>
          <input
            value={values.valuation}
            onChange={(e) => setValues({ ...values, valuation: e.target.value })}
            type="number"
            className="disabled:bg-gray-200 w-full border border-gray-300 rounded px-2 py-1 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <div className="mb-4">
          <label className="block font-medium text-gray-600 mb-1">Percentage owned</label>
          <input
            value={values.percentageOwned}
            onChange={(e) => setValues({ ...values, percentageOwned: e.target.value })}
            type="number"
            className="disabled:bg-gray-200 w-full border border-gray-300 rounded px-2 py-1 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <div className="mb-4">
          <label className="block font-medium text-gray-600 mb-1">Total invested (€)</label>
          <input
            value={values.initialInvestment}
            onChange={(e) => setValues({ ...values, initialInvestment: e.target.value })}
            type="number"
            className="disabled:bg-gray-200 w-full border border-gray-300 rounded px-2 py-1 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <div className="mb-4 col-span-2">
          <label className="block font-medium text-gray-600 mb-1">Description</label>
          <textarea
            value={values.description}
            onChange={(e) => setValues({ ...values, description: e.target.value })}
            className="w-full border h-32 border-gray-300 rounded px-2 py-1 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <div className="mb-4">
          <label className="block font-medium text-gray-600 mb-1">Diff(Gains)</label>
          <input
            value={values.gains}
            onChange={(e) => setValues({ ...values, gains: e.target.value })}
            className="disabled:bg-gray-200 w-full border border-gray-300 rounded px-2 py-1 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        <div className="mb-4">
          <label className="block font-medium text-gray-600 mb-1">Status</label>
          <select
            className="!py-2 focus:!ring-0"
            value={values.status || ""}
            onChange={(e) => {
              e.persist();
              setValues((prev) => ({ ...prev, status: e?.target?.value }));
            }}>
            <option disabled value="">
              Select
            </option>
            {["ACTIVE", "INACTIVE"].map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className={"flex"}>
        <button className="btn btn-primary" onClick={onSave}>
          Save
        </button>
        <button className="btn btn-secondary ml-2" onClick={onDelete}>
          Delete
        </button>
      </div>
    </div>
  );
}

export default Edit;
