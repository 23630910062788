import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { MdShare, MdModeEdit, MdClose, MdPanTool } from "react-icons/md";
import toast from "react-hot-toast";

import { AppContext } from "./context";
import { copyToClipboard } from "../../../utils";

export default ({}) => {
  const { index, setIndex, workshop, setMode, mode } = useContext(AppContext);
  const max = workshop.steps.length;
  const currentStep = workshop.steps[index - 1];

  const progressValue = (index * 100) / max;

  const user = useSelector((state) => state.Auth.user);

  return (
    <div className="bg-gray-100 flex justify-between items-center border-b border-gray-200">
      <div className="w-full">
        <div className="flex flex-col md:flex-row">
          <div className="flex items-center gap-3 p-3">
            <div className="border border-gray-200 relative w-10 h-10 rounded-xl overflow-hidden">
              <img src={workshop.image} alt="workshop.image" />
            </div>
            <div className="flex-1">
              <div className="text-xs text-gray-600 uppercase">{workshop.name}</div>
              <div className="text-base md:text-xl font-black">{currentStep.title}</div>
            </div>
          </div>
          <div className="flex-1 self-center">
            <div className="w-full max-w-xs mx-auto bg-gray-300 rounded-full h-2">
              <div className="bg-blue-600 h-2 rounded-full transition-all duration-300" style={{ width: `${progressValue}%` }}></div>
            </div>
          </div>
          <div className="flex items-center gap-3 px-3 self-end md:self-center">
            <button
              title={mode === "normal" ? "Medium" : "Normal"}
              className="bg-white rounded-full w-9 h-9 border border-gray-200 flex items-center justify-center text-gray-600 hover:border-gray-300 hover:text-primary"
              onClick={() => setMode(mode === "normal" ? "medium" : "normal")}>
              <MdPanTool className="text-base" />
            </button>
            <button
              title="Share"
              className="bg-white rounded-full w-9 h-9 border border-gray-200 flex items-center justify-center text-gray-600 hover:border-gray-300 hover:text-primary"
              onClick={() => {
                copyToClipboard(`https://accounting.selego.co/${workshop.public ? "learn_public" : "learn"}/${workshop._id}?index=${index}`);
                toast.success("URL copied !");
              }}>
              <MdShare className="text-base" />
            </button>
            {user.role === "admin" && (
              <Link to={`/learn-editor/${workshop._id}?step=${index - 1}`} className="text-blue-600 text-xs text-center	underline">
                <button
                  title="Edit"
                  className="bg-white rounded-full w-9 h-9 border border-gray-200 flex items-center justify-center text-gray-600 hover:border-gray-300 hover:text-primary"
                  onClick={() => {
                    copyToClipboard(`https://accounting.selego.co/${workshop.public ? "learn_public" : "learn"}/${workshop._id}?index=${index}`);
                    toast.success("URL copied !");
                  }}>
                  <MdModeEdit className="text-base" />
                </button>
              </Link>
            )}
            <button
              title="Close"
              className="bg-white rounded-full w-9 h-9 border border-gray-200 flex items-center justify-center text-gray-600 hover:border-gray-300 hover:!text-primary"
              onClick={() => setIndex(0)}>
              <MdClose className="text-base" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
