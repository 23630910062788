import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import Budget from "../../../components/budget";
import Loader from "../../../components/loader";
import SelectType from "../../../components/selectType";
import SelectUser from "../../../components/selectUser";
import DebounceInput from "../../../components/DebounceInput";
import Modal from "../../../components/modal";
import Pagination from "../../../components/Paginator";
import SelectOrganisation from "../../../components/selectOrganisation";

import api from "../../../services/api";

export default () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [filters, setFilters] = useState({
    search: searchParams.get("search") || "",
    lead_id: searchParams.get("lead_id") || "",
    operating_partner_id: searchParams.get("operating_partner_id") || "",
    type: searchParams.get("type") || "",
    page: parseInt(searchParams.get("page")) || 1,
    per_page: 10,
  });
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState();
  const user = useSelector((state) => state.Auth.user);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const query = { status: "active" };
        if (filters.search) query.search = filters.search;
        if (filters.lead_id) query.lead_id = filters.lead_id;
        if (filters.operating_partner_id) query.operating_partner_id = filters.operating_partner_id;
        if (filters.type) query.type = filters.type;
        if (user.role === "client") query.client_id = user._id;
        query.page = filters.page;
        query.per_page = filters.per_page;

        const { ok, data } = await api.post("/project/search", query);
        if (!ok) throw new Error("Failed to fetch projects");
        setProjects(data.projects);
        setTotal(data.total);

        const newSearchParams = new URLSearchParams();
        if (filters.search) newSearchParams.set("search", filters.search);
        if (filters.lead_id) newSearchParams.set("lead_id", filters.lead_id);
        if (filters.operating_partner_id) newSearchParams.set("operating_partner_id", filters.operating_partner_id);
        if (filters.type) newSearchParams.set("type", filters.type);
        if (filters.page) newSearchParams.set("page", filters.page);

        setSearchParams(newSearchParams);
      } catch (error) {
        console.error(error);
        toast.error("Failed to fetch projects");
      }
      setLoading(false);
    };
    fetchData();
  }, [filters]);

  return (
    <div className="py-8 px-10">
      <div className="flex items-center justify-between mb-4">
        <h1 className="text-2xl font-semibold">{`${total ===undefined ? `Loading...` : `${total} projects`} `}</h1>
        <Create />
      </div>
      <section>
        <div className="flex items-center justify-between gap-4 mb-4">
          <div className="flex-1 space-y-1">
            <label htmlFor="search" className="text-sm font-semibold">
              Search
            </label>
            <DebounceInput debounce={300} value={filters.search} onChange={(e) => setFilters({ ...filters, search: e.target.value })} className="input" placeholder="Search" />
          </div>
          <div className="w-1/5 space-y-1">
            <label htmlFor="lead" className="text-sm font-semibold">
              Lead
            </label>
            <SelectUser value={filters.lead_id} onChange={(user) => setFilters({ ...filters, lead_id: user._id })} />
          </div>
          <div className="w-1/5 space-y-1">
            <label htmlFor="op" className="text-sm font-semibold">
              OP
            </label>
            <SelectUser value={filters.operating_partner_id} onChange={(user) => setFilters({ ...filters, operating_partner_id: user._id })} />
          </div>
          <div className="w-1/5 space-y-1">
            <label htmlFor="type" className="text-sm font-semibold">
              type
            </label>
            <div className="w-full">
              <SelectType
                value={filters.type}
                onChange={(value) => setFilters({ ...filters, type: value })}
                className="text-sm font-normal text-[#212325] border border-lightShade-grey py-2.5 rounded-[10px] self-center"
              />
            </div>
          </div>
        </div>
        <div className="space-y-6">
          {(projects || []).map((project) => (
            <Project project={project} />
          ))}
          <div className="flex justify-center mx-[25%]">
            <Pagination page={filters.page} setPage={(page) => setFilters({ ...filters, page })} last={Math.ceil(total / filters.per_page)} />
          </div>
        </div>
      </section>
    </div>
  );
};

const Project = ({ project }) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.Auth.user);
  return (
    <div
      key={project._id}
      onClick={() => navigate(`/project/${project._id}`)}
      className="grid grid-cols-12 items-center justify-between bg-white rounded-lg cursor-pointer border border-gray-200 hover:border-gray-300">
      <div className="col-span-8 flex items-center gap-x-6 p-4">
        <img className="h-12 w-12 flex-none rounded-md bg-gray-50 object-contain" src={project.logo} alt="project logo" />
        <div className="min-w-0">
          <div className="flex items-start gap-x-3 flex-wrap">
            <p className="text-base font-semibold leading-6 text-gray-900">{project.name}</p>
            {project.type ? (
              <p className="text-blue-700 bg-blue-50 ring-blue-600/20 rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset">{project.type}</p>
            ) : null}
            <p className="text-yellow-800 bg-yellow-50 ring-yellow-600/20 rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset">
              {project.organisation_name}
            </p>
          </div>

          <div className="flex flex-row items-center mt-2">
            <div className="flex items-center gap-x-2 mr-2">
              {project.lead_avatar && <img src={project.lead_avatar} alt="lead avatar" className="h-6 w-6 rounded-full" />}
              <p className="text-sm font-semibold text-gray-700">{project.lead_name || "none"}</p>
            </div>
            <div className="flex items-center gap-x-2 mr-2">
              {project.responsible_avatar && <img src={project.responsible_avatar} alt="op avatar" className="h-6 w-6 rounded-full" />}
              <p className="text-sm font-semibold text-gray-700">{project.responsible_name || "none"}</p>
            </div>
          </div>
          <div className="hidden sm:block mt-1 leading-5">
            {(project.description ? project.description : "").split("\n").map((line, i) => (
              <p key={i} className="text-sm font-medium text-gray-700">
                {line}
              </p>
            ))}
          </div>
        </div>
      </div>

      <div className="col-span-4 w-full p-4">
        <Budget project={project} />
      </div>
    </div>
  );
};

const Create = () => {
  const [values, setValues] = useState({ name: "", type: "", organisation_id: "", organisation_name: "" });
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async () => {
    setLoading(true);
    try {
      values.status = "active";
      const res = await api.post("/project", values);
      if (!res.ok) throw res;
      toast.success("Created!");
      setOpen(false);
      navigate(`/project/${res.data._id}`);
    } catch (e) {
      console.log(e);
      toast.error("Some Error!", e.code);
    }
    setLoading(false);
  };

  return (
    <>
      <button className="btn blue-btn" onClick={() => setOpen(true)}>
        Create new project
      </button>
      <Modal isOpen={open} className="max-w-3xl w-full p-6" onClose={() => setOpen(false)}>
        <div className="p-8">
          <h2 className="text-2xl font-semibold mb-4">Create Project</h2>
          <div className="grid grid-cols-2 gap-4 ">
            <div className="w-full col-span-2">
              <div className="text-sm font-medium mb-2">Name</div>
              <input className="projectsInput block" name="name" value={values.name} onChange={(e) => setValues({ ...values, name: e.target.value })} />
            </div>
            <div className="w-full mb-6">
              <div className="text-sm font-medium mb-2">Organisation</div>
              <SelectOrganisation
                value={values.organisation_id}
                className="projectsInput"
                onChange={(e) => setValues({ ...values, organisation_name: e.name, organisation_id: e._id })}
              />
            </div>
            <div className="w-full mb-6">
              <div className="text-sm font-medium mb-2">Type</div>
              <SelectType value={values.type} onChange={(value) => setValues({ ...values, type: value })} className="projectsInput" />
            </div>
          </div>
          <div className="flex justify-end mt-6">
            <button className="btn blue-btn w-32" disabled={!values.type || !values.organisation_id || !values.name || loading} onClick={handleSubmit}>
              {loading ? <Loader /> : "Create"}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};
