import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import toast from "react-hot-toast";

import Loader from "../../../components/loader";
import api from "../../../services/api";

const Dashboard = ({ project }) => {
  const [budgetChart, setBudgetChart] = useState({ amountUsed: 0, amount: 0 });

  const [budget, setBudget] = useState([]);
  const [devSeries, setDevSeries] = useState([]);
  const [labels, setLabels] = useState([]);
  const [otherSeries, setOtherSeries] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [total, setTotal] = useState(0);
  const [activities, setActivities] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchBudget();
    fetchActivities();
    fetchInvoices();
  }, []);

  const fetchBudget = async () => {
    setLoading(true);
    try {
      const res = await api.post(`/budget/search`, { projectId: project._id, status: "active" });
      if (!res.ok) return toast.error("Failed to fetch credits");
      setBudget(res.data);
      setBudgetChart({ amountUsed: res.data.reduce((acc, cur) => acc + (cur.amountUsed || 0), 0), amount: res.data.reduce((acc, cur) => acc + (cur.amount || 0), 0) });
    } catch (error) {
      console.error(error);
      toast.error("Failed to fetch data");
    }
  };

  const fetchActivities = async () => {
    try {
      // from this year
      const dateFrom = new Date();
      dateFrom.setMonth(0);
      dateFrom.setDate(1);
      dateFrom.setHours(0, 0, 0, 0);

      const { data } = await api.post(`/activity/search`, { projectId: project._id, dateFrom });
      setActivities(data);
    } catch (error) {
      console.error(error);
      toast.error("Failed to fetch data");
    }
  };

  const fetchInvoices = async () => {
    try {
      const query = { projectId: project._id };
      const { data, ok } = await api.post(`/invoice/search`, query);
      if (!ok) return toast.error("Error while fetching data");
      setInvoices(data);
      setTotal(data.length);
    } catch (error) {
      toast.error("Error while refreshing");
      console.error(error);
    }

    setLoading(false);
  };

  const invoicesAmount = invoices.reduce((acc, e) => acc + e.total, 0);
  const invoicesNotPaid = invoices.filter((e) => e.sent === "yes" && e.received === "no").length || 0;
  const invoicesNotPaidAmount = invoices.filter((e) => e.sent === "yes" && e.received === "no").reduce((acc, e) => acc + e.total, 0);

  if (loading) return <Loader />;

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-4 gap-3">
        <div className="rounded-md bg-white border col-span-1 flex flex-col gap-4 px-4 py-2">
          <Budget budgetChart={budgetChart} budget={budget} />
        </div>
        <div className="rounded-md bg-white border col-span-2 px-4 py-2">
          <Activities activities={activities} />
        </div>
        <div className="rounded-md bg-white border col-span-1 px-4 py-2">
          <Invoices total={total} invoicesAmount={invoicesAmount} invoicesNotPaid={invoicesNotPaid} invoicesNotPaidAmount={invoicesNotPaidAmount} />
        </div>
      </div>
    </div>
  );
};

const Budget = ({ budgetChart, budget }) => {
  return (
    <>
      <h2 className="text-lg font-semibold">Global Budget</h2>
      <div className="flex-1 flex flex-col items-center justify-around gap-8">
        <div className="flex flex-col w-full">
          <div className="flex items-center justify-between w-full">
            <div className="flex items-center">
              <span className="w-3 h-3 bg-pie-budget-remaining rounded-full mr-2"></span>
              <span>Amount</span>
            </div>
            <div className="flex-1 border-b border-gray-300 mx-2"></div>
            <span>{budgetChart.amount?.toLocaleString("fr", { style: "currency", currency: "EUR" })}</span>
          </div>
          <div className="flex items-center justify-between w-full">
            <div className="flex items-center">
              <span className="w-3 h-3 bg-bar-dev rounded-full mr-2"></span>
              <span>Amount used</span>
            </div>
            <div className="flex-1 border-b border-gray-300 mx-2"></div>
            <span>{budgetChart.amountUsed?.toLocaleString("fr", { style: "currency", currency: "EUR" })}</span>
          </div>
        </div>
        <CircleScore value={budgetChart.amountUsed} total={budgetChart.amount} />
        <div className="flex flex-col w-full">
          <h3 className=" text-lg">Details - Amount Used</h3>
          {budget.map((b, index) => (
            <div key={index} className="flex-1 py-2">
              <div className="flex items-center justify-between w-full">
                <div className="flex items-center">
                  <p className="text-sm font-light pb-1">{b.name}</p>
                </div>
                <div className="flex-1 border-b border-gray-300 mx-2 min-w-[20px]"></div>
                <span className="text-sm">{b.amountUsed?.toLocaleString("fr", { style: "currency", currency: "EUR" })}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

const CircleScore = ({ value, total = 100 }) => {
  const radius = 30;
  const circumference = 2 * Math.PI * radius;
  const percentage = value / total;
  const strokeDashoffset = String(circumference * (1 - percentage));

  return (
    <div className="relative flex items-center justify-center w-48 h-48">
      <svg className="rotate-[-120deg]" width="100%" height="100%" viewBox="0 0 80 80">
        <circle cx="40" cy="40" r={radius} fill="transparent" stroke={percentage > 0.9 ? "#f4c6b7" : "#c0d8e3"} strokeWidth="8" />
        <circle
          cx="40"
          cy="40"
          r={radius}
          fill="transparent"
          stroke={percentage > 0.9 ? "#db410f" : "#024f75"}
          strokeWidth="8"
          strokeDasharray={circumference}
          strokeDashoffset={strokeDashoffset}
          strokeLinecap="round"
        />
      </svg>
      <div className="absolute flex flex-col items-center">{Math.round(percentage * 100)}%</div>
    </div>
  );
};
const Activities = ({ activities }) => {
  const labels = [...new Set(activities.map((activity) => new Date(activity.date).toLocaleDateString()))].sort();

  const positions = [...new Set(activities.map((activity) => activity.userPosition))];

  const seriesData = positions.map((position) => {
    const data = new Array(labels.length).fill(0);
    activities.forEach((activity) => {
      if (activity.userPosition === position) {
        const index = labels.indexOf(new Date(activity.date).toLocaleDateString());
        data[index] += activity.total / 8;
      }
    });
    return { name: position, data };
  });

  const totalDays = seriesData.reduce((acc, series) => acc + series.data.reduce((acc, cur) => acc + cur, 0), 0);

  const chartOptions = {
    chart: {
      type: "bar",
      height: 350,
      stacked: true,
      toolbar: { show: true },
      zoom: { enabled: true },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "45%",
        distributed: false,
        dataLabels: { total: { enabled: true, style: { fontSize: "13px", fontWeight: 900 } } },
      },
    },
    tooltip: { followCursor: true },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      itemMargin: {
        horizontal: 40,
        vertical: 20,
      },
    },
    xaxis: { categories: labels, labels: { style: { fontSize: "12px" } } },
  };

  return (
    <>
      <h2 className="text-lg font-semibold">Time Spent</h2>
      <div className="grid grid-cols-3 gap-8 col-span-2 my-8">
        <div className="border border-black rounded-md flex justify-start items-center p-2">
          <div className="flex flex-col justify-between">
            <label className="text-xs font-medium">Total Days Completed</label>
            <label className="text-xl font-bold mt-1">{totalDays.toFixed(2)}</label>
          </div>
        </div>
        {positions.map((position) => {
          const totalPositionDays = seriesData.find((series) => series.name === position).data.reduce((acc, cur) => acc + cur, 0);
          return (
            <div key={position} className="border border-black rounded-md flex justify-start items-center p-2">
              <div className="flex flex-col justify-between">
                <label className="text-xs font-medium">Total {position} Days</label>
                <label className="text-xl font-bold mt-1">{totalPositionDays.toFixed(2)}</label>
              </div>
            </div>
          );
        })}
      </div>
      <ReactApexChart options={chartOptions} series={seriesData} type="bar" height={450} />
    </>
  );
};

const Invoices = ({ total, invoicesAmount, invoicesNotPaid, invoicesNotPaidAmount }) => {
  return (
    <>
      <h2 className="text-lg font-semibold">Invoices</h2>
      <div className="flex flex-col gap-4 mt-8">
        <div className="border border-black rounded-md p-4 flex flex-col gap-2">
          <h2 className="text-base font-semibold">Number of Invoices</h2>
          <p className="text-xl font-bold">{total}</p>
        </div>
        <div className="border border-black rounded-md p-4 flex flex-col gap-2">
          <h2 className="text-base font-semibold">Total Amount of Invoices</h2>
          <p className="text-xl font-bold">{(invoicesAmount || 0).toLocaleString("fr", { style: "currency", currency: "EUR" })}</p>
        </div>
        <div className="border border-black rounded-md p-4 flex flex-col gap-2">
          <h2 className="text-base font-semibold">Number of Invoices Dues</h2>
          <p className="text-xl font-bold text-red-600">{invoicesNotPaid}</p>
        </div>
        <div className="border border-black rounded-md p-4 flex flex-col gap-2">
          <h2 className="text-base font-semibold">Total Amount of Invoices dues</h2>
          <p className="text-xl font-bold text-red-600">{(invoicesNotPaidAmount || 0).toLocaleString("fr", { style: "currency", currency: "EUR" })}</p>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
