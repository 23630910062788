import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { GrFormClose } from "react-icons/gr";
import { useSelector } from "react-redux";
import DebounceInput from "react-debounce-input";
import { HiMagnifyingGlass } from "react-icons/hi2";
import { MdDownload } from "react-icons/md";
import { IoIosLink } from "react-icons/io";
import toast from "react-hot-toast";
import { copyToClipboard } from "../../../utils";

import DatePicker from "../../../components/DatePicker";
import api from "../../../services/api";
import SelectProjects from "../components/SelectProjects";
import SelectCards, { CARDS } from "../components/SelectCards";
import SelectStatuses, { STATUSES } from "../components/SelectStatuses";
import SelectBanks, { BANKS } from "../components/SelectBanks";
import SelectCountries, { COUNTRIES } from "../components/SelectCountries";
import SelectCategories, { CATEGORIES } from "../components/SelectCategories";
import SelectSubcategories, { SUBCATEGORIES } from "../components/SelectSubcategories";
import SelectUser from "../../../components/selectUser";
import Pagination from "../../../components/pagination";
import Stats from "./Stats";
import Loader from "../../../components/loader";

import BankModal from "../../../components/bankModal";

export default () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [banks, setBanks] = useState(null);
  const [filters, setFilters] = useState();
  const [loading, setLoading] = useState(false);
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const [total, setTotal] = useState(null);
  const [selectedBank, setSelectedBank] = useState(null);

  const navigate = useNavigate();
  const organisation = useSelector((state) => state.Auth.organisation);

  useEffect(() => {
    if (!filters) return;
    update({ filters });
    if (filters.page) query.set("page", filters.page);
    if (filters.per_page) query.set("per_page", filters.per_page);
    else query.delete("per_page");
    if (filters.search) query.set("search", filters.search);
    else query.delete("search");
    if (filters.projects) query.set("project_ids", filters.projects.map((p) => p.value).join(","));
    else query.delete("project_ids");
    if (filters.statuses) query.set("statuses", filters.statuses.map((p) => p.value).join(","));
    else query.delete("statuses");
    if (filters.banks) query.set("banks", filters.banks.map((p) => p.value).join(","));
    else query.delete("banks");
    if (filters.cards) query.set("card_id", filters.cards.map((p) => p.value).join(","));
    else query.delete("card_id");
    if (filters.countries) query.set("countries", filters.countries.map((c) => c.value).join(","));
    else query.delete("countries");
    if (filters.categories) query.set("categories", filters.categories.map((c) => c.value).join(","));
    else query.delete("categories");
    if (filters.sub_categories) query.set("sub_categories", filters.sub_categories.map((c) => c.value).join(","));
    else query.delete("sub_categories");
    if (filters.user) query.set("user_id", filters.user._id);
    else query.delete("user_id");
    if (filters.startDate) query.set("startDate", filters.startDate);
    else query.delete("startDate");
    if (filters.endDate) query.set("endDate", filters.endDate);
    else query.delete("endDate");
    if (filters.minAmount) query.set("minAmount", filters.minAmount);
    else query.delete("minAmount");
    if (filters.maxAmount) query.set("maxAmount", filters.maxAmount);
    else query.delete("maxAmount");
    if (organisation) query.set("organisation_id", organisation._id);

    navigate({ pathname: location.pathname, search: query.toString() });
  }, [filters]);

  useEffect(() => {
    (async () => {
      const _filters = { ...filters };
      _filters.search = query.get("search") || "";
      _filters.page = query.get("page") || 1;
      _filters.per_page = query.get("per_page") || 100;

      if (query.get("project_ids")) {
        const promises = query
          .get("project_ids")
          .split(",")
          .map((id) => api.get(`/project/${id}`));
        const projects = await Promise.all(promises);
        _filters.projects = projects.map((p) => ({ value: p.data._id, label: p.data.name }));
      }
      if (query.get("statuses")) {
        _filters.statuses = query
          .get("statuses")
          .split(",")
          .map((s) => STATUSES.find((st) => st.value === s));
      } else {
        _filters.statuses = STATUSES.filter((st) => st.value === "TODO");
      }
      if (query.get("banks"))
        _filters.banks = query
          .get("banks")
          .split(",")
          .map((s) => BANKS.find((st) => st.value === s));

      if (query.get("card_id"))
        _filters.cards = query
          .get("card_id")
          .split(",")
          .map((s) => CARDS.find((st) => st.value === s));

      if (query.get("countries"))
        _filters.countries = query
          .get("countries")
          .split(",")
          .map((s) => COUNTRIES.find((st) => st.value === s));
      if (query.get("categories"))
        _filters.categories = query
          .get("categories")
          .split(",")
          .map((s) => CATEGORIES.find((st) => st.value === s));
      if (query.get("sub_categories"))
        _filters.sub_categories = query
          .get("sub_categories")
          .split(",")
          .map((s) => SUBCATEGORIES.find((st) => st.value === s));
      if (query.get("user_id")) {
        _filters.user = (await api.get(`/user/${query.get("user_id")}`)).data;
      }
      if (query.get("startDate")) _filters.startDate = query.get("startDate");
      if (query.get("endDate")) _filters.endDate = query.get("endDate");
      setFilters(_filters);
    })();
  }, []);

  async function update({ filters }) {
    const _filters = { ...filters };
    if (organisation) _filters.organisation_id = organisation?._id;
    setLoading(true);
    const obj = createQueryFromFilter(filters);
    const { data } = await api.post(`/bank/search`, obj);
    setLoading(false);
    setBanks(data.banks);
    setTotal(data.total);
  }
  const handleOpen = (hit) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("bank_id", hit._id);
    setSearchParams(newSearchParams);
  };

  if (!banks) return <div>Loading...</div>;

  return (
    <div>
      <div className="p-2">
        <BankModal bank={selectedBank} setBank={setSelectedBank} />
        <div className="flex justify-between items-center flex-wrap">
          <div className="w-full">
            <div className="flex gap-1 items-center justify-between">
              <div className="flex flex-1 gap-1 items-center">
                <div className="relative rounded-md shadow-sm w-full">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <HiMagnifyingGlass className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </div>
                  <DebounceInput
                    debounceTimeout={300}
                    name="email"
                    id="email"
                    className="block w-full rounded-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 md:text-sm mb-2"
                    placeholder="Search (name, description)"
                    value={filters.search}
                    onChange={(e) => {
                      e.persist();
                      setFilters((f) => ({ ...f, search: e.target.value, page: 1 }));
                    }}
                  />
                </div>
                {loading ? <Loader size="small" /> : null}
              </div>
              <div className="flex flex-1 gap-1 items-center justify-end">
                <button
                  className="flex justify-between items-center gap-1 px-2 py-1 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={() => {
                    const v = `${window.location}`;
                    copyToClipboard(v);
                    console.log("✍️  v:", v);
                    toast.success("Link copy to clipboard");
                  }}>
                  <IoIosLink />
                  Copy link
                </button>
                <Export filters={filters} />
              </div>
            </div>
            <div className="flex items-center flex-wrap gap-1">
              <SelectCards values={filters.cards} onChange={(e) => setFilters((f) => ({ ...f, cards: e }))} />
              <SelectProjects values={filters.projects} onChange={(e) => setFilters((f) => ({ ...f, projects: e }))} />
              <SelectStatuses values={filters.statuses} onChange={(e) => setFilters((f) => ({ ...f, statuses: e }))} />
              <SelectBanks
                values={filters.banks}
                onChange={(e) => {
                  setFilters((f) => ({ ...f, banks: e }));
                }}
              />
              <SelectCategories values={filters.categories} onChange={(e) => setFilters((f) => ({ ...f, categories: e }))} />
              <SelectSubcategories values={filters.sub_categories} onChange={(e) => setFilters((f) => ({ ...f, sub_categories: e }))} />
              <SelectUser placeholder="Lead" value={{ name: filters.user }} onChange={(e) => setFilters({ ...filters, user: e })} />
              <DatePicker value={filters} onChange={setFilters} />

              <DebounceInput
                debounce={300}
                value={filters.minAmount}
                className="block w-[180px] rounded-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 md:text-sm mb-2"
                onChange={(e) => setFilters({ ...filters, minAmount: e.target.value })}
                placeholder="Min Amount"
              />

              <DebounceInput
                debounce={300}
                value={filters.maxAmount}
                onChange={(e) => setFilters({ ...filters, maxAmount: e.target.value })}
                className="block w-[180px] rounded-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 md:text-sm mb-2"
                placeholder="Max Amount"
              />
            </div>
            <div>
              <Filters filters={filters} setFilters={setFilters} />
            </div>
          </div>
        </div>
        <Stats filters={filters} />
        <div className="overflow-x-auto bg-white mt-2">
          <table className="md:w-full table-auto">
            <thead className="border-t border-b-2  border-t-[#dee2e6] border-l-0 border-r-0 border-b-[#dee2e6]">
              <tr>
                <th scope="col" className="py-2 px-3 text-left text-sm font-semibold text-gray-900 md:pl-3 pl-0">
                  Action
                </th>{" "}
                <th scope="col" className="py-2 px-3 text-left text-sm font-semibold text-gray-900 md:pl-3 pl-0">
                  Date
                </th>
                <th scope="col" className="py-2 px-3 text-left text-sm font-semibold text-gray-900 md:pl-3 pl-0">
                  Name
                </th>
                <th scope="col" className="py-2 px-3 text-left text-sm font-semibold text-gray-900 md:pl-3 pl-0">
                  Amount
                </th>
                <th scope="col" className="py-2 px-3 text-left text-sm font-semibold text-gray-900 md:pl-3 pl-0">
                  Category
                </th>
                <th scope="col" className="py-2 px-3 text-left text-sm font-semibold text-gray-900 md:pl-3 pl-0">
                  Sub-category
                </th>
                <th scope="col" className="py-2 px-3 text-left text-sm font-semibold text-gray-900 md:pl-3 pl-0">
                  Project
                </th>
                <th scope="col" className="py-2 px-3 text-left text-sm font-semibold text-gray-900 md:pl-3 pl-0">
                  Type
                </th>
                <th scope="col" className="py-2 px-3 text-left text-sm font-semibold text-gray-900 md:pl-3 pl-0">
                  Tax
                </th>
                <th scope="col" className="py-2 px-3 text-left text-sm font-semibold text-gray-900 md:pl-3 pl-0">
                  Doc
                </th>
                <th scope="col" className="py-2 px-3 text-left text-sm font-semibold text-gray-900 md:pl-3 pl-0">
                  Status
                </th>
                <th scope="col" className="py-2 px-3 text-left text-sm font-semibold text-gray-900 md:pl-3 pl-0">
                  Card
                </th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>Loading...</tr>
              ) : (
                (banks || []).map((hit, index) => {
                  return (
                    <tr key={hit._id} className="hover:bg-[#dee2e6] border-b border-[#dee2e6]">
                      <td className="whitespace-nowrap py-1 px-3 text-sm font-normal text-gray-900 md:pl-3 pl-0">
                        <button className="bg-blue-500 hover:bg-blue-700 text-white my-1 px-1 rounded-sm" onClick={() => handleOpen(hit)}>
                          open
                        </button>
                      </td>{" "}
                      <td className="whitespace-nowrap py-1 px-3 text-sm font-normal text-gray-900 md:pl-3 pl-0">
                        <div>
                          <div>{hit.date.slice(0, 10)}</div>
                          <div className="text-gray-400 text-xs">created at {new Date(hit.created_at).toLocaleString()}</div>
                        </div>
                      </td>
                      <td className="whitespace-nowrap py-1 px-3 text-sm font-normal text-gray-900 md:pl-3 pl-0">
                        <div>{hit.name?.substring(0, 20)}</div>
                        <div className="text-gray-400 text-xs">{hit.description?.substring(0, 20)}...</div>
                      </td>
                      <td className="whitespace-nowrap py-1 px-3 text-sm font-normal text-gray-900 md:pl-3 pl-0">
                        <div className="flex items-center gap-1">
                          <Amount amount={hit.amount} />
                        </div>
                      </td>
                      <td className="whitespace-nowrap py-1 px-3 text-sm font-normal text-gray-900 md:pl-3 pl-0">{hit.category}</td>
                      <td className="whitespace-nowrap py-1 px-3 text-sm font-normal text-gray-900 md:pl-3 pl-0">{hit.sub_category}</td>
                      <td className="whitespace-nowrap py-1 px-3 text-sm font-normal text-gray-900 md:pl-3 pl-0">{hit.projectName}</td>
                      <td className="whitespace-nowrap py-1 px-3 text-sm font-normal text-gray-900 md:pl-3 pl-0">
                        {hit.source} {hit.type}
                      </td>
                      <td className="whitespace-nowrap py-1 px-3 text-sm font-normal text-gray-900 md:pl-3 pl-0">{`${(hit.tax || 0).toFixed()}€`}</td>
                      <td className="whitespace-nowrap py-1 px-3 text-sm font-normal text-gray-900 md:pl-3 pl-0">
                        <Files files={hit.files} />
                      </td>
                      <td className="whitespace-nowrap py-1 px-3 text-sm font-normal text-gray-900 md:pl-3 pl-0">
                        <Status status={hit.status} />
                      </td>
                      <td className="whitespace-nowrap py-1 px-3 text-sm font-normal text-gray-900 md:pl-3 pl-0">{CARDS.find((card) => card.value === hit.card_id)?.label}</td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
        <Pagination
          per_page={filters.per_page}
          total={total}
          onNext={() => setFilters((f) => ({ ...f, page: +f.page + 1 }))}
          onPrevious={() => setFilters((f) => ({ ...f, page: +f.page - 1 }))}
          currentPage={filters.page}
        />
      </div>
    </div>
  );
};

const createQueryFromFilter = (filters) => {
  const obj = {};
  console.log(filters);
  if (filters.projects) obj.project_ids = filters.projects.map((e) => e.value);
  if (filters.statuses) obj.statuses = filters.statuses.map((e) => e.value);
  if (filters.categories) obj.categories = filters.categories.map((e) => e.value);
  if (filters.sub_categories) obj.sub_categories = filters.sub_categories.map((e) => e.value);
  if (filters.countries) obj.countries = filters.countries.map((e) => e.value);
  if (filters.cards) obj.card_id = filters.cards.map((e) => e.value);
  if (filters.user) obj.user_id = filters.user._id;
  if (filters.startDate) obj.startDate = filters.startDate;
  if (filters.endDate) obj.endDate = filters.endDate;
  if (filters.page) obj.page = filters.page;
  if (filters.per_page) obj.per_page = filters.per_page;
  if (filters.search) obj.search = filters.search;
  if (filters.banks) obj.banks = filters.banks.map((e) => e.value);
  if (filters.minAmount) obj.minAmount = filters.minAmount;
  if (filters.maxAmount) obj.maxAmount = filters.maxAmount;
  console.log(obj);
  return obj;
};

const Export = ({ filters }) => {
  const onClick = async () => {
    delete filters.page;
    delete filters.per_page;

    const obj = createQueryFromFilter(filters);
    obj.per_page = 10000;

    const { data } = await api.post(`/bank/search`, obj);

    let csvContent = "data:text/csv;charset=utf-8,";
    csvContent += `Index;Name;Description;Date;Month;Amount;Tax;Country;Category;SubCategory;Docs;Invoice Name;Status\n`;

    for (let i = 0; i < data.banks.length; i++) {
      const e = data.banks[i];
      const description = encodeURIComponent(e.description);
      const str = `${i};"${e.name.replace("#", "")}";"${description}";"${e.date.substring(0, 10)}";"${e.month?.substring(0, 10)}";"${e.amount}";"${e.tax || 0}";"${e.country}";"${
        e.category
      }";"${e.sub_category}";"${e.files.join(" ")}";"${e.invoiceName}";"${e.status}"\n`;
      csvContent += str;
    }

    var encodedUri = encodeURI(csvContent);

    const formattedStartDate = new Date(filters?.startDate).toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
    const formattedEndDate = new Date(filters?.endDate).toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });

    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute(
      "download",
      `export_${filters?.startDate ? "from_" + formattedStartDate + "_" : ""}${filters?.endDate ? "to_" + formattedEndDate + "_" : ""}statuses_${filters?.statuses
        .map((status) => status.value)
        .join("_")}.csv`,
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <button
      className="flex justify-between items-center gap-1 px-2 py-1 shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      onClick={onClick}>
      <MdDownload />
      Export
    </button>
  );
};

const Files = ({ files }) => {
  if (files.length === 0) return <div>❌</div>;
  return files.map((e) => {
    return (
      <a key={e} href={e} target="_blank" className="cursor-pointer">
        🧾
      </a>
    );
  });
};

const Amount = ({ amount }) => {
  let color = amount > 0 ? "bg-green-50 text-green-700 border-[1px] border-green-100" : "bg-red-50 text-red-700 border-[1px] border-red-100";
  return <div className={`px-2 py-1 rounded-sm flex justify-center items-center text-sm font-bold ${color}`}>{amount}€</div>;
};

const Status = ({ status }) => {
  const selected = status === "DONE";
  let color = selected ? "bg-[#28a745]" : "bg-[#dc3545]";
  return <div className={`w-16 mt-[1px] h-4 flex justify-center items-center text-[#fff] text-[75%] font-bold rounded-sm ${color}`}>{status}</div>;
};

const Filters = ({ filters, setFilters }) => {
  const arr = [];

  const Tag = ({ title, children }) => {
    return (
      <div className="flex items-center gap-2 p-1  text-sm px-2 text-[13px] rounded-lg border-[1px] border-gray-400 text-gray-700">
        {title}:{children}
      </div>
    );
  };

  if (filters.user) {
    arr.push(
      <Tag title="User">
        <div className="flex items-center gap-1 cursor-pointer" onClick={() => setFilters({ ...filters, user: undefined })}>
          <div>{filters.user.name}</div>
          <img src={filters.user.avatar} className="rounded-lg w-7 h-7" />
        </div>
      </Tag>,
    );
  }

  if (filters.cards?.length) {
    arr.push(
      <Tag title="Card">
        {filters.cards.map((card) => {
          return (
            <div
              className="cursor-pointer bg-blue-500 text-white flex items-center gap-1 px-2 rounded-sm"
              onClick={() =>
                setFilters((f) => ({
                  ...f,
                  cards: f.cards.filter((_card) => _card.value !== card.value),
                }))
              }>
              {card.label}
              <GrFormClose />
            </div>
          );
        })}
      </Tag>,
    );
  }

  if (filters.projects?.length) {
    arr.push(
      <Tag title="Project">
        {filters.projects.map((project) => {
          return (
            <div
              className="cursor-pointer bg-blue-500 text-white flex items-center gap-1 px-2 rounded-sm"
              onClick={() =>
                setFilters((f) => ({
                  ...f,
                  projects: f.projects.filter((_project) => _project.value !== project.value),
                }))
              }>
              {project.label}
              <GrFormClose />
            </div>
          );
        })}
      </Tag>,
    );
  }

  if (filters.categories?.length) {
    arr.push(
      <Tag title="Category">
        {filters.categories.map((category) => {
          return (
            <div
              className="cursor-pointer bg-blue-500 text-white flex items-center gap-1 px-2 rounded-sm"
              onClick={() =>
                setFilters((f) => ({
                  ...f,
                  categories: f.categories.filter((_category) => _category.value !== category.value),
                }))
              }>
              {category.label}
              <GrFormClose />
            </div>
          );
        })}
      </Tag>,
    );
  }

  if (filters.sub_categories?.length) {
    arr.push(
      <Tag title="Sub-category">
        {filters.sub_categories.map((sub_category) => {
          return (
            <div
              className="cursor-pointer bg-blue-500 text-white flex items-center gap-1 px-2 rounded-sm"
              onClick={() =>
                setFilters((f) => ({
                  ...f,
                  sub_categories: f.sub_categories.filter((_sub_category) => _sub_category.value !== sub_category.value),
                }))
              }>
              {sub_category.label}
              <GrFormClose />
            </div>
          );
        })}
      </Tag>,
    );
  }

  if (filters.statuses?.length) {
    arr.push(
      <Tag title="Status">
        {filters.statuses.map((status) => {
          return (
            <div
              className="cursor-pointer bg-blue-500 text-white flex items-center gap-1 px-2 rounded-sm"
              onClick={() =>
                setFilters((f) => ({
                  ...f,
                  statuses: f.statuses.filter((_status) => _status.value !== status.value),
                }))
              }>
              {status.label}
              <GrFormClose />
            </div>
          );
        })}
      </Tag>,
    );
  }

  if (filters.startDate && filters.endDate) {
    arr.push(
      <Tag title="Date">
        <div
          className="cursor-pointer bg-blue-500 text-white flex items-center gap-1 px-2 rounded-sm"
          onClick={() => setFilters({ ...filters, startDate: undefined, endDate: undefined })}>
          {`From ${new Date(filters.startDate).toDateString()} to ${new Date(filters.endDate).toDateString()}`}
          <GrFormClose />
        </div>
      </Tag>,
    );
  }

  if (filters.banks?.length) {
    arr.push(
      <Tag title="Bank">
        {filters.banks.map((bank) => {
          return (
            <div
              className="cursor-pointer bg-blue-500 text-white flex items-center gap-1 px-2 rounded-sm"
              onClick={() => setFilters((f) => ({ ...f, banks: f.banks.filter((_bank) => _bank.value !== bank.value) }))}>
              {bank.label}
              <GrFormClose />
            </div>
          );
        })}
      </Tag>,
    );
  }

  return (
    <div className="flex gap-2 p-2">
      <div className="flex gap-2">
        {arr.map((e) => e)}
        {arr.length > 0 && (
          <div className="flex items-center gap-2 p-1 text-sm px-2 text-[13px] rounded-lg border-[1px] border-gray-300 text-gray-600 cursor-pointer" onClick={() => setFilters({})}>
            Clear all
            <GrFormClose />
          </div>
        )}
      </div>
    </div>
  );
};
