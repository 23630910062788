import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import Loader from "../../components/loader";
import SelectUser from "../../components/selectUser";
import Pagination from "../../components/pagination";
import SelectWorkshop from "./components/SelectWorkshop";
import api from "../../services/api";

export default () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const query = new URLSearchParams(search);

  const [userWorkshops, setUserWorkshops] = useState();
  const [total, setTotal] = useState(0);
  const [filters, setFilters] = useState();

  const getUserWorkshops = async () => {
    const { data, total: t } = await api.post("/workshop_user/search", filters);
    setUserWorkshops(data);
    setTotal(t);
  };

  useEffect(() => {
    (async () => {
      const _filters = { page: 1, per_page: 25 };
      _filters.workshop_id = query.get("workshop_id") || "";
      setFilters(_filters);
    })();
  }, []);

  useEffect(() => {
    if (!filters) return;
    getUserWorkshops();
    if (filters.workshop_id) query.set("workshop_id", filters.workshop_id);
    else query.delete("workshop_id");
    navigate({ pathname: location.pathname, search: query.toString() });
  }, [filters]);

  if (!userWorkshops) return <Loader />;

  return (
    <div className="p-4">
      <h3 className="text-lg mb-4 font-semibold">Patrons response</h3>
      <div className="my-3 flex items-center gap-2">
        <SelectUser className="projectsInput m-0" value={filters.user_id} onChange={(u) => setFilters({ ...filters, user_id: u._id })} />
        <SelectWorkshop value={filters.workshop} onChange={(e) => setFilters((f) => ({ ...f, workshop: e, workshop_id: e?.value }))} />
      </div>
      <div className="bg-white rounded-lg py-4">
        <table className="min-w-full divide-y divide-gray-200">
          <thead>
            <tr className="border-b border-[#E5EAEF] text-xs font-bold text-left uppercase">
              <th scope="col" className="px-4 py-3">
                User
              </th>
              <th scope="col" className="px-4 py-3">
                Workshop name
              </th>
              <th scope="col" className="px-4 py-3">
                Workshop version
              </th>
              <th scope="col" className="px-4 py-3">
                Score
              </th>
              <th scope="col" className="px-4 py-3">
                Comment
              </th>
              <th scope="col" className="px-4 py-3">
                Started at
              </th>
              <th scope="col" className="px-4 py-3">
                Finished at
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {userWorkshops.map((w) => {
              return (
                <tr key={w._id} onClick={() => window.open(`/learn/${w.workshop_id}`)} className="hover:bg-blue-50 cursor-pointer text-sm font-medium text-gray-600 text-left">
                  <td className="px-4 py-2">
                    <div className="flex items-center gap-1">
                      {/* <img src={w.user_avatar} alt="userlogo" className="rounded-full object-cover w-7 h-7" /> */}
                      <div className="group-hover:underline">{w.user_name}</div>
                    </div>
                  </td>
                  <td className="px-4 py-2">{w.workshop_name}</td>
                  <td className="px-4 py-2">{w.workshop_version}</td>
                  <td className="px-4 py-2">{w.feedback_rating}</td>
                  <td className="px-4 py-2">{w.feedback_text}</td>
                  <td className="px-4 py-2">{(w.created_at || "").slice(0, 10)}</td>
                  <td className="px-4 py-2">{(w.finished_at || "").slice(0, 10)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="flex justify-end mt-2">
        <Pagination
          per_page={filters.per_page}
          total={total}
          onNext={() => setFilters((f) => ({ ...f, page: +f.page + 1 }))}
          onPrevious={() => setFilters((f) => ({ ...f, page: +f.page - 1 }))}
          currentPage={filters.page}
        />
      </div>
    </div>
  );
};
