import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import moment from "moment";
import { HiMagnifyingGlass } from "react-icons/hi2";
import DebounceInput from "react-debounce-input";

import { useSelector } from "react-redux";
import SelectUsers from "../../components/selectUser";

import api from "../../services/api";
import FolkModal from "./folkModal";
import Board from "react-trello";
import { categoryExplanations, percentageSebHappy } from "./pipe";
import { RiMoneyEuroCircleFill } from "react-icons/ri";
import Loose from "./folkModal/loose";

export default () => {
  const user = useSelector((state) => state.Auth.user);
  const navigate = useNavigate();

  const [folks, setFolks] = useState([]);
  const [selectedFolk, setSelectedfolk] = useState(null);
  const [filters, setFilters] = useState({ showAll: false, user });
  const [lostModalOpen, setLostModalOpen] = useState(false);
  const [lostModalFolk, setSelectedLostFolk] = useState(null);

  const { search } = useLocation();
  const query = new URLSearchParams(search);

  useEffect(() => {
    fetch();
  }, [filters]);

  async function fetch() {
    const newFilters = {};
    newFilters.contactType = "PARTNER";
    const { data } = await api.post("/folk/search", newFilters);
    setFolks(data);
  }

  return (
    <div className="bg-white h-full">
      <FolkModal onClose={() => setSelectedfolk(null)} contact={selectedFolk} />
      <Loose open={lostModalOpen} setOpen={setLostModalOpen} values={lostModalFolk} setValues={setSelectedLostFolk} onClose={fetch} />
      <div className="flex justify-between mb-4">
        <div className="flex space-x-4">
          <div>
            <div className="px-1 text-sm text-gray-600 font-medium">{"Owner"}</div>
            <SelectUsers value={filters.user} userName={filters.user?.name} onChange={(v) => setFilters({ ...filters, user: v?._id })} />
          </div>
        </div>
        <Create afterCreate={() => fetch()} />
      </div>
      <div>
        <Board
          components={{ Card }}
          style={{ backgroundColor: "#F5F6FC", overflow: "scroll", height: "100%" }}
          laneStyle={{ backgroundColor: "#e5e6eC", overflow: "scroll" }}
          hideCardDeleteIcon
          data={{
            lanes: ["CREATED", "PROSPECTION", "QUALIFIED", "DISCOVERY", "PROPOSAL", "NEGOTIATION", "CLOSING", "WON", "LOST"].map((category) => ({
              id: category,
              title: (
                <div>
                  <h2 className="text-base">
                    {category}&nbsp;{percentageSebHappy[category]}
                  </h2>
                  <div className="text-gray-600 text-sm font-normal flex gap-1 items-center">
                    <RiMoneyEuroCircleFill />
                    {folks
                      .filter((folk) => folk.status === category)
                      .reduce((prev, curr) => prev + (curr.budget || 0), 0)
                      .toLocaleString()}
                    &nbsp;-&nbsp;
                    {folks.filter((folk) => folk.status === category).length}&nbsp;folks
                  </div>
                  <div className="max-w-[250px] overflow-hidden overflow-wrap text-sm font-normal text-gray-600 whitespace-pre-line">{categoryExplanations[category]}</div>
                </div>
              ),
              cards: folks
                .filter((folk) => folk.status === category)
                .sort((a, b) => {
                  const dateA = new Date(a.last_updated_at);
                  const dateB = new Date(b.last_updated_at);
                  return dateB - dateA;
                })
                .map((folk) => ({ folk, id: folk._id })),
            })),
          }}
          onCardClick={(id) => {
            const folk = folks.find((f) => f._id === id);
            setSelectedfolk(folk);
            query.set("folk_modal_id", folk._id);
            navigate({ search: query.toString() });
          }}
          onCardMoveAcrossLanes={(fromLaneId, toLaneId, cardId) => {
            if (toLaneId === "LOST") {
              setLostModalOpen(true);
              setSelectedLostFolk(folks.find((f) => f._id === cardId));
              return;
            }

            const folk = folks.find((f) => f._id === cardId);
            folk.status = toLaneId;
            api.put(`/folk/${cardId}`, folk);
            toast.success("folk moved successfully");
          }}
        />
      </div>
    </div>
  );
};

const Card = ({ folk, onClick }) => {
  return (
    <div className="w-[250px]">
      <div className="p-2 my-2 flex flex-col bg-gray-50 rounded shadow-md cursor-pointer" onClick={onClick}>
        <div className="flex justify-between space-x-2">
          {(folk.first_name || folk.last_name) && <div className="text-sm text-gray-500">{`${folk.first_name} ${folk.last_name}`}</div>}
          {/* {folk.probability && <div>{`${folk.probability}%`}</div>} */}
        </div>
        <div>{folk.company}</div>
        <div className="text-sm text-gray-500">{folk.domain}</div>
        <div className="text-sm text-gray-500">{`${folk.entity ?? ""}  ${folk.team ?? ""}`}</div>
        <div className="text-sm text-gray-500">{folk.start && "STARTS: " + moment(folk.start).format("DD/MM/YYYY, HH:mm")}</div>
        <div className="text-sm text-gray-500">{folk.end_at && "DEADLINE: " + moment(folk.end_at).format("DD/MM/YYYY, HH:mm")}</div>
        <hr className="mt-2 mb-2" />
        <div className="flex justify-between">
          <div className="flex items-center">
            <img src={folk.user_avatar} className="w-8 h-8 rounded-full" alt={folk.user_name} />
            {folk.people.map((e) => (
              <img key={e.user_avatar} className="h-8 w-8 rounded-full bg-gray-50 ring-2 ring-white" src={e.user_avatar} alt={e.user_avatar} />
            ))}
          </div>
          <div>
            <div className="text-xs text-gray-500">{`Updated : ${moment(folk.updated_at).format("DD/MM/YYYY")}`}</div>
            <div className="text-xs text-gray-500">{`Created : ${moment(folk.created_at).format("DD/MM/YYYY")}`}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Create = ({ afterCreate }) => {
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({});

  async function onCreate() {
    const { res, ok } = await api.post("/folk", { ...values, contactType: "PARTNER" });
    if (!ok) return toast.error("Error");
    setOpen(false);
    toast.success("Created!");
    afterCreate();
  }

  return (
    <>
      <div className="text-right">
        <button className="btn btn-primary" onClick={() => setOpen(true)}>
          Create a partner
        </button>
      </div>
      {open ? (
        <div className=" absolute top-0 bottom-0 left-0 right-0  bg-[#00000066] flex justify-center items-center p-[1rem] z-50 " onClick={() => setOpen(false)}>
          <div
            className="w-full md:w-[60%] h-fit  bg-[white] p-[25px] rounded-md"
            onClick={(e) => {
              e.stopPropagation();
            }}>
            <React.Fragment>
              <div className="mb-4">
                <label className="block mb-2">FirstName</label>
                <input
                  type="text"
                  name="first_name"
                  value={values.first_name}
                  onChange={(e) => setValues({ ...values, first_name: e.target.value })}
                  className="w-full p-2 border rounded"
                />
              </div>
              <div className="mb-4">
                <label className="block mb-2">LastName</label>
                <input
                  type="text"
                  name="last_name"
                  value={values.last_name}
                  onChange={(e) => setValues({ ...values, last_name: e.target.value })}
                  className="w-full p-2 border rounded"
                />
              </div>
              <button type="submit" className="btn btn-primary mt-4" onClick={onCreate}>
                Create
              </button>
            </React.Fragment>
          </div>
        </div>
      ) : null}
    </>
  );
};
