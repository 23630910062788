import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";
import { IoIosInformationCircleOutline } from "react-icons/io";

import api from "../../../services/api";
import { getMonths } from "../../../constants/date";
import Loader from "../../../components/loader";

const Bonus = ({}) => {
  const me = useSelector((state) => state.Auth.user);
  const [searchParams, setSearchParams] = useSearchParams();
  const [filters, setFilters] = useState({
    date: searchParams.get("date") ? new Date(searchParams.get("date")) : new Date(Date.UTC(new Date().getFullYear(), new Date().getMonth() - 1, 1)),
    user: searchParams.get("user") ? searchParams.get("user") : me._id,
  });
  const [team, setTeam] = useState([]);
  const [users, setUsers] = useState([]);
  const [activities, setActivities] = useState(null);
  const [projects, setProjects] = useState([]);
  const [budgets, setBudgets] = useState([]);
  const [invoices, setInvoices] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await api.post(`/user/search`, { role: "admin" });
        if (!res.ok) throw new Error("Error while fetching data");
        setUsers(res.data.users);
      } catch (error) {
        console.error(error);
        toast.error("Error while fetching data");
      }
      setLoading(false);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const resT = await api.post(`/user/search`, { contracts: ["VIE", "CDD", "CDI", "APPRENTICESHIP", "INTERNSHIP", "FREELANCE"], status: "active" });
        if (!resT.ok) throw new Error("Error while fetching data");
        // get all users with manager_id ius the select and all parents/childrens of the manager
        const newTeam = [];
        const u = resT.data.users.find((e) => e._id === filters.user);
        if (u) newTeam.push(u);

        function getChilds(userId) {
          const childs = resT.data.users.filter((e) => e.manager_id === userId);
          newTeam.push(...childs);
          for (let i = 0; i < childs.length; i++) {
            getChilds(childs[i]);
          }
        }
        getChilds(filters.user);
        setTeam(newTeam);

        const resA = await api.post(`/activity/search`, {
          userIds: newTeam.map((e) => e._id),
          dateFrom: filters.date,
          dateTo: new Date(filters.date.getFullYear(), filters.date.getMonth() + 1, 0),
        });
        if (!resA.ok) throw new Error("Error while fetching data");

        for (let i = 0; i < resA.data.length; i++) {
          if (["admin", "prospection", "timeoff"].includes(resA.data[i].projectType)) resA.data[i].value = 0;
          if (["timeoff"].includes(resA.data[i].projectType)) resA.data[i].cost = 0;
        }
        setActivities(resA.data);

        const resP = await api.post(`/project/search`, {
          lead_id: filters.user,
        });
        if (!resP.ok) throw new Error("Error while fetching data");
        setProjects(resP.data.projects);

        const resB = await api.post(`/budget/search`, {
          projectIds: resP.data.projects.map((e) => e._id),
          dateFrom: filters.date,
          dateTo: new Date(filters.date.getFullYear(), filters.date.getMonth() + 1, 0),
        });
        if (!resB.ok) throw new Error("Error while fetching data");
        setBudgets(resB.data);

        const resI = await api.post(`/invoice/search`, {
          budgetIds: resB.data.map((e) => e._id),
          per_page: 200,
        });
        if (!resI.ok) throw new Error("Error while fetching data");
        setInvoices(resI.data);

        const newSearchParams = new URLSearchParams();
        newSearchParams.set("date", filters.date.toISOString());
        newSearchParams.set("user", filters.user);
        setSearchParams(newSearchParams);
      } catch (error) {
        console.error(error);
        toast.error("Error while fetching data");
      }
      setLoading(false);
    };
    fetchData();
  }, [filters]);

  // let renta = getRenta(month);

  // 12.5% du benef sur les gens
  // Si projets < 20% -> mettre ce chiffre a 0;

  // Profit par team par projet
  // Minus projet pas profitable
  // Moin les gens non occupe
  // Plus performance de la boite

  return (
    <div className="py-6 space-y-6">
      <div className="flex items-center gap-4">
        <select className="select w-56" value={filters.user} onChange={(e) => setFilters({ ...filters, user: e.target.value })}>
          <option value="">Select user</option>
          {users.map((item) => (
            <option key={item._id} value={item._id}>
              {item.name}
            </option>
          ))}
        </select>
        <select className="select w-56" value={filters.date?.getTime() || ""} onChange={(e) => setFilters({ ...filters, date: new Date(parseInt(e.target.value)) })}>
          <option value="">Select a month</option>
          {getMonths({ max: new Date() }).map((e, i) => (
            <option key={i} value={e.getTime()}>
              {e.toLocaleDateString("en-US", { year: "numeric", month: "long" })}
            </option>
          ))}
        </select>
      </div>

      {loading ? (
        <Loader />
      ) : (
        <>
          {/* <Summary team={team} data={data} invoices={budgets} renta={renta}></Summary> */}
          {/* <Renta renta={renta} /> */}
          <Team users={team} />
          <CompanyHealth date={filters.date} />
          <Projects projects={projects} users={team} activities={activities} />
          <Occupation users={team} activities={activities} />
          <Budgets budgets={budgets} invoices={invoices} filters={filters} />
          {/* <Detail data={activities} /> */}
          {/* <Prospection user={user} /> */}
        </>
      )}
      {/* // profit de lui et de sa team  */}
      {/* {mode === "month" && <ProfitPerMonth data={data} />} */}
    </div>
  );
};

const Team = ({ users }) => {
  return (
    <div className="border border-gray-300 rounded-lg p-6 bg-white space-y-4">
      <h2 className="text-lg font-bold">Team</h2>
      <div className="grid grid-cols-4 gap-4">
        {users.map((user, index) => (
          <div key={index} className="bg-gray-100 rounded-md shadow-sm h-24 p-4 flex items-center">
            <img src={user.avatar} alt={user.name} className="w-10 h-10 rounded-full" />
            <div className="ml-4 flex-1 overflow-hidden">
              <div className="text-lg font-semibold">{user.name}</div>
              <div className="text-sm text-gray-500 truncate">{user.job_title}</div>
              <div className="text-sm text-gray-500 truncate">{user.contract}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const Projects = ({ projects, activities, users }) => {
  return (
    <div className="border border-gray-300 rounded-lg p-6 bg-white space-y-4">
      <h2 className="text-lg font-bold">Projects</h2>
      <div className="grid grid-cols-3 gap-4">
        {projects.map((project, index) => {
          const a = activities.filter((a) => a.projectId === project._id);
          const u = users.filter((e) => a.some((b) => b.userId === e._id));

          return (
            <div key={index} className="bg-gray-100 rounded-md shadow-sm h-36 p-4 flex items-center">
              <img src={project.logo} alt={project.name} className="w-10 h-10 rounded-full" />
              <div className="ml-4 flex-1">
                <div className="text-lg font-semibold">{project.name}</div>
                <div className="text-sm text-gray-500">{u.map((e) => e.name).join(", ")}</div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const Occupation = ({ users, activities }) => {
  if (!users) return null;
  if (!activities) return null;

  const a = activities.filter((a) => !["admin", "prospection", "timeoff"].includes(a.projectType));
  const totalHours = a.reduce((acc, e) => acc + e.total, 0) / 8 / users.length;
  const totalValue = a.reduce((acc, e) => acc + e.value, 0);
  const totalSalary = users.reduce((acc, e) => {
    if (e.total_monthly_cost) return acc + e.total_monthly_cost;
    else {
      const cost = activities.filter((a) => a.userId === e._id && !["timeoff"].includes(a.projectType)).reduce((acc, e) => acc + e.cost, 0);
      return acc + cost;
    }
  }, 0);

  return (
    <div className="border border-gray-300 rounded-lg p-6 bg-white space-y-4">
      <div>
        <h2 className="text-lg font-bold">Occupation</h2>
      </div>

      <table className="w-full table-fixed">
        <thead>
          <tr className="border-b border-gray-200 text-sm font-semibold">
            <th colSpan={2} className="p-2">
              Name
            </th>
            <th className="p-2 text-right">Daily Rate</th>
            <th className="p-2 text-right">Monthly Cost</th>
            <th className="p-2 text-right">
              <div className="flex items-center justify-end relative gap-2 group">
                Activities (days)
                <IoIosInformationCircleOutline className="text-gray-500" />
                <span className="w-32 absolute z-10 hidden group-hover:block py-1 px-2 text-xs text-white bg-gray-500 rounded transform -translate-x-1/2 left-1/2 bottom-full mb-2">
                  On projects only. This value might not reflect the invoiced activity
                </span>
              </div>
            </th>
            <th className="p-2 text-right">
              <div className="flex items-center justify-end relative gap-2 group">
                Activities (€)
                <IoIosInformationCircleOutline className="text-gray-500" />
                <span className="w-32 absolute z-10 hidden group-hover:block py-1 px-2 text-xs text-white bg-gray-500 rounded transform -translate-x-1/2 left-1/2 bottom-full mb-2">
                  On projects only. This value might not reflect the invoiced activity
                </span>
              </div>
            </th>
            <th className="p-2">
              <div className="flex items-center justify-end relative gap-2 group">
                Profits (€)
                <IoIosInformationCircleOutline className="text-gray-500" />
                <span className="w-32 absolute z-10 hidden group-hover:block py-1 px-2 text-xs text-white bg-gray-500 rounded transform -translate-x-1/2 left-1/2 bottom-full mb-2">
                  Profit = Vendu - Total Monthly Cost
                </span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {users.map((e, index) => {
            const a = activities.filter((a) => a.userId === e._id && !["admin", "prospection", "timeoff"].includes(a.projectType));
            const hours = a.reduce((acc, e) => acc + e.total, 0) / 8;
            const value = a.reduce((acc, e) => acc + e.value, 0);
            const cost = activities.filter((a) => a.userId === e._id && !["timeoff"].includes(a.projectType)).reduce((acc, e) => acc + e.cost, 0);
            const monthlyCost = e.total_monthly_cost || cost;

            return (
              <tr key={index} className="border-b border-gray-200 text-sm">
                <td colSpan={2} className="p-2">
                  {e.name}
                </td>
                <td className="p-2 text-right">{(e.tjms || 0).toLocaleString("fr", { style: "currency", currency: "EUR" })}</td>
                <td className="p-2 text-right">{(monthlyCost || 0).toLocaleString("fr", { style: "currency", currency: "EUR" })}</td>
                <td className="p-2 text-right">{(hours || 0).toFixed(2)} days</td>
                <td className="p-2 text-right">{(value || 0).toLocaleString("fr", { style: "currency", currency: "EUR" })}</td>
                <td className="p-2 text-right">{(value - monthlyCost).toLocaleString("fr", { style: "currency", currency: "EUR" })}</td>
              </tr>
            );
          })}

          <tr className="border-t border-gray-200 text-sm font-semibold">
            <td colSpan={3} className="p-2">
              Total / Average
            </td>
            <td className="p-2 text-right">{(totalSalary || 0).toLocaleString("fr", { style: "currency", currency: "EUR" })}</td>
            <td className="p-2 text-right">{(totalHours || 0).toFixed(2)}j</td>
            <td className="p-2 text-right">{(totalValue || 0).toLocaleString("fr", { style: "currency", currency: "EUR" })}</td>
            <td className="p-2 text-right">{(totalValue - totalSalary || 0).toLocaleString("fr", { style: "currency", currency: "EUR" })}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const Budgets = ({ budgets, invoices, filters }) => {
  if (!budgets) return null;

  budgets.sort((a, b) => a.projectName?.localeCompare(b.projectName));

  return (
    <div className="border border-gray-300 rounded-lg p-6 bg-white space-y-4">
      <div>
        <h2 className="text-lg font-bold">Budgets</h2>
      </div>

      <table className="w-full table-fixed">
        <thead>
          <tr className="border-b border-gray-200 text-sm font-semibold">
            <th className="p-2">Project</th>
            <th colSpan={2} className="p-2">
              Name
            </th>
            <th className="p-2 text-right">Amount</th>
            <th className="p-2 text-right">Amount used</th>
            <th className="p-2 text-right">Invoiced in {filters.date.toLocaleDateString("en-US", { year: "numeric", month: "long" })}</th>
            <th className="p-2 text-right">Profits</th>
          </tr>
        </thead>
        <tbody>
          {budgets.map((e, index) => {
            const i = invoices.filter(
              (i) => i.budgetId === e._id && new Date(i.date).getMonth() === filters.date.getMonth() && new Date(i.date).getFullYear() === filters.date.getFullYear(),
            );
            return (
              <tr key={index} className="border-b border-gray-200 text-sm">
                <td className="p-2">{e.projectName}</td>
                <td colSpan={2} className="p-2">
                  {e.name}
                </td>
                <td className="p-2 text-right">{(e.amount || 0).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</td>
                <td className="p-2 text-right">{(e.amountUsed || 0).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</td>
                <td className="p-2 text-right">{i.reduce((acc, cur) => acc + cur.netTotal, 0).toLocaleString("fr", { style: "currency", currency: "EUR" })}</td>
                <td className="p-2 text-right">%</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const CompanyHealth = ({ date }) => {
  const [lastMonthEbidta, setLastMonthEbidta] = useState();
  const [ebidta, setEbidta] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get(`/report/ebitda`);
        if (!res.ok) throw res;
        setEbidta(res.data.find((e) => e.month === date.getMonth() && e.year === date.getFullYear()));
        setLastMonthEbidta(
          res.data.find((e) => (date.getMonth() !== 0 ? e.month === date.getMonth() - 1 && e.year === date.getFullYear() : e.month === 11 && e.year === date.getFullYear() - 1)),
        );
      } catch (error) {
        console.error(error);
        toast.error("Error while fetching data");
      }
    };
    fetchData();
  }, [date]);

  if (!ebidta) return <div />;

  const revenuesImprovement = ((ebidta.totalIncome || 0) - (lastMonthEbidta?.totalIncome || 0)) / (lastMonthEbidta?.totalIncome || 1);
  const generalCostPart = (ebidta.totalGeneralCosts || 0) / (ebidta.totalIncome || 1);
  const brutMargin = (ebidta.totalVariableCosts + ebidta.totalIncome || 0) / (ebidta.totalIncome || 1);
  const ebitdaMargin = (ebidta.ebitda || 0) / (ebidta.totalIncome || 1);
  return (
    <div className="border border-gray-300 rounded-lg p-6 bg-white space-y-4">
      <div>
        <h2 className="text-lg font-bold">Company's health</h2>
      </div>
      <div className="grid grid-cols-4 gap-4">
        <div className="bg-gray-100 rounded-md shadow-sm h-24 p-4">
          <div className="flex items-center gap-4 relative group">
            <h3 className="text-base font-semibold">Revenues</h3>
            <IoIosInformationCircleOutline className="text-gray-500" />
            <span className="w-32 absolute z-10 hidden group-hover:block py-1 px-2 text-xs text-white bg-gray-500 rounded transform -translate-x-1/2 left-1/2 bottom-full mb-2">
              Sum of all the invoice sent in this month and the subventions
            </span>
          </div>
          <div className="text-right text-lg font-bold">{(ebidta.totalIncome || 0).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</div>
          <div className="text-right text-sm flex items-center gap-2 justify-end">
            <p>Compare to last month</p>
            <span className={`text-right text-sm ${revenuesImprovement < 0 ? "text-red-700" : "text-green-700"}`}>
              {revenuesImprovement > 0 ? "+" : ""} {revenuesImprovement.toLocaleString("fr-FR", { style: "percent", maximumFractionDigits: 2 })}
            </span>
          </div>
        </div>

        <div className="bg-gray-100 rounded-md shadow-sm h-24 p-4">
          <div className="flex items-center gap-4 relative group">
            <h3 className="text-base font-semibold">General costs</h3>
            <IoIosInformationCircleOutline className="text-gray-500" />
            <span className="w-32 absolute z-10 hidden group-hover:block py-1 px-2 text-xs text-white bg-gray-500 rounded transform -translate-x-1/2 left-1/2 bottom-full mb-2">
              All the costs that are not directly related to a project (should be less than 20%)
            </span>
          </div>
          <div className="text-right text-lg font-bold">{(ebidta.totalGeneralCosts || 0).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</div>
          <div className="text-right text-sm flex items-center gap-2 justify-end">
            <p>Part of revnues</p>
            <span className={`text-right text-sm ${generalCostPart > 0.2 ? "text-red-700" : "text-green-700"}`}>
              {generalCostPart.toLocaleString("fr-FR", { style: "percent", maximumFractionDigits: 2 })}
            </span>
          </div>
        </div>

        <div className="bg-gray-100 rounded-md shadow-sm h-24 p-4">
          <div className="flex items-center gap-4 relative group">
            <h3 className="text-base font-semibold">Variable costs</h3>
            <IoIosInformationCircleOutline className="text-gray-500" />
            <span className="w-32 absolute z-10 hidden group-hover:block py-1 px-2 text-xs text-white bg-gray-500 rounded transform -translate-x-1/2 left-1/2 bottom-full mb-2">
              Brut margin = (Revenues - Variable costs) / Revenues (should be more than 38%)
            </span>
          </div>
          <div className="text-right text-lg font-bold">{(ebidta.totalVariableCosts || 0).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</div>
          <div className="text-right text-sm flex items-center gap-2 justify-end">
            <p>Brut margin</p>
            <span className={`text-right text-sm ${brutMargin < 0.38 ? "text-red-700" : "text-green-700"}`}>
              {brutMargin.toLocaleString("fr-FR", { style: "percent", maximumFractionDigits: 2 })}
            </span>
          </div>
        </div>

        <div className="bg-gray-100 rounded-md shadow-sm h-24 p-4">
          <div className="flex items-center gap-4 relative group">
            <h3 className="text-base font-semibold">EBITDA</h3>
            <IoIosInformationCircleOutline className="text-gray-500" />
            <span className="w-32 absolute z-10 hidden group-hover:block py-1 px-2 text-xs text-white bg-gray-500 rounded transform -translate-x-1/2 left-1/2 bottom-full mb-2">
              Earnings Before Interest, Taxes, Depreciation, and Amortization
            </span>
          </div>
          <div className="text-right text-lg font-bold">{(ebidta.ebitda || 0).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</div>
          <div className="text-right text-sm flex items-center gap-2 justify-end">
            <p>Margin</p>
            <span className={`text-right text-sm ${ebitdaMargin < 0.18 ? "text-red-700" : "text-green-700"}`}>
              {ebitdaMargin.toLocaleString("fr-FR", { style: "percent", maximumFractionDigits: 2 })}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const Summary = ({ team, data, invoices, renta }) => {
  if (!data) return <div />;

  let profits = 0;

  for (let i = 0; i < data.length; i++) {
    profits += data[i].value - data[i].cost;
  }

  profits = Math.round(profits * 0.125);

  let malusTeam = 0;
  let occupation = getMoneyPerUser(team, data);

  for (let i = 0; i < occupation.length; i++) {
    if (((occupation[i].value * 100) / occupation[i].potentielMax).toFixed(2) < 30) malusTeam = Math.round((profits * 1) / 3);
  }
  // si un gars est occupé < 30%, then malus = - 1/3 * profits

  let malusProjects = 0;
  // si des projets ont une marge à <40%

  for (let i = 0; i < invoices.length; i++) {
    if ((invoices[i].netTotal - invoices[i].cost) / invoices[i].netTotal < 0.4) malusProjects = Math.round((profits * 1) / 3);
  }

  let finalBonus = 0;

  let sharedBonus = 0;

  if (renta.margin > 10) finalBonus = profits - malusTeam - malusProjects;

  return (
    <div>
      <label className="font-bold">Your Bonus Summary</label>
      <div className="font-bold">Unlocked bonus this month ? {renta.margin > 10 ? "yes" : "no"}</div>
      <div className="text-sm text-gray-500">The bonuses are unlocked only if the company does at least 10% margin</div>
      <hr></hr>
      <div>Total profits on your team : {profits}€</div>
      <div className="text-sm text-gray-500">12.5% of profits of your team</div>
      <div>Malus occupation : -{malusTeam}€</div>
      <div className="text-sm text-gray-500">If a guy is not busy at least 30% of the time, then -33% on your bonus</div>
      <div>Malus projects : -{malusProjects}€</div>
      <div className="text-sm text-gray-500">If a project you're responsible for is not at 40% margin</div>
      <div className="font-bold">Total bonus for you : {finalBonus}€</div>
      <div className="font-bold">Bonus shared from the company : {sharedBonus}€</div>
      <div className="text-sm text-gray-500">to be defined</div>
    </div>
  );
};

const getMoneyPerUser = (users, data) => {
  for (let i = 0; i < data.length; i++) {
    if (["admin", "prospection", "timeoff"].includes(data[i].projectType)) data[i].value = 0;
  }

  let money_per_user = [];

  for (let i = 0; i < users.length; i++) {
    let value = 0;
    let cost = 0;
    let name = users[i].name;
    let tjms = users[i].tjms;
    let tjm = users[i].tjm;
    let nbHeures = 0;

    for (let j = 0; j < data.length; j++) {
      if (data[j].userName == name) value += data[j].value;
      if (data[j].userName == name && data[j].projectType != "timeoff") nbHeures += data[j].total;
      if (data[j].userName == name && data[j].projectType != "timeoff") cost += data[j].cost;
    }

    money_per_user.push({
      name: name,
      cost: Math.round(cost),
      value: value,
      profits: Math.round(value - cost),
      potentielMax: Math.round((tjms * nbHeures) / 8),
      potentielMaxMonth: Math.round(tjms * 22),
      costMonth: Math.round(tjm * 22),
    });
  }

  return money_per_user;
};

const Detail = ({ data }) => {
  if (!data) return <div />;

  return (
    <div className="flex flex-col gap-4">
      <div className="text-sm text-gray-500">The costs for holidays are at 0 because the costs of the guys when they're on holidays are already included in their daily cost</div>
      <div className="overflow-x-auto">
        <div className="align-middle inline-block min-w-full">
          <table className="min-w-full">
            <thead>
              <tr className="border-b border-gray-200 dark:border-gray-700 bg-gray-50 dark:bg-gray-800">
                <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-gray-400">Date</th>
                <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-gray-400">People</th>
                <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-gray-400">Project</th>
                <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-gray-400">Type</th>
                <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-gray-400">Total</th>
                <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-gray-400">Cost</th>
                <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-gray-400">Value</th>
                <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-gray-400">Profit</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-100 dark:bg-gray-900 dark:divide-gray-800">
              {(data || []).map((e, i) => {
                return (
                  <tr key={i}>
                    <td className="px-6 py-2 whitespace-nowrap">
                      <div className="text-sm text-gray-900 dark:text-gray-100">{new Date(e.date).toString().substring(0, 15)}</div>
                    </td>
                    <td className="px-6 py-2 whitespace-nowrap">
                      <div className="text-sm text-gray-900 dark:text-gray-100">{e.userName}</div>
                    </td>
                    <td className="px-6 py-2 whitespace-nowrap">
                      <div className="text-sm text-gray-900 dark:text-gray-100">{e.projectName}</div>
                    </td>
                    <td className="px-6 py-2 whitespace-nowrap">
                      <div className="text-sm text-gray-900 dark:text-gray-100">{e.projectType}</div>
                    </td>
                    <td className="px-6 py-2 whitespace-nowrap">
                      <div className="text-sm text-gray-900 dark:text-gray-100">{e.total / 8} days</div>
                    </td>
                    <td className="px-6 py-2 whitespace-nowrap">
                      <div className="text-sm text-gray-900 dark:text-gray-100">{e.cost.toFixed(0)}€</div>
                    </td>
                    <td className="px-6 py-2 whitespace-nowrap">
                      <div className="text-sm text-gray-900 dark:text-gray-100">{e.value.toFixed(0)}€</div>
                    </td>
                    <td className="px-6 py-2 whitespace-nowrap">
                      <div className="text-sm text-gray-900 dark:text-gray-100">{e.value.toFixed(0) - e.cost.toFixed(0)}€</div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const getRenta = (date) => {
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getDetails();
  }, [date]);

  async function getDetails() {
    if (!date) return;
    setLoading(true);
    const d1 = new Date();
    const res1 = await api.post(`/invoice/search`, {
      dateFrom: new Date(d1.getFullYear(), date - 1, 1),
      dateTo: new Date(d1.getFullYear(), date, 0),
      type: { $nin: ["timeoff"] },
    });

    setInvoices(res1.data);
    setLoading(false);
  }

  const revenuTotal = invoices.reduce((acc, e) => acc + e.netTotal, 0).toFixed(0);
  const costTotal = invoices.reduce((acc, e) => acc + e.cost, 0).toFixed(0); //je pense que faut plutôt mettre les coûts des activities + banks ce sera plus fair

  let renta = {
    revenuTotal: revenuTotal,
    costTotal: costTotal,
    profits: revenuTotal - costTotal,
    margin: Math.round(((revenuTotal - costTotal) / revenuTotal) * 100),
  };

  return renta;
};

const Renta = ({ renta }) => {
  return (
    <div>
      <hr></hr>
      <label className="font-bold">Company's summary of the month</label>
      <div>Revenu total : {renta.revenuTotal}€ </div>
      <div className="text-sm text-gray-500">Sum of netTotal of invoices</div>
      <div>Coût total : {renta.costTotal}€</div>
      <div className="text-sm text-gray-500">Sum of cost of invoices linked to the month we're looking at, except holidays</div>
      <div>Profit total : {renta.profits}€</div>
      <div>Margin total : {renta.margin}%</div>
    </div>
  );
};

const Prospection = ({ user }) => {
  const [folks, setFolks] = useState([]);
  const [loading, setLoading] = useState(false);

  return (
    <div>
      <hr></hr>
      <label className="font-bold">Folks</label>
      <div className="text-sm text-gray-500">Show the pipe of the guy</div>
      <div className="grid grid-cols-1 gap-4">
        {folks.map((folk, index) => (
          <div key={index} className="bg-white border-[1px] border-gray-200 rounded-md shadow-sm dark:border-gray-700 dark:bg-gray-800">
            <div className="flex items-center p-4">
              <img src={folk.project_logo} alt={folk.project_name} className="w-10 h-10 rounded-full" />
              <div className="ml-4">
                <div className="text-lg font-semibold">{folk.project_name}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

// const ProfitPerMonth = ({ data: d }) => {
//   function getData(data) {
//     const obj = [];
//     for (let i = 0; i < (data || []).length; i++) {
//       const d = data[i];
//       const find = obj.find((e) => e.date === d.date);
//       if (!find) {
//         obj.push({ date: d.date, arr: [d] });
//       } else {
//         find.arr.push(d);
//       }
//     }

//     for (let i = 0; i < obj.length; i++) {
//       const o = obj[i];
//       const cost = o.arr.reduce((a, b) => a + b.cost, 0);
//       const value = o.arr.reduce((a, b) => {
//         if (b.projectType === "admin") return a + 0;
//         if (b.projectType === "prospection") return a + 0;
//         if (b.projectType === "timeoff") return a + 0;
//         return a + b.value;
//       }, 0);

//       const days = (o.arr.reduce((a, b) => a + b.total, 0) / 8).toFixed(2);
//       o.cost = cost;
//       o.value = value;
//       o.days = days;
//     }

//     //sort by date
//     obj.sort((a, b) => new Date(b.date) - new Date(a.date));

//     return obj;
//   }

//   if (!d) return <div />;

//   const obj = getData(d);

//   const totalCost = obj.reduce((a, b) => a + b.cost, 0);
//   const totalValue = obj.reduce((a, b) => a + b.value, 0);
//   const totalDays = obj.reduce((a, b) => a + parseFloat(b.days), 0).toFixed(2);

//   const totalProfit = totalValue - totalCost;

//   return (
//     <div className="flex flex-col">
//       <div className="overflow-x-auto">
//         <div className="align-middle inline-block min-w-full">
//           <table className="min-w-full">
//             <thead>
//               <tr className="border-b border-gray-200 dark:border-gray-700 bg-gray-50 dark:bg-gray-800">
//                 <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-gray-400">Date</th>
//                 <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-gray-400">Days</th>
//                 <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-gray-400">Cost</th>
//                 <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-gray-400">Value</th>
//                 <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-gray-400">Profit</th>
//               </tr>
//             </thead>
//             <tbody className="bg-white divide-y divide-gray-100 dark:bg-gray-900 dark:divide-gray-800">
//               {(obj || []).map((e, i) => {
//                 const profit = e.value - e.cost;
//                 return (
//                   <tr key={i}>
//                     <td className="px-6 py-2 whitespace-nowrap">
//                       <div className="text-sm text-gray-900 dark:text-gray-100">{new Date(e.date).toString().substring(0, 15)}</div>
//                     </td>
//                     <td className="px-6 py-2 whitespace-nowrap">
//                       <div className="text-sm text-gray-900 dark:text-gray-100">{e.days} days</div>
//                     </td>
//                     <td className="px-6 py-2 whitespace-nowrap">
//                       <div className="text-sm text-gray-900 dark:text-gray-100">{e.cost.toFixed(0)}€</div>
//                     </td>
//                     <td className="px-6 py-2 whitespace-nowrap">
//                       <div className="text-sm text-gray-900 dark:text-gray-100">{e.value.toFixed(0)}€</div>
//                     </td>
//                     <td className="px-6 py-2 whitespace-nowrap">
//                       <div className="text-sm text-gray-900 dark:text-gray-100">{profit.toFixed(0)}€</div>
//                     </td>
//                   </tr>
//                 );
//               })}
//               <tr key="total">
//                 <td className="px-6 py-4 whitespace-nowrap">
//                   <div className="text-sm text-gray-900 dark:text-gray-100">Total</div>
//                 </td>
//                 <td className="px-6 py-4 whitespace-nowrap">
//                   <div className="text-sm text-gray-900 dark:text-gray-100">{totalDays} days</div>
//                 </td>
//                 <td className="px-6 py-4 whitespace-nowrap">
//                   <div className="text-sm text-gray-900 dark:text-gray-100">{totalCost.toFixed(0)}€</div>
//                 </td>
//                 <td className="px-6 py-4 whitespace-nowrap">
//                   <div className="text-sm text-gray-900 dark:text-gray-100">{totalValue.toFixed(0)}€</div>
//                 </td>
//                 <td className="px-6 py-4 whitespace-nowrap">
//                   <div className="text-sm text-gray-900 dark:text-gray-100">{totalProfit.toFixed(0)}€</div>
//                 </td>
//               </tr>
//             </tbody>
//           </table>
//         </div>
//       </div>
//     </div>
//   );
// };

// const UserAccordion = ({ users, setUsers }) => {
//   const [tree, setTree] = useState([]);

//   useEffect(() => {
//     get();
//   }, []);

//   async function get() {
//     try {
//       const res2 = await api.post(`/user/search`, { role: "applicant", statuses: ["HIRE"] });
//       arr.push(...res2.data.users);
//       arr = arr.map((e) => ({ ...e, children: [] }));
//       const items = arr.reduce((acc, e) => {
//         acc[e._id] = { ...e, children: [] };
//         return acc;
//       }, {});
//       arr = arr.reduce((acc, e) => {
//         if (e.manager_id && items[e.manager_id]) {
//           items[e.manager_id].children.push(items[e._id]);
//         } else {
//           acc.push(items[e._id]);
//         }
//         return acc;
//       }, []);

//       setTree(arr);
//     } catch (error) {
//       console.error(error);
//     }
//   }

//   // add a checkbox

//   const User = ({ data, level }) => {
//     const checked = users.find((e) => e._id === data._id);

//     return (
//       <div className="flex flex-col gap-2">
//         <div className="flex items-center gap-2">
//           <div style={{ width: level * 20 }}></div>
//           <div className="flex items-center gap-2">
//             <input
//               type="checkbox"
//               checked={checked}
//               onChange={(e) => {
//                 if (e.target.checked) {
//                   setUsers([...users, data]);
//                 } else {
//                   setUsers(users.filter((e) => e._id !== data._id));
//                 }
//               }}
//             />
//           </div>
//           <div>{data.name}</div>
//         </div>
//         <div className="flex flex-col gap-2">
//           {data.children.map((e, i) => (
//             <User key={i} data={e} level={level + 1} />
//           ))}
//         </div>
//       </div>
//     );
//   };

//   // accordier and checkbox
//   return (
//     <div className="flex flex-col gap-2">
//       <div className="flex flex-col gap-2">
//         {tree.map((e, i) => (
//           <User key={i} data={e} level={0} />
//         ))}
//       </div>
//     </div>
//   );
// };

export default Bonus;
