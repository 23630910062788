import React, { useEffect, useState } from "react";
import FileInput from "../../../../components/FileInput";
import RichTextEditorSun from "../../../../components/RichTextEditor";
import { applyFontClassToHtml } from "../utils";

const CvExperience = ({ values, editing, onChange }) => {
  if (editing) return <CvExperienceForm values={values} onChange={onChange} />;

  return (
    <div className="flex flex-row gap-4">
      <div className="w-[100px]">{values.logo_url && <img src={values.logo_url} alt="image.png" className="w-full max-h-[100px] object-contain rounded" />}</div>
      <div className="w-full">
        <div className="flex flex-row justify-between">
          <h4 className="font-medium font-poppins w-2/3 text-lg">{values.name}</h4>
          <h4 className="mr-2 font-poppins whitespace-nowrap text-sm text-gray-800">{values.start_and_end_date}</h4>
        </div>
        <div className="font-medium text-sm mb-1 font-poppins">{values.location}</div>
        <div className="text-sm font-poppins" dangerouslySetInnerHTML={{ __html: applyFontClassToHtml(values.description) }}></div>
        {values.image_url && <img src={values.image_url} alt="image.png" className="w-full max-h-[300px] object-contain rounded mt-5" />}
      </div>
    </div>
  );
};

const CvExperienceForm = ({ values, onChange }) => {
  function handleChange(e) {
    onChange({ ...values, [e.target.name]: e.target.value });
  }

  return (
    <div className="flex flex-col gap-2 p-2 bg-white border border-gray-200 rounded-md shadow-md my-6 ">
      <div className="w-[100px]">
        <label htmlFor="image_url">Logo</label>
        <FileInput folder="quotes/cv_experiences" value={values.logo_url} name="logo_url" onChange={handleChange} />
      </div>
      <div className="flex flex-row justify-between">
        <div>
          <label htmlFor="title">Experience Name</label>
          <input className="input" type="text" id="name" name="name" value={values.name} onChange={handleChange} />
        </div>
        <div>
          <label htmlFor="title">Start & End Date</label>
          <input className="input" type="text" id="start_and_end_date" name="start_and_end_date" value={values.start_and_end_date} onChange={handleChange} />
        </div>
      </div>
      <div>
        <label htmlFor="location">Location</label>
        <input className="input" type="text" id="location" name="location" value={values.location} onChange={handleChange} />
      </div>
      <div>
        <label htmlFor="description">Description</label>
        <RichTextEditorSun
          buttonList={[
            ["undo", "redo"],
            ["font", "fontSize", "formatBlock"],
            ["paragraphStyle", "blockquote"],
            ["bold", "underline", "italic", "strike", "subscript", "superscript"],
            ["fontColor", "hiliteColor"],
            ["align", "list", "lineHeight"],
            ["outdent", "indent"],

            ["table", "horizontalRule", "link", "image", "video"],
            ["removeFormat"],
          ]}
          values={values.description}
          onChange={(e) => onChange({ ...values, description: e })}
        />
      </div>
      <label htmlFor="image_url">Image</label>
      <FileInput folder="quotes/cv_experiences" value={values.image_url} name="image_url" onChange={handleChange} />
    </div>
  );
};

export default CvExperience;
