import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import html2pdf from "html2pdf.js";

import Loader from "../../components/loader";

import { TEMPLATE_FREELANCE, isFreelanceValid } from "./templates/Freelance";
import { TEMPLATE_FREELANCE_2, isFreelance2Valid } from "./templates/Freelance2";
import { TEMPLATE_NDA, isNdaValid } from "./templates/Nda";

import api from "../../services/api";

export default function View() {
  const { id } = useParams();
  const [contract, setContract] = useState();
  const [values, setValues] = useState({ date: new Date() });

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const { data } = await api.get(`/contract/${id}`);
      setValues({ ...values, ...data });
      setContract(data);
    })();
  }, [id]);

  const update = async () => {
    values.name = values.properties?.name || "";
    const { data } = await api.put(`/contract/${id}`, values);
    toast.success("Updated !");
    setValues({ ...values, ...data });
    setContract(data);
  };

  const remove = async () => {
    if (!confirm("Are you sure ?")) return;
    await api.remove(`/contract/${id}`);
    toast.success("Deleted !");
    navigate("/document");
  };

  const makePrint = async () => {
    if (!isValid(contract)) return toast.error("Please fill all required fields");
    const saveOptions = {
      margin: 0,
      filename: `${contract.template}.pdf`,
      image: { type: "jpeg", quality: 1 },
      html2canvas: { dpi: 300 },
      jsPDF: { unit: "mm", format: "a4" },
      pagebreak: { mode: "css" },
    };
    const mainElemToPrint = document.getElementById("content");
    await html2pdf().set(saveOptions).from(mainElemToPrint).save();
  };

  function isValid(contract) {
    if (contract.template === "NDA_V1") return isNdaValid(values);
    if (contract.template === "FREELANCE_V1") return isFreelanceValid(values);
    if (contract.template === "FREELANCE_V2") return isFreelance2Valid(values);
    return false;
  }

  function getTemplate(name) {
    if (name === "FREELANCE_V1") return <TEMPLATE_FREELANCE values={values} setValues={setValues} />;
    if (name === "FREELANCE_V2") return <TEMPLATE_FREELANCE_2 values={values} setValues={setValues} />;
    if (name === "NDA_V1") return <TEMPLATE_NDA values={values} setValues={setValues} />;
    return <div />;
  }

  if (!contract) return <Loader />;

  return (
    <div className="bg-white m-4 p-5">
      <div>{getTemplate(contract.template)}</div>

      <div className="flex items-center mt-4 space-x-2">
        <button className="bg-green-700 text-white py-2 px-5 rounded-md text-sm font-medium" onClick={makePrint}>
          Download
        </button>
        <button className="bg-primary text-white py-2 px-5 rounded-md text-sm font-medium" onClick={update}>
          Update
        </button>
        <button className="bg-red-700 text-white py-2 px-5 rounded-md text-sm font-medium" onClick={remove}>
          Delete
        </button>
      </div>
    </div>
  );
}
