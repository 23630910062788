import React, { useState } from "react";

import { ROLES } from "./constants"


export default () => {

  console.log("BENCHMARKS_CDI", BENCHMARKS_CDI)
  return <div className="m-2">
    <span className="text-[14px] font-medium text-[#212325] bt-2 mb-3">
        Here is some benchmark based on :
        <br />
        <a href="https://datarecrutement.fr/etude-salaire/">- DataRecrutement</a>
        <br />
        <a href="https://shodo.io/shomulator">- Shodo</a>
        <br />
        <a href="https://www.linkedin.com/posts/pauline-egea-yeita-collectif-produit_chez-yeita-nous-naimons-pas-les-cases-et-activity-7051811173276504064-r_zj">- Yeita</a>
        <br />
        <a href="https://drive.google.com/file/d/1kDFWMl4qNBadIVomMlzS_cAmGYaYhS3C/view?_hsenc=p2ANqtz-8n0MLGbbA_Ssigtj43VpsVE6pkGxqg0LvR_yInyiBgLmJ17Bu8MelcWG_Kchk-6NgdJMSmzyJRoCAbG_Lc_IQB0jbMQA&_hsmi=252992940">- The Product Crew</a> 
        <br />
        <a href="https://marketing-pictures.s3.eu-west-1.amazonaws.com/Salary_Report_2023/talentio_r_FR.pdf">- Talent.io x Figures</a> 
        <br />
        Our grid is based on those numbers.
        <br />
        We have +7% growth per year in the first 7 years of experience and then +3% growth per year of experience.
        <br />
        <br />
    </span>
    <table>
      <thead>
        <tr>
          <th>Job</th>
          <th>Experience</th>
          <th>Shodo</th>
          <th>DataRecrutement</th>
          <th>The Product Crew</th>
          <th>Average</th>
        </tr>
      </thead>
      <tbody>
        {BENCHMARKS_CDI.map((e, i) => {
          return <tr key={i}>
            {e.map((el, j) => {
              return <td key={j}>{el}</td>
            })}
          </tr>
        })}
      </tbody>
    </table>
  </div>
}

const BENCHMARKS_CDI = []

BENCHMARKS_CDI.push([ROLES.FULLSTACK, 0, 42000, 38800, 36000, 38900])
BENCHMARKS_CDI.push([ROLES.FULLSTACK, 1, 45000, 41700, 38000, 41500])
BENCHMARKS_CDI.push([ROLES.FULLSTACK, 2, 48000, 44800, 40000, 44200])
BENCHMARKS_CDI.push([ROLES.FULLSTACK, 3, 52000,	48200, 43000,	4700])
BENCHMARKS_CDI.push([ROLES.FULLSTACK, 4, 55000,	51800,	46000,	50900])
BENCHMARKS_CDI.push([ROLES.FULLSTACK, 5, 65000, 55000, 52000, 57333])
BENCHMARKS_CDI.push([ROLES.FULLSTACK, 6, 62000,	59800,	53000,	58200])
BENCHMARKS_CDI.push([ROLES.FULLSTACK, 7, 65000,	64300,	56000,	61700])
BENCHMARKS_CDI.push([ROLES.FULLSTACK, 8, 68000,	66300,	59000,	64400])
BENCHMARKS_CDI.push([ROLES.FULLSTACK, 9, 70000,	68300,	68000,	68700])
BENCHMARKS_CDI.push([ROLES.FULLSTACK, 10, 72000, 70300,	73000, 71800])

BENCHMARKS_CDI.push([ROLES.DEVOPS, 0, 42000,	43000,	42000,	42300])
BENCHMARKS_CDI.push([ROLES.DEVOPS, 1, 45000,	46000,	45000,	45300])
BENCHMARKS_CDI.push([ROLES.DEVOPS, 2, 48000,	49600,	48000,	48500])
BENCHMARKS_CDI.push([ROLES.DEVOPS, 3, 52000,	52900,	64000,	56300])
BENCHMARKS_CDI.push([ROLES.DEVOPS, 4, 55000,	56000,	67000,	59300])
BENCHMARKS_CDI.push([ROLES.DEVOPS, 5, 58000,	59000,	70000,	6200])
BENCHMARKS_CDI.push([ROLES.DEVOPS, 6, 62000,	62700,	72100,	65600])
BENCHMARKS_CDI.push([ROLES.DEVOPS, 7, 65000,	66000,	74200,	68400])
BENCHMARKS_CDI.push([ROLES.DEVOPS, 8, 68000,	69000,	76500,	71100])
BENCHMARKS_CDI.push([ROLES.DEVOPS, 9, 70000,	72600,	78000,	73400])
BENCHMARKS_CDI.push([ROLES.DEVOPS, 10, 72000,	75900,	8100,	76300])

BENCHMARKS_CDI.push([ROLES.DATA, 0 , 42000, 45000,	41000,	42700])
BENCHMARKS_CDI.push([ROLES.DATA, 1, 45000,	48000,	43000,	45300])
BENCHMARKS_CDI.push([ROLES.DATA, 2, 48000,	51000,	45000,	48000])
BENCHMARKS_CDI.push([ROLES.DATA, 3, 52000,	54800,	47000,	51300])
BENCHMARKS_CDI.push([ROLES.DATA, 4, 55000,	58900, 51000,	55000])
BENCHMARKS_CDI.push([ROLES.DATA, 5, 58000,	63300,	55000,	58700])
BENCHMARKS_CDI.push([ROLES.DATA, 6, 6200,	68100,	57000,	62300])
BENCHMARKS_CDI.push([ROLES.DATA, 7, 65000,	73200,	59000, 65700])
BENCHMARKS_CDI.push([ROLES.DATA, 8, 68000,	75400,	63000,	68800])
BENCHMARKS_CDI.push([ROLES.DATA, 9, 70000,	77700,	65000,	70900])
BENCHMARKS_CDI.push([ROLES.DATA, 10, 72000,	80000,	68000,	73300])

BENCHMARKS_CDI.push([ROLES.PRODUCT, 0 , 42000, 45000,	41000,	42700])
BENCHMARKS_CDI.push([ROLES.PRODUCT, 1, 45000,	48000,	43000,	45300])
BENCHMARKS_CDI.push([ROLES.PRODUCT, 2, 48000,	51000,	45000,	48000])
BENCHMARKS_CDI.push([ROLES.PRODUCT, 3, 52000,	54800,	47000,	51300])
BENCHMARKS_CDI.push([ROLES.PRODUCT, 4, 55000,	58900, 51000,	55000])
BENCHMARKS_CDI.push([ROLES.PRODUCT, 5, 58000,	63300,	55000,	58700])
BENCHMARKS_CDI.push([ROLES.PRODUCT, 6, 6200,	68100,	57000,	62300])
BENCHMARKS_CDI.push([ROLES.PRODUCT, 7, 65000,	73200,	59000, 65700])
BENCHMARKS_CDI.push([ROLES.PRODUCT, 8, 68000,	75400,	63000,	68800])
BENCHMARKS_CDI.push([ROLES.PRODUCT, 9, 70000,	77700,	65000,	70900])
BENCHMARKS_CDI.push([ROLES.PRODUCT, 10, 72000,	80000,	68000,	73300])

const BENCHMARKS_CDI_START = []

BENCHMARKS_CDI_START.push([ROLES.FULLSTACK, 0, 38000])
BENCHMARKS_CDI_START.push([ROLES.DEVOPS, 0, 42000])
BENCHMARKS_CDI_START.push([ROLES.DATA, 0, 40000])
BENCHMARKS_CDI_START.push([ROLES.PRODUCT, 0, 40000])
BENCHMARKS_CDI_START.push([ROLES.SALES, 0, 35000])
BENCHMARKS_CDI_START.push([ROLES.GROWTH, 0, 37000])




export { BENCHMARKS_CDI, BENCHMARKS_CDI_START} 