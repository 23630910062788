import React from "react";
import { Route, Routes } from "react-router-dom";

import Create from "./create";
import Profile from "./profile";
import Contract from "./contract";
import Complete from "./old/complete";

const Auth = () => {
  return (
    <Routes>
      <Route path="/" element={<Create />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/contract" element={<Contract />} />
      <Route path="/complete" element={<Complete />} />
    </Routes>
  );
};

export default Auth;
