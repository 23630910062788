export const CARDS = [
  { value: "1397223", label: "Bunq Account" },
  { value: "3995515", label: "Seb 1" },
  { value: "3995524", label: "Fab" },
  { value: "3995520", label: "Max" },
  { value: "4506172", label: "Théo old card" },
  { value: "5820083", label: "Théo LM" },
  { value: "4506175", label: "Hugo L" },
  { value: "4690301", label: "Docloop" },
  { value: "4746846", label: "Fridgee" },
  { value: "4747857", label: "Growth" },
  { value: "4969349", label: "FKM" },
  { value: "4964474", label: "Seb 2" },
  { value: "4992148", label: "Expenses" },
  { value: "", label: "Unknown" },
];

export const BANKS = [
  { value: "BUNQ", label: "Bunq" },
  { value: "BNP", label: "Bnp" },
  { value: "BUNQ_LESTUD", label: "Le stud" },
];

export const CATEGORIES = [
  { value: "GENERAL_COST", label: "General Cost" },
  { value: "VARIABLE_COST  ", label: "Variable Cost" },
  { value: "DISBURSEMENT", label: "Disbursement" },
  { value: "INCOME", label: "Income" },
  { value: "REFUND", label: "Refund" },
];

export const SUBCATEGORIES = [
  { value: "BANK", label: "BANK" },
  { value: "TRAVEL", label: "TRAVEL" },
  { value: "HOSTING", label: "HOSTING" },
  { value: "OFFICE", label: "OFFICE" },
  { value: "ADS", label: "ADS" },
  { value: "ACCOUNTING", label: "ACCOUNTING" },
  { value: "TOOLS", label: "TOOLS" },
  { value: "FOODANDBEVERAGE", label: "FOODANDBEVERAGE" },
  { value: "LEGAL", label: "LEGAL" },
  { value: "OTHER", label: "OTHER" },

  { value: "SALARY_FREELANCE", label: "SALARY_FREELANCE" },
  { value: "SALARY_EMPLOYEE", label: "SALARY_EMPLOYEE" },
  { value: "TAX_SALARY", label: "TAX_SALARY" },
  { value: "BUSINESS_INTRODUCER_COST", label: "BUSINESS_INTRODUCER_COST" },

  { value: "INVOICE", label: "INVOICE" },
  { value: "VAT_INCOME", label: "VAT_INCOME" },
  { value: "BUSINESS_INTRODUCER_INCOME", label: "BUSINESS_INTRODUCER_INCOME" },
  { value: "SUBVENTION", label: "SUBVENTION" },

  { value: "CORPORATION_TAX", label: "CORPORATION_TAX" },
  { value: "VAT_DISBURSEMENT", label: "VAT_DISBURSEMENT" },
  { value: "TRANSFER", label: "TRANSFER" },
  { value: "INVEST", label: "INVEST" },
  { value: "DIVIDEND", label: "DIVIDEND" },
  { value: "IT_EQUIPMENT", label: "IT_EQUIPMENT" },

  { value: "REFUND", label: "REFUND" },
];

export const CATEGORIES_OBJ = {
  GENERAL_COST: ["BANK", "TRAVEL", "HOSTING", "OFFICE", "ADS", "ACCOUNTING", "TOOLS", "FOODANDBEVERAGE", "LEGAL", "OTHER"],
  VARIABLE_COST: ["SALARY_FREELANCE", "SALARY_EMPLOYEE", "TAX_SALARY", "BUSINESS_INTRODUCER_COST"],
  DISBURSEMENT: ["CORPORATION_TAX", "VAT_DISBURSEMENT", "TRANSFER", "INVEST", "DIVIDEND", "IT_EQUIPMENT"],
  INCOME: ["INVOICE", "VAT_INCOME", "BUSINESS_INTRODUCER_INCOME", "SUBVENTION"],
  REFUND: ["REFUND"],
};
