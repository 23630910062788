import "isomorphic-fetch";
import amplitude from "amplitude-js";

const ENV = "production";

class Ampli {
  constructor() {
    if (process.env.NODE_ENV === ENV) {
      amplitude.getInstance().init("7b0e920409ffac96fb1dc0b8290b900e", null, {
        trackingOptions: {
          city: false,
          ip_address: false,
        },
      });
    }
  }

  logEvent(str, obj) {
    if (process.env.NODE_ENV === ENV) {
      amplitude.getInstance().logEvent(str, obj);
    } else {
      console.log({ amplitude: { str, obj } });
    }
  }
}

const API = new Ampli();
export default API;
