import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import toast from "react-hot-toast";

import Loader from "../../../components/loader";
import api from "../../../services/api";

export default () => {
  const [channel, setChannel] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    (async () => {
      const { data } = await api.get(`/jobchannel/${id}`);
      setChannel(data);
    })();
  }, []);

  const navigate = useNavigate();

  async function onDelete() {
    const confirm = window.confirm("Are you sure ?");
    if (!confirm) return;
    await api.remove(`/jobchannel/${id}`);
    toast.success("successfully removed!");
    navigate("/jobchannel");
  }

  async function handleSubmit() {
    try {
      const { ok, data, code } = await api.put(`/jobchannel/${id}`, channel);
      if (!ok) return toast.error(`Error !\n${code}`, { icon: "❌" });
      setChannel(data);
      toast.success("Channel updated !");
    } catch (e) {
      console.log(e);
      toast.error("Some Error!", e.code);
    }
  }

  if (!channel) return <Loader />;

  console.log("channel", channel);

  return (
    <div className="flex flex-col gap-4 pt-2 ">
      <div className="bg-white rounded-2xl p-12 pb-6">
        <div className="grid grid-cols-2 gap-4">
          <div>
            <div className="text-[14px] text-[#212325] font-medium mt-2">Name (*)</div>
            <input
              className="projectsInput text-[14px] font-normal text-[#212325] rounded-[10px]"
              value={channel.name}
              onChange={(e) => setChannel({ ...channel, name: e.target.value })}
            />
          </div>
          <div>
            <div className="text-[14px] text-[#212325] font-medium">Url</div>
            <input
              className="projectsInput text-[14px] font-normal text-[#212325] rounded-[10px]"
              value={channel.url}
              onChange={(e) => setChannel({ ...channel, url: e.target.value })}
            />
          </div>
          <div>
            <div className="text-[14px] text-[#212325] font-medium">Login</div>
            <input
              className="projectsInput text-[14px] font-normal text-[#212325] rounded-[10px]"
              value={channel.login}
              onChange={(e) => setChannel({ ...channel, login: e.target.value })}
            />
          </div>
          <div>
            <div className="text-[14px] text-[#212325] font-medium">Password</div>
            <input
              className="projectsInput text-[14px] font-normal text-[#212325] rounded-[10px]"
              value={channel.password}
              onChange={(e) => setChannel({ ...channel, password: e.target.value })}
            />
          </div>
          <div className=" col-span-2">
            <div className="text-[14px] text-[#212325] font-medium">Description</div>
            <textarea
              rows="5"
              className="projectsInput h-20 text-[14px] font-normal text-[#212325] rounded-[10px]"
              value={channel.description}
              onChange={(e) => setChannel({ ...channel, description: e.target.value })}
            />
          </div>
          <div>
            <div className="text-[14px] text-[#212325] font-medium">Category</div>
            <input
              className="projectsInput text-[14px] font-normal text-[#212325] rounded-[10px]"
              value={channel.category}
              onChange={(e) => setChannel({ ...channel, category: e.target.value })}
            />
          </div>
          <div>
            <div className="text-[14px] text-[#212325] font-medium">Location</div>
            <input
              className="projectsInput text-[14px] font-normal text-[#212325] rounded-[10px]"
              value={channel.location}
              onChange={(e) => setChannel({ ...channel, location: e.target.value })}
            />
          </div>

          <div>
            <div className="text-[14px] text-[#212325] font-medium">Contacts</div>
            <input
              className="projectsInput text-[14px] font-normal text-[#212325] rounded-[10px]"
              value={channel.contacts}
              onChange={(e) => setChannel({ ...channel, contacts: e.target.value })}
            />
          </div>
        </div>

        <div className="italic text-sm mt-3">created at {new Date(channel.created_at).toLocaleString()}</div>
        <div className="flex  mt-2">
          <button className="bg-[#0560FD] text-[16px] font-medium text-[#fff] py-[12px] px-[22px] rounded-[10px]" onClick={handleSubmit}>
            Update
          </button>
          <button className="ml-[10px] bg-[#F43F5E] text-[16px] font-medium text-[#FFFFFF] py-[12px] px-[22px] rounded-[10px]" onClick={onDelete}>
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};
