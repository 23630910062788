import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";

import api from "../../../services/api";

export default ({ job }) => {
  const [filter, setFilter] = useState({ search: "" });
  const [users, setUsers] = useState(null);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    load();
  }, [filter]);

  async function load() {
    setLoading(true);
    const newFilter = { ...filter };
    newFilter.role = "applicant";
    newFilter.job_ids = [job._id];

    const { data } = await api.post(`/user/search`, newFilter);

    setUsers(data.users);
    setTotal(data.total);
    setLoading(false);
  }

  if (loading) return <div>Loading...</div>;

  console.log(users);

  return (
    <div className="p-1">
      <section className="flex justify-between">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {/* <SelectStatus value={filter.status} onChange={(status) => setFilter({ ...filter, status })} /> */}
          <div>{total} applicants</div>
        </div>
        <Export users={users} />
      </section>
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Name
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Country
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Channel
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Created at
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {(users || []).map((user) => (
            <tr key={job._id}>
              <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
                <div className="flex items-center">
                  <Link to={`/job/${job._id}?user_modal_id=${user._id}&user_modal_tab=about`}>{user.name}</Link>
                </div>
              </td>
              <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500">{user.country}</td>
              <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500">{user.channel_name}</td>
              <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500">{user.created_at}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};


const Export = ({ users }) => {
  const onClick = async () => {
    let csvContent = "data:text/csv;charset=utf-8,";
    csvContent += `Index;Name;Linkedin;TJM;\n`;

    for (let i = 0; i < users.length; i++) {
      const e = users[i];
      const str = `${i};${e.name};${e.linkedin};${e.tjm};\n`;
      csvContent += str;
    }

    var encodedUri = encodeURI(csvContent);
    window.open(encodedUri);
  };

  return (
    <button className="btn btn-primary" onClick={onClick}>
      Export
    </button>
  );
};