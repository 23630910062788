import { useState, useRef } from "react";
import { LuUploadCloud } from "react-icons/lu";

import API from "../services/api";
import { readFileAsync } from "../utils";

const FileInputStyled = ({ title, onChange, name, folder, disabled = false }) => {
  const [loading, setLoading] = useState(false);
  const [fileName, setFileName] = useState("");
  const inputRef = useRef(null);

  const handleFileChange = async (e) => {
    setLoading(true);

    const files = e.target.files;
    const filePromises = Array.from(files).map(async (f) => {
      const rawBody = await readFileAsync(f);
      const { data } = await API.post(`/file`, { file: { rawBody, name: f.name }, folder });
      return data;
    });

    const fileDataArray = await Promise.all(filePromises);
    onChange({ target: { value: fileDataArray, name } });
    setLoading(false);
  };

  return (
    <div className="relative w-54 h-12 mb-4">
      <input disabled={disabled} ref={inputRef} id={`openFile-${name}`} type="file" onChange={handleFileChange} className="hidden" />
      {loading ? (
        <div className="w-full h-full flex justify-center items-center">Loading...</div>
      ) : (
        <>
          <button
            type="button"
            className="bg-white w-full h-full text-xs text-center p-2 flex justify-center items-center border rounded-md"
            onClick={() => inputRef.current.click()}>
            <div className="flex justify-start items-center gap-2">
              <LuUploadCloud size={24} />
              {title}
            </div>
          </button>
          {/* Display the file name below the button */}
          {fileName && <div className="text-xs text-center mt-2">{fileName}</div>}
        </>
      )}
    </div>
  );
};

export default FileInputStyled;
