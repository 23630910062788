import React, { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { RiArrowDownSLine, RiArrowUpSLine, RiCloseLine, RiCheckLine } from "react-icons/ri";

const Select = ({
  options,
  value,
  onChange,
  disabled = false,
  multiple = false,
  nullable = true,
  placeholder = "Sélectionner une option",
  width = "w-64",
  position = "left-0 top-full",
  getValue = (o) => o,
  getLabel = (o) => o,
  by = (a, b) => getValue(a) === getValue(b),
}) => (
  <Listbox value={value || ""} onChange={onChange} disabled={disabled} by={by} multiple={multiple}>
    {({ open }) => (
      <>
        <div className="relative w-full">
          <div
            className={`w-full flex items-center justify-between border border-gray-300 rounded hover:border-sky-700 ${
              (multiple && value.length > 0) || (!multiple && value) ? "!border-sky-700" : ""
            }`}>
            <Listbox.Button className="flex-1 text-left placeholder:text-gray-600 text-sm py-2 px-3">
              {multiple && value.length > 0 ? (
                <p className="truncate text-left text-sm text-black">
                  {getLabel(value[0])} {value.length > 1 && `+${value.length - 1}`}
                </p>
              ) : !multiple && value ? (
                <p className="truncate text-left text-sm text-black">{getLabel(value)}</p>
              ) : (
                <p className="truncate text-gray-700 opacity-60 text-left text-sm">{placeholder}</p>
              )}

              {((multiple && !value.length) || (!multiple && !value) || !nullable) && !disabled && (
                <div className="absolute inset-y-0 right-0 flex items-center pr-2">{open ? <RiArrowUpSLine /> : <RiArrowDownSLine />}</div>
              )}
            </Listbox.Button>{" "}
            {((multiple && value.length > 0) || (!multiple && value)) && !disabled && nullable && (
              <button className="flex items-center pr-2" onClick={() => onChange(multiple ? [] : null)}>
                <RiCloseLine />
              </button>
            )}
          </div>

          <Transition show={open} as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Listbox.Options className={`absolute z-40 my-1 max-h-64 ${width} ${position} text-base overflow-y-scroll border border-neutral-200 rounded-md bg-white space-y-1 p-1`}>
              {options.map((option, i) => (
                <Listbox.Option key={i} value={option} className="select-none list-none">
                  {({ active, selected }) => (
                    <div className={`${active ? "bg-gray-100" : "bg-transparent"} px-3 py-2 flex items-center justify-between`}>
                      <p className={`block truncate text-sm ${selected ? "font-semibold" : "font-normal"}`}>{getLabel(option)}</p>
                      {selected && <RiCheckLine />}
                    </div>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </>
    )}
  </Listbox>
);

export default Select;
