import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

import SelectMonth from "../../components/selectMonth";
import SelectProject from "../../components/selectProject";
import SelectUser from "../../components/selectUser";
import Loader from "../../components/loader";

import api from "../../services/api";
import Select from "../../components/Select";
import Combobox from "../../components/Combobox";

const MONTHS = ["January", "February", "March", "April", "May", "June", "July", "August", "Septembe", "October", "November", "December"];

const InvoiceList = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [filters, setFilters] = useState({
    month: searchParams.get("month") || "",
    year: searchParams.get("year") || new Date().getFullYear(),
    project: searchParams.get("project") || "",
    sent: searchParams.get("sent") || "",
    received: searchParams.get("received") || "no",
    organisation: searchParams.get("organisation") || "",
  });
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState([]);
  const [organisations, setOrganisations] = useState([]);

  const navigate = useNavigate();
  const organisation = useSelector((state) => state.Auth.organisation);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const { ok, data } = await api.post("/project/search", { status: "active", per_page: 1000 });
        if (ok) setProjects(data.projects);
      } catch (error) {
        toast.error("An error occurred while fetching projects");
      }
    };

    const fetchOrganisations = async () => {
      try {
        const { ok, data } = await api.post("/organisation/search");
        if (ok) setOrganisations(data);
      } catch (error) {
        toast.error("An error occurred while fetching organisations");
      }
    };

    fetchProjects();
    fetchOrganisations();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const obj = { limit: 1000 };
      if (filters.month) {
        const year = filters.year || new Date().getFullYear();
        const month = MONTHS.indexOf(filters.month);
        obj.date = new Date(Date.UTC(year, month, 1));
      }
      if (filters.project) obj.projectId = filters.project;
      if (filters.received) obj.received = filters.received;
      if (filters.projectLeadId) obj.projectLeadId = filters.projectLeadId;
      if (filters.sent) obj.sent = filters.sent;
      if (filters.organisation) obj.organisation_id = filters.organisation;

      const newSearchParams = new URLSearchParams();
      if (filters.year) newSearchParams.set("year", filters.year);
      if (filters.month) newSearchParams.set("month", filters.month);
      if (filters.project) newSearchParams.set("project", filters.project);
      if (filters.received) newSearchParams.set("received", filters.received);
      if (filters.organisation) newSearchParams.set("organisation", filters.organisation);
      setSearchParams(newSearchParams);

      setLoading(true);

      const res = await api.post("/invoice/search", obj);
      setLoading(false);
      if (res.ok) setData(res.data);
    };
    fetchData();
  }, [filters, organisation]);

  return (
    <div className="m-10 flex flex-col gap-6">
      <div className="flex flex-col md:flex-row gap-3 items-center w-full justify-between">
        <div className="flex items-center rounded border border-sky-700 p-2 px-3 text-sm text-sky-700">
          <b>{data?.length || 0}</b>&nbsp;invoice(s)
        </div>
        <div className="flex items-center gap-3">
          <CreateModal />
          <ExportModal data={data} />
        </div>
      </div>

      <div className="w-full flex-col md:flex-row flex items-center gap-4">
        <div className="w-full">
          <label className="text-xs text-gray-500">Year</label>
          <Select
            options={[2021, 2022, 2023, 2024]}
            value={filters.year}
            onChange={(e) => setFilters({ ...filters, year: e, month: !e ? undefined : filters.month })}
            placeholder="Year"
            width="w-full md:w-80"
            disabled={loading}
            nullable={false}
          />
        </div>
        <div className="w-full">
          <label className="text-xs text-gray-500">Month</label>
          <Select
            options={MONTHS}
            value={filters.month}
            onChange={(e) => setFilters({ ...filters, year: e && filters.year ? filters.year : new Date().getFullYear(), month: e })}
            placeholder="Month"
            width="w-full md:w-80"
            disabled={loading}
          />
        </div>
        <div className="w-full">
          <label className="text-xs text-gray-500">Project</label>
          <Combobox
            options={projects}
            value={projects.find((e) => e._id === filters.project)}
            onChange={(e) => setFilters({ ...filters, project: e._id })}
            placeholder="Project"
            getValue={(o) => o._id}
            getLabel={(o) => o.name}
            match={(o, s) => o.name.toLowerCase().includes(s.toLowerCase())}
            width="w-full md:w-80"
            disabled={loading}
          />
        </div>
        <div className="w-full">
          <label className="text-xs text-gray-500">Organisation</label>
          <Combobox
            options={organisations}
            value={filters.organisation ? organisations.find((e) => e._id === filters.organisation) : null}
            onChange={(e) => setFilters({ ...filters, organisation: e ? e._id : "" })}
            placeholder="Organisation"
            getValue={(o) => o._id}
            getLabel={(o) => o.name}
            match={(o, s) => o.name.toLowerCase().includes(s.toLowerCase())}
            width="w-full md:w-80"
            disabled={loading}
          />
        </div>

        <div className="w-full">
          <label className="text-xs text-gray-500">Leader</label>
          <SelectUser
            className="mb-3"
            label="Assigned to"
            userName={filters.projectLeadName}
            value={{ user_name: filters.projectLeadName, user_id: filters.projectLeadId }}
            onChange={(e) => {
              setFilters({ ...filters, projectLeadId: e._id, projectLeadName: e.name });
            }}
          />
        </div>
        <div className="w-full">
          <label className="text-xs text-gray-500">Received</label>
          <Select
            options={["all", "yes", "no"]}
            value={filters.received}
            onChange={(e) => setFilters({ ...filters, received: e })}
            placeholder="Received"
            width="w-full md:w-80"
            getValue={(o) => o}
            getLabel={(o) => o.charAt(0).toUpperCase() + o.slice(1)}
            disabled={loading}
          />
        </div>
        <div className="w-full">
          <label className="text-xs text-gray-500">Sent</label>
          <Select
            options={["all", "yes", "no"]}
            value={filters.sent}
            onChange={(e) => setFilters({ ...filters, sent: e })}
            placeholder="Received"
            width="w-full md:w-80"
            getValue={(o) => o}
            getLabel={(o) => o.charAt(0).toUpperCase() + o.slice(1)}
            disabled={loading}
          />
        </div>
      </div>
      <section>{loading ? <Loader /> : <List data={data} />}</section>
    </div>
  );
};

const List = ({ data }) => (
  <ul className="flex flex-col gap-1">
    <li className="w-full list-none flex flex-row gap-2 px-4">
      <h3 className="w-2/12 text-gray-800 text-sm mb-0">Name</h3>
      <h3 className="w-3/12 text-gray-800 text-sm mb-0">Status</h3>
      <h3 className="flex-1 text-gray-800 text-sm mb-0">Informations</h3>
      <h3 className="w-1/12 text-gray-800 text-sm mb-0">Leader</h3>
    </li>
    {data.map((e, i) => {
      const date = new Date(e.date);
      const isNewMonth = i === 0 || date.getMonth() !== new Date(data[i - 1].date).getMonth();
      return (
        <div key={i}>
          {isNewMonth && (
            <div className="list-none flex items-center bg-blue-100 rounded mb-1 p-2">
              <h3 className="text-sm text-gray-700">
                {MONTHS[date.getMonth()]} {date.getFullYear()}
              </h3>
            </div>
          )}
          <Item data={e} />
        </div>
      );
    })}
  </ul>
);

const BILLING = {
  MONTHLY: <span className="p-1 rounded bg-blue-100">Monthly</span>,
  ONE_TIME: <span className="p-1 rounded bg-green-100">One time</span>,
};

const Item = ({ data }) => (
  <li className="list-none">
    <Link to={`/invoice/${data._id}`}>
      <div className="p-4 flex flex-col gap-2 md:flex-row items-center w-full rounded border border-gray-200 text-sm text-gray-700 hover:border-sky-700 appearance-none">
        <div className="w-2/12 flex flex-col gap-2 ">
          <h4 className="text-center md:text-left">{data.name}</h4>
        </div>
        <div className="w-2/12 flex flex-col items-start gap-2">
          {data.sent === "yes" ? (
            <>
              <div className="flex items-center gap-2 justify-center">
                <div className="w-2 h-2 rounded bg-green-600 mr-2" />
                <span className="text-xs text-gray-700">Sent the {new Date(data.sentAt).toLocaleDateString("fr")}</span>
              </div>
              {data.received === "yes" ? (
                <div className="flex items-center gap-2 justify-center">
                  <div className="w-2 h-2 rounded bg-green-600 mr-2" />
                  <p className="flex-1 text-xs text-gray-700 whitespace-break-spaces">Received the {new Date(data.receivedAt).toDateString()}</p>
                </div>
              ) : data.late > 0 ? (
                <div className="flex items-center gap-2 justify-center">
                  <div className="w-2 h-2 rounded bg-red-600 mr-2" />
                  <p className="flex-1 text-xs text-gray-700 whitespace-break-spaces">{data.late} days late</p>
                </div>
              ) : (
                <div className="flex items-center gap-2 justify-center">
                  <div className="w-2 h-2 rounded bg-orange-400 mr-2" />
                  <p className="flex-1 text-xs text-gray-700 whitespace-break-spaces">Not received {-data.late} days remaining</p>
                </div>
              )}
            </>
          ) : new Date() > new Date(data.dueAt) ? (
            <div className="flex items-center gap-2 justify-center">
              <div className="w-2 h-2 rounded bg-red-600 mr-2" />
              <p className="flex-1 text-xs text-gray-700 whitespace-break-spaces">Not sent - should have been sent on {new Date(data.dueAt).toLocaleDateString("fr")}</p>
            </div>
          ) : (
            <div className="flex items-center gap-2 justify-center">
              <div className="w-2 h-2 rounded bg-orange-400 mr-2" />
              <p className="flex-1 text-xs text-gray-700 whitespace-break-spaces">Not sent</p>
            </div>
          )}
        </div>

        <div className="flex-1 grid-cols-4 gap-2 hidden md:grid">
          <div className="flex items-center w-1/6">
            <label className="text-xs text-gray-500">Type</label>
            <span className="ml-2 text-xs text-gray-700">{data.type}</span>
          </div>
          <div className="flex items-center">
            <label className="text-xs text-gray-500">Budget</label>
            <span className="ml-2 text-xs text-gray-700">{data.budget ? `${data.budget.toLocaleString("fr", { style: "currency", currency: "EUR" })}` : ` -- `}</span>
          </div>
          <div className="flex items-center">
            <label className="text-xs text-gray-500">Cost</label>
            <span className="ml-2 text-xs text-gray-700">{data.cost ? `${data.cost.toLocaleString("fr", { style: "currency", currency: "EUR" })}` : ` -- `}</span>
          </div>
          <div className="flex items-center">
            <label className="text-xs text-gray-500">Margin</label>
            <span className="ml-2 text-xs text-gray-700">{data.margin != null ? `${data.margin} %` : ` -- `}</span>
          </div>
          <div className="flex items-center">
            <label className="text-xs text-gray-500">Billing</label>
            <span className="ml-2 text-xs text-gray-700">{BILLING[data.billing] || data.billing}</span>
          </div>
          <div className="flex items-center">
            <label className="text-xs text-gray-500">Index</label>
            <span className="ml-2 text-xs text-gray-700">{data.index || " -- "}</span>
          </div>
          <div className="flex items-center">
            <label className="text-xs text-gray-500">Total</label>
            <span className="ml-2 text-xs text-gray-700">{data.total ? `${data.total.toLocaleString("fr", { style: "currency", currency: "EUR" })}` : ` -- `}</span>
          </div>
          <div className="flex items-center">
            <label className="text-xs text-gray-500">Profit</label>
            <p className="flex-1 ml-2 text-xs text-gray-700">{data.profit != null ? `${data.profit.toLocaleString("fr", { style: "currency", currency: "EUR" })}` : ` -- `}</p>
          </div>
        </div>
        <div className="w-1/12 flex-col gap-2 hidden md:flex">
          <h4 className="w-full text-xs text-gray-700">{data.projectLeadName}</h4>
        </div>
      </div>
    </Link>
  </li>
);

const CreateModal = () => {
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({});
  const navigate = useNavigate();

  async function onCreate() {
    if (!values.project) return toast.error("Missing Project !");
    const res = await api.post("/invoice", { project_id: values.project._id, date: values.date });
    navigate(`/invoice/${res.data._id}`);
  }

  return (
    <>
      <div className="text-right">
        <button className="empty-btn" onClick={() => setOpen(true)}>
          Create an invoice
        </button>
      </div>
      {open ? (
        <div className=" absolute top-0 bottom-0 left-0 right-0  bg-[#00000066] flex justify-center p-[1rem] z-50 " onClick={() => setOpen(false)}>
          <div
            className="w-full md:w-[60%] h-fit  bg-[white] p-[25px] rounded-md"
            onClick={(e) => {
              e.stopPropagation();
            }}>
            <React.Fragment>
              <div>
                <div className="flex flex-wrap justify-between mt-3">
                  <div className="w-full md:w-[48%] mt-2">
                    <div className="text-[14px] text-[#212325] font-medium">Project (*)</div>
                    <SelectProject indexDefaultValue={0} value={values?.project?.name} onChange={(e) => setValues({ ...values, project: e })} />
                  </div>
                  <div className="w-full md:w-[48%] mt-2">
                    <div className="text-[14px] text-[#212325] font-medium">Mois (*)</div>
                    <SelectMonth
                      placeholder="Select month"
                      indexDefaultValue={0}
                      start={-3}
                      value={values.date}
                      onChange={(e) => setValues({ ...values, date: e.target.value })}
                      name="date"
                    />
                  </div>
                </div>
              </div>
              <br />
              <button className="mt-[1rem] bg-[#0560FD] text-[16px] font-medium text-[#FFFFFF] py-[12px] px-[22px] rounded-[10px]" onClick={onCreate}>
                Create
              </button>
            </React.Fragment>
          </div>
        </div>
      ) : null}
    </>
  );
};

const ExportModal = ({ data }) => {
  console.log(`data`, data);
  const onClick = async () => {
    let csvContent = "data:text/csv;charset=utf-8,";
    csvContent += `Id;Name;Index;Sent at;Due At;Link to invoice;Total;Net Total;Days late;Penality; With Penality\n`;
    csvContent += data
      .map(
        (e) =>
          `${e.name};${e.index};${(e.sentAt || "").substring(0, 10)};${(e.dueAt || "").substring(0, 10)}; ${e.file ? e.file : "not generated"};${e.total}€;${e.netTotal}€;${
            e.late
          } days late;${e.penality}€; ${(e.total + e.penality).toLocaleString("fr", { style: "currency", currency: "EUR" })}`,
      )
      .join("\n");
    var encodedUri = encodeURI(csvContent);
    window.open(encodedUri);
  };

  return (
    <button className="empty-btn" onClick={onClick}>
      Export
    </button>
  );
};

export default InvoiceList;
