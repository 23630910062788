import React from "react";
import { Route, Routes } from "react-router-dom";

import List from "./list";
import View from "./view";

const JobChannel = () => {
  return (
    <Routes>
      <Route path="/" element={<List />} />
      <Route path="/:id" element={<View />} />
    </Routes>
  );
};
export default JobChannel;
