import React, { useEffect, useState } from "react";
import { IoTrash } from "react-icons/io5";
import { getBase64file } from "../utils";

import RichTextEditorSun from "../../../../components/RichTextEditor";
import FileInput from "../../../../components/FileInput";
import MultiSelect from "../../../../components/MultiSelect";

import API from "../../../../services/api";

const MultipleImagesBlock = ({ values, editing, onChange }) => {
  const [images, setImages] = useState([]);
  useEffect(() => {
    if (!values.images || values.images.length === 0) return;
    getImages();
  }, [values.images]);

  const getImages = async () => {
    const base64Images = [];
    for (const image of values.images) {
      const base64 = await getBase64file(image.logo_url);
      base64Images.push({ ...image, logo_url: base64 });
    }
    setImages(base64Images);
  };

  if (editing) return <MultipleImagesForm values={values} onChange={onChange} />;

  return (
    <div className={`grid ${values.grid_props ? values.grid_props : "grid-cols-5"} gap-2`}>
      {images?.map((image, i) => (
        <div className={`w-full rounded-lg border-gray-200 flex justify-center items-center ${values.border ? "border p-1" : ""}`} key={i}>
          <img src={image.logo_url} alt="image-logo_url" />
        </div>
      ))}
    </div>
  );
};

const MultipleImagesForm = ({ values, onChange }) => {
  const [refresh, setRefresh] = useState(false);
  const [projects, setProjects] = useState([]);
  const [number, setNumber] = useState(Number(values.grid_props?.split("-")[2]));

  useEffect(() => {
    (async () => {
      const res = await API.get("/project?status=active");
      let projects = res.data
        .sort(function (a, b) {
          if (a.name?.toLowerCase() < b.name?.toLowerCase()) return -1;
          if (a.name?.toLowerCase() > b.name?.toLowerCase()) return 1;
          return 0;
        })
        .map((j) => ({ value: j._id, label: j.name, logo_url: j.logo }));
      projects.push({ value: "", label: "Unknown", logo_url: "" });
      setProjects(projects);
    })();
  }, []);

  function handleChange(e) {
    setRefresh(true);
    onChange({ ...values, [e.target.name]: e.target.value });
  }

  return (
    <div className="flex flex-col gap-2 bg-white border border-gray-200 rounded-md shadow-md p-5 my-5">
      <div className="w-full flex justify-between">
        <div className="flex items-center gap-4">
          <label>Column number - {number}</label>
          <button
            onClick={() => {
              setNumber(number - 1);
              handleChange({
                target: {
                  name: "grid_props",
                  value: `grid-cols-${number - 1}`,
                },
              });
            }}
            disabled={number === 1}
            className={`w-6 h-6 rounded-lg ${number === 1 ? "gray-btn" : "blue-btn"}`}>
            -
          </button>
          <button
            onClick={() => {
              setNumber(number + 1);
              handleChange({
                target: {
                  name: "grid_props",
                  value: `grid-cols-${number + 1}`,
                },
              });
            }}
            disabled={number === 12}
            className={`w-6 h-6 rounded-lg ${number === 12 ? "gray-btn" : "blue-btn"}`}>
            +
          </button>
        </div>
        <div className="flex items-center gap-4">
          <label>Border {values.border}</label>
          <input
            className="w-5 h-5 border rounded-md cursor-pointer"
            type="checkbox"
            id="border"
            name="border"
            checked={values.border}
            onChange={(e) => {
              handleChange({
                target: {
                  name: "border",
                  value: e.target.checked,
                },
              });
            }}
          />
        </div>
      </div>
      <hr className="my-4" />

      <div className="flex flex-col gap-4">
        <label>Project bank</label>
        <MultiSelect
          id="select-projects"
          options={projects}
          values={values.images}
          onSelectedChange={(e) => {
            handleChange({ target: { name: "images", value: e } });
          }}
          placeholder="Select a project"
        />
      </div>
      <div className="flex flex-wrap gap-5 mt-8">
        <FileInput
          className="w-[22%] min-h-20 border rounded-lg border-gray-200 p-5 flex justify-center items-center"
          folder="quotes/images"
          name="logo_url"
          resetOnChange={true}
          onChange={(e) => {
            const newClients = values.images ? [...values.images] : [];
            newClients.push({ value: "", label: "Unknown", logo_url: e.target.value[0] });
            handleChange({ target: { name: "images", value: newClients } });
          }}
        />
        {values.images?.map((image, i) => {
          return (
            <div className="w-[22%] border rounded-lg border-gray-200 p-5 flex justify-center items-center relative" key={i}>
              <img src={image.logo_url} alt="image-logo" />
              <button
                className="gray-btn h-5 w-5 p-0 absolute top-1 right-1"
                onClick={() => {
                  const newImages = values.images ? [...values.images] : [];
                  newImages.splice(i, 1);
                  handleChange({ target: { name: "images", value: newImages } });
                }}>
                <IoTrash />
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MultipleImagesBlock;
