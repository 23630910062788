import moment from "moment";
import React, { useEffect, useState } from "react";
import { HiCalendar } from "react-icons/hi2";
import { useNavigate, useLocation } from "react-router-dom";

import Loader from "../../../components/loader";
import api from "../../../services/api";
import { useSelector } from "react-redux";

const DaysOff = () => {
  const [hollidayActivities, setHollidayActivities] = useState(null);
  const [peopleCurrentlyOff, setPeopleCurrentlyOff] = useState(null);

  let d = new Date();
  d.setHours(0, 0, 0, 0);

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const navigate = useNavigate();

  useEffect(() => {
    getDaysOff();
  }, []);

  useEffect(() => {
    if (!hollidayActivities) return;
    setPeopleCurrentlyOff((hollidayActivities || []).filter((e) => differenceDays(new Date(), e.ranges[0].start) === 0));
  }, [hollidayActivities]);

  async function getDaysOff() {
    const dateFrom = new Date(Date.UTC(d.getUTCFullYear(), d.getUTCMonth(), 1));
    const dateTo = new Date(dateFrom);
    dateTo.setMonth(dateTo.getMonth() + 3);
    dateTo.setDate(0);

    const { data } = await api.get(`/activity?projectName=${encodeURIComponent("Leave paid")},Unavailable,RTT&dateFrom=${dateFrom.getTime()}&dateTo=${dateTo.getTime()}`);

    const hollidays = [];

    for (let i = 0; i < data.length; i++) {
      const slots = [];
      for (let j = 0; j < data[i].detail.length; j++) {
        if (data[i].detail[j].value === 0) continue;
        const slotDate = new Date(data[i].date);
        slotDate.setDate(j + 1);
        if (slotDate < d) continue; // skip past days
        slots.push(slotDate);
      }
      if (slots.length == 0) continue;

      const findIndex = hollidays.findIndex((e) => e.userName === data[i].userName);
      if (findIndex > -1) {
        hollidays[findIndex].slots = hollidays[findIndex].slots.concat(slots);
      } else {
        hollidays.push({ userName: data[i].userName, slots, userAvatar: data[i].userAvatar, userId: data[i].userId });
      }
    }

    for (let i = 0; i < hollidays.length; i++) {
      hollidays[i].ranges = createRangesFromDays(hollidays[i].slots.sort((a, b) => a - b));
    }

    setHollidayActivities(hollidays);
  }

  const differenceDays = (date1, date2) => {
    let difference = date2.getTime() - date1.getTime();
    let totalDays = Math.ceil(difference / (1000 * 3600 * 24));
    return totalDays;
  };

  if (!peopleCurrentlyOff) return <Loader />;

  return (
    <div className="grid grid-cols-1 gap-8">
      <div className="bg-white border-[1px] border-gray-200 rounded-md overflow-hidden">
        <div className="p-3 flex items-center justify-between">
          <div className="md:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">{peopleCurrentlyOff?.length} people off</h1>
            <p className="mt-2 text-sm text-gray-700">A list of all the users currently not working</p>
          </div>
          <a
            href="/days-off"
            target="_blank"
            rel="noreferrer"
            className="hover:no-underline border-[1px] border-blue-600 text-blue-600 hover:bg-blue-600 hover:text-[#ffffff] py-0.5 px-2.5 rounded-full text-base font-normal flex items-center gap-1">
            <HiCalendar /> Calendar view
          </a>
        </div>
        <div className="mt-2 flow-root">
          <div className="overflow-x-auto">
            <div className="bg-white inline-block min-w-full align-middle">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th scope="col" className="py-2 px-3 text-left text-sm font-semibold text-gray-900 md:pl-3 pl-0">
                      Name
                    </th>
                    <th scope="col" className="py-2 px-3 text-left text-sm font-semibold text-gray-900">
                      Dates
                    </th>
                    {/* <th scope="col" className="py-2 px-3 text-left text-sm font-semibold text-gray-900">
                      Duration
                    </th> */}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {peopleCurrentlyOff.map((item) => (
                    <tr key={item.userName} className="even:bg-gray-100">
                      <td
                        className="whitespace-nowrap py-1 px-3 text-sm font-semibold text-gray-900 md:pl-3 pl-0 cursor-pointer group"
                        onClick={() => {
                          query.set("user_modal_id", item.userId);
                          navigate({ search: query.toString() });
                        }}>
                        <div className="flex items-center gap-1">
                          <img src={item.userAvatar} alt="userlogo" className="rounded-full w-7 h-7" />
                          <div>
                            <div className="group-hover:underline">{item.userName}</div>
                          </div>
                        </div>
                      </td>
                      <td className="whitespace-nowrap py-1 px-3 text-sm font-normal text-gray-900 md:pl-3 pl-0">
                        <div className="flex flex-1 flex-col gap-1">
                          {item.ranges.map((range) => {
                            if (range.start === range.end)
                              return (
                                <div className="" key={range.start.getTime()}>
                                  {moment(range.start).format("DD MMMM YYYY")}
                                </div>
                              );
                            return (
                              <div key={range.start.getTime()}>
                                <div className="flex items-center gap-1">
                                  {moment(range.start).format("DD MMM YYYY")} ... {moment(range.end).format("DD MMM YYYY")} ({differenceDays(range.start, range.end) + 1} days)
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white border-[1px] border-gray-200 rounded-md overflow-hidden">
        <div className="p-3 md:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">Incoming days off</h1>
          <p className="mt-2 text-sm text-gray-700">A list of all the users that will be not working in the near future</p>
        </div>
        <div className="mt-2 flow-root">
          <div className="overflow-x-auto">
            <div className="bg-white inline-block min-w-full align-middle">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th scope="col" className="py-2 px-3 text-left text-sm font-semibold text-gray-900 md:pl-3 pl-0">
                      Name
                    </th>
                    <th scope="col" className="py-2 px-3 text-left text-sm font-semibold text-gray-900">
                      Dates
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {hollidayActivities
                    .sort((a, b) => {
                      const nextHollidayA = differenceDays(new Date(), a.ranges[0].start);
                      const nextHollidayB = differenceDays(new Date(), b.ranges[0].start);
                      return nextHollidayA - nextHollidayB;
                    })
                    .filter((e) => differenceDays(new Date(), e.ranges[0].start) > 0)
                    .map((item) => (
                      <tr key={item.userName} className="even:bg-gray-100">
                        <td
                          className="whitespace-nowrap py-1 px-3 text-sm font-semibold text-gray-900 md:pl-3 pl-0 cursor-pointer group"
                          onClick={() => {
                            query.set("user_modal_id", item.userId);
                            navigate({ search: query.toString() });
                          }}>
                          <div className="flex items-center gap-1">
                            <img src={item.userAvatar} alt="userlogo" className="rounded-full w-7 h-7" />
                            <div>
                              <div className="group-hover:underline">{item.userName}</div>
                            </div>
                          </div>
                        </td>
                        <td className="whitespace-nowrap py-1 px-3 text-sm font-normal text-gray-900 md:pl-3 pl-0">
                          <div className="flex flex-1 flex-col gap-1">
                            {item.ranges.map((range) => {
                              if (range.start === range.end)
                                return (
                                  <div className="" key={range.start.getTime()}>
                                    {moment(range.start).format("DD MMMM YYYY")}
                                  </div>
                                );
                              return (
                                <div key={range.start.getTime()}>
                                  <div className="flex items-center gap-1">
                                    {moment(range.start).format("DD MMM YYYY")} ... {moment(range.end).format("DD MMM YYYY")} ({differenceDays(range.start, range.end) + 1} days)
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function createRangesFromDays(days) {
  const ranges = [];
  let start = days[0];

  const ONEDAY = 1 * 24 * 60 * 60 * 1000;
  for (let i = 1; i < days.length; i++) {
    const _from = days[i - 1];
    const _to = days[i];

    // if the difference between the two days is more than one day -> we have a range
    // if the 2 days are monday and friday and the difference is less than 4 days (monday and friday around the weekend) -> we stay in the same range
    if (_to.getTime() - _from.getTime() > ONEDAY && !(_to.getDay() === 1 && _from.getDay() === 5 && _to.getTime() - _from.getTime() < ONEDAY * 4)) {
      ranges.push({ start, end: _from });
      start = _to;
    }
  }
  ranges.push({ start, end: days[days.length - 1] });
  return ranges;
}

export default DaysOff;
