import React, { useEffect, useState } from "react";

import Campaigns from "./campaigns";
import Contacts from "./contacts";
import Companies from "./companies";
import Sources from "./sources";
// import Upload from "./upload";
// import Mapping from "./mapping";

const TABS = {
  CONTACTS: "Contacts",
  COMPANIES: "Companies",
  CAMPAIGN: "Campaign",
  SOURCE: "Source",
};

export default () => {
  const [tab, setTab] = useState(TABS.CONTACTS);

  return (
    <div>
      <div className="m-2">
        <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
          <ul className="flex flex-wrap -mb-px list-none space-x-2">
            <li
              onClick={(e) => setTab(TABS.CAMPAIGN)}
              className={`inline-block p-2 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 cursor-pointer dark:hover:text-gray-300 ${
                tab === TABS.CAMPAIGN && "border-primary"
              }`}>
              Campaigns
            </li>
            <li
              onClick={(e) => setTab(TABS.CONTACTS)}
              className={`inline-block p-2 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 cursor-pointer dark:hover:text-gray-300 ${
                tab === TABS.CONTACTS && "border-primary"
              }`}>
              Contacts
            </li>
            <li
              onClick={(e) => setTab(TABS.COMPANIES)}
              className={`inline-block p-2 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 cursor-pointer dark:hover:text-gray-300 ${
                tab === TABS.COMPANIES && "border-primary"
              }`}>
              Companies
            </li>
            <li
              onClick={(e) => setTab(TABS.SOURCE)}
              className={`inline-block p-2 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 cursor-pointer dark:hover:text-gray-300 ${
                tab === TABS.SOURCE && "border-primary"
              }`}>
              Sources
            </li>
          </ul>
        </div>
        {tab === TABS.CAMPAIGN && <Campaigns />}
        {tab === TABS.CONTACTS && <Contacts />}
        {tab === TABS.COMPANIES && <Companies />}
        {tab === TABS.SOURCE && <Sources />}
      </div>
    </div>
  );
};
