import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Loader from "../../../components/loader";
import api from "../../../services/api";

import toast from "react-hot-toast";

import MultipleSelectInput from "../../../components/MultipleSelectInput";
import SelectOrganisation from "../../../components/selectOrganisation";
import FileInput from "../../../components/FileInput";
import SelectUser from "../../../components/selectUser";

export default function EditProject() {
  const [project, setProject] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    const { data: u } = await api.get(`/project/${id}`);
    setProject(u);
  }

  const navigate = useNavigate();

  if (!project) return <Loader />;

  async function onSave() {
    try {
      await api.put(`/project/${project._id}`, project);
      toast.success(`${project.name} updated`);
    } catch (e) {
      console.error(e);
      toast.error("Some Error!");
    }
  }

  console.log(project);

  return (
    <div>
      <div className="p-4">
        <div className="bg-[#FFFFFF] pb-4 border border-[#E5EAEF] rounded-[16px]">
          <React.Fragment>
            <FileInput value={project.logo} name="logo" folder="/project" onChange={(e) => setProject({ ...project, logo: e.target.value[0] })} />
            <FileInput value={project.background} name="background" folder="/project" onChange={(e) => setProject({ ...project, background: e.target.value[0] })} />
            <div className="py-3 px-4">
              <div className="flex gap-4 flex-wrap">
                <div className="w-full md:w-[260px] mt-2">
                  <div className="text-[14px] text-[#212325] font-medium">Name of project</div>
                  <input
                    className="projectsInput text-[14px] font-normal text-[#212325] rounded-[10px]"
                    name="name"
                    value={project.name}
                    onChange={(e) => setProject({ ...project, name: e.target.value })}
                  />
                </div>
                <div className="w-full md:w-[260px] mt-2">
                  <div className="text-[14px] text-[#212325] font-medium">Lead by</div>
                  <SelectUser
                    value={{ _id: project.lead_id, name: project.lead_name, avatar: project.lead_avatar }}
                    userName={project.lead_name}
                    name="lead"
                    onChange={async (e) => {
                      try {
                        const { data } = await api.put(`/project/${project._id}`, { lead_id: e._id, lead_name: e.name, lead_avatar: e.avatar, lead_email: e.email });
                        setProject(data);
                        toast.success("Lead updated!");
                      } catch (e) {
                        console.error(e);
                        toast.error("Some Error!");
                      }
                    }}
                  />
                </div>
                <div className="w-full md:w-[260px] mt-2">
                  <div className="text-[14px] text-[#212325] font-medium">Responsible</div>
                  <SelectUser
                    value={{ _id: project.responsible_id, name: project.responsible_name, avatar: project.responsible_avatar }}
                    userName={project.responsible_name}
                    name="responsible"
                    onChange={async (e) => {
                      try {
                        const { data } = await api.put(`/project/${project._id}`, { responsible_id: e._id, responsible_name: e.name, responsible_avatar: e.avatar });
                        setProject(data);
                        toast.success("Lead updated!");
                      } catch (e) {
                        console.error(e);
                        toast.error("Some Error!");
                      }
                    }}
                  />
                </div>

                <div className="w-full md:w-[260px] mt-2">
                  <div className="text-[14px] text-[#212325] font-medium">Operating parner</div>
                  <SelectUser
                    value={{ _id: project.operating_partner_id, name: project.operating_partner_name, avatar: project.operating_partner_avatar }}
                    userName={project.operating_partner_name}
                    name="op"
                    onChange={async (e) => {
                      const { data } = await api.put(`/project/${project._id}`, {
                        operating_partner_id: e._id,
                        operating_partner_name: e.name,
                        operating_partner_avatar: e.avatar,
                        operating_partner_email: e.email,
                      });
                      setProject(data);
                      toast.success("Lead OPd updated!");
                    }}
                  />
                </div>
                <div className="w-full md:w-[260px] mt-2">
                  <SelectOrganisation
                    value={project.organisation_id}
                    onChange={async (e) => {
                      const { data } = await api.put(`/project/${project._id}`, { organisation_id: e._id, organisation_name: e.name });
                      setProject(data);
                      toast.success("Orga updated");
                    }}
                  />
                </div>
                <div className="w-full md:w-[260px] mt-2">
                  <div className="text-[14px] text-[#212325] font-medium	">VAT number</div>
                  <input
                    className="projectsInput text-[14px] font-normal text-[#212325] rounded-[10px]"
                    name="tax"
                    value={project.tax}
                    onChange={(e) => setProject({ ...project, tax: e.target.value })}
                  />
                </div>
                <div className="w-full md:w-[260px] mt-2">
                  <div className="text-[14px] text-[#212325] font-medium	">SIREN</div>
                  <input
                    className="projectsInput text-[14px] font-normal text-[#212325] rounded-[10px]"
                    name="siren"
                    value={project.siren}
                    onChange={(e) => setProject({ ...project, siren: e.target.value })}
                  />
                </div>
                <div className="w-full md:w-[260px] mt-2">
                  <div className="text-[14px] text-[#212325] font-medium	">Status</div>
                  <select
                    className="projectsInput text-[14px] font-normal text-[#212325] rounded-[10px]"
                    name="status"
                    value={project.status}
                    onChange={(e) => setProject({ ...project, status: e.target.value })}>
                    <option value=""></option>
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </select>
                </div>
                <div className="mt-3">
                  <div className="text-[14px] text-[#212325] font-medium">Tax Type</div>
                  <select
                    className="projectsInput text-[14px] font-normal text-[#212325] rounded-[10px]"
                    name="taxType"
                    value={project.taxType}
                    onChange={(e) => setProject({ ...project, taxType: e.target.value })}>
                    <option value=""></option>
                    <option value="FR 20%">FR 20%</option>
                    <option value="NL 21%">NL 21%</option>
                    <option value="N/A (Not applicable)">N/A (Not applicable)</option>
                  </select>
                </div>
              </div>
              <div className="mt-4">
                <div className="text-[14px] text-[#212325] font-medium	">Official client name we're billing</div>
                <input
                  className="projectsInput text-[14px] font-normal text-[#212325] rounded-[10px]"
                  name="address"
                  value={project.clientName}
                  onChange={(e) => setProject({ ...project, clientName: e.target.value })}
                />
              </div>
              <div className="mt-4">
                <div className="text-[14px] text-[#212325] font-medium	">Address</div>
                <input
                  className="projectsInput text-[14px] font-normal text-[#212325] rounded-[10px]"
                  name="address"
                  value={project.address}
                  onChange={(e) => setProject({ ...project, address: e.target.value })}
                />
                <div className="w-full md:w-[260px] mt-2" />
              </div>
              <div className="flex gap-4 mt-3">
                <div className="w-full md:w-[260px] ">
                  <div className="text-[14px] text-[#212325] font-medium	">Type</div>
                  <select
                    className="projectsInput text-[14px] font-normal text-[#212325] rounded-[10px]"
                    name="type"
                    value={project.type}
                    onChange={(e) => setProject({ ...project, type: e.target.value })}>
                    <option value=""></option>
                    <option value=""></option>
                    {["prospection", "startup-project", "startup-invest", "admin", "timeoff"].map((e) => {
                      return (
                        <option key={e} value={e}>
                          {e}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className="flex items-center space-x-4">
                  <div className="text-[14px] text-[#212325] font-medium">Market type (for tax) : </div>

                  <label className="flex items-center space-x-2 cursor-pointer">
                    <span className="text-sm font-normal text-gray-500">WBSO</span>
                    <input
                      type="checkbox"
                      name="wbso"
                      checked={project.wbso_eligible}
                      onChange={(e) => {
                        setProject({ ...project, wbso_eligible: e.target.checked });
                      }}
                    />
                  </label>
                  <label className="flex items-center space-x-2 cursor-pointer">
                    <span className="text-sm font-normal text-gray-500">CIR</span>
                    <input
                      type="checkbox"
                      name="cir"
                      checked={project.cir_eligible}
                      onChange={(e) => {
                        setProject({ ...project, cir_eligible: e.target.checked });
                      }}
                    />
                  </label>
                </div>
              </div>

              <div className="w-full mt-3">
                <MultipleSelectInput
                  value={project.clientsMails}
                  label="Mails Recipients"
                  placeholder="elon.mush@tesla.com"
                  onChange={(e) => setProject({ ...project, clientsMails: e })}
                />
              </div>

              <div className="w-full mt-3">
                <div className="text-[14px] text-[#212325] font-medium">Description</div>
                <textarea
                  className="w-full border border-[#ced4da] rounded-[10px] text-[14px] font-normal p-2 mt-2  focus:outline-none focus:ring focus:ring-[#80bdff]"
                  type="textarea"
                  rows="5"
                  placeholder="Please your comment...."
                  name="description"
                  value={project.description}
                  onChange={(e) => setProject({ ...project, description: e.target.value })}></textarea>
              </div>

              <div className="w-full mt-3">
                <div className="text-[14px] text-[#212325] font-medium">Objective</div>
                <textarea
                  className="w-full border border-[#ced4da] rounded-[10px] text-[14px] font-normal p-2 mt-2  focus:outline-none focus:ring focus:ring-[#80bdff]"
                  type="textarea"
                  rows="5"
                  placeholder="Please your comment...."
                  name="objective"
                  value={project.objective}
                  onChange={(e) => setProject({ ...project, objective: e.target.value })}></textarea>
              </div>
              <div className="w-full mt-3">
                <div className="text-[14px] text-[#212325] font-medium	">Iframe for metabase</div>
                <input
                  className="projectsInput text-[14px] font-normal text-[#212325] rounded-[10px]"
                  name="kpis_iframe"
                  value={project.kpis_iframe}
                  onChange={(e) => setProject({ ...project, kpis_iframe: e.target.value })}
                />
              </div>
              {/* <Kpis project={project} onChange={handleChange} /> */}
              <div className="text-xl mt-8">Links</div>
              <div className="w-full mt-3">
                <div className="text-[14px] text-[#212325] font-medium	">Website</div>
                <input
                  className="projectsInput text-[14px] font-normal text-[#212325] rounded-[10px]"
                  name="website"
                  value={project.website}
                  onChange={(e) => setProject({ ...project, website: e.target.value })}
                />
              </div>
              <div className="w-full mt-3">
                <div className="text-[14px] text-[#212325] font-medium	">Metabase</div>
                <input
                  className="projectsInput text-[14px] font-normal text-[#212325] rounded-[10px]"
                  name="metabase"
                  value={project.metabase}
                  onChange={(e) => setProject({ ...project, metabase: e.target.value })}
                />
              </div>
              <div className="w-full mt-3">
                <div className="text-[14px] text-[#212325] font-medium	">Roadmap</div>
                <input
                  className="projectsInput text-[14px] font-normal text-[#212325] rounded-[10px]"
                  name="roadmap"
                  value={project.roadmap}
                  onChange={(e) => setProject({ ...project, roadmap: e.target.value })}
                />
              </div>
              <div className="w-full mt-3">
                <div className="text-[14px] text-[#212325] font-medium	">Github</div>
                <input
                  className="projectsInput text-[14px] font-normal text-[#212325] rounded-[10px]"
                  name="github"
                  value={project.github}
                  onChange={(e) => setProject({ ...project, github: e.target.value })}
                />
              </div>
              <div className="w-full mt-3">
                <div className="text-[14px] text-[#212325] font-medium	">Autres</div>
                {/* {(project.links || []).map((link) => {
                  return (
                    <div className="flex flex-1 flex-row mt-2 items-center gap-1">
                      <div className="flex gap-1 flex-1 items-center">
                        <input
                          className="projectsInput mt-0 text-[14px] font-normal text-[#212325] rounded-[10px]"
                          value={link.label}
                          onChange={(e) => {
                            const links = project.links.reduce((prev, current) => {
                              const tempLink = current;
                              if (current.url === link.url) {
                                tempLink.label = e.target.value;
                              }
                              return [...prev, tempLink];
                            }, []);
                            handleChange({ target: { value: links, name: "links" } });
                          }}
                        />
                        <input
                          className="projectsInput mt-0 text-[14px] font-normal text-[#212325] rounded-[10px]"
                          value={link.url}
                          onChange={(e) => {
                            const links = project.links.reduce((prev, current) => {
                              const tempLink = current;
                              if (current.label === link.label) {
                                tempLink.url = e.target.value;
                              }
                              return [...prev, tempLink];
                            }, []);
                            handleChange({ target: { value: links, name: "links" } });
                          }}
                        />
                      </div>
                      <div className={`flex justify-center cursor-pointer text-xl hover:text-red-500`}>
                        <MdDeleteForever
                          onClick={() => {
                            const newLinks = project.links.filter((l) => l.url !== link.url);
                            handleChange({ target: { value: newLinks, name: "links" } });
                          }}
                        />
                      </div>
                    </div>
                  );
                })} */}
                {/* <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    const newLink = {
                      label: bufferOtherLinkLabel || bufferOtherLink,
                      url: bufferOtherLink,
                    };
                    handleChange({ target: { value: [...project.links, newLink], name: "links" } });
                    setBufferOtherLink("");
                    setBufferOtherLinkLabel("");
                  }}>
                  <input
                    className="projectsInput text-[14px] font-normal text-[#212325] rounded-[10px]"
                    name="other-links-label"
                    value={bufferOtherLinkLabel}
                    onChange={(e) => {
                      setBufferOtherLinkLabel(e.target.value);
                    }}
                    placeholder="My super website"
                  />
                  <input
                    className="projectsInput text-[14px] font-normal text-[#212325] rounded-[10px]"
                    required
                    name="other-links"
                    value={bufferOtherLink}
                    onChange={(e) => {
                      setBufferOtherLink(e.target.value);
                    }}
                    placeholder="https://mysuperwebsite.com"
                  />
                  {bufferOtherLink ? (
                    <button className="px-4 py-2 rounded-xl bg-[#0560FD] text-white mt-2" type="submit">
                      ajouter
                    </button>
                  ) : null}
                </form> */}
              </div>
              <div className="w-full mt-3">
                <div className="text-[14px] text-[#212325] font-medium">Note</div>
                <textarea
                  className="w-full border border-[#ced4da] rounded-[10px] text-sm p-2 mt-2 focus:outline-none focus:ring focus:ring-[#80bdff]"
                  type="textarea"
                  rows="7"
                  placeholder="Please your comment...."
                  name="note"
                  value={project.note}
                  onChange={(e) => setProject({ ...project, note: e.target.value })}></textarea>
              </div>
            </div>
            <div className="flex ml-3 mt-2">
              <button className="ml-[10px] bg-[#0560FD] text-[16px] font-medium text-[#fff] py-[12px] px-[22px] rounded-[10px]" onClick={onSave}>
                Update
              </button>
            </div>
          </React.Fragment>
        </div>
      </div>
    </div>
  );
}
