import React, { useState } from "react";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";

import Modal from "../../../../components/modal";
import RichTextEditorSun from "../../../../components/RichTextEditor";

const COLUMNS_RANGE = ["Description", "Min Days", "Max Days", "TJM", "Min Total", "Max Total"];
const COLUMNS = ["Description", "Days", "TJM", "Total"];

const Table = ({ values, editing, onChange, onSave }) => {
  if (editing) return <TableForm values={values} onChange={onChange} onSave={onSave} />;

  const totalHT = (total_type) => values.arr.reduce((acc, row) => acc + parseFloat(row[total_type]), 0);
  const totalTTC = (total_type) => {
    const tax = parseFloat(values.tax) || 0;
    return values.arr.reduce((acc, row) => {
      const total = parseFloat(row[total_type]);
      return acc + total * (1 + tax / 100);
    }, 0);
  };

  const SELECTED_COLUMNS = values.range ? COLUMNS_RANGE : COLUMNS;

  return (
    <div className="space-y-4">
      <table className="w-full">
        <thead columns={SELECTED_COLUMNS.length} className="bg-[#0a405e] text-white font-poppins font-medium rounded-t-md">
          <tr>
            {SELECTED_COLUMNS.map((column, index) => (
              <th
                key={index}
                scope="col"
                colSpan={index === 0 ? 3 : 1}
                className={`px-3 py-2 ${index === 0 ? "text-left" : "text-center"} font-medium tracking-wider text-white text-sm`}>
                {column}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="border border-gray-100 rounded-bl-md">
          {(values.arr || []).map((row, index) => {
            return <TableRow key={index} row={row} rowIndex={index} updateCell={() => {}} range={values.range} />;
          })}
        </tbody>
        <tfoot>
          <tr>
            <td className="pt-5" />
          </tr>
          <tr>
            {values.range ? <td colSpan="4"></td> : <td colSpan="3"></td>}
            <td colSpan="2" className="px-3 py-2  bg-gray-50 font-poppins text-sm">
              Total HT:
            </td>
            {values.range ? (
              <>
                <td colSpan="1" className="bg-gray-50 px-3 py-2 text-left font-poppins  text-sm">
                  {(totalHT("min_total") || 0).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}
                </td>
                <td colSpan="1" className="bg-gray-50 px-3 py-2 text-left font-poppins  text-sm">
                  {(totalHT("max_total") || 0).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}
                </td>
              </>
            ) : (
              <td colSpan="1" className="bg-gray-50 px-3 py-2 text-left font-poppins  text-sm">
                {(totalHT("total") || 0).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}
              </td>
            )}
          </tr>

          <tr>
            {values.range ? <td colSpan="4"></td> : <td colSpan="3"></td>}
            <td colSpan="2" className="bg-gray-50 px-3 py-2  border-gray-200 font-poppins text-sm">
              TVA:
            </td>

            <td colSpan="2" className="bg-gray-50 px-3 py-2 text-left font-poppins  text-sm">
              {values["tax"] || 0}%
            </td>
          </tr>

          <tr>
            {values.range ? <td colSpan="4"></td> : <td colSpan="3"></td>}
            <td colSpan="2" className="bg-gray-50 px-3 py-2  border-t border-gray-200  font-poppins text-sm">
              Total TTC:
            </td>
            {values.range ? (
              <>
                <td colSpan="1" className="border-t border-gray-200 bg-gray-50 px-3 py-2 text-left font-poppins rounded-b-md  text-sm">
                  {(totalTTC("min_total") || 0).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}
                </td>
                <td colSpan="1" className="border-t border-gray-200 bg-gray-50 px-3 py-2 text-left font-poppins  text-sm">
                  {(totalTTC("max_total") || 0).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}
                </td>
              </>
            ) : (
              <td colSpan="1" className="border-t border-gray-200 bg-gray-50 px-3 py-2 text-left font-poppins  text-sm">
                {(totalTTC("total") || 0).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}
              </td>
            )}
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

const TableRow = ({ row, rowIndex, range }) => {
  return (
    <tr className="w-full text-sm text-left border-t border-gray-200 font-poppins" key={rowIndex}>
      <td colSpan="3" className="px-3 py-2 text-left  font-poppins">
        <div dangerouslySetInnerHTML={{ __html: row["description"] }} />
      </td>
      {range ? (
        <>
          <td colSpan="1" className="px-3 py-2 text-center font-poppins">
            {row["min_quantity"]}
          </td>
          <td colSpan="1" className="px-3 py-2 text-center font-poppins">
            {row["max_quantity"]}
          </td>
        </>
      ) : (
        <td colSpan="1" className="px-3 py-2 text-center font-poppins">
          {row["quantity"]}
        </td>
      )}

      <td colSpan="1" className="px-3 py-2 text-center font-poppins">
        {row["price"]}€
      </td>
      {range ? (
        <>
          <td colSpan="1" className="px-3 py-2 text-center font-poppins">
            {row["min_total"]}€
          </td>
          <td colSpan="1" className="px-3 py-2 text-center font-poppins">
            {row["max_total"]}€
          </td>
        </>
      ) : (
        <td colSpan="1" className="px-3 py-2 text-center font-poppins">
          {row["total"]}€
        </td>
      )}
    </tr>
  );
};

const TableForm = ({ values, onChange, onSave }) => {
  const handleAddRow = () => {
    const newValues = [...values.arr] || [];
    newValues.push({ description: `Item ${values.arr.length}`, price: 300, min_quantity: 1, max_quantity: 5, min_total: 300, max_total: 1500, tax: 0 });
    onChange({ ...values, arr: newValues });
  };

  const handleDelete = (index) => {
    if (!confirm("Are you sure ?")) return;
    const newValues = [...values.arr] || [];
    newValues.splice(index, 1);
    onChange({ ...values, arr: newValues });
  };

  const handleChange = (index, column, value) => {
    const newValues = [...values.arr] || [];
    newValues[index][column] = value;

    if (column === "min_quantity" || column === "price") {
      newValues[index]["min_total"] = Number(newValues[index]["price"] * newValues[index]["min_quantity"]);
    }
    if (column === "max_quantity" || column === "price") {
      newValues[index]["max_total"] = Number(newValues[index]["price"] * newValues[index]["max_quantity"]);
    }

    if (column === "quantity" || column === "price") {
      newValues[index]["total"] = Number(newValues[index]["price"] * newValues[index]["quantity"]);
    }

    onChange({ ...values, arr: newValues });
  };

  const totalHT = (total_type) => values.arr.reduce((acc, row) => acc + parseFloat(row[total_type]), 0);
  const totalTTC = (total_type) => {
    const tax = parseFloat(values.tax) || 0;
    return values.arr.reduce((acc, row) => {
      const total = parseFloat(row[total_type]);
      return acc + total * (1 + tax / 100);
    }, 0);
  };

  const SELECTED_COLUMNS = values.range ? COLUMNS_RANGE : COLUMNS;

  return (
    <div className="space-y-4">
      <div className="flex flex-row items-center gap-2">
        <div className="text-sm font-semibold">Range (min/max)</div>
        <input type="checkbox" name="range" checked={values.range} onChange={(e) => onChange({ ...values, range: e.target.checked })} />
      </div>
      <table className="w-full table-auto">
        <thead className="bg-[#0a405e] text-white font-poppins font-medium rounded-t-md">
          <tr>
            {SELECTED_COLUMNS.map((column, index) => (
              <th key={index} scope="col" className="px-3 py-2 text-left text-xs font-medium tracking-wider text-white">
                {column}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="border border-gray-100 rounded-bl-md">
          {(values.arr || []).map((row, index) => {
            const range = values.range;
            return (
              <tr className="w-full text-sm text-left border-b border-gray-200 font-poppins" key={index}>
                <td colSpan="3" className="px-3 py-2 text-left border-r border-gray-200 font-poppins">
                  <div dangerouslySetInnerHTML={{ __html: row["description"] }} />
                </td>
                {range ? (
                  <>
                    <td colSpan="1" className="px-3 py-2 border-r border-gray-200 text-center font-poppins">
                      {row["min_quantity"]}
                    </td>
                    <td colSpan="1" className="px-3 py-2 border-r border-gray-200 text-center font-poppins">
                      {row["max_quantity"]}
                    </td>
                  </>
                ) : (
                  <td colSpan="1" className="px-3 py-2 border-r border-gray-200 text-center font-poppins">
                    {row["quantity"]}
                  </td>
                )}

                <td colSpan="1" className="px-3 py-2 border-r border-gray-200 text-center font-poppins">
                  {row["price"]}€
                </td>
                {range ? (
                  <>
                    <td colSpan="1" className="px-3 py-2 border-r border-gray-200 text-center font-poppins">
                      {row["min_total"]}€
                    </td>
                    <td colSpan="1" className="px-3 py-2 border-r border-gray-200 text-center font-poppins">
                      {row["max_total"]}€
                    </td>
                  </>
                ) : (
                  <td colSpan="1" className="px-3 py-2 border-r border-gray-200 text-center font-poppins">
                    {row["total"]}€
                  </td>
                )}

                <td>
                  <div className="flex px-2 space-x-2">
                    <AiOutlineDelete
                      className="cursor-pointer"
                      onClick={() => {
                        handleDelete(index);
                      }}
                    />
                    <TableRowForm row={{ ...row, rowIndex: index }} range={values.range} onChange={handleChange} onSave={onSave} />
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <td className="p-4 m-0">
              <button className="btn btn-primary m-0" onClick={handleAddRow}>
                Add Row
              </button>
            </td>
          </tr>

          <tr>
            {values.range ? <td colSpan="2"></td> : <td colSpan="1"></td>}
            <td colSpan="2" className="p-4 text-right bg-gray-50 font-semibold font-poppins">
              Total HT:
            </td>
            {values.range ? (
              <>
                <td colSpan="1" className="bg-gray-50 text-left font-poppins font-semibold text-sm">
                  {(totalHT("min_total") || 0).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}
                </td>
                <td colSpan="1" className="bg-gray-50 text-left font-poppins font-semibold text-sm">
                  {(totalHT("max_total") || 0).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}
                </td>
              </>
            ) : (
              <td colSpan="1" className="bg-gray-50 text-left font-poppins font-semibold text-sm">
                {(totalHT("total") || 0).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}
              </td>
            )}
          </tr>

          <tr>
            {values.range ? <td colSpan="2"></td> : <td colSpan="1"></td>}
            <td colSpan="2" className="p-4 text-right bg-gray-50 font-semibold font-poppins">
              TVA :
            </td>

            <td colSpan={values.range ? 2 : 1} className="bg-gray-50 !p-0">
              <div className="flex justify-between items-center px-3">
                <input
                  className="border-none w-[45px] m-0"
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  value={values.tax}
                  onChange={(e) => onChange({ ...values, tax: e.target.value })}
                />
                <div>%</div>
              </div>
            </td>
          </tr>

          <tr>
            {values.range ? <td colSpan="2"></td> : <td colSpan="1"></td>}
            <td colSpan="2" className="p-4 text-right bg-gray-50 border-t border-gray-200 font-semibold font-poppins">
              Total TTC:
            </td>
            {values.range ? (
              <>
                <td colSpan="1" className="bg-gray-50 border-t border-gray-200 p-4 text-left font-poppins rounded-b-md font-semibold text-sm">
                  {(totalTTC("min_total") || 0).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}
                </td>
                <td colSpan="1" className="bg-gray-50 border-t border-gray-200 p-4 text-left font-poppins font-semibold text-sm">
                  {(totalTTC("max_total") || 0).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}
                </td>
              </>
            ) : (
              <td colSpan="1" className="bg-gray-50 border-t border-gray-200 p-4 text-left font-poppins font-semibold text-sm">
                {(totalTTC("total") || 0).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}
              </td>
            )}
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

const TableRowForm = ({ row, onChange, range, onSave }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <AiOutlineEdit className="cursor-pointer" onClick={() => setIsOpen(true)} />
      <Modal isOpen={isOpen} className="w-78" onClose={() => setIsOpen(false)}>
        <div className="space-y-2 p-4">
          {range ? (
            <>
              <div className="space-y-2">
                <label className="text-sm font-semibold" htmlFor="name">
                  Min quantity
                </label>
                <input
                  className="input"
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  value={row["min_quantity"]}
                  onChange={(e) => onChange(row.rowIndex, "min_quantity", e.target.value)}
                />
              </div>
              <div className="space-y-2">
                <label className="text-sm font-semibold" htmlFor="name">
                  Max quantity
                </label>
                <input
                  className="input"
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  value={row["max_quantity"]}
                  onChange={(e) => onChange(row.rowIndex, "max_quantity", e.target.value)}
                />
              </div>
            </>
          ) : (
            <div className="space-y-2">
              <label className="text-sm font-semibold" htmlFor="name">
                Quantity
              </label>
              <input
                className="input"
                type="number"
                onWheel={(e) => e.target.blur()}
                value={row["quantity"]}
                onChange={(e) => onChange(row.rowIndex, "quantity", e.target.value)}
              />
            </div>
          )}

          <div className="space-y-2">
            <label className="text-sm font-semibold" htmlFor="name">
              Price (€)
            </label>
            <input className="input" type="number" onWheel={(e) => e.target.blur()} value={row["price"]} onChange={(e) => onChange(row.rowIndex, "price", e.target.value)} />
          </div>

          <div className="space-y-2">
            <label className="text-sm font-semibold" htmlFor="name">
              Description
            </label>
            <RichTextEditorSun
              buttonList={[
                ["undo", "redo"],
                ["font", "fontSize", "formatBlock"],
                ["paragraphStyle", "blockquote"],
                ["bold", "underline", "italic", "strike", "subscript", "superscript"],
                ["fontColor", "hiliteColor"],
                ["align", "list", "lineHeight"],
                ["outdent", "indent"],
                ["table", "horizontalRule", "link", "image", "video"],
                ["removeFormat"],
              ]}
              values={row?.description || ""}
              onChange={(e) => onChange(row.rowIndex, "description", e)}
            />
          </div>
          <div className="w-full flex justify-end gap-4">
            <button className="empty-btn px-4" onClick={() => setIsOpen(false)}>
              Save and Continue Editing
            </button>
            <button className="blue-btn px-4" onClick={onSave}>
              Save and Exit Editing
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Table;
