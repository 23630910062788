import toast from "react-hot-toast";
import React from "react";
import { TicketPeople } from "./people";
import api from "../../../../services/api";
import SelectUsers from "../../../../components/selectUser";

export const TicketInfo = ({ ticket, setTicket }) => {
  async function onEditEndAt(value) {
    try {
      const { data } = await api.put(`/ticket/${ticket._id}`, { end_at: value });
      toast.success(`Deadline changed`);
      setTicket(data);
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <div className="w-[350px] p-2">
      <div className="mb-2">
        <h3>Lead</h3>
        <SelectLeader ticket={ticket} setTicket={setTicket} />
      </div>
      <div className={"mb-2"}>
        <TicketPeople
          ticket={ticket}
          onAdd={(e) => {
            toast.success(`todo`);
            toast.success(`Added ${e.user_name} to this conversation`);
          }}
        />
      </div>
      <div className={"mb-2"}>
        <h3>Files</h3>
        <div>
          {(ticket.messages || []).map((e, index) => (
            <div key={index} className="flex items-center space-x-4 mb-2">
              <div>
                {e.files.map((file, index) => (
                  <li key={index}>
                    <a href={file.url} target="_blank" rel="noopener noreferrer" className={"text-xs"}>
                      {file.name}
                    </a>
                  </li>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className={"mb-2"}>
        <h3>Deadline</h3>
        <div>
          <div className="flex items-center space-x-4 ml-auto">
            <input
              type="date"
              defaultValue={ticket.end_at ? new Date(ticket.end_at).toISOString().split("T")[0] : null}
              onChange={(e) => {
                onEditEndAt(e.target.value);
              }}
              className="mt-1 block w-full py-2 px-3 border rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const SelectLeader = ({ ticket, setTicket }) => {
  async function handleChange(user) {
    try {
      const obj = {};
      obj.leader_id = user._id;
      obj.leader_name = user.name;
      obj.leader_email = user.email;
      obj.leader_avatar = user.avatar;
      const { data } = await api.put(`/ticket/${ticket._id}`, obj);
      toast.success(`Leader changed`);
      setTicket(data);
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <div>
      <SelectUsers value={{ _id: ticket.leader_id, name: ticket.leader_name, avatar: ticket.leader_avatar }} userName={ticket.leader_name} onChange={handleChange} />
    </div>
  );
};
