import React, { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { IoFolderOutline, IoInformationCircleOutline } from "react-icons/io5";
import toast from "react-hot-toast";
import { TbLayoutGridAdd } from "react-icons/tb";
import { MdComputer } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";

import About from "./about";
import Files from "./files";
import Device from "./device";
import Skills from "./skills";

import { setUser } from "../../redux/auth/actions";
import Loader from "../../components/loader";
import api from "../../services/api";
import FileInput from "../../components/FileInput";
import { classNames } from "../../utils";

export default () => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const navigate = useNavigate();
  const [tab, setTab] = useState(query.get("tab") || "about");
  const user = useSelector((state) => state.Auth.user);

  if (!user) return <Loader />;

  const dispatch = useDispatch();

  const render = () => {
    if (tab === "about") return <About user={user} />;
    if (tab === "files") return <Files user={user} />;
    if (tab === "device") return <Device user={user} />;
    if (tab === "skills") return <Skills user={user} />;
    return <About user={user} />;
  };

  useEffect(() => {
    if (!tab) return setTab(query.get("tab") || "about");
    query.set("tab", tab);
    navigate({ search: query.toString() });
  }, [tab]);

  return (
    <div className="w-full h-[90vh] bg-gray-100 p-2">
      <div className="bg-white rounded-md flex flex-col w-full border-[1px] border-gray-200 overflow-hidden">
        <div className="flex items-center gap-2 p-4">
          <FileInput
            value={user.avatar}
            onChange={async (e) => {
              const { data } = await api.put(`/user/${user._id}`, { avatar: e.target.value[0] });
              dispatch(setUser(data));
              toast.success("Updated!");
            }}
            folder={`/user`}
          />
          <div className="flex justify-between flex-1">
            <div className="flex items-center gap-2">
              <div>
                <div className="text-lg font-bold">{user.name}</div>
                <div className="text-sm">{user.job_title}</div>
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="grid grid-cols-2 gap-x-4">
              <span className="text-xs flex-1 text-right text-[#b7b3b4]">Updated at</span>
              <span className="text-xs flex-1 text-left text-[#b7b3b4]">{moment(user.updated_at).format("DD/MM/YYYY, HH:mm")}</span>
              <span className="text-xs flex-1 text-right text-[#b7b3b4]">Created at</span>
              <span className="text-xs flex-1 text-left text-[#b7b3b4]">{moment(user.created_at).format("DD/MM/YYYY, HH:mm")}</span>
            </div>
          </div>
        </div>
        <nav className="flex flex-row w-full border-t-[1px] border-gray-200 overflow-x-scroll">
          <TabItem title="About" tab="about" setTab={setTab} active={tab === "about"} Icon={IoInformationCircleOutline} />
          {["admin", "normal"].includes(user.role) ? (
            <TabItem title={`Files ${user.documents.length ? `(${user.documents.length})` : ""}`} tab="files" setTab={setTab} active={tab === "files"} Icon={IoFolderOutline} />
          ) : null}
          <TabItem title="Skills" tab="skills" setTab={setTab} active={tab === "skills"} Icon={TbLayoutGridAdd} />
          {["CDI"].includes(user.contract) ? <TabItem title="Device" tab="device" setTab={setTab} active={tab === "device"} Icon={MdComputer} /> : null}
        </nav>
      </div>
      <div className="my-2" />
      {render()}
    </div>
  );
};

const TabItem = ({ tab, title, setTab, active, Icon, Badge }) => (
  <button
    onClick={() => setTab(tab)}
    className={classNames(
      "group inline-flex items-center px-4 py-2 cursor-pointer text-sm text-gray-500 hover:border-gray-300 hover:text-gray-700 border-b-2 ",
      active ? "text-primary  border-primary" : "border-transparent",
    )}>
    {Icon ? <Icon className={classNames(active ? "text-primary" : "text-gray-400 group-hover:text-gray-500", "-ml-0.5 mr-2 h-4 w-4")} aria-hidden="true" /> : null}
    <div className="flex items-center gap-2">{title}</div>
    {Badge ? Badge : null}
  </button>
);
