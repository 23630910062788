import React, { useState, useEffect } from "react";
import RichTextEditorSun from "../../../../components/RichTextEditor";
import FileInput from "../../../../components/FileInput";
import { applyFontClassToHtml } from "../utils";
import { getBase64file } from "../utils";

const position_class = {
  top: "items-start",
  center: "items-center",
  bottom: "items-end",
};

const TwoColsPresentation = ({ values, editing, onChange }) => {
  const [imageB64UrlRight, setImageB64UrlRight] = useState("");
  const [imageB64UrlLeft, setImageB64UrlLeft] = useState("");

  useEffect(() => {
    values.layout.split("-")[0] === "text"
      ? setImageB64UrlLeft("")
      : getBase64file(values.image_left).then((url) => {
          setImageB64UrlLeft(url);
        });
    values.layout.split("-")[1] === "text"
      ? setImageB64UrlRight("")
      : getBase64file(values.image_right).then((url) => {
          setImageB64UrlRight(url);
        });
  }, [values.image_left, values.image_right]);

  if (editing) return <TwoColsPresentationForm values={values} onChange={onChange} />;

  function renderBlock(splitIndex) {
    let attributeName = values.layout.split("-")[splitIndex] + "_";
    attributeName += splitIndex === 0 ? "left" : "right";
    if (values.layout.split("-")[splitIndex] === "text") {
      return (
        <div className="col-span-2">
          <div className="text-sm font-poppins rich-text" dangerouslySetInnerHTML={{ __html: applyFontClassToHtml(values[attributeName] || "") }}></div>
        </div>
      );
    } else {
      if (attributeName === "image_right") {
        return <>{imageB64UrlRight && <img src={imageB64UrlRight} alt="image.png" className="w-full max-h-[500px] object-contain" />}</>;
      } else if (attributeName === "image_left") {
        return <>{imageB64UrlLeft && <img src={imageB64UrlLeft} alt="image.png" className="w-full max-h-[500px] object-contain" />}</>;
      }
      return <></>;
    }
  }

  return (
    <div className={`flex flex-row ${position_class[values.vertical_alignment] || position_class.top} justify-center w-full gap-4`}>
      <div
        style={{
          width: values.width_left ? `${values.width_left}%` : "50%",
        }}>
        {renderBlock(0)}
      </div>
      <div
        style={{
          width: values.width_right ? `${values.width_right}%` : "50%",
        }}>
        {renderBlock(1)}
      </div>
    </div>
  );
};

const TwoColsPresentationForm = ({ values, onChange }) => {
  const [localValues, setlocalValues] = useState(values);

  useEffect(() => {
    onChange(localValues);
  }, [localValues]);

  function handleChange(e) {
    const { name, value } = e.target;

    setlocalValues((prev) => {
      let newValues = { ...prev, [name]: value };
      if (name === "width_left") {
        const parsedValue = parseInt(value, 10);
        newValues.width_right = 100 - parsedValue;
      } else if (name === "width_right") {
        const parsedValue = parseInt(value, 10);
        newValues.width_left = 100 - parsedValue;
      }

      return newValues;
    });
  }

  function renderForm(splitIndex) {
    if (!values.layout) return null;
    let attributeName = values.layout.split("-")[splitIndex] + "_";
    attributeName += splitIndex === 0 ? "left" : "right";

    if (values.layout.split("-")[splitIndex] === "text") {
      return (
        <div className="col-span-2">
          <div className="text-[14px] text-[#212325] font-medium mb-1">Description</div>
          <RichTextEditorSun
            buttonList={[
              ["undo", "redo"],
              ["font", "fontSize", "formatBlock"],
              ["paragraphStyle", "blockquote"],
              ["bold", "underline", "italic", "strike", "subscript", "superscript"],
              ["fontColor", "hiliteColor"],
              ["align", "list", "lineHeight"],
              ["outdent", "indent"],
              ["table", "horizontalRule", "link", "image", "video"],
              ["removeFormat"],
            ]}
            values={localValues[attributeName]}
            onChange={(e) => {
              setlocalValues((prev) => ({ ...prev, [attributeName]: e }));
            }}
          />
        </div>
      );
    } else {
      return (
        <div className="col-span-2">
          <div className="text-[14px] text-[#212325] font-medium mb-5">Image</div>
          <FileInput folder="quotes" name={attributeName} value={values[attributeName]} onChange={handleChange} />
        </div>
      );
    }
  }

  return (
    <div className="flex flex-col gap-2 p-2 bg-white border border-gray-200 rounded-md shadow-md my-6">
      <div className="grid grid-cols-4 gap-2">
        <div className="col-span-2">
          <div className="text-[14px] text-[#212325] font-medium mb-1">Layout</div>
          <select className="input" onChange={handleChange} name="layout" value={values.layout}>
            <option value="text-text">Text - Text</option>
            <option value="image-text">Image - Text</option>
            <option value="text-image">Text - Image</option>
            <option value="image-image">Image - Image</option>
          </select>
        </div>

        <div className="col-span-2">
          <div className="text-[14px] text-[#212325] font-medium mb-1">Vertical Alignment</div>
          <select className="input" onChange={handleChange} name="vertical_alignment" value={values.vertical_alignment}>
            <option value="top">Top</option>
            <option value="center">Center</option>
            <option value="bottom">Bottom</option>
          </select>
        </div>

        <div className="col-span-2">
          <div className="text-[14px] text-[#212325] font-medium mb-1">Left Width (%)</div>
          <input className="input" type="number" name="width_left" value={localValues.width_left} onChange={handleChange} />
        </div>

        <div className="col-span-2">
          <div className="text-[14px] text-[#212325] font-medium mb-1">Right Width (%)</div>
          <input className="input" type="number" name="width_right" value={localValues.width_right} onChange={handleChange} />
        </div>

        {renderForm(0)}
        {renderForm(1)}
      </div>
    </div>
  );
};

export default TwoColsPresentation;
