import React, { useState } from "react";
import toast from "react-hot-toast";
import API from "../services/api";
import Modal from "./modal";
import SelectUsers from "./selectUser";
import TextEditor from "./TextEditor";

const INFORMATION = "INFORMATION";
const RAW_DATA = "RAW_DATA";

const ActionModal = ({ action, setAction, refetch = () => {} }) => {
  const [tab, setTab] = useState(INFORMATION);

  if (!action) return null

  return (
    <Modal isOpen={!!action} onClose={() => setAction(null)} className="h-[90vh] overflow-auto w-[800px] px-2 no-scrollbar">
      <div className="w-full gap-x-4 gap-y-2 mt-2">
        <nav className="flex items-center gap-2 pl-2">
          <TabItem tab={INFORMATION} title="Information" setTab={setTab} active={tab === INFORMATION} />
          <TabItem tab={RAW_DATA} title="Raw Data" setTab={setTab} active={tab === RAW_DATA} />
        </nav>
        <div>
          {tab === INFORMATION && <Information action={action} setAction={setAction} refetch={refetch} />}
          {tab === RAW_DATA && <RawData data={action} />}
        </div>
      </div>
    </Modal>
  );
};

const Information = ({ action, setAction, refetch }) => {
  
  async function handleSubmit(e) {
    e.preventDefault();
    try {
      const { ok, data } = await API.put(`/action/${action._id}`, action);
      if (!ok) return toast.error("Error updating action");
      toast.success("Action updated!");
      refetch();
      setAction(data);
    } catch (e) {
      console.log(e);
      toast.error("Error updating action!");
    }
  }

  async function onDelete(id) {
    if (confirm("Are you sure you want to delete this action?") === false) return;
    try {
      await API.remove(`/action/${id}`);
      toast.success("Action deleted!");
      refetch();
      setAction(null);
    } catch (e) {
      console.log(e);
      toast.error("Error deleting action!");
    }
  }

  function handleChange(e) {
    setAction({ ...action, [e.target.name]: e.target.value });
  }

  return (
    <form onSubmit={handleSubmit} className="flex flex-wrap gap-2 p-4 text-sm my-4 border shadow-sm">
      <div className="font-semibold w-full flex gap-2">
        Company :<div className="font-semibold">{action.company ? action.company : "undefined company"}</div>
      </div>
      <div className="font-medium w-full sm:w-[48%] flex-col">
        Action name : <UserInput label="" name="name" type="text" value={action.name} onChange={handleChange} />
      </div>
      <div className=" font-medium w-full md:w-[48%] flex gap-2 flex-col">
        Owner :<SelectUsers userName={action.userName} onChange={(v) => setAction({ ...action, userId: v?._id, userName: v.name, userAvatar: v.avatar })} />
      </div>
      <div className=" font-medium w-full md:w-[48%] flex gap-2 flex-col">
        Responsible :
        <SelectUsers userName={action.responsibleName} onChange={(v) => setAction({ ...action, responsibleId: v?._id, responsibleName: v.name, responsibleAvatar: v.avatar })} />
      </div>
      <div className=" font-medium w-full sm:w-[48%] flex-col">
        Contact Name : <UserInput label="" name="folkName" type="text" value={action.folkName} onChange={handleChange} />
      </div>

      <div className="w-full sm:w-[48%]">
        <UserSelect label="Category" name="category" value={action.category} onChange={handleChange}>
          {!action.category && <option value="">Select</option>}
          <option value="CALL_BACK">Call Back</option>
          <option value="CONTACT_SOMEONE">Contact Someone</option>
          <option value="SEND_PROPOSAL">Send Proposal</option>
          <option value="OTHER">Other</option>
        </UserSelect>
      </div>

      <div className="w-full sm:w-[48%]">
        <UserSelect label="Status" name="status" value={action.status} onChange={handleChange}>
          {!action.status && <option value="">Select</option>}
          <option value="TODO">To do</option>
          <option value="PENDING">Pending</option>
          <option value="DONE">Done</option>
        </UserSelect>
      </div>

      <div className="w-full sm:w-[48%]">
        <UserInput label="Deadline" name="deadline" type="date" value={action.deadline?.split("T")[0]} onChange={handleChange} />
      </div>

      <div className="mt-3  w-full">
        <div className="font-medium mb-2">Description</div>
        <TextEditor value={action?.description || ""} onChange={(e) => setAction({ ...action, description: e.target.value })} defaultPrompt={``} />
      </div>

      <button type="submit" className="mt-3 bg-[#0560FD] text-[16px] font-medium text-[#FFFFFF] py-[12px] px-[22px] rounded-[10px]">
        Update Action
      </button>
      <button
        className="mt-3 ml-3 bg-[#F43F5E] text-[16px] font-medium text-[#FFFFFF] py-[12px] px-[22px] rounded-[10px]"
        onClick={(e) => {
          e.preventDefault();
          onDelete(action._id);
        }}>
        Delete
      </button>
    </form>
  );
};

const RawData = ({ data }) => {
  return (
    <div className="p-8 text-sm bg-gray-100 mt-4 border shadow-sm">
      <pre className="break-all whitespace-pre-wrap"> {JSON.stringify(data, null, 2)}</pre>
    </div>
  );
};

// ui componentss!!

const UserInput = ({ name, value, type = "text", onChange, label, className, disabled }) => (
  <div className={`${className} w-full`}>
    <div className="px-1 text-sm font-medium mb-2">{label}</div>
    <input
      className="w-full rounded-md border-[1px] bg-[#ffffff] disabled:bg-gray-100 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-sm placeholder:text-gray-300 py-2 px-2"
      name={name}
      value={value}
      onChange={onChange}
      type={type}
      disabled={disabled}
    />
  </div>
);

const UserSelect = ({ name, value, onChange, label, children }) => (
  <div className="w-full flex flex-col gap-2">
    <div className="font-medium">{label}</div>
    <select
      className="w-full rounded-md border-[1px] border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-sm placeholder:text-gray-300 py-2 px-2"
      type="select"
      name={name}
      value={value}
      onChange={onChange}>
      {children}
    </select>
  </div>
);

const TabItem = ({ tab, title, setTab, active }) => (
  <button
    onClick={() => setTab(tab)}
    className={`group inline-flex items-center px-3 py-2 text-sm cursor-pointer transition-colors  ${active ? "blue-btn" : "transparent-btn hover:bg-gray-200"}`}>
    <div className="flex items-center gap-2">{title}</div>
  </button>
);

export default ActionModal;
