import React, { useState, useEffect } from "react";
import { DebounceInput } from "react-debounce-input";

import api from "../../services/api";

import Loader from "../../components/loader";
import SelectUser from "../../components/selectUser";
import Table from "../../components/Table";
import { Link } from "react-router-dom";
import { MdArrowOutward } from "react-icons/md";
import toast from "react-hot-toast";

export default () => {
  const [users, setUsers] = useState([]);
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    search: "",
    sort: "-created_at",
    status: "active",
    contracts: ["CDD", "CDI", "INTERNSHIP", "FREELANCE", "APPRENTICESHIP", "VIE"],
    per_page: 1000,
  });

  async function get() {
    try {
      setLoading(true);
      const { data } = await api.post(`/user/search`, filters);
      setUsers(data.users);
    } catch (e) {
      console.log("a", e);
    } finally {
      setLoading(false);
    }
  }

  const getProjects = async () => {
    setLoading(true);
    try {
      const query = { status: "active", types: ["startup-project", "startup-invest"], per_page: 1000 };
      const { ok, data } = await api.post("/project/search", query);
      if (!ok) throw new Error("Failed to fetch projects");
      setProjects(data.projects);
    } catch (error) {
      console.error(error);
      toast.error("Failed to fetch projects");
    }
    setLoading(false);
  };

  useEffect(() => {
    get();
  }, [filters]);

  useEffect(() => {
    getProjects();
  }, []);

  const calculateAverageLevelBySubCategory = (users, subCategory) => {
    let totalLevel = 0;
    let count = 0;
    users.forEach((user) => {
      user.stats_skillLevels?.forEach((skill) => {
        if (skill.subCategory === subCategory) {
          totalLevel += skill.level;
          count++;
        }
      });
    });
    return count > 0 ? totalLevel / count : 0;
  };

  const projectHasLeader = ({ members }) => {
    const total = members.reduce((acc, u) => acc + (u.hoursLastThreeMonths || 0), 0);
    return members.some((member) => {
      const isLeader = member.userStatsSkillLevels?.find((skill) => skill.category === "Leadership" && skill.subCategory === "Experience" && skill.level >= 3);
      const isActive = member.hoursLastThreeMonths / total > 0.2;
      return isLeader && isActive;
    });
  };

  const projectHasBusiness = ({ members }) => {
    const total = members.reduce((acc, u) => acc + (u.hoursLastThreeMonths || 0), 0);
    return members.some((member) => {
      const isBusiness = member.userStatsSkillLevels?.find((skill) => skill.category === "Leadership" && skill.subCategory === "Business" && skill.level >= 3);
      const isActive = member.hoursLastThreeMonths / total > 0.2;
      return isBusiness && isActive;
    });
  };

  const projectHasCTO = ({ members }) => {
    const total = members.reduce((acc, u) => acc + (u.hoursLastThreeMonths || 0), 0);
    return members.some((member) => {
      const leader = member.userStatsSkillLevels?.find((skill) => skill.category === "Leadership" && skill.subCategory === "Experience" && skill.level >= 3);
      const tech = member.userStatsSkillLevels?.find((skill) => skill.category === "Code" && skill.subCategory === "LeStud principles" && skill.level >= 3);
      const isActive = member.hoursLastThreeMonths / total > 0.2;
      return leader && tech && isActive;
    });
  };

  const projectHasProduct = ({ members }) => {
    const total = members.reduce((acc, u) => acc + (u.hoursLastThreeMonths || 0), 0);
    return members.some((member) => {
      const isProduct = member.userStatsSkillLevels?.find((skill) => skill.category === "Product" && skill.subCategory === "Product strategy - Leadership" && skill.level >= 3);
      const isActive = member.hoursLastThreeMonths / total > 0.2;
      return isProduct && isActive;
    });
  };

  if (!users) return <Loader />;

  const HEADER_COLS = [
    { title: "Project" },
    { title: "Health" },
    {
      title: (
        <span>
          lead<span className="px-2 bg-sky-700 text-white">level</span>
          <span className="px-2 bg-sky-200">last three months</span>
        </span>
      ),
    },
  ];

  return (
    <div>
      <Table
        header={HEADER_COLS}
        loading={loading}
        height=""
        sticky={true}
        noResultRender={<div className="w-full bg-white text-center p-2 my-5 rounded shadow-sm">No result</div>}>
        {projects?.map((project) => {
          const team = users.filter((u) => project.team.find((member) => member.userId === u._id));
          const totalTeamHours = project.team.reduce((acc, u) => acc + (u.hoursLastThreeMonths || 0), 0);

          return (
            <tr key={project._id} className={`hover:bg-gray-200 odd:bg-gray-100 even:bg-white`}>
              <td className="px-2 py-1 w-2/12">
                <a className="flex items-center gap-2 hover:underline cursor-pointer" href={`/project/${project._id}`} target="_blank">
                  {project.name}
                  <MdArrowOutward />
                </a>
              </td>
              <td className="px-2 py-1 w-3/12">
                <div>
                  <Tag active={projectHasLeader({ members: project.team })} />
                  <span>has leader</span>
                </div>
                <div>
                  <Tag active={projectHasBusiness({ members: project.team })} />
                  <span>has business</span>
                </div>
                <div>
                  <Tag active={projectHasCTO({ members: project.team })} />
                  <span>has CTO</span>
                </div>
                <div>
                  <Tag active={projectHasProduct({ members: project.team })} />
                  <span>has product</span>
                </div>
              </td>
              <td className="px-2 py-1 w-7/12">
                <div className="flex flex-col items-end">
                  {project.team.map((member) => {
                    const skill = member.userStatsSkillLevels?.find((skill) => skill.subCategory === "Experience");
                    const percentage = (member.hoursLastThreeMonths / totalTeamHours) * 100;
                    return (
                      <span key={member._id} className="text-sm text-gray-900">
                        <img src={member.userAvatar} className="w-6 h-6 rounded-full mr-2" />
                        {member.userName}
                        <span className="px-2 bg-sky-700 text-white">{skill?.level}</span>
                        <span className="px-2 bg-sky-200">
                          {(member.hoursLastThreeMonths / 8).toFixed(2)}days - {percentage.toFixed(2)}%
                        </span>
                        <span className="px-2 bg-purple-200">XXXy xp</span>
                      </span>
                    );
                  })}
                  <span className="text-sm text-gray-900">
                    TEAM
                    <span className="px-2 bg-sky-700 text-white">{calculateAverageLevelBySubCategory(team, "Experience")}</span>
                    <span className="px-2 bg-sky-200">
                      {(totalTeamHours / 8).toFixed(2)}
                      days
                    </span>
                  </span>
                </div>
              </td>
            </tr>
          );
        })}
      </Table>
    </div>
  );
};

const Tag = ({ active }) => {
  let bgColor, textColor, dotColor;
  if (active) {
    bgColor = "bg-green-100";
    textColor = "text-green-600";
    dotColor = "bg-green-400";
  } else {
    bgColor = "bg-red-100";
    textColor = "text-red-600";
    dotColor = "bg-red-400";
  }
  return (
    <span className={`inline-flex items-center gap-x-1.5 rounded-md ${bgColor} px-2 py-1 text-xs font-medium ${textColor}`}>
      <div className={`h-2 w-2 rounded-full ${dotColor}`} />
    </span>
  );
};
