import React, { useEffect, useState } from "react";
import { FaLocationDot } from "react-icons/fa6";

import FileInput from "../../../../components/FileInput";

const CvHeader = ({ values, editing, onChange }) => {
  if (editing) return <CvHeaderForm values={values} onChange={onChange} />;

  return (
    <div className="flex flex-row gap-8 cursor-pointer items-center">
      <div className="w-32">{values.image_url && <img src={values.image_url} alt="image.png" className="w-32 max-h-32 object-cover rounded-full" />}</div>
      <div className="w-full">
        <h2 className="font-medium font-poppins text-2xl">{values.employee}</h2>
        <h3 className="font-poppins mb-3 font-medium text-sm text-gray-700">{values.description}</h3>
        <div className="flex flex-wrap  w-full border border-gray-100 bg-[#F9FAFC] divide-x">
          <div className="p-2 px-4 bg-[#106377] text-white flex-grow">
            <div className="font-poppins text-[11px] mb-2">Tarif indicatif</div>
            <div className="font-poppins mt-2 font-medium">{values.tjm}</div>
          </div>
          <div className="p-2 px-4 flex-grow">
            <div className="font-poppins text-[11px] text-gray-500  mb-2">Experience</div>
            <div className="font-poppins mt-2 font-medium text-black">{values.experience}</div>
          </div>
          <div className="p-2 px-4 flex-grow">
            <div className="font-poppins text-[11px] text-gray-500 mb-2">Disponibilité</div>
            <div className="font-poppins mt-2 font-medium text-black">{values.availability}</div>
          </div>
          <div className="p-2 px-4 flex-grow">
            <div className="font-poppins text-[11px] text-gray-500 items-center mb-2 flex gap-1">
              <FaLocationDot className="text-gray-500" />
              Localisation
            </div>
            <div className="font-poppins mt-2 font-medium text-black">{values.location}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CvHeaderForm = ({ values, onChange }) => {
  function handleChange(e) {
    onChange({ ...values, [e.target.name]: e.target.value });
  }

  return (
    <div className="flex flex-col gap-2 p-2 bg-white border border-gray-200 rounded-md shadow-md my-6">
      <div className="flex">
        <div className="w-1/3">
          <div className="font-medium">Image</div>
          <FileInput folder="quotes/cv_header" value={values.image_url} name="image_url" onChange={handleChange} />
        </div>
        <div className="w-2/3">
          <div className="font-medium mb-2">Employee name</div>
          <input className="input mb-2" type="text" name="employee" value={values.employee} onChange={handleChange} />
          <div className="font-medium mb-2">Sub Title</div>
          <input className="input mb-2" type="text" name="description" value={values.description} onChange={handleChange} />
          <div className="flex flex-wrap gap-4">
            <div>
              <div className="font-medium mb-2">Tarif indicatif</div>
              <input className="input" type="text" name="tjm" value={values.tjm} onChange={handleChange} />
            </div>
            <div>
              <div className="font-medium mb-2">Experience</div>
              <input className="input" type="text" name="experience" value={values.experience} onChange={handleChange} />
            </div>
            <div>
              <div className="font-medium mb-2">Disponibilité</div>
              <input className="input" type="text" name="availability" value={values.availability} onChange={handleChange} />
            </div>
            <div>
              <div className="font-medium mb-2">Localisation</div>
              <input className="input" type="text" name="location" value={values.location} onChange={handleChange} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CvHeader;
