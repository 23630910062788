import React, { useEffect, useState } from "react";
import FileInput from "../../../../components/FileInput";
import { getBase64file } from "../utils";

const Image = ({ values, editing, onChange }) => {
  const [imageB64Url, setImageB64Url] = useState("");

  useEffect(() => {
    if (values.image_url) {
      getBase64file(values.image_url).then((url) => {
        setImageB64Url(url);
      });
    }
  }, [values.image_url]);

  if (editing) return <ImageForm values={values} onChange={onChange} />;

  return (
    <div className="flex flex-row items-start justify-center w-full gap-4">
      <img
        src={imageB64Url}
        style={{
          width: values.image_width ? `${values.image_width}px` : "auto",
          height: "auto",
        }}
        className="w-full"
      />
    </div>
  );
};

const ImageForm = ({ values, onChange }) => {
  function handleChange(e) {
    onChange({ ...values, [e.target.name]: e.target.value });
  }

  return (
    <div className="flex flex-col gap-2 p-2 bg-white border border-gray-200 rounded-md shadow-md my-6">
      <div className=" grid grid-cols-4 gap-2">
        <div className="col-span-2">
          <div className="text-[14px] text-[#212325] font-medium mb-1">Image</div>
          <FileInput folder="quotes" name={`image_url`} value={values.image_url} onChange={handleChange} />

          <div className="flex flex-row gap-3 items-center mt-4">
            <div className="text-[14px] text-[#212325] font-medium mb-1">Width</div>
            <input className="input" type="number" name="image_width" value={values.image_width} onChange={handleChange} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Image;
