import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/loader";
import api from "../../services/api";
import { MdDeleteForever } from "react-icons/md";
import { IoInformationCircleSharp } from "react-icons/io5";
import DebounceInput from "react-debounce-input";

import SelectMonth from "../../components/selectMonth";
import SelectUser from "../../components/selectUser";
// import SelectInvoice from "../../components/SelectInvoice";
import Modal from "../../components/modal";

// Where to put my time if I am not working directly on a project ?
// - If I am working on strategy, coaching someone from the team or if I am learning something/doing a training, then hours should be add on Le Stud
// - If I am prospecting : if it's for startups, it should go to Prospection_Startup, and if it's for Le Collectif, Prospection Established Company
// - Last, If I am spending time on admin tasks, it should go to Selego or Le Collectif, depending on the company

import { getDaysInMonth } from "../../utils";

const Activity = () => {
  const [date, setDate] = useState(null);
  const [user, setUser] = useState(null);

  const u = useSelector((state) => state.Auth.user);

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const username = params.get("username");
    const userId = params.get("user_id");
    const date = params.get("date");
    if (date) setDate(new Date(date));

    // todo get the full data from the user
    if (userId) setUser((_u) => ({ ..._u, _id: userId }));
    if (username) setUser((_u) => ({ ..._u, name: username }));
    if (!userId) setUser(u);
  }, []);

  if (user === null) return <Loader />;

  // Container

  return (
    <div className="bg-white border-[1px] border-gray-200 rounded-md overflow-hidden w-screen md:w-full">
      <div className="border-l-4 border-yellow-400 bg-yellow-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <IoInformationCircleSharp className="h-5 w-5 text-yellow-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <p className="text-sm text-yellow-700">
              We need to give visibility to clients about what we do and what value we create. We dont need to be exact. It&apos;s not a time tracking tool but more an activity and
              reporting tool.
              <br />1 day = 8 hours
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap gap-5 p-2 md:!px-8">
        <SelectUser disabled={u.role !== "admin"} value={user} onChange={setUser} />
        <SelectMonth start={-3} indexDefaultValue={3} value={date} onChange={(e) => setDate(e.target.value)} showArrows />
      </div>
      {date && user && <Activities date={new Date(date)} user={user} />}
    </div>
  );
};

const AddBudget = ({ onAdd }) => {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [commonBudgets, setCommonBudgets] = useState([]);
  const [budgets, setBudgets] = useState([]);

  useEffect(() => {
    onSearch(search);
  }, [search]);

  useEffect(() => {
    getCommonBudget();
  }, []);

  async function getCommonBudget() {
    const { data, ok } = await api.get(`/budget/common`);
    if (!ok) return;
    setCommonBudgets(data);
  }

  async function onSearch(str) {
    const { data } = await api.post(`/budget/search`, { search: str, limit: 10, status: "active" });
    setBudgets(data);
  }

  return (
    <div>
      <div>
        <button className="m-3 w-[82px] h-[48px] py-[12px] px-[22px] bg-[#0560FD] text-[16px] font-medium text-[#fff] rounded-[10px]" onClick={() => setOpen(true)}>
          Add
        </button>
      </div>
      <Modal isOpen={open} onClose={() => setOpen(false)} className="max-w-4xl mx-auto p-6">
        <div className="space-y-6">
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {commonBudgets.map((e) => {
              return (
                <div
                  key={e._id}
                  className="bg-white rounded-md shadow-md p-4 cursor-pointer"
                  onClick={() => {
                    setOpen(false);
                    onAdd(e);
                  }}>
                  <img src={e.projectLogo} className="w-8 h-8 mb-2" />
                  <div className="font-bold mb-1">
                    {e.projectName} {e.year}
                  </div>
                  <div className="font-bold mb-1">{e.name}</div>
                  <div className="text-gray-600 overflow-hidden text-ellipsis line-clamp-3">{e.description}</div>
                </div>
              );
            })}
          </div>
          <div className="mt-4">
            <DebounceInput
              debounceTimeout={300}
              id="search"
              className="form-input block w-full p-2 border border-gray-300 rounded-md focus:border-blue-500 focus:ring-blue-500"
              placeholder="Search by Name"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />
          </div>

          <div className="space-y-4">
            {budgets.map((e) => {
              return (
                <div
                  key={e._id}
                  className="bg-white rounded-md shadow-md p-4 cursor-pointer"
                  onClick={() => {
                    setOpen(false);
                    onAdd(e);
                  }}>
                  <div className="flex items-center gap-2">
                    <img src={e.projectLogo} className="w-8 h-8" />
                    <div>
                      <div className="font-bold">
                        {e.projectName} {e.year}
                      </div>
                      <div className="font-bold">{e.name}</div>
                      <div className="text-gray-400 italic">{e.description}</div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Modal>
    </div>
  );
};

const Activities = ({ date, user }) => {
  const [activities, setActivities] = useState([]);

  useEffect(() => {
    get();
  }, [date, user]);

  const navigate = useNavigate();

  async function get() {
    try {
      const { data } = await api.post(`/activity/search`, { date: date.getTime(), userId: user._id });
      setActivities(data);
    } catch (e) {
      console.error(e);
    }
  }

  const days = getDaysInMonth(date.getMonth(), date.getFullYear());

  const onAddBudget = async (budget) => {
    const found = activities.find((a) => budget._id === a.budgetId);
    if (found) return toast.error(`Budget ${budget.name} already added !`);
    const detail = days.map((e) => ({ date: e.date, value: 0 }));
    const { data, code } = await api.post(`/activity`, { budgetId: budget._id, userId: user._id, date: date.getTime(), detail });
    if (code) return toast.error(code);
    setActivities([...activities, data]);
  };

  async function onSave() {
    for (let i = 0; i < activities.length; i++) {
      console.log(`activities[i]`, activities[i]);
      const { code } = await api.post(`/activity`, activities[i]);
      if (code) {
        toast.error(`${activities[i].projectName} not saved : ${code}`);
      } else {
        toast.success(`Saved ${activities[i].projectName}`);
      }
    }
  }

  async function onDelete(i) {
    if (!window.confirm("Are you sure ?")) return;
    await api.remove(`/activity/${activities[i]._id}`);
    const newActivities = [...activities];
    newActivities.splice(i, 1);
    setActivities(newActivities);
    toast.success(`Deleted `);
  }

  function onUpdateValue(i, j, value) {
    if (!user.tjm) return toast.error("Please contact an admin to set your TJM");
    if (!user.tjms) return toast.error("Please contact an admin to set your TJMS");

    const n = [...activities];

    n[i].detail[j].value = value;
    n[i].total = n[i].detail.reduce((acc, b) => acc + b.value, 0);

    /// 0 if this project
    if (n[i].project === "Unavailable") {
      n[i].cost = 0;
      n[i].value = 0;
    } else {
      n[i].cost = (n[i].total / 8) * user.tjm;
      n[i].value = (n[i].total / 8) * (user.tjms || 0);
    }

    setActivities(n);
  }

  const getWorkingDays = () => {
    return days.reduce((acc, a) => {
      const day = a.getDay();
      if (day === 0 || day == 6) return acc;
      return acc + 1;
    }, 0);
  };

  const getTotal = () => (activities.reduce((acc, a) => acc + a.total, 0) / 8).toFixed(2);

  return (
    <div className="flex flex-wrap py-3 gap-4 text-black">
      <div className="w-screen md:w-full p-2 md:!px-8">
        {true && (
          <div className="mt-2 rounded-xl bg-[#fff] overflow-auto">
            <div className="overflow-x-auto">
              <table className="w-full">
                <thead>
                  <tr>
                    <th className="py-[10px] text-[14px] font-bold text-[#212325] text-left pl-[10px]">Invoices</th>
                    {days.map((e) => {
                      const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
                      const _date = new Date(e);
                      const day = _date.getDay();
                      const weekday = days[day];
                      const date = _date.getDate();
                      return (
                        <th
                          className={`w-[20px] border border-[#E5EAEF] text-[12px] font-semibold text-center ${day == 0 || day == 6 ? "bg-[#FFD5F1]" : "bg-[white]"}`}
                          key={e}
                          day={day}>
                          <div>{weekday}</div>
                          <div>{date}</div>
                        </th>
                      );
                    })}
                    <th className={`w-[20px] border border-[#E5EAEF] text-[12px] font-semibold text-center bg-[white]`} />
                  </tr>
                </thead>
                <tbody>
                  <tr className="border-t border-b border-r border-[#E5EAEF]">
                    <th className="px-2">
                      <div className="flex justify-end w-full text-[12px] font-bold text-[#212325] italic">
                        <div>{`${getTotal()} / ${getWorkingDays()} days`}</div>
                      </div>
                    </th>
                    {days.map((e, i) => {
                      const v = activities.reduce((acc, a) => {
                        if (!a.detail[i]) return acc;
                        return acc + a.detail[i].value;
                      }, 0);
                      return <Field key={`day-${i}`} value={v} disabled />;
                    })}
                  </tr>
                  {activities.map((e, i) => {
                    return (
                      <React.Fragment key={`1-${e._id}`}>
                        <tr className="border-t border-b border-r border-[#E5EAEF]" key={`1-${e._id}`}>
                          <th className="w-[100px] border-t border-b border-r text-[12px] font-bold text-[#212325] text-left">
                            <div className="flex flex-1 items-center justify-between gap-1 px-2">
                              <div className="flex flex-col flex-1 items-center justify-start" onClick={() => navigate(`/project/${e.projectId}`)}>
                                <div> {e.projectName}</div>
                                <div className="text-xs italic font-normal">{e.budgetName}</div>
                              </div>
                              <div className="flex flex-col items-end">
                                <div className="text-xs italic font-normal">{(e.total / 8).toFixed(2)} days</div>
                                <div className="text-[10px] italic font-normal">{(((e.total / 8).toFixed(2) / getTotal()) * 100).toFixed(2)}%</div>
                              </div>
                            </div>
                          </th>
                          {e.detail.map((f, j) => {
                            return (
                              <Field
                                key={`${e.projectName} ${j}`}
                                freezed={e.freezed}
                                value={f.value || 0}
                                onChange={(a) => onUpdateValue(i, j, parseFloat(a.target.value || 0))}
                              />
                            );
                          })}
                          <th className={`border border-[#E5EAEF] py-[6px]`}>
                            <div className={`flex justify-center cursor-pointer text-xl hover:text-red-500`}>
                              <MdDeleteForever onClick={() => onDelete(i)} />
                            </div>
                          </th>
                        </tr>
                      </React.Fragment>
                    );
                  })}
                  <tr>
                    <th className="w-[50px] text-[12px] text-[#212325] px-[10px] py-2">
                      <AddBudget
                        onAdd={(e) => {
                          toast.success(`Budget ${e.name} added`);
                          onAddBudget(e);
                        }}
                      />
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
            <button className="m-3 w-[82px] h-[48px] py-[12px] px-[22px] bg-[#0560FD] text-[16px] font-medium text-[#fff] rounded-[10px]" onClick={onSave}>
              Save
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

const Field = ({ value = 0, onChange, freezed, ...rest }) => {
  let bgColor = freezed === "yes" ? "bg-[#F0F0F0]" : "bg-[white]";
  let textColor = "text-[#000]";
  if (value >= 7) {
    bgColor = "bg-[#216E39]";
    textColor = "text-[#fff]";
  } else if (value >= 5) {
    bgColor = "bg-[#30A14E]";
  } else if (value >= 3) {
    bgColor = "bg-[#40C463]";
  } else if (value > 0) {
    bgColor = "bg-[#9BE9A8]";
  } else {
    textColor = "text-[#aaa]";
  }

  return (
    <th className={`border border-[#E5EAEF] py-[6px] px-0 ${bgColor} m-0`}>
      <input
        className={`border-none min-w-[30px] w-full text-center ${bgColor} ${textColor} p-0`}
        disabled={freezed === "yes"}
        value={parseFloat(value)}
        min={0}
        {...rest}
        type="number"
        step="0.1"
        onChange={onChange}
        onFocus={(e) => {
          if (Number(e.target.value) === 0) {
            e.target.value = "";
          }
        }}
        onBlur={(e) => {
          if (e.target.value === "") {
            e.target.value = 0;
          }
        }}
      />
    </th>
  );
};

export default Activity;
