import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

import Modal from "../../components/modal";
import Loader from "../../components/loader";

import api from "../../services/api";

export default () => {
  const [contracts, setContracts] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const { data } = await api.get("/contract");
      setContracts(data);
    })();
  }, []);

  if (!contracts) return <Loader size="small" />;

  return (
    <div className="p-3">
      <div className="flex items-center justify-end mb-3">
        <Create />
      </div>
      <div className="bg-white rounded-lg py-4">
        <table className="min-w-full divide-y divide-gray-200">
          <thead>
            <tr className="border-b border-[#E5EAEF] text-[14px] text-[#212325] font-semibold	">
              <th scope="col" className="px-4 py-3 text-xs font-bold text-left text-gray-500 uppercase">
                User Name
              </th>
              <th scope="col" className="px-4 py-3 text-xs font-bold text-left text-gray-500 uppercase">
                Contract name
              </th>
              <th scope="col" className="px-4 py-3 text-xs font-bold text-left text-gray-500 uppercase">
                Contract date
              </th>
              <th scope="col" className="px-4 py-3 text-xs font-bold text-left text-gray-500 uppercase">
                created by
              </th>
              <th scope="col" className="px-4 py-3 text-xs font-bold text-left text-gray-500 uppercase">
                Created at
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {contracts.map((d) => (
              <tr className="hover:bg-blue-50 cursor-pointer" onClick={() => navigate(`/document/${d._id}`)}>
                <td className="px-4 py-2 text-sm font-medium text-gray-600">{d.name}</td>
                <td className="px-4 py-2 text-sm font-medium text-gray-600">{d.template}</td>
                <td className="px-4 py-2 text-sm font-medium text-gray-600">{d.date ? new Date(d.date).toISOString().slice(0, 10) : ""}</td>
                <td className="px-4 py-2 text-sm font-medium text-gray-600">{d.user_name}</td>
                <td className="px-4 py-2 text-sm font-medium text-gray-600">{d.created_at ? new Date(d.created_at).toISOString().slice(0, 10) : ""}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const Create = () => {
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({});

  const navigate = useNavigate();

  async function onCreate() {
    const { data } = await api.post("/contract", values);
    toast.success("Created!");
    navigate(`/document/${data._id}`);
  }

  return (
    <div>
      <div className="text-right">
        <button className="bg-[#0560FD] text-[#fff] py-[12px] px-[22px] h-[48px]	rounded-[10px] text-sm font-medium" onClick={() => setOpen(true)}>
          Create new contract
        </button>
      </div>
      <Modal isOpen={open} onClose={() => setOpen(false)} className="w-full max-w-md">
        <div className="max-w-2xl w-full h-auto bg-[white] p-[25px] rounded-md">
          <div className="space-y-2">
            <SelectTemplate value={values.template} onChange={(template) => setValues({ ...values, template })} />
          </div>
          <button
            className="mt-[1rem] bg-[#0560FD] text-[16px] font-medium text-[#FFFFFF] py-[12px] px-[22px] rounded-[10px] disabled:cursor-not-allowed"
            disabled={!values.template}
            onClick={onCreate}>
            Create
          </button>
        </div>
      </Modal>
    </div>
  );
};

const SelectTemplate = ({ value, onChange }) => {
  return (
    <div>
      <div className="text-[14px] text-[#212325] font-medium mb-2">Template</div>
      <select className="projectsInput text-[14px] font-normal text-[#212325] rounded-[10px] m-0" value={value} onChange={(e) => onChange(e.target.value)}>
        <option value="">Select a template</option>
        {[`NDA_V1`, `FREELANCE_V1`, `FREELANCE_V2`].map((t) => (
          <option value={t}>{t}</option>
        ))}
      </select>
    </div>
  );
};
