import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useSearchParams } from "react-router-dom";

import api from "../../../services/api";
import { MONTHS, YEARS } from "../../../constants";
import TablePagination from "../../../components/TablePagination";

const HEADER = [
  { title: "Name", colSpan: 2 },
  { title: "Date", position: "right" },
  { title: "TJM", position: "right" },
  { title: "Days", position: "right" },
  { title: "Cost", position: "right" },
  { title: "Budget", colSpan: 2 },
  { title: "Invoice", colSpan: 2 },
];

const Breakdown = ({ project }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [filters, setFilters] = useState({
    year: Number(searchParams.get("year")) || "",
    month: Number(searchParams.get("month")) || "",
    budget: searchParams.get("budget") || "",
    invoice: searchParams.get("invoice") || "",
    type: searchParams.get("type") || "",
  });
  const [budgets, setBudgets] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [selected, setSelected] = useState([]);
  const [activities, setActivities] = useState([]);
  const [banks, setBanks] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const resBudgets = await api.post("/budget/search", { projectId: project._id, limit: 50 });
        if (resBudgets.ok) setBudgets(resBudgets.data);

        const resInvoices = await api.post("/invoice/search", { projectId: project._id, limit: 50 });
        if (resInvoices.ok) setInvoices(resInvoices.data.sort((a, b) => (a.name || "").localeCompare(b.name)));
      } catch (error) {
        console.error(error);
        toast.error("Error while fetching data");
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        if (filters.type !== "bank") {
          const query = { projectId: project._id };

          if (filters.budget) query.budgetId = filters.budget;
          if (filters.year) {
            if (filters.month) {
              query.dateFrom = new Date(filters.year, filters.month, 1);
              query.dateTo = new Date(filters.year, filters.month + 1, 0);
            } else {
              query.dateFrom = new Date(filters.year, 0, 1);
              query.dateTo = new Date(filters.year, 11, 31);
            }
          }
          if (filters.invoice) query.invoiceId = filters.invoice;

          const { ok, data } = await api.post(`/activity/search`, query);
          if (ok) setActivities(data.filter((a) => a.value).map((e) => ({ ...e, type: "activity" })));
        } else {
          setActivities([]);
        }

        if (filters.type !== "activity") {
          const query = { projectId: project._id };
          query.notCategories = ["INCOME", "DISBURSEMENT"];
          query.per_page = 20000;
          query.budgetId = filters.budget;
          if (filters.year) {
            if (filters.month) {
              query.startDate = new Date(filters.year, filters.month, 1);
              query.endDate = new Date(filters.year, filters.month + 1, 0);
            } else {
              query.startDate = new Date(filters.year, 0, 1);
              query.endDate = new Date(filters.year, 11, 31);
            }
          }
          const { ok, data } = await api.post(`/bank/search`, query);
          if (ok) setBanks(data.banks.map((e) => ({ ...e, type: "bank", amount: -e.amount })));
        } else {
          setBanks([]);
        }

        // set search params
        const newSearchParams = new URLSearchParams();
        if (filters.year) newSearchParams.set("year", filters.year);
        if (filters.month) newSearchParams.set("month", filters.month);
        if (filters.budget) newSearchParams.set("budget", filters.budget);
        if (filters.invoice) newSearchParams.set("invoice", filters.invoice);
        if (filters.type) newSearchParams.set("type", filters.type);
        setSearchParams(newSearchParams);
      } catch (error) {
        toast.error("Error while refreshing");
        console.error(error);
      }
      setLoading(false);
    };
    fetchData();
  }, [filters]);

  let total = activities.reduce((acc, a) => acc + a.value, 0);
  total = banks.reduce((acc, a) => acc + a.amount, total);

  if (loading) return <div>loading...</div>;

  return (
    <div className="py-4 space-y-6">
      <div className="flex items-center gap-4 justify-end">
        <div className="space-y-2 w-full">
          <label className="block text-sm font-medium text-gray-700" htmlFor="year">
            Year
          </label>
          <select value={filters.year} onChange={(e) => setFilters({ ...filters, year: e.target.value })} className="select">
            <option value="">Select year</option>
            {YEARS.map((e, i) => (
              <option key={i} value={e}>
                {e}
              </option>
            ))}
          </select>
        </div>
        {filters.year && (
          <div className="space-y-2 w-full">
            <label className="block text-sm font-medium text-gray-700" htmlFor="month">
              Month
            </label>

            <select id="month" value={filters.month} onChange={(e) => setFilters({ ...filters, month: e.target.value })} className="select">
              <option value="">Select month</option>
              {MONTHS.map((e, i) => (
                <option key={i} value={i}>
                  {e}
                </option>
              ))}
            </select>
          </div>
        )}
        <div className="space-y-2 w-full">
          <label className="block text-sm font-medium text-gray-700" htmlFor="budget">
            Budget
          </label>

          <select id="budget" value={filters.budget} onChange={(e) => setFilters({ ...filters, budget: e.target.value })} className="select">
            <option value="">Select budget</option>
            {budgets.map((e, i) => (
              <option key={i} value={e._id}>
                {e.name}
              </option>
            ))}
          </select>
        </div>
        <div className="space-y-2 w-full">
          <label className="block text-sm font-medium text-gray-700" htmlFor="invoice">
            Invoice
          </label>
          <select value={filters.invoice} onChange={(e) => setFilters({ ...filters, invoice: e.target.value })} className="select">
            <option value="">Select invoice</option>
            {invoices.map((e, i) => (
              <option key={i} value={e._id}>
                {e.name}
              </option>
            ))}
          </select>
        </div>
        <div className="space-y-2 w-full">
          <label className="block text-sm font-medium text-gray-700" htmlFor="type">
            Type
          </label>
          <select value={filters.type} onChange={(e) => setFilters({ ...filters, type: e.target.value })} className="select">
            <option value="">Select type</option>
            <option value="activity">Activity</option>
            <option value="bank">Bank</option>
          </select>
        </div>
      </div>
      <TablePagination sticky={true} height="h-[32rem]" header={HEADER} total={activities.length + banks.length} pageSize={200}>
        <>
          {activities.map((item, index) => (
            <ActivityRow key={index} item={item} />
          ))}
          {banks.map((item) => (
            <BankRow key={item._id} item={item} />
          ))}
        </>
      </TablePagination>
    </div>
  );
};

const ActivityRow = ({ item }) => {
  return (
    <tr className="h-14 hover:bg-gray-100">
      <td colSpan={2} className="p-2 text-sm">
        <div className="flex-1 space-y-1">
          <p className="font-semibold">{item.userName}</p>
          <p className="text-xs text-gray-600">{item.userJobTitle}</p>
        </div>
      </td>
      <td className="text-right p-2 text-sm">{new Date(item.date).toLocaleDateString("en-US", { year: "numeric", month: "long" })} </td>
      <td className="text-right p-2 text-sm">{(item.userTjms || 0).toLocaleString("fr", { style: "currency", currency: "EUR" })}</td>
      <td className="text-right p-2 text-sm">{(item.total / 8).toFixed(2)}</td>
      <td className="text-right p-2 text-sm">{(item.value || 0).toLocaleString("fr", { style: "currency", currency: "EUR" })}</td>
      <td colSpan={2} className="p-2 text-sm">
        {item.budgetName}
      </td>
      <td colSpan={2} className="p-2 text-sm">
        {item.invoiceName}
      </td>
    </tr>
  );
};

const BankRow = ({ item }) => {
  return (
    <tr key={item._id} className="h-14 hover:bg-gray-100">
      <td colSpan={2} className="text-left p-2 text-sm">
        <p className="font-semibold">{item.name}</p>
      </td>
      <td className="p-2 text-sm">{new Date(item.date).toLocaleDateString("en-US", { year: "numeric", month: "long" })} </td>
      <td colSpan={3} className="text-right p-2 text-sm">
        {item.amount.toLocaleString("fr", { style: "currency", currency: "EUR" })}
      </td>
      <td colSpan={2} className="p-2 text-sm">
        {item.budgetName}
      </td>
      <td colSpan={2} className="p-2 text-sm">
        {item.invoiceName}
      </td>
    </tr>
  );
};
export default Breakdown;
