import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import api from "../../services/api";
import Pagination from "../../components/Paginator";
import dayjs from "dayjs";
import toast from "react-hot-toast";
import DebounceInput from "../../components/DebounceInput";
import ActionModal from "../../components/ActionModal";
import Table from "../../components/Table";
import Loader from "../../components/loader";

const HEADER = [
  { title: "Name", key: "name" },
  { title: "Description", key: "description" },
  { title: "Company", key: "company" },
  { title: "Contact", key: "folkName" },
  { title: "Creator", key: "userName" },
  { title: "Responsible", key: "responsibleName" },
  { title: "Category", property: "w-40" },
  { title: "Status", key: "status", property: "w-40" },
  { title: "Deadline", key: "deadline", property: "w-40" },
];

export default ({}) => {
  const [actions, setActions] = useState([]);
  const [selectedAction, setSelectedAction] = useState(null);
  const [total, setTotal] = useState();
  const [filters, setFilters] = useState({
    search: "",
    page: 1,
    pageSize: 30,
    total,
    sort: { created_at: -1 },
    status: "TODO+PENDING",
  });
  const [loading, setLoading] = useState(false);
  const [loadingRow, setLoadingRow] = useState(true);

  useEffect(() => {
    fetchActions();
  }, [filters]);

  async function fetchActions() {
    try {
      const { ok, data, total } = await api.post("/action/search", filters);
      if (!ok) return toast.error("Error fetching actions");
      setActions(data);
      setTotal(total);
    } catch (error) {
      console.log(error);
      toast.error("Error fetching actions");
    }
    setLoading(false);
    setLoadingRow(false);
  }

  const handleSort = (key) => {
    setFilters({ ...filters, sort: { [key]: !filters.sort[key] ? 1 : filters.sort[key] === 1 ? -1 : undefined } });
  };

  const { search } = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(search);

  if (loading) return <Loader />;

  return (
    <div className="bg-white shadow-md rounded p-4">
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center gap-2 w-2/3">
          <div className="space-y-1 w-1/3">
            <div className="px-1 text-sm text-gray-600 font-medium">Search</div>
            <DebounceInput
              debounce={300}
              value={filters.search}
              onChange={(value) => setFilters({ ...filters, search: value })}
              className="input rounded-md border-[1px] border-gray-300 shadow-sm"
              placeholder="Search"
            />
          </div>
          <div className="w-1/3">
            <UserSelect label="Category" name="category" value={filters.category} onChange={(e) => setFilters({ ...filters, category: e.target.value })}>
              <option value="">All</option>
              <option value="CALL_BACK">Call Back</option>
              <option value="CONTACT_SOMEONE">Contact Someone</option>
              <option value="SEND_PROPOSAL">Send Proposal</option>
              <option value="OTHER">Other</option>
            </UserSelect>
          </div>
          <div className="w-1/3">
            <UserSelect label="Status" name="status" value={filters.status} onChange={(e) => setFilters({ ...filters, status: e.target.value })}>
              <option value="">All</option>
              <option value="TODO+PENDING">To do & Pending</option>
              <option value="TODO">To do</option>
              <option value="PENDING">Pending</option>
              <option value="DONE">Done</option>
            </UserSelect>
          </div>
        </div>
      </div>
      <div className="overflow-x-auto">
        <Table
          header={HEADER}
          sort={filters.sort}
          total={total}
          onSort={handleSort}
          height="h-[30rem]"
          sticky={true}
          loading={loading}
          noResultRender={<div className="w-full bg-white text-center p-2 my-5 rounded shadow-sm">Aucune action n'a été trouvée</div>}>
          {loadingRow
            ? [...Array(filters.pageSize)].map((_, index) => (
                <tr key={index} className={`text-center cursor-pointer ${index % 2 === 0 ? "bg-neutral-50" : "bg-white"}`}>
                  {HEADER.map((_, index) => (
                    <td key={index} className="py-4 px-3">
                      <div className="h-4 bg-gray-200 rounded w-full" />
                    </td>
                  ))}
                </tr>
              ))
            : actions.map((action, index) => (
                <tr
                  key={index}
                  className={`text-center cursor-pointer text-sm ${index % 2 === 0 ? "bg-neutral-50" : "bg-white"} hover:bg-secondary`}
                  onClick={() => {
                    query.set("folk_modal_id", action.folkId);
                    query.set("folk_modal_tab", "info");
                    navigate({ search: query.toString() });
                    setSelectedAction(action);
                  }}>
                  <td className="py-4 px-3 text-left flex flex-wrap w-64">{action.name}</td>
                  <td className="py-4 px-3 text-left overflow-hidden max-w-64 ">{parseDescriptionHtml(action?.description || "")}</td>
                  <td className="py-4 px-3 text-left overflow-hidden ">{action.company ?? "-"}</td>
                  <td className="py-4 px-3 text-left overflow-hidden truncate">{action.folkName}</td>
                  <td className="py-4 px-3 text-left overflow-hidden truncate">{action.userName ?? "-"}</td>
                  <td className="py-4 px-3 text-left overflow-hidden truncate">{action.responsibleName ?? "-"}</td>
                  <td className="py-4 px-3 text-left overflow-hidden truncate">{action.category?.split("_").join(" ")}</td>
                  <td className="py-4 px-3 text-left overflow-hidden truncate">{action.status}</td>
                  <td className="py-4 px-2 text-left  truncate">{dayjs(action.deadline).format("YYYY-MM-DD")}</td>
                </tr>
              ))}
        </Table>
      </div>
      <div className="flex justify-center mx-[25%] mt-2">
        <Pagination page={filters.page} setPage={(page) => setFilters({ ...filters, page })} last={Math.ceil(total / filters.pageSize)} />
      </div>
      {setSelectedAction && <ActionModal action={selectedAction} refetch={() => fetchActions()} setAction={setSelectedAction} />}
    </div>
  );
};

const UserSelect = ({ name, value, onChange, label, children }) => (
  <div className="w-full">
    <div className="px-1 text-sm text-gray-600 font-medium">{label}</div>
    <select
      className="w-full rounded-md border-[1px] border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-sm placeholder:text-gray-300 py-2 px-2"
      type="select"
      name={name}
      value={value}
      onChange={onChange}>
      {children}
    </select>
  </div>
);

const parseDescriptionHtml = (description) => {
  if (!description) return "";
  const parsedText = new DOMParser().parseFromString(description, "text/html").documentElement.textContent;
  if (parsedText.length > 100) return parsedText.slice(0, 100) + "...";
  return parsedText;
}
