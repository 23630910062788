import React, { useState } from "react";
import { classNames } from "../../../utils";

import { CONTRACTS, DIVISION, LOCATION, ROLES, YEARSSCHOOL, SCHOOL, MANAGEMENT, BENCHMARKS_CDI, BENCHMARKS_CDI_START } from "./constants";

export default ({ user }) => {
  const [tab, setTab] = useState("day");

  if (!user.position) return <div />;

  let experience_total = 0;
  if (user.experience_total) {
    experience_total = user.experience_total;
  }

  user.experience_total = experience_total;

  let elements = [];

  if ([DIVISION.LE_COLLECTIF, DIVISION.SELEGO_FR].includes(user.division)) {
    elements = frenchInfo(user);
  }
  if ([DIVISION.SELEGO_NL].includes(user.division)) {
    elements = dutchInfo(user);
  }
  if ([DIVISION.SELESPAGNE].includes(user.division)) {
    elements = spanishInfo(user);
  }

  return (
    <div className="w-full">
      <label className="font-bold">Benchmark</label>

      <div className="p-4 mt-2 border border-gray-200 rounded-md">
        <div className="mt-2">
          {/* <TabItem title="Day" setTab={setTab} tab="day" active={tab === "day"} />
        <TabItem title="Month" setTab={setTab} tab="month" active={tab === "month"} />
        <TabItem title="Year" setTab={setTab} tab="year" active={tab === "year"} /> */}
          {elements.map((e, i) => {
            return e.title ? (
              <div className="flex flex-row justify-between py-1">
                <div>
                  <label>{e.title}</label>
                  <div className="text-sm text-gray-500">{e.subtitle}</div>
                </div>
                <div>{e.value}€</div>
              </div>
            ) : (
              <hr />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export const spanishInfo = (user) => {
  const elements = [];

  if (user.contract === CONTRACTS.INTERNSHIP) {
    let monthly_brut = 0;
    if (user.yearSchool === YEARSSCHOOL.FIFTHYEAR) {
      monthly_brut = 1200;
    } else if (user.yearSchool === YEARSSCHOOL.FOURTHYEAR) {
      monthly_brut = 1000;
    } else {
      monthly_brut = 800;
    }
    let brut = monthly_brut * 12;
    elements.push({ key: "salary", title: "Brut", subtitle: `${monthly_brut} net per month, you need to come to the office at least 75% of the time`, value: brut });

    elements.push({ title: "Net", subtitle: "Net is almost the same as brut for interns", value: brut });
  }

  if ([CONTRACTS.CDI, CONTRACTS.CDD].includes(user.contract)) {
    const index = BENCHMARKS_CDI_START.findIndex((e) => e[0] === user.position);

    let brut = 0;

    if (BENCHMARKS_CDI_START[index]) {
      if (user.experience_total < 7) {
        brut = BENCHMARKS_CDI_START[index][2] * Math.pow(1.075, user.experience_total) * 0.9;
      } else {
        brut = BENCHMARKS_CDI_START[index][2] * Math.pow(1.075, 7) * Math.pow(1.03, user.experience_total - 7) * 0.9;
      }
    }

    brut = 0.9 * brut;

    elements.push({ key: "salary", title: "Brut", subtitle: "", value: Math.round(brut) });

    if (user.office != LOCATION.REMOTE) {
      brut = brut / 0.9;
      elements.push({ title: "Brut - Coming to the office +10%", subtitle: "If you do come to an office at least twice a week 75% of the year", value: Math.round(brut) });
    }

    if (user.days_worked && parseInt(user.days_worked) !== 22) {
      brut = (brut * Math.round(user.days_worked / 4.4)) / 5;
      elements.push({ title: "Part time", subtitle: "", value: Math.round(brut) });
    }

    if (user.school == SCHOOL.ENGINEERING) {
      brut = brut * 1.08;
      elements.push({
        title: "Brut - Engineering School +8%",
        subtitle: "indicative +8% based on the market (specialized profils, in-depth training and higher resilience)",
        value: Math.round(brut),
      });
    }

    let bonus = 0;
    if (user.management == MANAGEMENT.LEAD) {
      bonus = brut * 0.05;
      elements.push({ title: "Bonus - Lead/Manager of a team 5%", subtitle: "", value: Math.round(bonus) });
    } else if (user.management == MANAGEMENT.HEADOF) {
      bonus = brut * 0.1;
      elements.push({ title: "Bonus - Head Of/Chief of a team 10%", subtitle: "", value: Math.round(bonus) });
    }

    let bonusSalesEstimation = 0;
    if (user.position == ROLES.SALES) {
      const salesGoal = (user.experience_total + 1) * 10000 * 12;
      bonusSalesEstimation = Math.round(salesGoal * 0.125 * 0.4);
      elements.push({
        title: "Bonus Sales - 12.5% of profits of the won projects (estimation)",
        subtitle: `12.5% of profits of the ${salesGoal}€ closed`,
        value: Math.round(bonusSalesEstimation),
      });
    }

    let totalPackage = brut + bonus + bonusSalesEstimation;
    elements.push({ title: "Total Package", subtitle: "", value: Math.round(totalPackage) });

    const tax = totalPackage * 0.23;

    const net = totalPackage - tax;
    elements.push({ title: "Net", subtitle: "", value: Math.round(net) });
  }

  return elements;
};

export const dutchInfo = (user) => {
  const elements = [];

  if (user.contract === CONTRACTS.INTERNSHIP) {
    let monthly_brut = 0;
    if (user.yearSchool === YEARSSCHOOL.FIFTHYEAR) {
      monthly_brut = 1500;
    } else if (user.yearSchool === YEARSSCHOOL.FOURTHYEAR) {
      monthly_brut = 1200;
    } else {
      monthly_brut = 1000;
    }
    let brut = monthly_brut * 12;

    elements.push({ key: "salary", title: "Brut", subtitle: `${monthly_brut} net per month, you need to come to the office at least 75% of the time`, value: brut });

    elements.push({ title: "Net", subtitle: "Net is almost the same as brut for interns", value: brut });
  }

  if ([CONTRACTS.CDI, CONTRACTS.CDD].includes(user.contract)) {
    const index = BENCHMARKS_CDI_START.findIndex((e) => e[0] === user.position);

    let brut = 0;

    if (user.experience_total < 7) {
      brut = BENCHMARKS_CDI_START[index][2] * Math.pow(1.075, user.experience_total) * 0.9;
    } else {
      brut = BENCHMARKS_CDI_START[index][2] * Math.pow(1.075, 7) * Math.pow(1.03, user.experience_total - 7) * 0.9;
    }

    elements.push({ title: "Brut", subtitle: "", value: Math.round(brut) });

    if (user.days_worked && parseInt(user.days_worked) !== 22) {
      brut = (brut * Math.round(user.days_worked / 4.4)) / 5;
      elements.push({ title: "Part time", subtitle: "", value: Math.round(brut) });
    }

    if (user.office != LOCATION.REMOTE) {
      brut = brut / 0.9;
      elements.push({ title: "Brut - Coming to the office +10%", subtitle: "If you do come to an office at least twice a week 75% of the year", value: Math.round(brut) });
    }

    if (user.school == SCHOOL.ENGINEERING) {
      brut = brut * 1.08;
      elements.push({
        title: "Brut - Engineering School +8%",
        subtitle: "indicative +8% based on the market (specialized profils, in-depth training and higher resilience)",
        value: Math.round(brut),
      });
    }

    let bonus = 0;
    if (user.management == MANAGEMENT.LEAD) {
      bonus = brut * 0.05;
      elements.push({ title: "Bonus - Lead/Manager of a team 5%", subtitle: "", value: Math.round(bonus) });
    } else if (user.management == MANAGEMENT.HEADOF) {
      bonus = brut * 0.1;
      elements.push({ title: "Bonus - Head Of/Chief of a team 10%", subtitle: "", value: Math.round(bonus) });
    }

    let bonusSalesEstimation = 0;
    if (user.position == ROLES.SALES) {
      const salesGoal = (user.experience_total + 1) * 10000 * 12;
      bonusSalesEstimation = Math.round(salesGoal * 0.125 * 0.4);
      elements.push({
        title: "Bonus Sales - 12.5% of profits of the won projects (estimation)",
        subtitle: `12.5% of profits of the ${salesGoal}€ closed`,
        value: Math.round(bonusSalesEstimation),
      });
    }

    let totalPackage = brut + bonus + bonusSalesEstimation;
    elements.push({ key: "salary", title: "Total Package Recommended", subtitle: "", value: Math.round(totalPackage) });

    let tax = 0;
    if (user.dutch_ruling) {
      tax = Math.round(totalPackage * 0.34 - 7128);
    } else {
      tax = Math.round(totalPackage * 0.4672 - 9564);
    }

    const net = totalPackage - tax;
    elements.push({ title: "Net", subtitle: "", value: Math.round(net) });
  }

  return elements;
};

export const frenchInfo = (user) => {
  const elements = [];

  if (user.contract === CONTRACTS.VIE) {
    let net = 0;
    console.log(user.office);
    if (user.office == LOCATION.BARCELONE) {
      net = Math.round(2382 * 12);
    } else if (user.office == LOCATION.AMSTERDAM) {
      net = Math.round(3108 * 12);
    }
    elements.push({
      key: "salary",
      title: "Brut",
      subtitle: "Reference : https://civiwebprod.cdn.prismic.io/civiwebprod/45b28a08-9245-4020-b95a-26f792a11704_BAREME+VIE+AU+1ER+JANVIER+2024+VF.pdf",
      value: net,
    });
    elements.push({ title: "Net", subtitle: "Reference : pour un VIE, brut = net", value: net });
    elements.push(<hr />);
  }

  if (user.contract === CONTRACTS.INTERNSHIP) {
    let monthly_brut = 0;
    if (user.yearSchool === YEARSSCHOOL.FIFTHYEAR) {
      monthly_brut = 1500 * 0.9;
    } else if (user.yearSchool === YEARSSCHOOL.FOURTHYEAR) {
      monthly_brut = 1200 * 0.9;
    } else {
      monthly_brut = 1000 * 0.9;
    }
    let brut = 12 * monthly_brut;
    elements.push({ key: "salary", title: "Brut", subtitle: `${monthly_brut} net per month`, value: brut });

    if (user.office != LOCATION.REMOTE) {
      brut = brut / 0.9;
      elements.push({ title: "Brut - Coming to the office +10%", subtitle: "When you come to an office at least twice a week 75% of the year", value: Math.round(brut) });
    }

    let french_ticketresto_employee = 0;

    if (user.french_ticketresto) {
      const perDayEmployee = 4.61;
      french_ticketresto_employee = Math.round((perDayEmployee * user.numberDays * 218) / 5);
      elements.push({
        title: "Tickets resto Employee",
        subtitle: `employee pays ${french_ticketresto_employee}) out of his brutto salary`,
        value: Math.round(french_ticketresto_employee),
      });
    }

    const net = brut - french_ticketresto_employee;
    elements.push({ title: "Net", subtitle: "Brut (- Tickets restau)", value: Math.round(net) });
  }

  if (user.contract === CONTRACTS.APPRENTICESHIP) {
    let monthly_brut = 0;
    if (user.yearsSchool === YEARSSCHOOL.FIFTHYEAR) {
      monthly_brut = 1800;
    } else if (user.yearsSchool === YEARSSCHOOL.FOURTHYEAR) {
      monthly_brut = 1500;
    } else {
      monthly_brut = 1300;
    }

    let brut = monthly_brut * 12;
    elements.push({ key: "salary", title: "Brut", subtitle: `${monthly_brut} net per month`, value: brut });

    let french_ticketresto_employee = 0;

    if (user.french_ticketresto) {
      const perDayEmployee = 4.61;
      french_ticketresto_employee = Math.round((perDayEmployee * user.numberDays * 218) / 5);
      elements.push({
        title: "Tickets resto Employee",
        subtitle: `employee pays ${french_ticketresto_employee}) out of his brutto salary`,
        value: Math.round(french_ticketresto_employee),
      });
    }

    const net = brut - french_ticketresto_employee;
    elements.push({ title: "Net", subtitle: "Brut - Tickets restau, net is almost the same for alternants", value: Math.round(net) });
  }

  if ([CONTRACTS.CDI, CONTRACTS.CDD].includes(user.contract)) {
    const index = BENCHMARKS_CDI_START.findIndex((e) => e[0] === user.position);

    let brut = 0;

    if (user.experience_total < 7) {
      brut = BENCHMARKS_CDI_START[index][2] * Math.pow(1.075, user.experience_total) * 0.9;
    } else {
      brut = BENCHMARKS_CDI_START[index][2] * Math.pow(1.075, 7) * Math.pow(1.03, user.experience_total - 7) * 0.9;
    }

    elements.push({ title: "Brut", subtitle: "", value: Math.round(brut) });

    if (user.days_worked && parseInt(user.days_worked) !== 22) {
      brut = (brut * Math.round(user.days_worked / 4.4)) / 5;
      elements.push({ title: "Part time", subtitle: "", value: Math.round(brut) });
    }

    if (user.office != LOCATION.REMOTE) {
      brut = brut / 0.9;
      elements.push({ title: "Brut - Coming to the office +10%", subtitle: "If you come to an office at least twice a week 75% of the year", value: Math.round(brut) });
    }

    if (user.school == SCHOOL.ENGINEERING) {
      brut = brut * 1.08;
      elements.push({
        title: "Brut - Engineering School +8%",
        subtitle: "indicative +8% based on the market (specialized profils, in-depth training and higher resilience)",
        value: Math.round(brut),
      });
    }

    let bonus = 0;
    if (user.management == MANAGEMENT.LEAD) {
      bonus = brut * 0.05;
      elements.push({ title: "Bonus - Lead/Manager of a team 5%", subtitle: "", value: Math.round(bonus) });
    } else if (user.management == MANAGEMENT.HEADOF) {
      bonus = brut * 0.1;
      elements.push({ title: "Bonus - Head Of/Chief of a team 10%", subtitle: "", value: Math.round(bonus) });
    }

    let bonusSalesEstimation = 0;
    if (user.position == ROLES.SALES) {
      const salesGoal = (user.experience_total + 1) * 10000 * 12;
      bonusSalesEstimation = Math.round(salesGoal * 0.125 * 0.4);
      elements.push({
        title: "Bonus Sales - 12.5% of profits of the won projects (estimation)",
        subtitle: `12.5% of profits of the ${salesGoal}€ closed`,
        value: Math.round(bonusSalesEstimation),
      });
    }

    let totalPackage = brut + bonus + bonusSalesEstimation;
    elements.push({ key: "salary", title: "Total Package", subtitle: "", value: Math.round(totalPackage) });

    elements.push(<hr />);

    const tax = totalPackage * 0.4432 - 6624;

    let french_ticketresto_employee = 0;

    if (user.french_ticketresto) {
      const perDayEmployee = 4.61;
      french_ticketresto_employee = Math.round((perDayEmployee * user.numberDays * 218) / 5);
      elements.push({ title: "Tickets resto Employee", subtitle: `employee pays ${french_ticketresto_employee} out of his brutto salary`, value: french_ticketresto_employee });
    }

    const net = totalPackage - tax - french_ticketresto_employee;
    elements.push({ title: "Net", subtitle: "Brut - Taxes - Tickets restau", value: Math.round(net) });

    if (CONTRACTS.CDD === user.contract) {
      const prime = 0.1 * (brut / 12).toFixed(0) * user.contractLength;
      elements.push({ key: "salary", title: "Primes fin CDD", subtitle: `0.1 x ${Math.round(brut / 12)} x ${user.contractLength}`, value: Math.round(prime) });
    }
  }

  return elements;
};

const TabItem = ({ tab, title, setTab, active, Icon }) => (
  <button
    onClick={() => setTab(tab)}
    className={classNames(
      "group inline-flex items-center px-4 py-2 cursor-pointer text-sm text-gray-500 hover:border-gray-300 hover:text-gray-700 border-b-2 border-transparent",
      active ? "text-primary  border-primary" : "",
    )}>
    <div className="flex items-center gap-2">{title}</div>
  </button>
);
