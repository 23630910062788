import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import html2pdf from "html2pdf.js";
import Modal from "../../../components/modal";
import api from "../../../services/api";
import MultipleSelectInput from "../../../components/MultipleSelectInput";

const saveOptions = {
  margin: 0,
  filename: `invoice.pdf`,
  image: { type: "jpeg", quality: 1 },
  html2canvas: { dpi: 300 },
  jsPDF: { unit: "mm", format: "a4" },
};

export default ({ invoice, project, onDone }) => {
  const [modalSend, setModalSend] = useState(false);

  const cc_emails = ["admin@selego.co", "noemie@selego.co"];
  console.log(invoice);

  if (project?.team.filter((e) => e.userRole === "client")) cc_emails.push(...project?.team.filter((e) => e.userRole === "client")?.map((user) => user.userEmail));

  if (project.operating_partner_email) cc_emails.push(project.operating_partner_email);
  if (project.lead_email) cc_emails.push(project.lead_email);

  const [mail, setMail] = useState({ recipients: project.clientsMails, subject: invoice.name, body: "", cc: cc_emails });

  useEffect(() => {
    loadTemplate("REQUEST1");
  }, []);

  function loadTemplate(templateName) {
    const obj = { ...mail };

    if (templateName === "REQUEST1") {
      obj.subject = invoice.name;
      obj.body = `Dear ${project.name},\n\nPlease find attached the invoice of ${invoice.total}€ in regard to ${invoice.projectName}.\n\nDue date: ${new Date(
        invoice.dueAt,
      ).toLocaleDateString("fr")}\n\nBest regards,\n\nAlice , Office manager`;
    }
    if (templateName === "REQUEST2") {
      obj.subject = `1st Payment Request overdue invoice ${invoice.name}`;
      obj.body = `Dear Sir,\n\nOur records show that, despite previous requests on our part, the invoice listed below from our client below has not been paid to Factris to date. The agreed payment period has now expired. As stated on the invoice, it has been assigned to Factris Channel S.A..\n\nPlease transfer the above-mentioned outstanding amount immediately to bank account NL30 INGB 0674 563 670, SWIFT INGBNL2A, in the name of Factris Channel S.A., quoting the invoice number.\n\nPlease note: You can only pay directly and in full discharge to Factris Channel S.A.. If payment is not made, we are forced to comply with our notification and registration obligation. We assume that it will not come to that and look forward to your payment with confidence.\n\nIf you have since paid the amount due to Factris, we kindly ask you to notify us immediately and consider this 2nd reminder as not sent.\n\nKind regards.\n\nAlice , Office manager`;
    }
    if (templateName === "REQUEST3") {
      obj.subject = `2nd Payment Request overdue invoice ${invoice.name}`;
      obj.body = `Dear Sir,\n\nOur records show that, despite previous requests on our part, the invoice listed below from our client below has not been paid to Factris to date. The agreed payment period has now expired. As stated on the invoice, it has been assigned to Factris Channel S.A..\n\nPlease transfer the above-mentioned outstanding amount immediately to bank account NL30 INGB 0674 563 670, SWIFT INGBNL2A, in the name of Factris Channel S.A., quoting the invoice number.\n\nPlease note: You can only pay directly and in full discharge to Factris Channel S.A.. If payment is not made, we are forced to comply with our notification and registration obligation. We assume that it will not come to that and look forward to your payment with confidence.\n\nIf you have since paid the amount due to Factris, we kindly ask you to notify us immediately and consider this 2nd reminder as not sent.\n\nKind regards.\n\nAlice , Office manager`;
    }
    if (templateName === "INVOICE_FRENCH") {
      obj.subject = `Facture #${invoice.index}  - Date d’échéance ${new Date(invoice.dueAt).toLocaleDateString("fr")}`;
      obj.body = `Bonjour!\n
Je vous présente la facture #${invoice.index} pour les services/produits fournis à votre entreprise. Le montant total de la facture est de ${invoice.total}€.\n
Veuillez noter que la date d’échéance pour le paiement de cette facture est le ${new Date(invoice.dueAt).toLocaleDateString(
        "fr",
      )}. Nous apprécierions un paiement rapide pour éviter tout intérêt supplémentaire.\n
Veuillez nous faire savoir si vous avez des questions ou des préoccupations concernant cette facture. Nous sommes à votre disposition pour toute clarification ou information supplémentaire.\n
Merci de votre temps et de votre considération.\n

Cordialement,
Alice`;
    }

    if (templateName === "INVOICE_ENGLISH") {
      obj.subject = `Hello ${invoice.name}`;
      obj.body = `Hello!\n
I am writing to present you with Invoice #${invoice.index} for the services/products provided to your company. The total amount of the invoice is ${invoice.total}€.\n
Please note that the due date for payment of this invoice is ${new Date(invoice.dueAt).toLocaleDateString(
        "fr",
      )}. We would appreciate prompt payment to avoid any additional interest.\n
If you have any questions or concerns regarding this invoice, please let us know. We are available to provide any clarification or additional information.\n
Thank you for your time and consideration.\n

Sincerely,
Alice`;
    }

    if (templateName === "REMINDER_FRENCH") {
      obj.subject = `Facture #${invoice.index} - Mise en demeure de paiement`;
      obj.body = `Bonjour!\n
Je vous écris au sujet de la facture #${invoice.index} que je vous ai présentée le ${new Date(invoice.sentAt).toLocaleDateString(
        "fr",
      )}. Malheureusement, nous n’avons pas encore reçu le paiement pour cette facture, qui était dû le ${new Date(invoice.dueAt).toLocaleDateString("fr")}.
Je voulais vérifier si vous avez besoin d’assistance pour effectuer le paiement ou si vous avez des questions concernant la facture. Si vous avez besoin d’un état de compte plus récent ou d’une copie de la facture, n’hésitez pas à nous le faire savoir.\n
Nous apprécierions votre attention rapide à ce sujet afin d’éviter tout intérêt supplémentaire ou des frais de gestion de recouvrement.\n
Merci de votre temps et de votre considération.

Cordialement,
Alice`;
    }

    if (templateName === "REMINDER_ENGLISH") {
      obj.subject = `Invoice #${invoice.index} - Payment Reminder`;
      obj.body = `Hello!\n
I am writing in regards to Invoice #${invoice.index} that I presented to you on ${new Date(invoice.sentAt).toLocaleDateString(
        "fr",
      )}. Unfortunately, we have not yet received payment for this invoice, which was due on ${new Date(invoice.dueAt).toLocaleDateString("fr")}.\n
I wanted to check if you need any assistance in making the payment or if you have any questions regarding the invoice. If you require a more recent statement of account or a copy of the invoice, please let us know.\n
We would appreciate your prompt attention to this matter in order to avoid any additional interest or collection management fees.\n
Thank you for your time and consideration.\n
Sincerely,
Alice`;
    }

    if (templateName === "LAST_REMINDER_ENGLISH") {
      obj.subject = `Invoice #${invoice.index} - Final Payment Reminder`;
      obj.body = `Hello!\n
I am writing regarding Invoice #${invoice.index} for which payment has still not been received. I previously sent you a payment reminder on [Reminder Date].\n
I would like to inform you that we are now considering this payment as overdue and will have to take steps to collect the debt. This may include additional fees for collection management expenses and/or engaging a debt collection agency.\n
Please contact me immediately to discuss this matter and find a mutually agreeable solution.
Thank you for your understanding.

Sincerely,

Alice`;
    }

    if (templateName === "LAST_REMINDER_FRENCH") {
      obj.subject = `Facture #${invoice.index} - Dernière mise en demeure de paiement`;
      obj.body = `Bonjour!\n
Je vous écris au sujet de la facture #${invoice.index} pour laquelle nous n’avons toujours pas reçu de paiement. Je vous avais précédemment envoyé un rappel de paiement le [Reminder Date].\n
Je tiens à vous informer que nous considérons ce paiement comme en souffrance et nous allons devoir entreprendre des mesures pour recouvrir la dette. Cela peut inclure des frais supplémentaires pour les frais de gestion de recouvrement et/ou l’engagement d’une agence de recouvrement de créances.\n
Je vous prie de prendre contact avec moi sans délai pour discuter de ce problème et trouver une solution qui convienne à toutes les parties.\n
Merci de votre compréhension.

Cordialement,

Alice`;
    }

    if (templateName === "PENALTIES_FRENCH") {
      const dayslate = Math.floor((new Date() - new Date(invoice.dueAt)) / (1000 * 60 * 60 * 24));
      const cal = Math.ceil(invoice.total * 0.15 * (dayslate / 365) + 40);

      obj.subject = `Facture #${invoice.index} - Dernière mise en demeure de paiement`;
      obj.body = `Bonjour,\n
Je vous écris au sujet de la facture #${invoice.index} pour laquelle nous n’avons pas reçu de paiement. La facture est échue depuis ${dayslate} jours. Je vous avais précédemment envoyé un rappel de paiement le [Reminder Date].\n
Je tiens à vous informer que nous considérons ce paiement comme en souffrance et nous allons devoir entreprendre des mesures pour recouvrir la dette. Cela peut inclure des frais supplémentaires pour les frais de gestion de recouvrement et l’engagement d’une agence de recouvrement de créances.\n
Je vous informe que les pénalités de retard prévues dans les conditions de la facture seront appliqués jusqu'à réception d'une preuve de paiement. Elles s'élèvent actuellement à ${cal}€.\n
Je vous prie de prendre contact avec moi dès que possible pour établir un plan de paiement si vous n'êtes pas en mesure de régler cette dette dans l'immédiat.\n

Merci de votre compréhension.

Cordialement,

Alice
Office Manager`;
    }

    setMail(obj);
  }

  async function sendInvoice() {
    try {
      if (!mail.recipients.length) return toast.error("Please add recipients");

      if (invoice?.file) {
        await api.post(`/invoice/send/${invoice._id}`, { mail });
      } else {
        const mainElemToPrint = document.getElementById("invoice");
        mainElemToPrint.classList.add("printing");
        let pdfAsString = await html2pdf().set(saveOptions).from(mainElemToPrint).outputPdf("datauristring");
        pdfAsString = pdfAsString.replace("data:application/pdf;filename=generated.pdf;base64,", "");
        mainElemToPrint.classList.remove("printing");
        await api.post(`/invoice/send/${invoice._id}`, { mail, pdf: pdfAsString });
      }

      toast.success("Invoice sent");
      setModalSend(false);
      onDone();
    } catch (error) {
      console.log(error);
      toast.error("Error sending invoice");
    }
  }

  return (
    <div>
      <div className="inline-block relative group w-full">
        <button disabled={!invoice.file || !invoice.taxType} onClick={() => setModalSend((e) => !e)} className="blue-btn w-full">
          Send
        </button>
        {!invoice.file && (
          <span className="absolute z-10 hidden group-hover:block p-2 text-sm text-white bg-gray-700 rounded transform -translate-x-1/2 left-1/2 bottom-full w-56 mb-2 text-center">
            You have to generate your PDF first
          </span>
        )}
        {invoice.file && !invoice.taxType && (
          <span className="absolute z-10 hidden group-hover:block p-2 text-sm text-white bg-gray-700 rounded transform -translate-x-1/2 left-1/2 bottom-full w-56 mb-2 text-center">
            You have to select a tax type first
          </span>
        )}
      </div>
      {modalSend && (
        <Modal isOpen={modalSend} className={"w-2/3"} onClose={() => setModalSend(false)}>
          <div className="flex flex-col gap-2 p-3 pt-5 w-full">
            <MultipleSelectInput value={mail.recipients} label="To" placeholder="Add recipients..." onChange={(e) => setMail({ ...mail, recipients: e })} />
            <MultipleSelectInput value={mail.cc} label="CC" placeholder="Add recipients..." onChange={(e) => setMail({ ...mail, cc: e })} />
            <div className={`w-full`}>
              <div className="px-1 text-sm text-gray-600 font-medium">Subject</div>
              <input
                className="w-full rounded-md border-[1px] bg-[#ffffff] disabled:bg-gray-100 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-sm placeholder:text-gray-300 py-2 px-2"
                name="subject"
                value={mail.subject}
                onChange={(e) => setMail({ ...mail, subject: e.target.value })}
              />
            </div>
            <div className="mt-3">
              <div className="px-1 text-sm text-gray-600 font-medium">Message</div>
              <textarea
                className="w-full rounded-md border-[1px] bg-[#ffffff] disabled:bg-gray-100 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-sm placeholder:text-gray-300 py-2 px-2"
                rows="12"
                name="description"
                value={mail.body}
                onChange={(e) => setMail({ ...mail, body: e.target.value })}
              />
            </div>
            <div className="flex justify-between">
              <a className="underline" href={invoice.file} target="_blank">
                Attachments pdf
              </a>
              <select onChange={(e) => loadTemplate(e.target.value)}>
                <option></option>
                <option key="INVOICE_FRENCH" value="INVOICE_FRENCH">
                  Invoice French
                </option>
                <option key="INVOICE_ENGLISH" value="INVOICE_ENGLISH">
                  Invoice English
                </option>
                <option key="REMINDER_FRENCH" value="REMINDER_FRENCH">
                  Reminder French
                </option>
                <option key="REMINDER_ENGLISH" value="REMINDER_ENGLISH">
                  Reminder English
                </option>
                <option key="LAST_REMINDER_FRENCH" value="LAST_REMINDER_FRENCH">
                  Last Reminder French
                </option>
                <option key="LAST_REMINDER_ENGLISH" value="LAST_REMINDER_ENGLISH">
                  Last Reminder English
                </option>
                <option key="REQUEST1" value="REQUEST1">
                  Load template request 1
                </option>
                <option key="REQUEST2" value="REQUEST2">
                  Load template request 2
                </option>
                <option key="REQUEST3" value="REQUEST3">
                  Load template request 3
                </option>
                <option key="PENALTIES_FRENCH" value="PENALTIES_FRENCH">
                  last reminder + penalties French
                </option>
              </select>
            </div>
            <div className="flex gap-1 items-center w-3/4 h-3/4 min-h-max min-w-max">
              <button
                className="ml-2 bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-2 text-sm border border-blue-500 hover:border-transparent rounded"
                onClick={() => sendInvoice()}>
                Send
              </button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};
