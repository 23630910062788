const CONTRACTS = {
  SELEGO_NL: "SELEGO_NL",
  SELEGO_FR: "SELEGO_FR",
  LE_COLLECTIF: "LE_COLLECTIF",
  FREELANCE: "FREELANCE",
};
const JOB_CONTRACTS = ["CDD", "CDI", "VIE", "INTERNSHIP", "APPRENTICESHIP", "FREELANCE"];
const JOB_POSITIONS = ["FULLSTACK", "CTO", "DATA", "DEVOPS", "PRODUCT", "SALES", "GROWTH", "DESIGN", "OTHER"];
const JOB_LOCATIONS= ["PARIS", "AMSTERDAM", "BARCELONE", "REMOTE", "PARIS/AMSTERDAM/BARCELONE"];

const OFFICE = ["PARIS", "AMSTERDAM", "BARCELONE", "ANY"];

const PAYMENTS = { PENDING: "PENDING", PAID: "PAID", TOPAY: "TOPAY", ERROR: "ERROR" };
const PAYMENT_METHODS = { PAYONEER: "PAYONEER", PAYPAL: "PAYPAL", TRANSFERWISE: "TRANSFERWISE", BANKTRANSFER: "BANKTRANSFER" };
const PERIOD = { MONTHLY: "MONTHLY", DAILY: "DAILY" };

const PROJECT_TYPE_COLORS = {
  prospection: {
    textColor: "text-[#85a29a]",
    backgroundColor: "bg-[#dbfff5]",
  },
  "startup-project": {
    textColor: "text-[#7CB255]",
    backgroundColor: "bg-[#E5FED6]",
  },
  "startup-invest": {
    textColor: "text-[#958f74]",
    backgroundColor: "bg-[#fff5d2]",
  },
  admin: {
    textColor: "text-[#9f8fa2]",
    backgroundColor: "bg-[#fbe7ff]",
  },
};

const PROJECT_GROUP_COLORS = {
  "GROUP A": "text-[#9f8fa2] bg-[#fbe7ff] ring-[#9f8fa2]/20",
  "GROUP B": "text-[#7CB255] bg-[#E5FED6] ring-[#7CB255]/20",
  "GROUP C": "text-[#958f74] bg-[#fff5d2] ring-[#958f74]/20",
  "GROUP D": "text-[#85a29a] bg-[#dbfff5] ring-[#85a29a]/20",
  "GROUP E": "text-red-700 bg-red-50 ring-red-600/20",
  "GROUP F": "text-red-700 bg-red-50 ring-red-600/20",
};

const SUPPORT_LABELS = {
  CONTRACT: "CONTRACT",
  INVOICE: "INVOICE",
  PAYMENT: "PAYMENT",
  TOOLS: "TOOLS",
  RECRUITMENT: "RECRUITMENT",
  ADMIN: "ADMIN",
  FINANCE: "FINANCE"
};

const MONTHS = ["January", "February", "March", "April", "May", "June", "July", "August", "Septembe", "October", "November", "December"];
const YEARS = [2021, 2022, 2023, 2024, 2025];

const CATEGORIES_KEY_WORDS = ["FRONT", "BACK", "AI","MOBILE","DEVOPS","SECURITY","DATA","PRODUCT","ACQUISITION", "GTM", "TOOLS", "DESIGN", "OTHER"];


export { CONTRACTS, PAYMENTS, PERIOD, PAYMENT_METHODS, PROJECT_TYPE_COLORS, PROJECT_GROUP_COLORS, SUPPORT_LABELS, MONTHS, YEARS, JOB_CONTRACTS, JOB_POSITIONS, OFFICE, JOB_LOCATIONS,CATEGORIES_KEY_WORDS };
