import { Routes, Route, Link, useLocation } from "react-router-dom";

import Occupation from "./occupation";
import All from "./all";
import User from "./user";
import Budget from "./budget";
import { useEffect, useState } from "react";

const TABS = [
  { title: "User", href: "/activity" },
  { title: "Budget", href: "/activity/budget" },
  { title: "All people", href: "/activity/all" },
  { title: "Occupation", href: "/activity/occupation" },
];

const Activity = () => {
  return (
    <div className="py-12 px-6 space-y-4">
      <TabBar tabs={TABS} />

      <Routes>
        <Route path="/" element={<User />} />
        <Route path="/user" element={<User />} />
        <Route path="/budget" element={<Budget />} />
        <Route path="/all" element={<All />} />
        <Route path="/occupation" element={<Occupation />} />
      </Routes>
    </div>
  );
};

const TabBar = ({ tabs }) => (
  <div className="overflow-x-scroll">
    <nav className="bg-white rounded-md flex item-center gap-1 w-full border border-neutral-300 p-1">
      {tabs.map((tab, i) => (
        <TabItem key={i} href={tab.href} title={tab.title} />
      ))}
    </nav>
  </div>
);

const TabItem = ({ href, title }) => {
  const location = useLocation();
  const [active, setActive] = useState(false);

  useEffect(() => {
    setActive(location.pathname === href);
  }, [location.pathname]);

  return (
    <Link to={href} className={`${active ? "blue-btn" : "transparent-btn"} relative rounded py-2 px-4 flex items-center gap-2`}>
      <div className="flex items-center gap-2 truncate">{title}</div>
    </Link>
  );
};

export default Activity;
