import Table from "../../../../../components/Table";
import Paginator from "../../../../../components/Paginator";
import { useLocation, useNavigate } from "react-router-dom";

const NotionTable = ({ total, notions, filters, setFilters, setSelectedNotion, loading }) => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const query = new URLSearchParams(search);

  const handleSort = (key) => {
    setFilters({ ...filters, sort: { [key]: !filters.sort[key] ? 1 : filters.sort[key] === 1 ? -1 : undefined } });
  };

  const PriorityTag = ({ item }) => {
    if (!item?.priority) return null;
    const colors = {
      P0: "bg-green-100 text-xs text-green-800",
      P1: "bg-yellow-100 text-xs text-yellow-800",
      P2: "bg-orange-100 text-xs text-orange-800",
      P3: "bg-red-100 text-xs text-red-800",
    };
    return <div className={`px-2 py-1 rounded w-fit ${colors[item?.priority]}`}>{item?.priority}</div>;
  };
  const CategoryTag = ({ item }) => {
    if (!item?.category) return null;
    const colors = {
      FEATURE: "bg-blue-100 text-xs text-blue-800",
      BUG: "bg-red-100 text-xs text-red-800",
      ITERATION: "bg-lime-100 text-xs text-lime-800",
    };
    return <div className={`px-2 py-1 rounded w-fit ${colors[item?.category]}`}>{item?.category}</div>;
  };
  const StatusTag = ({ item }) => {
    if (!item?.status) return null;
    const colors = {
      TODO: "bg-gray-200 text-xs text-gray-800",
      READY_TO_DEV: "bg-pink-100 text-xs text-pink-800",
      IN_PROGRESS: "bg-blue-100 text-xs text-blue-800",
      TESTING: "bg-purple-100 text-xs text-purple-800",
      DONE: "bg-green-100 text-xs text-green-800",
    };
    return <div className={`px-2 py-1 rounded w-fit ${colors[item?.status]}`}>{item?.status}</div>;
  };

  return (
    <>
      <Table
        sort={filters.sort}
        onSort={handleSort}
        total={total}
        header={[
          { title: "#", key: "index" },
          { title: "Name", key: "name" },
          { title: "Status", key: "status" },
          { title: "Priority", key: "priority" },
          { title: "Category", key: "category" },
          { title: "Budget", key: "budget_name" },
          { title: "Hours", key: "estimation_hours" },
          { title: "Price", key: "estimated_price" },
          { title: "Assignee", key: "users" },
          { title: "Created at", key: "created_at" },
          { title: "Creator", key: "user_name" },
        ]}
        loading={loading}
        height="h-[32rem]"
        sticky={true}
        noResultRender={<div className="w-full bg-white text-center p-2 my-5 rounded shadow-sm">Aucune résultat n'a été trouvé</div>}>
        {notions.map((item, index) => {
          return (
            <tr
              className={`${index % 2 === 0 ? "bg-gray-100" : "bg-gray-50"} cursor-pointer`}
              key={item._id}
              onClick={() => {
                query.set("task_modal_id", item._id);
                navigate({ search: query.toString() });
              }}>
              <td className="border hover:bg-gray-200 px-4 py-2 max-w-xs truncate">{item.index}</td>
              <td className="border hover:bg-gray-200 px-4 py-2 max-w-xs truncate">{item.name}</td>
              <td className="border hover:bg-gray-200 px-4 py-2 max-w-xs truncate">
                <StatusTag item={item} />
              </td>
              <td className="border hover:bg-gray-200 px-4 py-2 max-w-xs truncate">
                <PriorityTag item={item} />
              </td>
              <td className="border hover:bg-gray-200 px-4 py-2 max-w-xs truncate">
                <CategoryTag item={item} />
              </td>
              <td className="border hover:bg-gray-200 px-4 py-2 max-w-xs truncate">{item.budget_name}</td>
              <td className="border hover:bg-gray-200 px-4 py-2 max-w-xs truncate">{item.estimated_hours}</td>
              <td className="border hover:bg-gray-200 px-4 py-2 max-w-xs truncate">{item.estimated_price?.toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}</td>
              <td className="border hover:bg-gray-200 px-4 py-2 max-w-xs truncate">
                <div className="flex gap-1 flex-wrap">
                  {item.users?.map((e) => (
                    <img key={e.user_avatar} className="h-6 w-6 rounded-full" src={e.user_avatar} alt={e.user_name} />
                  ))}
                </div>
              </td>
              <td className="border hover:bg-gray-200 px-4 py-2 max-w-xs truncate">{new Date(item.created_at).toLocaleString()}</td>
              <td className="border hover:bg-gray-200 px-4 py-2 max-w-xs truncate">
                <div className="flex gap-1 flex-wrap">
                  <img key={item.user_avatar} className="h-6 w-6 rounded-full" src={item.user_avatar} alt={item.user_name} />
                </div>
              </td>
            </tr>
          );
        })}
      </Table>
      <div className="mt-1">
        <Paginator page={filters.page} setPage={(page) => setFilters({ ...filters, page })} last={Math.ceil(total / 100)} />
      </div>
    </>
  );
};

export default NotionTable;
