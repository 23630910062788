import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import Board from "react-trello";
import Card from "./kanban_card";
import api from "../../../../../services/api";

export default ({ total, notions, setNotions, filters, loading }) => {
  const KANBAN_LANES = ["TODO", "READY_TO_DEV", "IN_PROGRESS", "TESTING", "DONE"];

  const KANBAN_LANES_COLORS = {
    TODO: "#f3f4f6",
    READY_TO_DEV: "#fff0f6",
    IN_PROGRESS: "#e0f2fe",
    TESTING: "#f3e8ff",
    DONE: "#dcfce7",
  };

  const StatusTag = ({ status }) => {
    if (!status) return null;
    const colors = {
      TODO: "bg-gray-200 text-xs text-gray-800 border-gray-300",
      READY_TO_DEV: "bg-pink-100 text-xs text-pink-800 border-pink-200",
      IN_PROGRESS: "bg-blue-100 text-xs text-blue-800 border-blue-200",
      TESTING: "bg-purple-100 text-xs text-purple-800 border-purple-200",
      DONE: "bg-green-100 text-xs text-green-800 border-green-200",
    };
    return <div className={`px-2 py-1 rounded w-fit border ${colors[status]}`}>{status}</div>;
  };

  const computeTotalCost = (items) => {
    return items.reduce((acc, item) => acc + (item.estimated_price || 0), 0);
  };

  return (
    <div className="h-full w-full border border-gray-200 rounded overflow-hidden">
      {notions.length > 0 && (
        <Board
          components={{ Card }}
          style={{ backgroundColor: "white", overflow: "scroll", height: "100%", width: "100%" }}
          laneStyle={{ overflow: "scroll" }}
          hideCardDeleteIcon
          data={{
            lanes: KANBAN_LANES.map((category) => ({
              id: category,
              title: (
                <div className="flex items-center gap-2">
                  <StatusTag status={category} />
                  <div className="text-gray-600 text-xs py-1 font-semibold ml-4">{notions.filter((t) => t.status === category).length} items</div>
                  <div className="text-gray-600 text-xs py-1 font-semibold ml-2">
                    {computeTotalCost(notions.filter((t) => t.status === category)).toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}
                  </div>
                </div>
              ),
              cards: notions
                .filter((t) => t.status === category)
                .sort((a, b) => a.priority?.localeCompare(b.priority))
                .map((n) => ({ notion: n, id: n._id })),
              style: { backgroundColor: KANBAN_LANES_COLORS[category] },
            })),
          }}
          onCardMoveAcrossLanes={async (fromLaneId, toLaneId, cardId) => {
            const { data, ok } = await api.put(`/notion/${cardId}`, { status: toLaneId });
            if (!ok) return toast.error("Something went wrong");
            setNotions((prev) => prev.map((n) => (n._id === cardId ? data : n)));
            toast.success("Task moved successfully");
          }}
        />
      )}
    </div>
  );
};
