import React, { Fragment, useEffect, useState, useRef } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import { HiX } from "react-icons/hi";

import Loader from "../loader";

import api from "../../services/api";
import { classNames } from "../../utils";

export default () => {
  const [open, setOpen] = useState(false);
  const [answers, setAnswers] = useState([]);
  const [current, setCurrent] = useState(0);
  const cancelButtonRef = useRef();
  const [offset, setOffset] = useState(0);

  const user = useSelector((state) => state.Auth.user);

  useEffect(() => {
    if (!user) return;
    load();
  }, [user]);

  const load = async () => {
    const obj = {};
    obj.user_id = user._id;
    obj.status = "pending";
    const { data } = await api.post("/answer/search", obj);
    setAnswers(data);
    if (!!data.length) setOpen(true);
  };

  async function onPrevious() {
    setCurrent(current <= 0 ? 0 : current - 1);
  }

  async function onNext(answer) {
    const { data } = await api.put(`/answer/${answer._id}`, { ...answer, status: "done" });
    const index = answers.findIndex((a) => a._id === answer._id);
    answers[index].value = answer.value;

    if (current === answers.length - 1) {
      toast.success("Thank you !");
      return setOpen(false);
    }
    setCurrent(current >= answers.length ? answers.length : current + 1);
  }

  const arr = [];
  answers.map((answer) => arr.push(<Question answer={answer} onNext={onNext} onPrevious={onPrevious} />));

  if (!open) return null;

  return (
    <Transition show={open} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-50 overflow-y-auto bg-black bg-opacity-30 backdrop-blur-sm" initialFocus={cancelButtonRef} open={open} onClose={() => {}}>
        <div className="min-h-screen md:px-4">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <Dialog.Overlay className="fixed inset-0" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95">
            <div className={`bg-white rounded-lg absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 max-h-screen max-w-xl w-full p-6 overflow-auto`}>
              <div className="absolute top-2 right-2 " onMouseEnter={() => setOffset(-30)} onMouseLeave={() => setOffset(0)}>
                <button className="sr-only" ref={cancelButtonRef} />
                <HiX
                  className={`text-xl text-gray-500 transition-colors  hover:text-red-500`}
                  style={{ translate: offset }}
                  onClick={() => toast.success("CONGRATS, YOU CLICKED IT!")}
                />
              </div>
              <div className="pt-2">
                {answers.length > 1 ? (
                  <div className="mb-6 flex items-center justify-between">
                    <div className="text-sm font-medium">
                      Answering: {current + 1}/{answers.length}
                    </div>
                  </div>
                ) : null}
                {arr[current]}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

const Question = ({ answer, onNext, onPrevious }) => {
  const [value, setValue] = useState();
  const [loading, setLoading] = useState(true);
  const [question, setQuestion] = useState(null);

  const getQuestion = async () => {
    setLoading(true);
    const { data } = await api.get(`/question/${answer.question_id}`);
    console.log("✌️  resQuestion.data", data);
    setQuestion(data);
    setLoading(false);
  };

  useEffect(() => {
    setValue(answer.value);
    getQuestion();
  }, [answer]);

  if (loading) return <Loader />;

  return (
    <div>
      <h5 className="text-base font-bold">{question.title}</h5>
      <h5 className="mt-2 text-sm font-normal">{question.description}</h5>
      <div className="my-10" />
      <Options question={question} value={value} onChange={setValue} />
      <div className="flex items-center justify-between mt-6">
        <button className="btn gray-btn mt-4" onClick={onPrevious}>
          Previous
        </button>
        <button disabled={!value || loading} className="btn btn-primary mt-4" onClick={() => onNext({ ...answer, value })}>
          Next
        </button>
      </div>
    </div>
  );
};

const Options = ({ question, value, onChange }) => {
  return (
    <div className="flex flex-col items-start justify-around gap-1">
      {question.options.map((score) => {
        const isShining = score === value;
        return (
          <div
            className={classNames(
              "px-2 py-2 flex border border-gray-300 justify-center items-center rounded-lg text-xs cursor-pointer hover:bg-primary hover:text-white",
              isShining ? "bg-primary text-white" : "text-black",
            )}
            onClick={() => onChange(score)}>
            {score}
          </div>
        );
      })}
    </div>
  );
};
