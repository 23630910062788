import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import api from "../../../services/api";
import Select from "../../../components/Select";
import { RiDeleteBin4Line } from "react-icons/ri";
import { useSelector } from "react-redux";

const Perks = ({ project, setProject }) => {
  return (
    <div className="py-8">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">Perks</h2>
      </div>

      <ul className="flex flex-col gap-1">
        <li className="w-full list-none flex flex-row gap-4 px-4 mb-2">
          <h3 className="w-40 text-gray-800 text-sm mb-0">Name</h3>
          <h3 className="flex-1 text-gray-800 text-sm mb-0">Description</h3>
          <h3 className="w-40 text-gray-800 text-sm mb-0">Amount</h3>
          <h3 className="w-40 text-gray-800 text-sm">Status</h3>
        </li>
        {(project.perks || []).length === 0 ? (
          <div className="text-gray-800 text-center w-full mt-4">No perks</div>
        ) : (
          (project.perks || []).map((p, i) => <Item key={i} perk={p} project={project} setProject={setProject} />)
        )}
      </ul>
    </div>
  );
};

const Item = ({ perk, project, setProject }) => {
  const user = useSelector((state) => state.Auth.user);

  const handleUpdatePerk = async (value) => {
    try {
      const { ok, data } = await api.put(`/project/${project._id}`, { perks: project.perks.map((p) => (p.perkId === perk.perkId ? { ...p, perkStatus: value } : p)) });
      if (ok) {
        setProject(data);
        toast.success("Perk updated");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <li className="list-none px-4 py-2 flex flex-col gap-4 md:flex-row items-center w-full rounded border border-gray-200 text-sm text-gray-700 hover:border-sky-700 appearance-none">
      <div className="w-40  flex flex-col items-start justify-center gap-2">
        <img src={perk?.perkLogo} alt="perk" className="w-7 h-7 md:w-14 md:h-14 bg-gray-50 object-contain rounded-md" />
        <div className="flex-1 text-xs">{perk.perkName}</div>
      </div>
      <div className="flex-1 text-sm">{perk.perkDescription}</div>
      <div className="w-40 text-sm">{perk.amount.toLocaleString("fr", { style: "currency", currency: "EUR" })}</div>
      <div className="w-40">
        {user.role !== "client" ? (
          <Select
            nullable={false}
            width="w-40"
            options={["USED", "UNUSED", "REQUESTED"]}
            value={perk.perkStatus}
            onChange={(value) => handleUpdatePerk(value)}
            getLabel={(status) => <Status status={status} />}
          />
        ) : (
          <Status status={perk.perkStatus} />
        )}
      </div>
    </li>
  );
};

const Status = ({ status }) => {
  let bgColor = "bg-gray-100";
  let textColor = "text-gray-600";
  if (status === "USED") {
    bgColor = "bg-blue-100";
    textColor = "text-blue-600";
  }
  if (status === "REQUESTED") {
    bgColor = "bg-purple-100";
    textColor = "text-purple-600";
  }
  return <span className={`inline-flex items-center gap-x-1.5 rounded-md ${bgColor} px-2 py-1 text-xs font-medium ${textColor}`}>{status}</span>;
};

export default Perks;
