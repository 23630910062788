import React, { useEffect, useState } from "react";
import api from "../../../services/api";
import Loader from "../../../components/loader";
import toast from "react-hot-toast";

const Stats = ({ filters }) => {
  const [stats, setStats] = useState({ categories: [], negpos: { positive: 0, negative: 0 }, tva: { nl: 0, fr: 0, other: 0 }, total: 0 });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const query = {};
        if (filters.search) query.search = filters.search;
        if (filters.project.length) query.project_id = filters.project;
        if (filters.status) query.status = filters.status;
        if (filters.card) query.card_id = filters.card;
        if (filters.banq) query.source = filters.banq;
        if (filters.category) query.category = filters.category;
        if (filters.sub_category) query.sub_category = filters.sub_category;

        if (filters.from_date) query.startDate = filters.from_date;
        if (filters.to_date) query.endDate = filters.to_date;
        if (filters.from_price) query.priceMin = filters.from_price;
        if (filters.to_price) query.priceMax = filters.to_price;

        if (filters.page) query.page = filters.page;
        if (filters.per_page) query.per_page = filters.per_page;

        const { ok, data, total } = await api.post(`/bank/stats`, query);
        if (!ok) throw new Error("Error while fetching stats of banks");

        const categories = [];
        categories.push({ name: "BANK", value: data.categories.find((e) => e._id === "BANK")?.total || 0 });
        categories.push({ name: "HOSTING", value: data.categories.find((e) => e._id === "HOSTING")?.total || 0 });
        categories.push({ name: "ADS", value: data.categories.find((e) => e._id === "ADS")?.total || 0 });
        categories.push({ name: "SALARY", value: data.categories.find((e) => e._id === "SALARY")?.total || 0 });
        categories.push({ name: "TOOLS", value: data.categories.find((e) => e._id === "TOOLS")?.total || 0 });
        categories.push({ name: "OFFICE", value: data.categories.find((e) => e._id === "OFFICE")?.total || 0 });
        categories.push({ name: "ACCOUNTING", value: data.categories.find((e) => e._id === "ACCOUNTING")?.total || 0 });
        categories.push({ name: "INVOICE", value: data.categories.find((e) => e._id === "INVOICE")?.total || 0 });
        categories.push({ name: "TRANSFER", value: data.categories.find((e) => e._id === "TRANSFER")?.total || 0 });
        categories.push({ name: "TAX", value: data.categories.find((e) => e._id === "TAX")?.total || 0 });
        categories.push({ name: "TRAVEL", value: data.categories.find((e) => e._id === "TRAVEL")?.total || 0 });
        categories.push({ name: "FOODANDBEVERAGE", value: data.categories.find((e) => e._id === "FOODANDBEVERAGE")?.total || 0 });
        categories.push({ name: "OTHER", value: data.categories.find((e) => e._id === "")?.total || 0 });

        const negpos = { negative: data.negpos.find((e) => e._id === "negative")?.total || 0, positive: data.negpos.find((e) => e._id === "positive")?.total || 0 };
        const tva = {
          nl: data.tva.find((e) => e._id === "Netherland")?.total || 0,
          fr: data.tva.find((e) => e._id === "France")?.total || 0,
          other: data.tva.find((e) => e._id === "Other")?.total || 0,
        };
        setStats({ categories, negpos, tva, total });
      } catch (error) {
        console.error(error);
        toast.error("Error while fetching stats of banks");
      }
      setLoading(false);
    };
    fetchData();
  }, [filters]);

  return (
    <>
      <section className="grid grid-cols-6 gap-4">
        <MainItem label="Mutations" value={stats?.total.toLocaleString("fr")} loading={loading} />
        <MainItem
          label="Sum total"
          value={stats?.categories.reduce((prev, acc) => (acc.value || 0) + prev, 0).toLocaleString("fr", { style: "currency", currency: "EUR" })}
          unit="€"
          loading={loading}
        />
        <MainItem label="Sum negative" value={stats?.negpos.negative.toLocaleString("fr", { style: "currency", currency: "EUR" })} loading={loading} />
        <MainItem label="Sum positive" value={stats?.negpos.positive.toLocaleString("fr", { style: "currency", currency: "EUR" })} loading={loading} />
        <MainItem label="TVA NL" value={stats?.tva.nl.toLocaleString("fr", { style: "currency", currency: "EUR" })} loading={loading} />
        <MainItem label="TVA FR" value={stats?.tva.fr.toLocaleString("fr", { style: "currency", currency: "EUR" })} loading={loading} />
        <MainItem label="TVA Other" value={stats?.tva.other.toLocaleString("fr", { style: "currency", currency: "EUR" })} loading={loading} />
      </section>
      <section className="grid grid-cols-7 gap-x-4 gap-y-2">
        {stats.categories.map((e, i) => (
          <SecondaryItem key={i} label={e.name} value={(e.value || 0).toLocaleString("fr", { style: "currency", currency: "EUR" })} loading={loading} />
        ))}
      </section>
    </>
  );
};

const MainItem = ({ label, value, loading }) => {
  return (
    <div className="flex flex-col items-center justify-center border border-gray-300 rounded p-2">
      <label className="w-full text-left text-sm font-medium">{label}</label>
      {loading ? <Loader size="small" /> : <span className="w-full text-left text-xl font-semibold">{value}</span>}
    </div>
  );
};

const SecondaryItem = ({ label, value, loading }) => {
  return (
    <div className="w-full flex flex-row items-center justify-between border border-gray-300 rounded p-2">
      <label className="text-xs font-medium truncate">{label}</label>
      {loading ? <Loader size="small" /> : <span className="text-sm font-semibold">{value}</span>}
    </div>
  );
};

export default Stats;
