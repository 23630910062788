import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

import api from "../../../../services/api";

export default ({ contact, setContact }) => {
  const user = useSelector((state) => state.Auth.user);

  const [values, setValues] = useState(contact);

  const handleDelete = async () => {
    const confirm = window.confirm("Are you sure ?");
    if (!confirm) return;
    try {
      await api.remove(`/s_contact/${contact._id}`);
      toast.success("successfully removed!");
      setContact(null);
    } catch (error) {}
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const { data } = await api.put(`/s_contact/${user._id}`, values);
      setContact(data);
      toast.success("Updated!");
    } catch (e) {
      console.log(e);
      toast.error("Some Error!");
    }
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      <div>
        <form onSubmit={handleSubmit} className="p-6 space-y-6">
          <div className="space-y-4">
            <div className="space-y-2">
              <input className="input w-full" type="text" name="first_name" value={values.first_name} onChange={(e) => setValues({ ...values, first_name: e.target.value })} />
            </div>
            <div className="space-y-2">
              <input className="input w-full" type="text" name="last_name" value={values.last_name} onChange={(e) => setValues({ ...values, last_name: e.target.value })} />
            </div>
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700" htmlFor="notes">
                Notes
              </label>
              <textarea rows={4} className="input w-full" type="text" name="notes" value={values.notes} onChange={(e) => setValues({ ...values, notes: e.target.value })} />
            </div>
            <div className="flex space-x-4">
              <button type="submit" className="btn btn-primary">
                Save
              </button>
              <button type="button" className="btn btn-danger" onClick={handleDelete}>
                Delete
              </button>
            </div>
          </div>
        </form>
      </div>
      <div>
        <Activities contact={contact} />
      </div>
    </div>
  );
};

const Activities = ({ contact }) => {
  const [activities, setActivities] = useState([]);

  useEffect(() => {
    get();
  }, [contact]);

  async function get() {
    const { data } = await api.post(`/s_activity/search`, { contact_id: contact._id });
    setActivities(data);
  }

  console.log(`activities`, activities);

  return (
    <div className="p-6">
      <div className="space-y-4">
        {activities.map((activity) => (
          <div key={activity._id} className="border p-4 rounded flex justify-between items-center">
            <div className="font-semibold">{activity.type}</div>
            <div className="text-sm text-gray-500">{new Date(activity.created_at).toLocaleString()}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

