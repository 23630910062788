import React, { useEffect } from "react";

import amplitude from "../../../../services/amplitude";

const Complete = () => {
  useEffect(() => {
    amplitude.logEvent("complete");
  }, []);

  return (
    <div className="py-4 px-8">
      <div className="bg-white rounded-xl p-4">
        <h1 className="flex justify-center text-dull-black text-2xl font-semibold mb-1">Your application has been registered!</h1>
        <p className="flex justify-center text-gray-400 text-base">If your profile matches one or more of our clients, we’ll get in contact.</p>
      </div>
    </div>
  );
};

export default Complete;
