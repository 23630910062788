import React, { useState, useEffect } from "react";
import RichTextEditor from "react-rte";
import toast from "react-hot-toast";

import Modal from "./modal";
import LoadingButton from "./loadingButton";

import { GptIcon } from "../assets/Icons";

import API from "../services/api";

export default ({ title, onChange, reload, value, className, defaultPrompt, name = "default" }) => {
  const [text, setText] = useState("");

  // initialize the value of the text editor
  // the text editor is buggy when used direct state
  useEffect(() => {
    setText(value);
  }, [reload]);

  return (
    <div>
      {title && <div className="text-base font-medium mb-2">{title}</div>}
      <Editor value={text} onChange={onChange} name={name} className={className} defaultPrompt={defaultPrompt} />
    </div>
  );
};

const Editor = ({ onChange, value, className, name = "default", defaultPrompt }) => {
  const [str, setStr] = useState(null);
  const [aiModal, setAIModal] = useState(false);

  useEffect(() => {
    setStr(RichTextEditor.createValueFromString(value || "", "html"));
  }, [value]);

  function update(v) {
    setStr(v);
    if (onChange) onChange({ target: { value: v.toString("html"), name } });
  }

  if (str === null) return <div>Loading ...</div>;

  const toolbarConfig = {
    display: ["INLINE_STYLE_BUTTONS", "BLOCK_TYPE_BUTTONS", "BLOCK_ALIGNMENT_BUTTONS", "LINK_BUTTONS", "BLOCK_TYPE_DROPDOWN", "HISTORY_BUTTONS"],
    INLINE_STYLE_BUTTONS: [
      { label: "Bold", style: "BOLD", className: "custom-css-class" },
      { label: "Italic", style: "ITALIC" },
      { label: "Underline", style: "UNDERLINE" },
    ],
    BLOCK_TYPE_DROPDOWN: [
      { label: "Normal", style: "unstyled" },
      { label: "Inline Title", style: "header-three" },
    ],
    BLOCK_TYPE_BUTTONS: [
      { label: "UL", style: "unordered-list-item" },
      { label: "OL", style: "ordered-list-item" },
    ],
    BLOCK_ALIGNMENT_BUTTONS: [
      { label: "Align Left", style: "ALIGN_LEFT" },
      { label: "Align Center", style: "ALIGN_CENTER" },
      { label: "Align Right", style: "ALIGN_RIGHT" },
      { label: "Align Justify", style: "ALIGN_JUSTIFY" },
    ],
  };

  const AIButton = () => (
    <button className="border border-blue-600 text-blue-600 p-2 py-1.5 text-xs" onClick={() => setAIModal(true)}>
      <GptIcon />
    </button>
  );

  return (
    <div className="w-full" style={{ height: "100%" }}>
      <RichTextEditor
        customControls={[AIButton]}
        style={{ height: "300px" }}
        name={name}
        onChange={update}
        value={str}
        className={className + " richtexteditor input"}
        toolbarConfig={toolbarConfig}
      />
      {aiModal && (
        <AIModal
          onClose={() => setAIModal(false)}
          defaultPrompt={defaultPrompt}
          onChange={(v) => {
            const val = value + "\n\n ---- AI Generated ---- \n\n" + v;
            update(RichTextEditor.createValueFromString(val.toString("html"), "html"));
          }}
        />
      )}
    </div>
  );
};

const AIModal = ({ onClose, onChange, defaultPrompt = "" }) => {
  const [loading, setLoading] = useState(false);
  const [prompt, setPrompt] = useState(defaultPrompt);

  async function handleGenerate() {
    try {
      setLoading(true);
      const { ok, data, code } = await API.post(`/gpt`, { prompt });
      if (!ok) return toast.error(`Error !\n${code}`, { icon: "❌" });
      const val = data.replaceAll("\n", "<br/>");
      onChange(val);
      setLoading(false);
      onClose();
    } catch (e) {
      console.log(e);
      toast.error("Some Error!", e.code);
    }
  }

  return (
    <Modal isOpen={true} onClose={onClose} className="max-w-4xl w-full p-4">
      <div className="bg-white rounded-2xl font-bold">Prompt</div>
      <textarea className="border block w-full h-[200px] my-4" value={prompt} onChange={(e) => setPrompt(e.target.value)} />
      <div>
        <LoadingButton loading={loading} className="btn btn-primary" onClick={handleGenerate}>
          Generate
        </LoadingButton>
      </div>
    </Modal>
  );
};
