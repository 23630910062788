import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import Loader from "../../components/loader";

import api from "../../services/api";

export default () => {
  const [devices, setDevices] = useState();
  const navigate = useNavigate();
  const { search } = useLocation();
  const query = new URLSearchParams(search);

  useEffect(() => {
    (async () => {
      const { data } = await api.get("/device");
      setDevices(data);
    })();
  }, []);

  const renderStatus = (device) => {
    if (device.status === "active") {
      return (
        <div className="flex gap-2 items-center w-full">
          <div className="w-2 h-2 rounded-full bg-green-400" />
          <div className="text-xs tracking-wider text-green-400">{device.status}</div>
        </div>
      );
    } else if (device.status === "inactive") {
      return (
        <div className="flex gap-2 items-center w-full">
          <div className="w-2 h-2 rounded-full bg-red-400" />
          <div className="text-xs tracking-wider text-red-400">{device.status}</div>
        </div>
      );
    }
  };

  if (!devices) return <Loader size="small" />;

  return (
    <div className="p-3">
      <div className="flex items-center justify-between mb-3">
        <p>count : {devices.length}</p>
        <a href="/device/add" className="px-3 py-1 rounded-md text-white bg-primary">
          Add device
        </a>
      </div>
      <div className="bg-white rounded-lg">
        <table className="min-w-full divide-y divide-gray-200 table-fixed">
          <thead>
            <tr className="border-b border-gray-300">
              <th scope="col" className="px-4 py-3 text-xs font-bold text-left text-gray-500 uppercase">
                Nom
              </th>
              <th scope="col" className="px-4 py-3 text-xs font-bold text-left text-gray-500 uppercase">
                Model
              </th>
              <th scope="col" className="px-4 py-3 text-xs font-bold text-left text-gray-500 uppercase">
                Serial number
              </th>
              <th scope="col" className="px-4 py-3 text-xs font-bold text-left text-gray-500 uppercase">
                Status
              </th>
              <th scope="col" className="px-4 py-3 text-xs font-bold text-left text-gray-500 uppercase">
                User / Office
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {devices.map((d) => (
              <tr className="hover:bg-blue-50">
                <td className="px-4 py-2 text-sm font-medium text-gray-600 ">
                  <Link className={`hover:underline cursor-pointer ${!d.name && "italic"}`} to={`/device/${d._id.toString()}`}>
                    {d.name || "No name"}
                  </Link>
                </td>
                <td className="px-4 py-2 text-sm font-medium text-gray-600 ">{d.model}</td>
                <td className="px-4 py-2 text-sm font-medium text-gray-600">{d.serial_number}</td>
                <td className="px-4 py-2 text-sm font-medium text-gray-600">{renderStatus(d)}</td>
                <td className="px-4 py-2 text-sm font-medium text-gray-600">
                  {d.userId && (
                    <div
                      className="flex items-center gap-2 group cursor-pointer"
                      onClick={() => {
                        query.set("user_modal_id", d.userId);
                        navigate({ search: query.toString() });
                      }}>
                      <img src={d.userAvatar} alt="mac-logo" className="w-6 h-6 rounded-full" />
                      <div className="group-hover:underline">{d.userName}</div>
                    </div>
                  )}
                  {d.office && (
                    <div className="flex items-center gap-2 group cursor-pointer">
                      <p className="group-hover:underline font-bold">{d.office}</p>
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
