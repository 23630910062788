import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import { IoIosInformationCircleOutline } from "react-icons/io";

import api from "../../services/api";
import Comments from "./Comments";
import SelectUsers from "../selectUser";
import SelectBudget from "../SelectBudget";

export default ({ notion, setNotion }) => {
  const user = useSelector((state) => state.Auth.user);

  const getSuggestedEstimatedHours = (effort_tshirt_size) => {
    const tshirtSizes = {
      XS: 0.5,
      S: 1,
      M: 2,
      L: 4,
      XL: 8,
    };
    return tshirtSizes[effort_tshirt_size] || 0;
  };

  return (
    <div className="flex mx-2 gap-2">
      <div className="w-1/3">
        <div className="relative w-full max-w-2xl mx-auto bg-white px-4 py-2 border border-gray-200 rounded-md">
          <div className="text-xs font-semibold mb-1">Category</div>
          <div className="mb-2">
            <SelectCategory
              value={notion.category}
              onChange={async (e) => {
                const { data } = await api.put(`/notion/${notion._id}`, { category: e.target.value });
                setNotion(data);
                toast.success("Category updated!");
              }}
            />
          </div>
          <div className="text-xs font-semibold mb-1">Priority</div>
          <div className="mb-2">
            <SelectPriority
              value={notion.priority}
              onChange={async (e) => {
                const { data } = await api.put(`/notion/${notion._id}`, { priority: e.target.value });
                setNotion(data);
                toast.success("Priority updated!");
              }}
            />
          </div>
          <div className="text-xs font-semibold mb-1">Budget</div>
          <div className="mb-2">
            <SelectBudget
              value={{ name: notion.budget_name, _id: notion.budget_id }}
              onChange={async (e) => {
                const { data } = await api.put(`/notion/${notion._id}`, { budget_name: e.name, budget_id: e._id });
                setNotion(data);
                toast.success("Budget updated!");
              }}
              projectId={notion.project_id}
            />
          </div>

          <div className="text-xs font-semibold mb-1">Effort</div>
          <div className="mb-2">
            <select
              className="input"
              value={notion.effort_tshirt_size}
              onChange={async (e) => {
                const effort_tshirt_size = e.target.value;
                const estimated_hours = getSuggestedEstimatedHours(effort_tshirt_size);
                const estimated_price = ((estimated_hours * 500) / 8).toFixed(2);
                const { data } = await api.put(`/notion/${notion._id}`, { effort_tshirt_size, estimated_hours, estimated_price });
                setNotion(data);
                toast.success("Cost updated!");
              }}>
              <option value="">No value</option>
              <option value="XS">XS (30 min)</option>
              <option value="S">S (1h)</option>
              <option value="M">M (2h)</option>
              <option value="L">L (4h)</option>
              <option value="XL">XL (+8h)</option>
            </select>
          </div>
          <div className="text-xs font-semibold mb-1">Estimated hours</div>
          <div className="mb-2">
            <Hours
              value={notion.estimated_hours}
              onChange={async (estimated_hours) => {
                const estimated_price = ((estimated_hours * 500) / 8).toFixed(2);
                const { data } = await api.put(`/notion/${notion._id}`, { estimated_hours, estimated_price });
                setNotion(data);
                toast.success("Hours updated!");
              }}
            />
          </div>
          <div className="flex gap-x-1 text-xs font-semibold mb-1 items-center" data-tip data-for={`estimated-price`}>
            Estimated price
            <IoIosInformationCircleOutline />
          </div>
          <ReactTooltip id={`estimated-price`}>
            <span>Based on a 500€ daily rate</span>
          </ReactTooltip>
          <div className="mb-2">
            <input
              type="number"
              className="w-full rounded-md border-[1px] border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-sm placeholder:text-gray-300 py-2 px-2 disabled:bg-gray-100"
              value={notion.estimated_price}
              disabled
              onChange={async (e) => {
                const { data } = await api.put(`/notion/${notion._id}`, { estimated_price: e });
                setNotion(data);
                toast.success("Price updated!");
              }}
            />
          </div>
          <div className="text-xs font-semibold mb-1">Status</div>
          <div className="mb-2">
            <select
              className="input"
              value={notion.status}
              onChange={async (e) => {
                const { data } = await api.put(`/notion/${notion._id}`, { status: e.target.value });
                setNotion(data);
                toast.success("Status updated!");
              }}>
              <option value="">No status</option>
              <option value="TODO">TODO</option>
              <option value="READY_TO_DEV">READY_TO_DEV</option>
              <option value="IN_PROGRESS">IN_PROGRESS</option>
              <option value="TESTING">TESTING</option>
              <option value="DONE">DONE</option>
            </select>
          </div>

          <div className="text-xs font-semibold mb-1">Assignees</div>
          <div className="">
            <div className="flex items-center w-full gap-2 flex-wrap">
              {notion.users.map((e) => (
                <div key={e.user_avatar} className="group relative">
                  <span className=" opacity-0 group-hover:opacity-100 absolute -top-2 -right-2">x</span>
                  <img
                    className="h-6 w-6 rounded-full"
                    src={e.user_avatar}
                    alt={e.user_name}
                    onClick={async () => {
                      try {
                        const users = notion.users.filter((u) => u.user_id !== e.user_id);
                        const { data, ok } = await api.put(`/notion/${notion._id}`, { users });
                        if (!ok) return toast.error("Something went wrong!");
                        setNotion(data);
                        toast.success(`Removed ${e.user_name} from task ${notion.project_name}#${notion.index}`);
                      } catch (e) {
                        console.log(e);
                        toast.error("Something went wrong!");
                      }
                    }}
                  />
                </div>
              ))}
              <SelectUsers
                name="people"
                className="projectsInput"
                placeholder="+ add people"
                onChange={async (user) => {
                  try {
                    if (!user._id) return;
                    if (notion.users.find((u) => u.user_id === user._id)) return;
                    const users = [...notion.users, { user_id: user._id, user_name: user.name, user_avatar: user.avatar, user_email: user.email }];
                    const { data, ok } = await api.put(`/notion/${notion._id}`, { users });
                    if (!ok) return toast.error("Something went wrong!");
                    setNotion(data);
                    toast.success(`Added ${user.name} to task ${notion.project_name}#${notion.index}`);
                  } catch (e) {
                    console.log(e);
                    toast.error("Something went wrong!");
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="w-2/3 flex-1">
        <Comments
          value={notion.comments}
          onAdd={async (e) => {
            console.log("Adding comment", e);
            const comments = [...notion.comments, { text: e, user_name: user.name, user_id: user._id, user_avatar: user.avatar }];
            const { data } = await api.put(`/notion/${notion._id}`, { comments });
            setNotion(data);
            toast.success("Comment added!");
          }}
          onDelete={async (id) => {
            const comments = notion.comments.filter((c) => c._id !== id);
            const { data } = await api.put(`/notion/${notion._id}`, { comments });
            setNotion(data);
            toast.success("Comment deleted!");
          }}
        />
        <div className="mb-2">
          <span className="flex items-center text-xs text-gray-600 italic">
            Created by&nbsp;{notion.user_name} on {moment(notion.created_at).format("DD/MM/YYYY")}
          </span>
        </div>
      </div>
    </div>
  );
};

const Hours = ({ value, onChange }) => {
  const [hours, setHours] = useState(null);

  useEffect(() => {
    setHours(value);
  }, [value]);

  return (
    <input
      type="number"
      className="w-full rounded-md border-[1px] border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-sm placeholder:text-gray-300 py-2 px-2"
      value={hours}
      onChange={(e) => setHours(e.target.value)}
      onBlur={(e) => onChange(hours)}
    />
  );
};

const Price = ({ value, onChange }) => {
  const [price, setPrice] = useState(null);

  useEffect(() => {
    setPrice(value);
  }, [value]);

  return (
    <input
      type="number"
      className="w-full rounded-md border-[1px] border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-sm placeholder:text-gray-300 py-2 px-2"
      value={price}
      onChange={(e) => setPrice(e.target.value)}
      onBlur={(e) => onChange(price)}
    />
  );
};

const SelectPriority = ({ value, onChange }) => {
  return (
    <select
      className="w-full rounded-md border-[1px] border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-sm placeholder:text-gray-300 py-2 px-2"
      value={value}
      onChange={onChange}>
      <option value="">No priority</option>
      <option value="P0">P0 (Critical)</option>
      <option value="P1">P1 (Important)</option>
      <option value="P2">P2 (Medium)</option>
      <option value="P3">P3 (Low)</option>
    </select>
  );
};

const SelectCategory = ({ value, onChange }) => {
  return (
    <select
      className="w-full rounded-md border-[1px] border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-sm placeholder:text-gray-300 py-2 px-2"
      value={value}
      onChange={onChange}>
      <option value="">No category</option>
      <option value="BUG">BUG</option>
      <option value="FEATURE">FEATURE</option>
      <option value="ITERATION">ITERATION</option>
    </select>
  );
};
