import React, { useEffect, useState } from "react";
import { RiErrorWarningLine } from "react-icons/ri";

import SebSignaturePng from "../../../assets/seb-signature.png";

const TEMPLATE_FREELANCE_2 = ({ values, setValues }) => {
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    const e = [];
    if (!values.date) e.push(`Missing date`);
    if (!values.properties?.name) e.push(`Missing name`);
    if (!values.properties?.tjm) e.push(`Missing tjm`);
    if (!values.properties?.siren) e.push(`Missing siren`);
    if (!values.properties?.address) e.push(`Missing address`);
    if (!values.properties?.job_title) e.push(`Missing job_title`);
    setErrors(e);

    const renderValue = ({ value, label }) => value || `<span style='background-color:yellow;font-style:italic'>${label}</span>`;

    document.querySelector("#content #name").innerHTML = renderValue({ value: values.properties?.name, label: "NAME" });
    document.querySelector("#content #name-1").innerHTML = renderValue({ value: values.properties?.name, label: "NAME" });
    document.querySelector("#content #siren").innerHTML = renderValue({ value: values.properties?.siren, label: "SIREN" });
    document.querySelector("#content #address").innerHTML = renderValue({ value: values.properties?.address, label: "ADDRESS" });
    document.querySelector("#content #job_title").innerHTML = renderValue({ value: values.properties?.job_title, label: "JOB_TITLE" });
    document.querySelector("#content #tjm").innerHTML = renderValue({ value: values.properties?.tjm, label: "TJM" });
    document.querySelector("#content #date").innerHTML = renderValue({ value: formatDate(values?.date), label: "DATE" });
  }, [values]);

  return (
    <div>
      <div className="grid grid-cols-4 gap-3 items-center justify-between mb-3">
        <div>
          <div className="font-medium mb-1 text-sm">Choose date</div>
          <input
            className="projectsInput text-[14px] font-normal text-[#212325] rounded-[10px]"
            type="date"
            value={formatDate(values.date)}
            onChange={(e) => setValues({ ...values, date: new Date(e.target.value) })}
          />
        </div>

        <div>
          <div className="font-medium mb-1 text-sm">Name</div>
          <input
            className="projectsInput text-[14px] font-normal text-[#212325] rounded-[10px]"
            value={values.properties?.name}
            onChange={(e) => setValues({ ...values, properties: { ...values.properties, name: e.target.value } })}
          />
        </div>
        <div>
          <div className="font-medium mb-1 text-sm">SIREN</div>
          <input
            className="projectsInput text-[14px] font-normal text-[#212325] rounded-[10px]"
            value={values.properties?.siren}
            onChange={(e) => setValues({ ...values, properties: { ...values.properties, siren: e.target.value } })}
          />
        </div>
        <div>
          <div className="font-medium mb-1 text-sm">Address</div>
          <input
            className="projectsInput text-[14px] font-normal text-[#212325] rounded-[10px]"
            value={values.properties?.address}
            onChange={(e) => setValues({ ...values, properties: { ...values.properties, address: e.target.value } })}
          />
        </div>

        <div>
          <div className="font-medium mb-1 text-sm">TJM</div>
          <input
            className="projectsInput text-[14px] font-normal text-[#212325] rounded-[10px]"
            value={values.properties?.tjm}
            onChange={(e) => setValues({ ...values, properties: { ...values.properties, tjm: e.target.value } })}
          />
        </div>

        <div>
          <div className="font-medium mb-1 text-sm">Job Title</div>
          <input
            className="projectsInput text-[14px] font-normal text-[#212325] rounded-[10px]"
            value={values.properties?.job_title}
            onChange={(e) => setValues({ ...values, properties: { ...values.properties, job_title: e.target.value } })}
          />
        </div>
      </div>
      {!!errors.length && (
        <div className="border-l-4 border-red-400 bg-red-50 p-4 mb-2">
          <div className="flex">
            <div className="flex-shrink-0">
              <RiErrorWarningLine className="h-5 w-5 text-red-400" aria-hidden="true" />
            </div>
            <div className="ml-3">
              {errors.map((text) => (
                <p className="text-sm text-red-700">{text}</p>
              ))}
            </div>
          </div>
        </div>
      )}
      <div id="content" className="p-4 text-base">
        <div dangerouslySetInnerHTML={{ __html: html }} />
        <img src={SebSignaturePng} alt="" />
      </div>
    </div>
  );
};

function formatDate(date) {
  if (!date) return;
  return new Date(date).toISOString().slice(0, 10);
}

const html = `
<p><strong>&nbsp;Contrat de freelance&nbsp;</strong></p>
<p>Entre les soussignés :</p>
<br/>
<p><span id="name">{{NAME}}</span>, immatriculé(e) au <span id="siren">{{SIREN}}</span> domicilié(e) <span id="address">{{ADDRESS}}</span>,
<br/>
<p>ci-après dénommé « le Freelance»,</p>
<p>d'une part,</p>
<br/>
<p>et</p>
<br/>
<p>L'entreprise individuelle SELEGO B.V, immatriculée au RCS de AMSTERDAM 892 265 687 domiciliée OUDESCHANS 85 II , 1011 KW AMSTERDAM PAYS-BAS,</p>
<br/>
<p>ci-après dénommé « le Client »,</p>
<p>d'autre part,</p>
<br/>
<p>il a été convenu ce qui suit :</p>
<br/>
<p><b>Article 1er - Objet de la prestation</b></p>
<p>Le présent contrat est un contrat de freelance ayant pour objet la mission suivante : <span id="job_title"></span>.</p>
<br/>
<p><b>Article 2 - Durée et délai</b></p>
<p>Le contrat prend effet à la date de signature des présentes pour une durée indéterminée.</p>
<br/>
<p><b>Article 3 - Lieu d’exécution de la prestation</b></p>
<p>La prestation sera réalisée :<br/>
- dans les locaux du Freelance, à l'adresse mentionnée ci-dessus.</p>
<br/>
<p><b>Article 4 - Prix et modalités de paiement</b></p>
<p>En contrepartie de la réalisation des prestations définies à l'article premier ci-dessus, le client versera au Freelance :<br/>
- la somme de <span id="tjm"></span> Euros HT par jour.</p>
<br/>
<p>Les sommes prévues ci-dessus seront payées par le moyen suivant:<br/>
- virement bancaire<br/>
dans les 30 jours de la réception de la facture par le client.</p>
<br/>
<p><b>Article 5 - Obligations du Freelance</b></p>
<p>Le Freelance s’engage à exécuter sa mission avec diligence et professionnalisme et selon les règles en vigueur dans sa profession. Le Freelance a une obligation de moyens.<br/>
Le Freelance s’engage au respect d’une obligation de confidentialité en ce qui concerne le contenu de la mission, les informations et les documents communiqués par le Client.</p>
<br/>
<p><b>Article 6 - Obligations du client</b></p>
<p>Le client s’engage à donner au Freelance toutes les informations nécessaires sur ses besoins et sur les impératifs à respecter. Le Client tiendra à la disposition du Freelance toutes les informations pouvant contribuer à la bonne réalisation de l'objet du présent contrat.</p>
<br/>
<p><b>Article 7 - Responsabilité</b></p>
<p>Chacune des parties sera responsable de la bonne exécution de ses obligations telles qu'elles résultent du présent contrat. Le Freelance sera seul responsable des faits de ses préposés.<br/>
À l'exclusion de tout cas de force majeure, le Freelance est responsable des préjudices qu'il cause directement par sa faute ou sa négligence.</p>
<br/>
<p><b>Article 8 - Changement de circonstances</b></p>
<p>Si un changement de circonstances imprévisible lors de la conclusion du contrat rend l'exécution excessivement onéreuse pour une partie qui n'avait pas accepté d'en assumer le risque, celle-ci peut demander une renégociation du contrat à son cocontractant. Cette demande doit être faite par lettre recommandée avec accusé de réception, en précisant la date et la nature du ou des événements à l'origine du changement de circonstances, en chiffrant le montant du préjudice financier actuel ou à venir et en faisant une proposition de dédommagement pour remédier à ce changement. Elle continue à exécuter ses obligations durant la renégociation.<br/>
En cas de refus ou d'échec de la renégociation, les parties peuvent convenir de la résolution du contrat, à la date et aux conditions qu'elles déterminent, ou demander d'un commun accord au juge de procéder à son adaptation. A défaut d'accord dans un délai raisonnable, le juge peut, à la demande d'une partie, réviser le contrat ou y mettre fin, à la date et aux conditions qu'il fixe.</p>
<br/>
<p><b>Article 9 - Rupture du contrat</b></p>
<p>Le présent contrat pourra être résilié à tout moment par l’une ou l’autre des parties, sous réserve du respect d'un préavis minimum de 3 mois, notifié par lettre recommandée avec accusé de réception. La résiliation, dans ces conditions, ne saurait ouvrir droit à aucune indemnité au profit de la partie qui n'a pas demandé la résiliation, pour quelque raison que ce soit.<br/>
En cas d'inexécution par l'une des parties de ses obligations nées du présent contrat, la résiliation du contrat serait encourue de plein droit.<br/>
La résiliation interviendra dans les 30 jours suivant l'envoi d'une mise en demeure adressée par la partie victime du manquement, restée sans effet, et ce sans préjudice des dommages et intérêts qui pourraient être réclamés par la partie qui demande la résiliation.</p>
<br/>
<p><b>Article 10 - Force majeure</b></p>
<p>Il y a force majeure en matière contractuelle lorsqu'un événement échappant au contrôle du débiteur, qui ne pouvait être raisonnablement prévu lors de la conclusion du contrat et dont les effets ne peuvent être évités par des mesures appropriées, empêche l'exécution de son obligation par le débiteur.<br/>
Si l'empêchement est temporaire, l'exécution de l'obligation est suspendue à moins que le retard qui en résulterait ne justifie la résolution du contrat. Si l'empêchement est définitif, le contrat est résolu de plein droit et les parties sont libérées de leurs obligations dans les conditions prévues aux articles 1351 et 1351-1 du Code civil.<br/>
Aucune des deux parties ne sera tenue responsable du retard constaté en raison des événements de force majeure.
En cas de force majeure, constatée par l'une des parties, celle-ci doit en informer l'autre partie par écrit dans les meilleurs délais.<br/>
Les délais prévus pour la réalisation de la prestation seront décalés en fonction de la durée du retard dû à la force majeure.</p>
<br/>
<p><b>Article 11 - Loi applicable</b></p>
<p>Le présent contrat est régi par la loi française.<br/>
Tout litige qui résulterait du présent contrat devra être soumis au tribunal compétent dont dépend le siège social du Freelance.</p>
<br/>
<p>Fait le <span id="date"></span></p> à Paris</p>
<br/>
<p><b>Le Freelance</b></p>
<p id="name-1"></p>
<br/>
<p><b>Le Client</b></p>
<p>L'entreprise individuelle SELEGO B.V</p>
<br/>


`;

function isFreelance2Valid(values) {
  return values.date && values.properties?.name && values.properties?.tjm && values.properties?.siren && values.properties?.address && values.properties?.job_title;
}

export { TEMPLATE_FREELANCE_2, isFreelance2Valid };
