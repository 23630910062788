import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Loader from "../../components/loader";
import api from "../../services/api";
import SelectUser from "../../components/selectUser";
// import Company from "./company";
import { Chart as ChartJS, registerables } from "chart.js";

ChartJS.register(...registerables);

export default function () {
  const user = useSelector((state) => state.Auth.user);
  const [currentUser, setCurrentUser] = useState(user);
  const [isLoading, setIsLoading] = useState(false);
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    get();
  }, [currentUser]);

  const get = async () => {
    setIsLoading(true);
    const query = {};
    if (currentUser) query.userId = currentUser._id;
    const { data } = await api.post(`/wallet_transaction/search`, query);
    setTransactions(data);
    setIsLoading(false);
  };

  if (isLoading) return <Loader />;

  const totalCashOut = transactions.filter((t) => t.status === "done" && t.amount < 0).reduce((acc, t) => acc + t.amount, 0);
  const totalPending = transactions.filter((t) => t.status === "pending").reduce((acc, t) => acc + t.amount, 0);
  const totalAvailable = transactions.filter((t) => t.status === "done" && t.amount > 0).reduce((acc, t) => acc + t.amount, 0) + totalCashOut;
  const total = transactions.reduce((acc, t) => acc + t.amount, 0) - totalCashOut;

  return (
    <>
      <SelectUser
        value={currentUser?._id}
        userName={currentUser?.name}
        onChange={(newUser) => {
          if (!newUser.name) return setCurrentUser(null);
          setCurrentUser(newUser);
        }}
      />

      <div className="bg-white rounded-lg border border-gray-300 p-6 mt-5">
        <div className="mb-4">
          <h3>Total Cash Out: €{totalCashOut.toFixed(2)}</h3>
          <h3>Total Pending: €{totalPending.toFixed(2)}</h3>
          <h3>Total Available: €{totalAvailable.toFixed(2)}</h3>
          <h3>Total: €{total.toFixed(2)}</h3>
        </div>
        <table className="w-full">
          <thead>
            <tr className="text-left border-b border-gray-300">
              <th className="pb-4">Description</th>
              <th className="pb-4">Date</th>
              <th className="pb-4">Status</th>
              <th className="pb-4 text-right">Amount</th>
            </tr>
          </thead>
          <tbody>
            {transactions.map((transaction) => {
              const dateObj = new Date(transaction.date);
              const formattedDate = dateObj.toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "short",
                year: "numeric",
              });

              return (
                <tr key={transaction._id} className="border-b border-gray-300">
                  <td className="py-4">{transaction.description}</td>
                  <td className="py-4">{formattedDate}</td>
                  <td className="py-4">{transaction.status}</td>
                  <td className="py-4 text-right">{transaction.amount}€</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}
