import { useEffect } from "react";

const Signature = ({ values, editing, onChange }) => {
  if (editing) return <SignatureForm values={values} onChange={onChange} />;
  return (
    <div>
      <p className="font-poppins font-medium text-xl mb-3">Signature</p>
      <div className="flex gap-5">
        <div className="w-1/2">
          <p className="">Nom: {values.name}</p>
          <p className="">Date: {new Date(values.date).toLocaleDateString("fr")}</p>
          <img className="w-48" src={values.signature} />
        </div>
        <div className="w-1/2">
          <p className="">Nom:</p>
          <p className="">Date:</p>
        </div>
      </div>
    </div>
  );
};

const SignatureForm = ({ values, onChange }) => {
  useEffect(() => {
    const obj = {};
    if (!values.name) obj.name = "Sebastien Le Goff";
    if (!values.date) obj.date = new Date();
    if (!values.signature) obj.signature = "https://accounting.selego.co/assets/seb-signature-Bjzm0-mo.png";
    onChange({ ...values, ...obj });
  }, []);

  return (
    <div>
      <p className="font-poppins mb-3 font-semibold text-2xl">Signature</p>
      <div className="flex gap-5">
        <div className="w-1/2 space-y-2">
          <input className="input" type="text" name="name" value={values.name} onChange={(e) => onChange({ ...values, name: e.target.value })} />
          <input className="input" type="date" name="date" value={values.date} onChange={(e) => onChange({ ...values, date: e.target.value })} />
          <img className="max-w-xs" src="https://accounting.selego.co/assets/seb-signature-Bjzm0-mo.png" />
        </div>
        <div className="w-1/2 space-y-2">
          <p className="ml-16">Nom:</p>
          <p className="ml-16">Date:</p>
        </div>
      </div>
    </div>
  );
};

export { Signature, SignatureForm };
export default Signature;
