const CONTRACTS = {
  CDD: "CDD",
  CDI: "CDI",
  VIE: "VIE",
  INTERNSHIP: "INTERNSHIP",
  APPRENTICESHIP: "APPRENTICESHIP",
  FREELANCE: "FREELANCE",
};

const LOCATION = {
  REMOTE: "Remote",
  PARIS: "Paris",
  AMSTERDAM: "Amsterdam",
  BARCELONE: "Barcelone",
};

const DIVISION = {
  LE_COLLECTIF: "LE_COLLECTIF",
  SELEGO_FR: "SELEGO_FR",
  SELEGO_NL: "SELEGO_NL",
  SELESPAGNE: "SELESPAGNE",
};

const YEARSSCHOOL = {
  FIRSTYEAR: "FIRSTYEAR",
  SECONDYEAR: "SECONDYEAR",
  THIRDYEAR: "THIRDYEAR",
  FOURTHYEAR: "FOURTHYEAR",
  FIFTHYEAR: "FIFTHYEAR",
};

const ROLES = {
  FULLSTACK: "FULLSTACK",
  DATA: "DATA",
  DEVOPS: "DEVOPS",
  PRODUCT: "PRODUCT",
  SALES: "SALES",
  GROWTH: "GROWTH",
};

const SCHOOL = {
  ENGINEERING: "ENGINEERING",
  BOOTCAMP: "BOOTCAMP",
  OTHER: "OTHER",
};

const MANAGEMENT = {
  NO: "NO",
  LEAD: "LEAD / MANAGER",
  HEADOF: "HEAD OF / DIRECTOR / CHIEF",
};

const BENCHMARKS_CDI = [];

BENCHMARKS_CDI.push([ROLES.FULLSTACK, 0, 42000, 38800, 36000, 38900]);
BENCHMARKS_CDI.push([ROLES.FULLSTACK, 1, 45000, 41700, 38000, 41500]);
BENCHMARKS_CDI.push([ROLES.FULLSTACK, 2, 48000, 44800, 40000, 44200]);
BENCHMARKS_CDI.push([ROLES.FULLSTACK, 3, 52000, 48200, 43000, 4700]);
BENCHMARKS_CDI.push([ROLES.FULLSTACK, 4, 55000, 51800, 46000, 50900]);
BENCHMARKS_CDI.push([ROLES.FULLSTACK, 5, 65000, 55000, 52000, 57333]);
BENCHMARKS_CDI.push([ROLES.FULLSTACK, 6, 62000, 59800, 53000, 58200]);
BENCHMARKS_CDI.push([ROLES.FULLSTACK, 7, 65000, 64300, 56000, 61700]);
BENCHMARKS_CDI.push([ROLES.FULLSTACK, 8, 68000, 66300, 59000, 64400]);
BENCHMARKS_CDI.push([ROLES.FULLSTACK, 9, 70000, 68300, 68000, 68700]);
BENCHMARKS_CDI.push([ROLES.FULLSTACK, 10, 72000, 70300, 73000, 71800]);

BENCHMARKS_CDI.push([ROLES.DEVOPS, 0, 42000, 43000, 42000, 42300]);
BENCHMARKS_CDI.push([ROLES.DEVOPS, 1, 45000, 46000, 45000, 45300]);
BENCHMARKS_CDI.push([ROLES.DEVOPS, 2, 48000, 49600, 48000, 48500]);
BENCHMARKS_CDI.push([ROLES.DEVOPS, 3, 52000, 52900, 64000, 56300]);
BENCHMARKS_CDI.push([ROLES.DEVOPS, 4, 55000, 56000, 67000, 59300]);
BENCHMARKS_CDI.push([ROLES.DEVOPS, 5, 58000, 59000, 70000, 6200]);
BENCHMARKS_CDI.push([ROLES.DEVOPS, 6, 62000, 62700, 72100, 65600]);
BENCHMARKS_CDI.push([ROLES.DEVOPS, 7, 65000, 66000, 74200, 68400]);
BENCHMARKS_CDI.push([ROLES.DEVOPS, 8, 68000, 69000, 76500, 71100]);
BENCHMARKS_CDI.push([ROLES.DEVOPS, 9, 70000, 72600, 78000, 73400]);
BENCHMARKS_CDI.push([ROLES.DEVOPS, 10, 72000, 75900, 8100, 76300]);

BENCHMARKS_CDI.push([ROLES.DATA, 0, 42000, 45000, 41000, 42700]);
BENCHMARKS_CDI.push([ROLES.DATA, 1, 45000, 48000, 43000, 45300]);
BENCHMARKS_CDI.push([ROLES.DATA, 2, 48000, 51000, 45000, 48000]);
BENCHMARKS_CDI.push([ROLES.DATA, 3, 52000, 54800, 47000, 51300]);
BENCHMARKS_CDI.push([ROLES.DATA, 4, 55000, 58900, 51000, 55000]);
BENCHMARKS_CDI.push([ROLES.DATA, 5, 58000, 63300, 55000, 58700]);
BENCHMARKS_CDI.push([ROLES.DATA, 6, 6200, 68100, 57000, 62300]);
BENCHMARKS_CDI.push([ROLES.DATA, 7, 65000, 73200, 59000, 65700]);
BENCHMARKS_CDI.push([ROLES.DATA, 8, 68000, 75400, 63000, 68800]);
BENCHMARKS_CDI.push([ROLES.DATA, 9, 70000, 77700, 65000, 70900]);
BENCHMARKS_CDI.push([ROLES.DATA, 10, 72000, 80000, 68000, 73300]);

BENCHMARKS_CDI.push([ROLES.PRODUCT, 0, 42000, 45000, 41000, 42700]);
BENCHMARKS_CDI.push([ROLES.PRODUCT, 1, 45000, 48000, 43000, 45300]);
BENCHMARKS_CDI.push([ROLES.PRODUCT, 2, 48000, 51000, 45000, 48000]);
BENCHMARKS_CDI.push([ROLES.PRODUCT, 3, 52000, 54800, 47000, 51300]);
BENCHMARKS_CDI.push([ROLES.PRODUCT, 4, 55000, 58900, 51000, 55000]);
BENCHMARKS_CDI.push([ROLES.PRODUCT, 5, 58000, 63300, 55000, 58700]);
BENCHMARKS_CDI.push([ROLES.PRODUCT, 6, 6200, 68100, 57000, 62300]);
BENCHMARKS_CDI.push([ROLES.PRODUCT, 7, 65000, 73200, 59000, 65700]);
BENCHMARKS_CDI.push([ROLES.PRODUCT, 8, 68000, 75400, 63000, 68800]);
BENCHMARKS_CDI.push([ROLES.PRODUCT, 9, 70000, 77700, 65000, 70900]);
BENCHMARKS_CDI.push([ROLES.PRODUCT, 10, 72000, 80000, 68000, 73300]);

const BENCHMARKS_CDI_START = [];

BENCHMARKS_CDI_START.push([ROLES.FULLSTACK, 0, 38000]);
BENCHMARKS_CDI_START.push([ROLES.DEVOPS, 0, 42000]);
BENCHMARKS_CDI_START.push([ROLES.DATA, 0, 40000]);
BENCHMARKS_CDI_START.push([ROLES.PRODUCT, 0, 40000]);
BENCHMARKS_CDI_START.push([ROLES.SALES, 0, 35000]);
BENCHMARKS_CDI_START.push([ROLES.GROWTH, 0, 37000]);


const PRICING = [];

PRICING.push([ROLES.FULLSTACK, 0, 493, 375, 430]);
PRICING.push([ROLES.FULLSTACK, 1, 514, 425, 470]);
PRICING.push([ROLES.FULLSTACK, 2, 534, 470, 500]);
PRICING.push([ROLES.FULLSTACK, 3, 562, 515, 540]);
PRICING.push([ROLES.FULLSTACK, 4, 590, 560, 575]);
PRICING.push([ROLES.FULLSTACK, 5, 611, 600, 610]);
PRICING.push([ROLES.FULLSTACK, 6, 639, 625, 640]);
PRICING.push([ROLES.FULLSTACK, 7, 659, 650, 650]);
PRICING.push([ROLES.FULLSTACK, 8, 664, 675, 675]);
PRICING.push([ROLES.FULLSTACK, 9, 678, 700, 700]);
PRICING.push([ROLES.FULLSTACK, 10, 700, 750, 750]);

PRICING.push([ROLES.DEVOPS, 0, 493, 400, 475]);
PRICING.push([ROLES.DEVOPS, 1, 514, 450, 500]);
PRICING.push([ROLES.DEVOPS, 2, 534, 500, 525]);
PRICING.push([ROLES.DEVOPS, 3, 562, 525, 550]);
PRICING.push([ROLES.DEVOPS, 4, 590, 575, 575]);
PRICING.push([ROLES.DEVOPS, 5, 611, 625, 600]);
PRICING.push([ROLES.DEVOPS, 6, 639, 650, 650]);
PRICING.push([ROLES.DEVOPS, 7, 659, 700, 675]);
PRICING.push([ROLES.DEVOPS, 8, 664, 750, 700]);
PRICING.push([ROLES.DEVOPS, 9, 678, 775, 775]);
PRICING.push([ROLES.DEVOPS, 10, 700, 800, 800]);

PRICING.push([ROLES.DATA, 0, 493, 400, 450]);
PRICING.push([ROLES.DATA, 1, 514, 440, 500]);
PRICING.push([ROLES.DATA, 2, 534, 500, 525]);
PRICING.push([ROLES.DATA, 3, 562, 540, 550]);
PRICING.push([ROLES.DATA, 4, 590, 580, 575]);
PRICING.push([ROLES.DATA, 5, 611, 600, 600]);
PRICING.push([ROLES.DATA, 6, 639, 700, 650]);
PRICING.push([ROLES.DATA, 7, 659, 725, 700]);
PRICING.push([ROLES.DATA, 8, 664, 750, 715]);
PRICING.push([ROLES.DATA, 9, 678, 800, 750]);
PRICING.push([ROLES.DATA, 10, 700, 825, 800]);

PRICING.push([ROLES.PRODUCT, 0, 493, 450, 450]);
PRICING.push([ROLES.PRODUCT, 1, 514, 500, 500]);
PRICING.push([ROLES.PRODUCT, 2, 534, 525, 550]);
PRICING.push([ROLES.PRODUCT, 3, 562, 550, 575]);
PRICING.push([ROLES.PRODUCT, 4, 590, 575, 600]);
PRICING.push([ROLES.PRODUCT, 5, 611, 600, 615]);
PRICING.push([ROLES.PRODUCT, 6, 639, 625, 650]);
PRICING.push([ROLES.PRODUCT, 7, 659, 650, 675]);
PRICING.push([ROLES.PRODUCT, 8, 664, 700, 700]);
PRICING.push([ROLES.PRODUCT, 9, 678, 750, 750]);
PRICING.push([ROLES.PRODUCT, 10, 700, 800, 800]);

const PRICING_START = [];

PRICING_START.push([ROLES.FULLSTACK, 0, 400]);
PRICING_START.push([ROLES.DEVOPS, 0, 430]);
PRICING_START.push([ROLES.DATA, 0, 425]);
PRICING_START.push([ROLES.PRODUCT, 0, 450]);
PRICING_START.push([ROLES.SALES, 0, 350]);
PRICING_START.push([ROLES.GROWTH, 0, 400]);

export { CONTRACTS, LOCATION, DIVISION, YEARSSCHOOL, ROLES, SCHOOL, MANAGEMENT, BENCHMARKS_CDI, BENCHMARKS_CDI_START, PRICING, PRICING_START };
