import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

import RichTextEditor from "../../../../components/RichTextEditor";

import api from "../../../../services/api";

export default ({ campaign, setCampaign }) => {
  const user = useSelector((state) => state.Auth.user);

  const [values, setValues] = useState(campaign);

  const handleDelete = async () => {
    const confirm = window.confirm("Are you sure ?");
    if (!confirm) return;
    try {
      await api.remove(`/s_campaign/${campaign._id}`);
      toast.success("successfully removed!");
      setCampaign(null);
    } catch (error) {}
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      console.log(values);
      const { data } = await api.put(`/s_campaign/${campaign._id}`, values);
      setCampaign(data);
      toast.success("Updated!");
    } catch (e) {
      console.log(e);
      toast.error("Some Error!");
    }
  };

  return (
    <form onSubmit={handleSubmit} className="p-6 space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
        <div className="space-y-2 w-full">
          <input className="input" type="text" name="name" value={values.name} onChange={(e) => setValues({ ...values, name: e.target.value })} />
        </div>
        <div className="space-y-2 w-full col-span-4 h-[300px]">
          <RichTextEditor values={values.description} onChange={(description) => setValues({ ...values, description })} />
        </div>
        <div>
          <button type="submit" className="bg-red-500 text-white py-2 px-4 rounded-md">
            Update
          </button>
        </div>
      </div>
    </form>
  );
};
