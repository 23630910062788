import React from "react";
import { Route, Routes } from "react-router-dom";

import List from "./list";
import View from "./view";
// import ViewApplicant from "./viewApplicant";

const Job = () => {
  return (
    <Routes>
      <Route path="/" element={<List />} />
      <Route path="/:id/*" element={<View />} />
    </Routes>
  );
};

export default Job;
