import React, { useEffect, useState } from "react";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { IoInformationCircleOutline } from "react-icons/io5";

import Modal from "../../../../components/modal";

import About from "./about";
import Raw from "./raw";

import { classNames } from "../../../../utils";

export default ({ campaign, setCampaign }) => {
  const [tab, setTab] = useState("about");

  if (!campaign) return <></>;

  return (
    <Modal isOpen={true} onClose={() => setCampaign(null)}>
      <div className="w-full h-[90vh] bg-[#FBFBF9] p-2 overflow-y-auto">
        <div className="bg-white rounded-md flex flex-col w-full border-[1px] border-gray-200 overflow-hidden">
          <div className="flex items-center gap-2 p-4">
            <div className="flex justify-between flex-1">
              <div className="flex items-center gap-2">
                <div>
                  <div className="text-lg font-bold">Campaign : {campaign.name}</div>
                </div>
              </div>
            </div>
            <div className="flex flex-col">
              <div className="grid grid-cols-2 gap-x-4">
                <span className="text-xs flex-1 text-right text-[#b7b3b4]">Updated at</span>
                <span className="text-xs flex-1 text-left text-[#b7b3b4]">{moment(campaign.updated_at).format("DD/MM/YYYY, HH:mm")}</span>
                <span className="text-xs flex-1 text-right text-[#b7b3b4]">Created at</span>
                <span className="text-xs flex-1 text-left text-[#b7b3b4]">{moment(campaign.created_at).format("DD/MM/YYYY, HH:mm")}</span>
              </div>
            </div>
          </div>
          <nav className="flex flex-row w-full border-t-[1px] border-gray-200 overflow-x-scroll">
            <TabItem title="About" tab="about" setTab={setTab} active={tab === "about"} Icon={IoInformationCircleOutline} />
            <TabItem title="Raw" tab="raw" setTab={setTab} active={tab === "raw"} />
          </nav>
        </div>
        <div className="my-2" />
        {tab === "about" ? <About campaign={campaign} setCampaign={setCampaign} /> : null}
        {tab === "raw" ? <Raw campaign={campaign} setCampaign={setCampaign} /> : null}
      </div>
    </Modal>
  );
};

const TabItem = ({ tab, title, setTab, active, Icon, Badge }) => (
  <button
    onClick={() => setTab(tab)}
    className={classNames(
      "group inline-flex items-center px-4 py-2 cursor-pointer text-sm text-gray-500 hover:border-gray-300 hover:text-gray-700 border-b-2 ",
      active ? "text-primary  border-primary" : "border-transparent",
    )}>
    {Icon ? <Icon className={classNames(active ? "text-primary" : "text-gray-400 group-hover:text-gray-500", "-ml-0.5 mr-2 h-4 w-4")} aria-hidden="true" /> : null}
    <div className="flex items-center gap-2">{title}</div>
    {Badge ? Badge : null}
  </button>
);
