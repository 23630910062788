import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { JOB_POSITIONS, JOB_CONTRACTS, PAYMENT_METHODS } from "../../../constants";

import api from "../../../services/api";
import SelectUser from "../../selectUser";
import { useSelector } from "react-redux";
import { format } from "date-fns";

const About = ({ user, setUser }) => {
  const sessionUser = useSelector((state) => state.Auth.user);
  const [values, setValues] = useState(user);

  const handleDelete = async () => {
    const confirm = window.confirm("Are you sure ?");
    if (!confirm) return;
    try {
      await api.remove(`/user/${user._id}`);
      toast.success("successfully removed!");
      setUser(null);
    } catch (error) {}
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const newUser = await api.put(`/user/${user._id}`, values);
      setUser(newUser.data);
      toast.success("Updated!");
    } catch (e) {
      console.log(e);
      toast.error("Some Error!");
    }
  };

  return (
    <form onSubmit={handleSubmit} className="p-6 space-y-6">
      <PersonalDetails values={values} setValues={setValues} />
      {sessionUser.role === "admin" && (
        <>
          <JobDetails values={values} setValues={setValues} />
          <AdminDetails values={values} setValues={setValues} />
          <div className="flex justify-end gap-4">
            <button className=" blue-btn" onClick={handleSubmit}>
              Update
            </button>
            <button className="red-btn" onClick={handleDelete}>
              Delete
            </button>
          </div>
        </>
      )}
    </form>
  );
};

const PersonalDetails = ({ values, setValues }) => {
  return (
    <div className="border border-gray-300 rounded p-6 space-y-4">
      <h2 className="text-gray-800">Personal information</h2>

      <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
        <div className="space-y-2 w-full">
          <label className="block text-sm font-medium text-gray-700" htmlFor="name">
            Name
          </label>
          <input className="input" type="text" name="name" value={values.name} onChange={(e) => setValues({ ...values, name: e.target.value })} />
        </div>
        <div className="space-y-2 w-full">
          <label className="block text-sm font-medium text-gray-700" htmlFor="email">
            Email
          </label>
          <input className="input" type="text" name="email" value={values.email} onChange={(e) => setValues({ ...values, email: e.target.value })} />
        </div>
        <div className="space-y-2 w-full">
          <label className="block text-sm font-medium text-gray-700" htmlFor="role">
            Role
          </label>
          <select className="input" name="role" value={values.role} onChange={(e) => setValues({ ...values, role: e.target.value })}>
            <option value="admin">Admin</option>
            <option value="normal">Normal</option>
            <option value="applicant">Applicant</option>
            <option value="client">Client</option>
            <option value="lead">Lead</option>
          </select>
        </div>
        <div className="space-y-2 w-full">
          <label className="block text-sm font-medium text-gray-700" htmlFor="availability">
            Availability
          </label>
          <select className="input" name="availability" value={values.availability} onChange={(e) => setValues({ ...values, availability: e.target.value })}>
            <option value="available">Available</option>
            <option value="not available">Not Available</option>
          </select>
        </div>

        <div className="space-y-2 w-full">
          <label className="block text-sm font-medium text-gray-700" htmlFor="github">
            Github
          </label>
          <input className="input" type="text" name="github" value={values.github} onChange={(e) => setValues({ ...values, github: e.target.value })} />
        </div>
        <div className="space-y-2 w-full">
          <label className="block text-sm font-medium text-gray-700" htmlFor="calendly">
            Calendly
          </label>
          <input className="input" type="text" name="calendly" value={values.calendly} onChange={(e) => setValues({ ...values, calendly: e.target.value })} />
        </div>
        <div className="space-y-2 w-full">
          <label className="block text-sm font-medium text-gray-700" htmlFor="linkedin">
            Linkedin
          </label>
          <input className="input" type="text" name="linkedin" value={values.linkedin} onChange={(e) => setValues({ ...values, linkedin: e.target.value })} />
        </div>
        <div className="space-y-2 w-full">
          <label className="block text-sm font-medium text-gray-700" htmlFor="meeting">
            Meeting
          </label>
          <input className="input" type="text" name="meeting" value={values.meeting} onChange={(e) => setValues({ ...values, meeting: e.target.value })} />
        </div>
        <div className="space-y-2 w-full col-span-4">
          <label className="block text-sm font-medium text-gray-700" htmlFor="description">
            Description
          </label>
          <textarea row={4} className="input" type="text" name="description" value={values.description} onChange={(e) => setValues({ ...values, description: e.target.value })} />
        </div>
        <div className="space-y-2 w-full">
          <label className="block text-sm font-medium text-gray-700" htmlFor="action_google_review">
            Action Google Review
          </label>
          <select
            className="input"
            name="action_google_review"
            value={values.action_google_review}
            onChange={async (e) => {
              const newValue = e.target.value;
              setValues({ ...values, action_google_review: newValue });
              try {
                const { data } = await api.put(`/user/${values._id}`, { action_google_review: newValue });
                setValues(data);
                toast.success("Google Review action updated!");
              } catch (error) {
                toast.error("Failed to update Google Review action.");
              }
            }}>
            <option value="">(empty)</option>
            <option value="TO DO">TO DO</option>
            <option value="DONE">DONE</option>
          </select>
        </div>

        <div className="space-y-2 w-full">
          <label className="block text-sm font-medium text-gray-700" htmlFor="action_sortlist">
            Action Sortlist
          </label>
          <select
            className="input"
            name="action_sortlist"
            value={values.action_sortlist}
            onChange={async (e) => {
              const newValue = e.target.value;
              setValues({ ...values, action_sortlist: newValue });
              try {
                const { data } = await api.put(`/user/${values._id}`, { action_sortlist: newValue });
                setValues(data);
                toast.success("Sortlist action updated!");
              } catch (error) {
                toast.error("Failed to update Sortlist action.");
              }
            }}>
            <option value="">(empty)</option>
            <option value="TO DO">TO DO</option>
            <option value="DONE">DONE</option>
          </select>
        </div>

        <Tags
          tags={["seb", "newsletter", "google review", "sortlist", "trustpilot", "codeur"]}
          value={values.tags}
          onChange={async (tags) => {
            const { data } = await api.put(`/user/${values._id}`, { tags });
            setValues(data);
            toast.success("Updated!");
          }}
        />
      </div>
    </div>
  );
};

const Tags = ({ value = [], onChange, tags }) => {
  const handleChange = (e) => {
    let arr = [...value];
    const tag = e.target.name;
    if (arr.includes(tag)) {
      arr = arr.filter((t) => t !== tag);
    } else {
      arr = [...arr, tag];
    }
    onChange(arr);
  };

  return (
    <div className="space-y-2 w-full col-span-4">
      <label className="block text-sm font-medium text-gray-700" htmlFor="tags">
        Tags
      </label>
      {tags.map((tag) => (
        <div className="flex items-center mb-4" key={tag}>
          <input type="checkbox" id={tag} name={tag} checked={value.includes(tag)} onChange={handleChange} className="rounded" />
          <label className="ml-2 text-sm font-medium text-gray-700" htmlFor={tag}>
            {tag}
          </label>
        </div>
      ))}
    </div>
  );
};

const JobDetails = ({ values, setValues }) => {
  return (
    <div className="border border-gray-300 rounded p-6 space-y-4">
      <h2 className="text-gray-800">Job informations</h2>

      <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
        <div className="space-y-2 w-full">
          <label className="block text-sm font-medium text-gray-700" htmlFor="contract">
            Contract
          </label>
          <select className="input" name="contract" value={values.contract} onChange={(e) => setValues({ ...values, contract: e.target.value })}>
            <option value=""></option>
            {JOB_CONTRACTS.map((e) => (
              <option key={e} value={e}>
                {e}
              </option>
            ))}
          </select>
        </div>
        <div className="space-y-2 w-full">
          <label className="block text-sm font-medium text-gray-700" htmlFor="position">
            Position
          </label>
          <select className="input" name="position" value={values.position} onChange={(e) => setValues({ ...values, position: e.target.value })}>
            <option value=""></option>
            {JOB_POSITIONS.map((e) => (
              <option key={e} value={e}>
                {e}
              </option>
            ))}
          </select>
        </div>
        <div className="space-y-2 w-full">
          <label className="block text-sm font-medium text-gray-700" htmlFor="job_title">
            Job title
          </label>
          <input className="input" type="text" name="job_title" value={values.job_title} onChange={(e) => setValues({ ...values, job_title: e.target.value })} />
        </div>
        <div className="space-y-2 w-full">
          <label className="block text-sm font-medium text-gray-700" htmlFor="job_title">
            Seniority
          </label>
          <input className="input" type="text" name="seniority" value={values.seniority} onChange={(e) => setValues({ ...values, seniority: e.target.value })} />
        </div>
        <div className="space-y-2 w-full">
          <label className="block text-sm font-medium text-gray-700" htmlFor="division">
            Division
          </label>
          <select className="input" name="division" value={values.division} onChange={(e) => setValues({ ...values, division: e.target.value })}>
            <option value=""></option>
            <option value="LE_COLLECTIF">LE_COLLECTIF</option>
            <option value="SELEGO_FR">SELEGO_FR</option>
            <option value="SELEGO_NL">SELEGO_NL</option>
            <option value="SELESPAGNE">SELESPAGNE</option>
          </select>
        </div>

        {values.contract !== "FREELANCE" ? (
          <div className="space-y-2 w-full">
            <label className="block text-sm font-medium text-gray-700" htmlFor="office">
              Office
            </label>
            <select className="input" name="office" value={values.office} onChange={(e) => setValues({ ...values, office: e.target.value })}>
              <option value=""></option>
              <option value="Paris">Paris</option>
              <option value="Amsterdam">Amsterdam</option>
              <option value="Barcelone">Barcelone</option>
              <option value="Remote">Remote</option>
            </select>
          </div>
        ) : (
          <div />
        )}

        <div className="space-y-2 w-full">
          <label className="block text-sm font-medium text-gray-700" htmlFor="manager">
            Manager
          </label>
          <SelectUser
            value={{ _id: values.manager_id }}
            userName={values.manager_name}
            onChange={(e) => setValues({ ...values, manager_id: e._id, manager_name: e.name, manager_avatar: e.avatar })}
          />
        </div>
        <div className="space-y-2 w-full">
          <label className="block text-sm font-medium text-gray-700" htmlFor="disponibility">
            Disponibility
          </label>
          <select className="input" name="disponibility" value={values.disponibility} onChange={(e) => setValues({ ...values, disponibility: e.target.value })}>
            <option value="full">full</option>
            <option value="20 hrs/week">20 hrs/week</option>
            <option value="10 hrs/week">10 hrs/week</option>
            <option value="5 hrs/week">5 hrs/week</option>
          </select>
        </div>
        <div className="space-y-2 w-full">
          <label className="block text-sm font-medium text-gray-700" htmlFor="starting_date">
            Starting date
          </label>
          <input
            className="input"
            type="date"
            name="starting_date"
            value={values.starting_date ? new Date(values.starting_date).toISOString().split("T")[0] : null}
            onChange={(e) => setValues({ ...values, starting_date: e.target.value })}
          />
        </div>
        <div className="space-y-2 w-full">
          <label className="block text-sm font-medium text-gray-700" htmlFor="exit_date">
            Exit date
          </label>
          <input
            className="input"
            type="date"
            name="exit_date"
            value={values.exit_date ? new Date(values.exit_date).toISOString().split("T")[0] : null}
            onChange={(e) => setValues({ ...values, exit_date: e.target.value })}
          />
        </div>
        <div className="w-full flex items-start gap-2">
          <div className="space-y-2 w-full">
            <label className="block text-sm font-medium text-gray-700" htmlFor="wbso_eligible">
              WBSO Eligible
            </label>

            <input
              type="checkbox"
              id="wbso_eligible"
              checked={values.wbso_eligible}
              onChange={(e) => setValues({ ...values, wbso_eligible: e.target.checked })}
              className="rounded"
            />
          </div>

          <div className="space-y-2 w-full">
            <label className="block text-sm font-medium text-gray-700" htmlFor="cir_eligible">
              CIR Eligible
            </label>

            <input type="checkbox" id="cir_eligible" checked={values.cir_eligible} onChange={(e) => setValues({ ...values, cir_eligible: e.target.checked })} className="rounded" />
          </div>
        </div>
      </div>
    </div>
  );
};

const AdminDetails = ({ values, setValues }) => {
  return (
    <div className="border border-gray-300 rounded p-6 space-y-4">
      <h2 className="text-gray-800">Admin informations</h2>

      <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
        <div className="space-y-2 w-full">
          <label className="block text-sm font-medium text-gray-700" htmlFor="status">
            Status
          </label>
          <select className="input" name="status" value={values.status} onChange={(e) => setValues({ ...values, status: e.target.value })}>
            <option value=""></option>
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
          </select>
        </div>
        <div className="space-y-2 w-full">
          <label className="block text-sm font-medium text-gray-700" htmlFor="bsn">
            BSN
          </label>
          <input className="input" type="text" name="bsn" value={values.bsn} onChange={(e) => setValues({ ...values, bsn: e.target.value })} />
        </div>

        <div className="space-y-2 w-full">
          <label className="block text-sm font-medium text-gray-700" htmlFor="payment_method">
            Payment method
          </label>
          <select className="input" name="payment_method" value={values.payment_method} onChange={(e) => setValues({ ...values, payment_method: e.target.value })}>
            <option value=""></option>
            {Object.keys(PAYMENT_METHODS).map((e) => {
              return (
                <option key={e} value={e}>
                  {e}
                </option>
              );
            })}
          </select>
        </div>
        <div className="space-y-2 w-full">
          <label className="block text-sm font-medium text-gray-700" htmlFor="iban">
            Iban
          </label>
          <input className="input" type="text" name="iban" value={values.iban} onChange={(e) => setValues({ ...values, iban: e.target.value })} />
        </div>
      </div>
    </div>
  );
};

export default About;
