import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import MakeTransaction from "./makeTransaction";
import api from "../../services/api";


export default function () {
  const [data, setData] = useState(null);

  useEffect(() => {
    get();
  }, []);

  const get = async () => {
    setData(null);
    const { data } = await api.get("/wallet_transaction");
    setData(data);
  };

  if (!data) return <div>Loading</div>;

  return (
    <div className="bg-white min-h-screen">
      <div className="max-w-screen-lg mx-auto">
        <div className="grid gap-4">
          <MakeTransaction />
          <table className="md:w-full table-auto">
            <thead className="border-t border-b-2	 border-t-[#dee2e6] border-l-0 border-r-0 border-b-[#dee2e6]">
              <tr>
                <th scope="col" className="py-2 px-3 text-left text-sm font-semibold text-gray-900 md:pl-3 pl-0">
                  Avatar
                </th>
                <th scope="col" className="py-2 px-3 text-left text-sm font-semibold text-gray-900 md:pl-3 pl-0">
                  Name
                </th>
                <th scope="col" className="py-2 px-3 text-left text-sm font-semibold text-gray-900 md:pl-3 pl-0">
                  Description
                </th>
                <th scope="col" className="py-2 px-3 text-left text-sm font-semibold text-gray-900 md:pl-3 pl-0">
                  Amount
                </th>
                <th scope="col" className="py-2 px-3 text-left text-sm font-semibold text-gray-900 md:pl-3 pl-0">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {(data || []).map((hit) => {
                return (
                  <tr key={hit._id} className="hover:bg-[#dee2e6] border-b border-[#dee2e6]">
                    <td className="whitespace-nowrap py-1 px-3 text-sm font-normal text-gray-900 md:pl-3 pl-0">
                      <img src={hit.userAvatar} alt="User Avatar" className="w-10 h-10 rounded-full mr-2" />
                    </td>
                    <td className="whitespace-nowrap py-1 px-3 text-sm font-normal text-gray-900 md:pl-3 pl-0">
                      <div>
                        <div>{hit.userName}</div>
                      </div>
                    </td>
                    <td className="whitespace-nowrap py-1 px-3 text-sm font-normal text-gray-900 md:pl-3 pl-0">
                      <div>
                        <div>{hit.description}</div>
                        <div className="text-gray-400 text-xs">{hit.status}</div>
                      </div>
                    </td>
                    <td className="whitespace-nowrap py-1 px-3 text-sm font-normal text-gray-900 md:pl-3 pl-0">
                      <div>{hit.amount}€</div>
                    </td>
                    <td className="whitespace-nowrap py-1 px-3 text-sm font-normal text-gray-900 md:pl-3 pl-0">
                      <button
                        onClick={async (e) => {
                          e.preventDefault();
                          if (!window.confirm("Are you sure?")) return;
                          await api.remove(`/wallet_transaction/${hit._id}`);
                          toast.success(`OK`);
                          get();
                        }}
                        className="bg-gradient-to-r from-purple-500 to-indigo-500 text-white py-3 px-5 h-12 rounded-lg text-lg font-medium transform hover:scale-105 transition duration-300">
                        delete
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
