import React, { useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";

import Modal from "../../components/modal";
import api from "../../services/api";
import Table from "../../components/Table";

export default () => {
  const [companies, setCompanies] = useState(null);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({ status: "ACTIVE" });

  useEffect(() => {
    load();
  }, [filters]);

  async function load() {
    try {
      setLoading(true);
      const { data } = await api.post("/portfolio_company/search", { status: filters.status });
      setCompanies(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }

  const navigate = useNavigate();

  if (loading) return <div>Loading...</div>;

  return (
    <div className="bg-white min-h-screen">
      <div className="max-w-screen-lg mx-auto">
        <div className="">
          <CreateCompany className="ml-auto" onUpdate={load} />
          <div className="w-full flex justify-end">
            <select
              className="!py-2 focus:!ring-0 "
              value={filters.status || ""}
              onChange={(e) => setFilters((prev) => ({ ...prev, status: e?.target?.value === "All" ? "" : e?.target?.value }))}>
              <option disabled value="">
                Select
              </option>
              {["All", "ACTIVE", "INACTIVE"].map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </select>
          </div>

          <Table
            total={100}
            header={[
              { title: "Company", key: "company" },
              { title: "Description", key: "description" },
              { title: "Percentage owned", key: "last_name" },
              { title: "Current Company value", key: "owner" },
              { title: "Value of percentage owned", key: "company" },
              { title: "Perf", key: "status_created_date" },
            ]}
            loading={loading}
            height="h-[32rem]"
            sticky={true}
            noResultRender={<div className="w-full bg-white text-center p-2 my-5 rounded shadow-sm">Aucune résultat n'a été trouvé</div>}>
            {(companies || []).map((item, index) => {
              const valueOwned = (item.percentage * item.valuation) / 100;
              return (
                <tr className={`${index % 2 === 0 ? "bg-gray-100" : "bg-gray-50"} cursor-pointer`} key={item._id} onClick={() => navigate(`/portfolio/${item._id}`)}>
                  <td className="border hover:bg-gray-200 px-4 py-2 max-w-xs truncate">{item.name}</td>
                  <td className="border hover:bg-gray-200 px-4 py-2 max-w-xs truncate">{item.description}</td>
                  <td className="border hover:bg-gray-200 px-4 py-2 max-w-xs truncate">{item.percentage} %</td>
                  <td className="border hover:bg-gray-200 px-4 py-2 max-w-xs truncate">{formetNumber(item.valuation)} €</td>
                  <td className="border hover:bg-gray-200 px-4 py-2 max-w-xs truncate">{formetNumber(valueOwned)} €</td>
                  <td className="border hover:bg-gray-200 px-4 py-2 max-w-xs truncate">{}</td>
                </tr>
              );
            })}
          </Table>
        </div>
      </div>
    </div>
  );
};

function formetNumber(number) {
  if(!number) return "-";
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

const CreateCompany = ({ onUpdate }) => {
  const [values, setValues] = useState({});
  const [open, setOpen] = useState(false);

  async function onSave() {
    const { data, ok } = await api.post(`/portfolio_company`, values);
    if (!ok) return toast.error("Error creating share");
    toast.success("Share created");
    setOpen(false);
    onUpdate();
  }

  return (
    <>
      <button className="btn btn-primary ml-auto mb-2" onClick={() => setOpen(true)}>
        Create Company
      </button>
      <Modal isOpen={open} className="max-w-xl w-full p-6" onClose={() => setOpen(false)}>
        <div className="p-6">
          <h1 className="text-2xl font-bold mb-6">Create a new company</h1>

          <div className="w-1/2 flex flex-col mb-4">
            <label className="block font-medium text-gray-600 mb-1">Name of the company</label>
            <input
              onChange={(e) => setValues({ ...values, name: e.target.value })}
              className="w-full border border-gray-300 rounded px-2 py-1 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
        </div>
        <div className="flex justify-end">
          <button className="btn btn-primary" onClick={onSave}>
            Create
          </button>
        </div>
      </Modal>
    </>
  );
};

// import React, { useEffect, useState } from "react";
// import api from "../../services/api";

// import Table from "../../components/Table";
// import Paginator from "../../components/Paginator";
// import FolkModal from "./folkModal";

// export default () => {

//   useEffect(() => {
//     fetch();
//   }, [filters]);

//   async function fetch() {
//     setLoading(true);
//     const newFilters = { type: "CONQUEST", ...filters };
//     const { data, total } = await api.post("/folk/search", newFilters);
//     setFolks(data);
//     setTotal(total);
//     setLoading(false);
//   }

//   return (
//     <div className="bg-white h-full">
//       <Search setFilters={setFilters} filters={filters} />
//       <FolkModal onClose={() => setSelectedfolk(null)} contact={selectedFolk} />
//   <div>{total} items</div>

//   );
// };
