import React, { useState } from "react";
import { useParams } from "react-router-dom";

import Questions from "./Questions";
import Answers from "./Answers";

export default () => {
  const [tab, setTab] = useState("questions");

  return (
    <div className="p-4">
      <nav className="bg-white rounded-md flex item-center gap-2 w-full border border-neutral-300 overflow-hidden p-1 mb-4">
        <TabItem tab="questions" title="Questions" setTab={setTab} active={tab === "questions"} />
        <TabItem tab="answers" title="Answers" setTab={setTab} active={tab === "answers"} />
      </nav>

      {tab === "questions" && <Questions />}
      {tab === "answers" && <Answers />}
    </div>
  );
};

const TabItem = ({ tab, title, setTab, active }) => (
  <button onClick={() => setTab(tab)} className={`${active ? "blue-btn" : "transparent-btn"} relative rounded py-2 px-4 flex items-center gap-2`}>
    <div className="flex items-center gap-2">{title}</div>
  </button>
);
