import React, { useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { setUser } from "../../../redux/auth/actions";
import LoadingButton from "../../../components/loadingButton";
import api from "../../../services/api";
import amplitude from "../../../services/amplitude";

const CONTRACTS = {
  INDEFINITCONTRACT: "Indefinite contract",
  MONTHLYFREELANCING: "Monthly freelancing",
  DAILYFREELANCING: "Daily Freelancing",
  INTERSHIP: "Internship/apprenticeship",
};

const Contract = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const applicant = useSelector((state) => state.Auth.user);
  const [contracts, setContracts] = useState(applicant?.contracts || []);

  const onSubmit = async () => {
    try {
      const obj = { contracts };
      const { data, code, ok } = await api.put(`/user/${applicant._id}`, obj);
      if (!ok) return toast.error(code);

      await api.post("/application", { mission_id: applicant.job_id, mission_name: applicant.job_title, jobpost_id: applicant.jobpost_id });
      if (data) dispatch(setUser(data));
      amplitude.logEvent("contract");
      toast.success("Account created successfully");
      navigate("/");
    } catch (e) {
      console.log("e", e);
      toast.error("ERROR");
    }
  };

  const addContract = async (c) => {
    if (contracts.includes(c)) {
      setContracts(contracts.filter((e) => e !== c));
      return;
    }
    setContracts([...contracts, c]);
  };

  return (
    <div className="flex flex-col pt-12 bg-light-white min-h-screen">
      <div className="w-full bg-dush-gray h-1">
        <div className="bg-shade-blue h-1 w-full"></div>
      </div>
      <div className="flex flex-col flex-1 max-w-3xl w-full mx-auto">
        <div className="flex-1 mx-16 pt-10 pb-28">
          <div className="mb-6">
            <div>
              <p className="text-dull-black text-2xl font-semibold">What type of contract are you looking for</p>
              <div className="text-light-grey text-base font-medium mt-2">
                <span>Select the type of contract you’d like to be proposed.</span>
              </div>
            </div>
            <Expertise checked={contracts.includes(CONTRACTS.INDEFINITCONTRACT)} text={CONTRACTS.INDEFINITCONTRACT} onChange={() => addContract(CONTRACTS.INDEFINITCONTRACT)} />
            <Expertise checked={contracts.includes(CONTRACTS.MONTHLYFREELANCING)} text={CONTRACTS.MONTHLYFREELANCING} onChange={() => addContract(CONTRACTS.MONTHLYFREELANCING)} />
            <Expertise checked={contracts.includes(CONTRACTS.DAILYFREELANCING)} text={CONTRACTS.DAILYFREELANCING} onChange={() => addContract(CONTRACTS.DAILYFREELANCING)} />
            <Expertise checked={contracts.includes(CONTRACTS.INTERSHIP)} text={CONTRACTS.INTERSHIP} onChange={() => addContract(CONTRACTS.INTERSHIP)} />
          </div>
        </div>
        <div className="flex w-full md:w-1/2 fixed bottom-0 justify-between mt-20 px-20 py-6 border-t-[1px] border-gray-100 bg-white/10 backdrop-blur">
          <LoadingButton
            onClick={() => navigate(-1)}
            className={`bg-white w-32 border !border-chips-text text-chips-text rounded-large font-medium text-base p-[8px] min-h-[42px]`}>
            Previous
          </LoadingButton>
          <LoadingButton
            disabled={!contracts.length}
            onClick={onSubmit}
            className={`w-32 bg-chips-text hover:bg-shade-sky-blue text-white rounded-large font-medium text-base p-[8px] min-h-[42px]`}>
            Join
          </LoadingButton>
        </div>
      </div>
    </div>
  );
};

const Expertise = ({ checked, text, onChange }) => {
  return (
    <label
      className={`flex items-center justify-between gap-5 py-2.5 px-4 w-ninety ${
        checked ? "border !border-chips-text" : "border !border-gray-bord"
      }  rounded-large mt-3 cursor-pointer`}>
      <div className="flex items-center gap-5 ">
        <input onChange={onChange} checked={checked} className="w-4 h-4" type="checkbox" />
        <span className="text-dull-black text-base font-medium">{text}</span>
      </div>
    </label>
  );
};

export default Contract;
