import React, { useEffect, useState } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

const MONTHS = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
// eslint-disable-next-line react/display-name
export default ({ value = "", onChange, start = 0, name = "date", indexDefaultValue, showArrows = false, disabled = false }) => {
  const [months, setMonths] = useState([]);

  const handleNavigation = (offset) => {
    const d = new Date(value);
    const valueDate = new Date(Date.UTC(d.getUTCFullYear(), d.getUTCMonth(), 1));
    const index = months.findIndex((m) => m.getTime() === valueDate.getTime());
    onChange({ target: { value: months[index + offset], name } });
  };

  useEffect(() => {
    const arr = [];
    const d = new Date();
    for (let i = start; i < 30; i++) {
      arr.push(new Date(Date.UTC(d.getUTCFullYear(), d.getUTCMonth() - i, 1)));
    }
    setMonths(arr);
    if (!value && indexDefaultValue >= 0) onChange({ target: { value: arr[indexDefaultValue], name } });
  }, []);

  function getValue(v) {
    if (!v) return "";
    if (typeof v === "string") return new Date(v).getTime();
    if (typeof v === "number") return new Date(v).getTime();
    return v.getTime();
  }

  return (
    <div className="flex gap-2 items-center">
      <select
        disabled={disabled}
        className="block w-full rounded-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 md:text-sm"
        name={name}
        value={getValue(value)}
        onChange={(e) => {
          if (!e.target.value) return onChange({ target: { value: null, name } });
          onChange({ target: { value: new Date(parseInt(e.target.value)), name } });
        }}>
        <option disabled>Month</option>
        <option key={"all time"} value={""}>
          All time
        </option>
        {months.map((e) => {
          return <option key={e.getTime()} value={e.getTime()}>{`${MONTHS[e.getMonth()]} ${e.getFullYear()}`}</option>;
        })}
      </select>
      {showArrows ? (
        <>
          <div className="flex items-center justify-center w-7 h-7 bg-[#FFFFFF] rounded-full cursor-pointer shadow-sm" onClick={() => handleNavigation(1)}>
            <IoIosArrowBack />
          </div>
          <div className="flex items-center justify-center w-7 h-7 bg-[#FFFFFF] rounded-full cursor-pointer shadow-sm" onClick={() => handleNavigation(-1)}>
            <IoIosArrowForward />
          </div>
        </>
      ) : null}
    </div>
  );
};
