import React, { useState } from "react";
import toast from "react-hot-toast";

import TextEditor from "../../../components/TextEditor";
import Loader from "../../../components/loader";
import api from "../../../services/api";

const View = ({ job, setJob }) => {
  const [refresh, setRefresh] = useState(false);

  async function handleSubmit() {
    try {
      const { ok, data, code } = await api.put(`/job/${job._id}`, job);
      if (!ok) return toast.error(`Error !\n${code}`, { icon: "❌" });
      setJob(data);
      toast.success("Job post updated !");
    } catch (e) {
      console.log(e);
      toast.error("Some Error!", e.code);
    }
  }

  if (!job) return <Loader />;

  return (
    <div className="flex flex-col gap-4 p-2 ">
      <div className="p-12 pb-6 bg-white rounded-2xl">
        <TextEditor
          reload={refresh}
          value={job.description}
          onChange={(e) => {
            setJob({ ...job, description: e.target.value });
          }}
          title="Job description"
          defaultPrompt={defaultPrompt(job)}
        />
        <div className="flex mt-2">
          <button className="bg-[#0560FD] text-[16px] font-medium text-[#fff] py-[12px] px-[22px] rounded-[10px]" onClick={handleSubmit}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

const defaultPrompt = (job) => {
  return `You are a recruiter for our startup studio. Can you write a job offer with thoses informations ?
    
Company description : We are a startup studio located in Paris and Amsterdam. Our team is highly focused on technology and has a strong entrepreneurial mindset. 
We work with 6 to 10 startups every year, providing support in three key areas: development, product, and growth.
Some examples of the projects we're recruiting for include:
Champerché : A bioponic farm in Île de France that aims to bring agriculture as locally as possible while reducing energy consumption. We are working on the R&D side of this project, developing new technologies to make the farm more efficient.
Money walkie : A noebank for young people. This product is highly technical on the technology side and ambitious on the acquisition side. We co-created this product from its early days and have been working to develop it ever since.

Our tech stack is ReactJS,Nodejs, ReactNative, Mongodb, Postgresql, Tailwind

Job title : ${job.title}
    
Job persona : XXXXXXX ( example 2 years experience full stack developpeur. Entrepreneur mindset)
    `;
};

export default View;
