import React, { Fragment, useEffect, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { RiArrowDownSLine, RiArrowUpSLine, RiCheckLine } from "react-icons/ri";

import { useSelector, useDispatch } from "react-redux";
import { setOrganisation } from "../../redux/auth/actions";
import api from "../../services/api";

const Organisations = () => {
  const [organisations, setOrganisations] = useState();

  const { user, organisation } = useSelector((state) => state.Auth);
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      const { data } = await api.get("/organisation");
      setOrganisations(data);
    })();
  }, []);

  useEffect(() => {
    if (!organisations) return;
    const organisationIdLocalStorage = localStorage.getItem("organisation_id");
    if (organisationIdLocalStorage) {
      const organisationLocalStorage = organisations.find((o) => o._id === organisationIdLocalStorage);
      if (organisationLocalStorage) {
        handleOrg(organisationLocalStorage);
        return;
      } else {
        handleOrg(organisations.find((d) => d._id === user.organisation_id));
      }
    } else {
      handleOrg(organisations.find((d) => d._id === user.organisation_id));
    }
  }, [organisations]);

  const handleOrg = (v) => {
    dispatch(setOrganisation(v));
    localStorage.setItem("organisation_id", v._id);
  };

  if (!organisations || !organisation) return <></>;

  return <div />;
  
  return (
    <Select options={organisations} value={organisation} onChange={handleOrg} placeholder="Organisation" getLabel={(o) => o.name} width="w-full md:w-80" getValue={(o) => o._id} />
  );
};



const Select = ({
  options,
  value,
  onChange,
  disabled = false,
  placeholder = "Sélectionner une option",
  width = "w-64",
  position = "left-0 top-full",
  getValue = (o) => o,
  getLabel = (o) => o,
}) => (
  <Listbox value={value || ""} onChange={onChange} disabled={disabled}>
    {({ open }) => (
      <>
        <div className="relative w-full">
          <div className={`w-full flex items-center justify-between border border-slate-300 rounded hover:border-sky-700`}>
            <Listbox.Button className="flex-1 text-left placeholder:text-gray-600 text-sm py-2 px-3">
              {getValue(value) ? (
                <p className="truncate text-left text-sm text-black">{getLabel(value)}</p>
              ) : (
                <p className="truncate text-gray-700 opacity-60 text-left text-sm">{placeholder}</p>
              )}

              <div className="absolute inset-y-0 right-0 flex items-center pr-2">{open ? <RiArrowUpSLine /> : <RiArrowDownSLine />}</div>
            </Listbox.Button>
          </div>

          <Transition show={open} as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Listbox.Options
              className={`absolute z-50 my-1 max-h-64 bg-white ${width} ${position} text-base overflow-y-scroll border border-slate-300 rounded-md bg-white divide-y divide-gray-200`}>
              {options.map((option, i) => (
                <Listbox.Option key={i} value={option} className="select-none list-none">
                  {({ active, selected }) => (
                    <div className={`${active ? "bg-gray-100" : "bg-transparent"} px-3 py-2 flex items-center justify-between`}>
                      {console.log("option", option)}
                      <p className={`block truncate text-sm ${selected ? "font-semibold" : "font-normal"}`}>{getLabel(option)}</p>
                      {selected && <RiCheckLine />}
                    </div>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </>
    )}
  </Listbox>
);

export default Organisations;
