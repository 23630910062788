import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import Modal from "../../../components/modal";
import api from "../../../services/api";

export default ({}) => {
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({});
  const navigate = useNavigate();

  async function onSubmit(e) {
    e.preventDefault();
    try {
      const res = await api.post("/ticket", values);
      if (!res.ok) throw res;
      toast.success("Created!");
      setOpen(false);
      navigate(`/message/${res.data._id}`);
    } catch (err) {
      console.log("ERROR ", err);
    }
  }

  return (
    <div className="">
      <button className="btn btn-primary" onClick={() => setOpen(true)}>
        Create new ticket
      </button>
      <Modal isOpen={open} className="max-w-3xl w-full p-6" onClose={() => setOpen(false)}>
        <form>
          <div className="grid grid-cols-2 gap-4">
            <div className="w-full mb-6">
              <div className="text-sm font-medium mb-2">Name</div>
              <input className="projectsInput block" name="name" value={values.title} onChange={(e) => setValues({ ...values, title: e.target.value })} />
            </div>
            <button className="btn btn-primary w-32" disabled={!values.title} onClick={onSubmit}>
              Create
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
};
