/* eslint-disable react/display-name */
import React, { Fragment, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { IoTrash } from "react-icons/io5";
import { MdUpload } from "react-icons/md";
import Comments from "../Comments";
import SelectBudget from "../SelectBudget";

import api from "../../services/api";
import { readFileAsync } from "../../utils";
import { getMonths } from "../../constants/date";
import DebounceInput from "../DebounceInput";
import Loader from "../loader";
import { CATEGORIES_OBJ } from "../../constants/banks";

const NewInfo = ({ bank, setBank }) => {
  const [projects, setProjects] = useState([]);
  const [budgets, setBudgets] = useState([]);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { ok: okP, data: p } = await api.post("/project/search", { status: "active" });
        if (!okP) throw new Error("Error while fetching projects");
        setProjects(p.projects.sort((a, b) => (a.name || "").localeCompare(b.name)));

        const { ok: okU, data: u } = await api.post("/user/search", { role: ["admin", "normal"] });
        if (!okU) throw new Error("Error while fetching users");
        setUsers(u.users.sort((a, b) => (a.name || "").localeCompare(b.name)));
      } catch (error) {
        console.error(error);
        toast.error("Error while fetching project and users");
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (!bank.projectId) return;
    const fetchData = async () => {
      try {
        const { ok: okB, data: b } = await api.post("/budget/search", { projectId: bank.projectId });
        if (!okB) throw new Error("Error while fetching budget");
        setBudgets(b.sort((a, b) => (a.name || "").localeCompare(b.name)));
        // const { ok: okI, data: i } = await api.post("/invoice/search", { projectId: bank.projectId });
        // if (!okI) throw new Error("Failed to fetch invoice");
        // setBudgets(b.sort((a, b) => (a.name || "").localeCompare(b.name)));
      } catch (error) {
        console.error(error);
        toast.error("Error while fetching banks");
      }
    };
    fetchData();
  }, [bank.projectId]);

  const handleChange = async (values) => {
    try {
      const category = Object.keys(CATEGORIES_OBJ).find((key) => CATEGORIES_OBJ[key].includes(values.sub_category));
      if (category) {
        values.category = category;
      }

      const project = projects.find((e) => values.projectId === e._id);
      if (project) {
        values.projectName = project.name;
        values.projectType = project.type;
      }
      const budget = budgets.find((e) => values.budgetId === e._id);
      if (budget) {
        values.budgetName = budget.name;
      }

      const user = users.find((e) => values.walletUserId === e._id);
      if (user) {
        values.walletUserName = user.name;
        if (!bank.walletUserId) {
          const { ok } = await api.post(`/wallet_transaction`, {
            userId: user._id,
            userName: user.name,
            amount: bank.amount,
            description: bank.description,
            bankId: bank._id,
          });
          if (!ok) throw new Error("Error while creating wallet transaction");
          toast.success("Wallet transaction created");
        }
      }

      let text = "";
      if (values.sub_category !== bank.sub_category) text += `Subcategory changed from ${bank.sub_category} to ${values.sub_category}`;
      else if (values.status !== bank.status) text += `Status changed from ${bank.status} to ${values.status}`;
      else if (values.division !== bank.division) text += `Division changed from ${bank.division} to ${values.division}`;
      else if (values.month !== bank.month) text += `Month changed from ${bank.month} to ${values.month}`;
      else if (values.projectId !== bank.projectId) text += `Project changed from ${bank.projectName} (${bank.projectId}) to ${values.projectName} (${values.projectId})`;
      else if (values.budgetId !== bank.budgetId) text += `Budget changed from ${bank.budgetName} (${bank.budgetId}) to ${values.budgetName} (${values.budgetId})`;
      else if (values.walletUserId !== bank.walletUserId)
        text += `Wallet changed from ${bank.walletUserName} (${bank.walletUserId}) to ${values.walletUserId} (${values.budgetId})`;
      else if (values.country !== bank.country) text += `Country changed from ${bank.country} to ${values.country}`;
      else if (values.tax !== bank.tax) text += `Tax changed from ${bank.tax} to ${values.tax}`;
      else if (values.files[0] !== bank.files[0]) text += `File changed from ${bank.files[0]} to ${values.files[0]}`;
      await api.post(`/bank/log/${bank._id}`, { text });

      const { ok, data } = await api.put(`/bank/${bank._id}`, values);
      if (!ok) throw new Error("Error while updating bank");

      setBank(data);
      toast.success("Bank updated");
    } catch (error) {
      console.log(error);
      toast.error("Error while updating bank");
    }
  };

  return (
    <div className="grid grid-cols-4 gap-4">
      <div className="space-y-2 col-span-4">
        <label className="text-sm font-semibold">Description</label>
        <DebounceInput type="text" className="input" value={bank.description || ""} onChange={(e) => handleChange({ ...bank, description: e.target.value })} />
      </div>
      <div className="space-y-2">
        <label className="text-sm font-semibold">Category</label>
        <select className="select" value={bank.sub_category || ""} onChange={(e) => handleChange({ ...bank, sub_category: e.target.value })}>
          <option value="">Select a category</option>
          {Object.entries(CATEGORIES_OBJ).map(([k, v], i) => (
            <Fragment key={i}>
              <optgroup label={k} />
              {v.map((item, j) => (
                <option key={j} value={item}>
                  &nbsp;&nbsp;&nbsp;&nbsp;{item}
                </option>
              ))}
            </Fragment>
          ))}
        </select>
      </div>
      <div className="space-y-2">
        <label className="text-sm font-semibold">Status</label>
        <select className="select" value={bank.status || ""} onChange={(e) => handleChange({ ...bank, status: e.target.value })}>
          <option value="">Select a status</option>
          <option value="TODO">To do</option>
          <option value="DONE">Done</option>
        </select>
      </div>
      <div className="space-y-2">
        <label className="text-sm font-semibold">Division</label>
        <select className="select" value={bank.division || ""} onChange={(e) => handleChange({ ...bank, division: e.target.value })}>
          <option value="">Select a division</option>
          <option value="Global">Global</option>
          <option value="Paris">Paris</option>
          <option value="Amsterdam">Amsterdam</option>
          <option value="Barcelone">Barcelone</option>
        </select>
      </div>
      <div className="space-y-2">
        <label className="text-sm font-semibold">Date</label>
        <select className="select" value={new Date(bank.month)?.getTime() || ""} onChange={(e) => handleChange({ ...bank, month: new Date(parseInt(e.target.value)) })}>
          <option value="">Select a month</option>
          {getMonths().map((e, i) => (
            <option key={i} value={e.getTime()}>
              {e.toLocaleDateString("en-US", { year: "numeric", month: "long" })}
            </option>
          ))}
        </select>
      </div>
      <div className="space-y-2">
        <label className="text-sm font-semibold">Project</label>
        <select value={bank.projectId} onChange={(e) => handleChange({ ...bank, projectId: e.target.value })} className="select">
          <option value="">Select project</option>
          {projects.map((item) => (
            <option key={item._id} value={item._id}>
              {item.name}
            </option>
          ))}
        </select>
      </div>
      <div className="space-y-2">
          <label className="text-sm font-semibold">Budget</label>
          <SelectBudget
            projectId={bank.projectId}
            value={{ name: bank.budgetName, _id: bank.budgetId }}
            onChange={async (e) => {
              const res = await api.put(`/bank/${bank._id}`, { budgetId: e._id, budgetName: e.name });
              if (res.code) return toast.error(res.code);
              toast.success("Updated!");
            }}
          />
      </div>
      {/* {bank.budgetId ? (
        <div className="space-y-2">
          <label className="text-sm font-semibold">Invoice</label>
          <select value={bank.budgetId} onChange={(e) => handleChange({ ...bank, budgetId: e.target.value })} className="select">
            <option value="">Select budget</option>
            {budgets.map((item) => (
              <option key={item._id} value={item._id}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
      ) : (
        <div />
      )} */}
      {/* Assingned to label (bank.userName) */}
      <div className="space-y-2">
        <label className="text-sm font-semibold">Assigned to</label>
        <select value={bank.userName} onChange={(e) => handleChange({ ...bank, userName: e.target.value })} className="select">
          <option value={bank.userId || ""} selected>{bank.userName || "Select User"}</option>
          {users.map((item) => (
            <option key={item._id} value={item._id}>
              {item.name}
            </option>
          ))}
        </select>
      </div>
      <div className="space-y-2">
        <label className="text-sm font-semibold">From wallet</label>
        <select value={bank.walletUserId} onChange={(e) => handleChange({ ...bank, walletUserId: e.target.value })} className="select">
          <option value={bank.walletUserId || ""} selected>{bank.walletUserName || "Select User"}</option>
          {users.map((item) => (
            <option key={item._id} value={item._id}>
              {item.name}
            </option>
          ))}
        </select>
      </div>

      <div className="space-y-2">
        <label className="text-sm font-semibold">Country</label>
        <select className="select" value={bank.country || ""} onChange={(e) => handleChange({ ...bank, country: e.target.value })}>
          <option value="">Select a country</option>
          <option value="France">France</option>
          <option value="Spain">Spain</option>
          <option value="Netherland">Netherland</option>
          <option value="Other">Other</option>
        </select>
      </div>
      <div className="space-y-2">
        <label className="text-sm font-semibold">Tax</label>
        <DebounceInput type="number" className="input" value={bank.tax || ""} onChange={(e) => handleChange({ ...bank, tax: e.target.value })} />
      </div>
      <div className="col-span-2" />
      <div className="space-y-2 col-span-2">
        <label className="text-sm font-semibold">File</label>
        <FileInput folder="/bank" name="files" value={bank.files[0]} onChange={(e) => handleChange({ ...bank, files: [e.target.value] })} />
      </div>
      {/* comments section */}
      <div className="col-span-4">
        <div className="mt-2 bg-neutral-300 col-span-4 h-[1px]" />
        <h3 className="pt-2 text-sm font-semibold space-y-1">Comments</h3>
        <Comments
        value={bank.comments}
        onAdd={async (e) => {
          const { data } = await api.post(`/bank/comment/${bank._id}`, { text: e });
          setBank(data);
          toast.success("Comment added!");
        }}
        />
      </div>
    </div>
  );
};

// folder should look like "/myfolder"
const FileInput = ({ folder, ...props }) => {
  const [loading, setLoading] = useState(false);

  const handleDeleteFile = async () => {
    props.onChange({ target: { value: null, name: props.name } });
  };

  const handleChange = async (e) => {
    e.persist();
    setLoading(true);
    try {
      const file = e.target.files[0];
      if (!file) return;

      const rawBody = await readFileAsync(file);
      const { ok, data } = await api.post(`/file`, { file: { rawBody, name: file.name }, folder });
      if (!ok) throw new Error("Error while uploading file");

      props.onChange({ target: { value: data, name: props.name } });
    } catch (error) {
      console.error(error);
      toast.error("Error while uploading file");
    }
    setLoading(false);
  };

  if (props.value)
    return (
      <div className="flex items-center gap-4 justify-between">
        <a href={props.value} target="_blank" rel="noreferrer" className="underline text-sm truncate flex items-center gap-2">
          <span>{props.value}</span>
          <BiDotsVerticalRounded />
        </a>
        <button className="flex items-center gap-2" onClick={handleDeleteFile}>
          <IoTrash />
        </button>
      </div>
    );

  return (
    <div className="relative input">
      <label htmlFor={props.name} className="flex items-center justify-between gap-4 h-full w-full">
        <span className="text-sm">Upload</span>
        {loading ? <Loader size="small" /> : <MdUpload />}
      </label>
      <input id={props.name} type="file" onChange={handleChange} disabled={loading} />
    </div>
  );
};

export default NewInfo;
