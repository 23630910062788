import RichTextEditorSun from "../../../../components/RichTextEditor";
import { applyFontClassToHtml } from "../utils";

const Text = ({ values, editing, onChange }) => {
  if (editing) return <TextForm values={values} onChange={onChange} />;

  return <div className="text-sm font-poppins rich-text" dangerouslySetInnerHTML={{ __html: applyFontClassToHtml(values.description) }}></div>;
};

const TextForm = ({ values, onChange }) => {
  return (
    <RichTextEditorSun
      buttonList={[
        ["undo", "redo"],
        ["font", "fontSize", "formatBlock"],
        ["paragraphStyle", "blockquote"],
        ["bold", "underline", "italic", "strike", "subscript", "superscript"],
        ["fontColor", "hiliteColor"],
        ["align", "list", "lineHeight"],
        ["outdent", "indent"],

        ["table", "horizontalRule", "link", "image", "video"],
        ["removeFormat"],
      ]}
      values={values.description}
      onChange={(e) => {
        console.log(e);
        onChange({ ...values, description: e });
      }}
    />
  );
};

export default Text;
